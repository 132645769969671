import { TaskAltOutlined } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiGatewayAxiosInstance } from "../../utils/axios";
import FetchingSpinner from "../components/FetchingSpinner";
import AlertMessageComponent from "../components/AlertMessageComponent";
import SendOTP from "../../components/auth/SendOTP";

export default function VerifyMobileNumber() {
    const navigate = useNavigate()
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [clientData, setClientData] = useState({});
    const [pageLoading, setPageLoading] = useState(false);
    const [validToken, setValidToken] = useState(false);

    const [otpVerified, setOTPVerified] = useState(false)
    const [otpSent, setOTPsent] = useState(false);

    const [mobileNumberOTP, setMobileNumberOTP] = useState("")
    const [otpVerificationLoading, setOTPVerificationLoading] = useState(false);

    const [otpLoading, setOTPLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");
    let param = useParams();
    const id = param.id;
    const email = param.email;
    const validateEmail = async () => {
        try {
            setPageLoading(true);
            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/validate-number-verification-link`, { params: { id } });

            if (response.status === 200) {
                setPageLoading(false);
                // setIsTokenValid(true);
                setClientData(response.data.data);
                // setTimeout(() => {
                // updateEmailAddress({ id: response.data.data._id });
                // }, 3000);
            }
        } catch (error) {
            setPageLoading(false);
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    // const updateEmailAddress = async ({ id }) => {
    //     try {
    //         // setPageLoading(true);
    //         const response = await apiGatewayAxiosInstance.post(`/user_management/clients/update-verified-email`, { id, email });
    //         if (response.status === 200) {
    //             setIsTokenValid(true);
    //             setPageLoading(false);
    //             // setClientData(response.data.data);
    //             // setTimeout(() => {
    //             // updateEmailAddress({ id: response.data.data._id });
    //             // }, 3000);
    //         }
    //     } catch (error) {
    //         let errorMessage = "Server not reachable.";

    //         if (error.response) {
    //             errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
    //         }
    //         setErrorResponse(errorMessage);
    //     }
    // };





    const sendOTPTomobileNumber = async () => {
        try {
            setOTPLoading(true);

            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/send-OTP`,
                { params: { orgId: clientData.orgId } }
            )

            if (response.status === 200) {
                setOTPsent(true);

            }

            setOTPLoading(false)
        } catch (error) {
            // setPageLoading(false)
            let errorMessage = "Server not reachable.";


            if (error.response) {

                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
            setOTPLoading(false)
        }
    }
    const verifyOTPTomobileNumber = async () => {
        try {
            setOTPVerificationLoading(true);

            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/verify-OTP`,
                { params: { orgId: clientData.orgId, mobileNumberOTP } }
            )
            if (response.status === 200) {
                setOTPVerified(true);
                setOTPVerificationLoading(false);
                navigate('/auth/login');


            }

        } catch (error) {
            setOTPVerificationLoading(false)
            let errorMessage = "Server not reachable.";


            if (error.response) {

                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }
    }
    useEffect(() => {
        validateEmail();
    }, []);


    return (
        <div>
            {pageLoading ? (
                <FetchingSpinner />
            ) : clientData && !clientData?.mobileNumberVerified
                ? (


                    !otpVerified ?
                        <SendOTP
                            orgUserId={clientData?._id}
                            clientData={clientData}
                            sendOTPTomobileNumber={sendOTPTomobileNumber}
                            mobileNumberOTP={mobileNumberOTP}
                            setMobileNumberOTP={setMobileNumberOTP}
                            verifyOTPTomobileNumber={verifyOTPTomobileNumber}
                            otpSent={otpSent}
                            otpLoading={otpLoading}
                            otpVerificationLoading={otpVerificationLoading}
                        /> :
                        <>
                            {/* <Grid align='center'> */}
                            {/* <Typography
                        style={{ color: 'green' }}
                        component='h2'
                        variant='h4'
                    >
                        Congratulations
                    </Typography> */}
                            <Grid style={{ color: "green" }} align='center'>
                                <IconButton style={{ color: "green" }}>
                                    <TaskAltOutlined />
                                </IconButton>
                                <Typography component='h2' variant='h4'>
                                    Mobile Number Verified Successfully
                                </Typography>
                                {/* </Grid> */}
                            </Grid></>
                ) : (
                    <Grid style={{ color: "green" }} align='center'>

                        <Typography component='h2' variant='h4'>
                            Mobile Number Verified
                        </Typography>
                        {/* </Grid> */}
                    </Grid>
                )}
            <AlertMessageComponent
                message={errorResponse}
                fieldName={"errorResponse"}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />
        </div>
    );
}
