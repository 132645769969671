
export const cssClass = {
    'pin-bot-form-textarea': "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus: text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
    'btn-primary': "inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out",
    'card-title': "text-gray-900 text-xl leading-tight font-medium mb-2",
    'card-body': "text-gray-700 text-base mb-4",
    'pin-bot-form-input': "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
    'pin-bot-form-select': "form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
    'pin-bot-sidebar-item': "block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border-2 border-grey rounded m-1 p-2 mt-2 drop-shadow",
    'pin-bot-start-card': "pr-2 pl-2 rounded-t",
    'pin-bot-form-file': "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
    'pin-bot-form-label': "form-label inline-block mb-2 text-gray-700",
}