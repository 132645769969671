import React, { useCallback, useState, useEffect } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { MasterCardLayout } from './MasterCardLayout';
import { ContextMenu } from '../ContextMenu';

export function MediaNode(props) {
    const { id, data, selected } = props;
    const methods = data.methods;
    return (
        <MasterCardLayout
            title={data.title}
            tailwindClass={data.cardTailwindStyle}
            key={`media-master-card-${id}`}
            startNode={data?.isStartCard}
            nodeData={data}
        >
            {
                selected === true &&
                // Show context menu
                <ContextMenu
                    onDuplicate={(event) => { methods.onDuplicate(event, id) }}
                    onDelete={methods.onDelete}
                    nodeId={id}
                    methods={methods}
                    isStartCard={data.isStartCard}
                />
            }
            <div className="text-updater-node" id={`${id}`}>
                <Handle type="target" className='target-handle' position={Position.Left} />
                <div style={{ margin: '10px', fontSize: '10px' }}>
                    {/* {
                        data && data.contents && data.contents.images.map((images, idx) => {
                            return (
                                <div className="" key={`images_${idx}`}>

                                </div>
                            )
                        })
                    } */}
                </div>
                <Handle type="source" className='source-handle' position={Position.Right} />
            </div>
        </MasterCardLayout >
    );
}
