import React from "react";
import {
    Box,
    Card,
    FormControl,
    TextField,
    Typography,
    CardContent,
    IconButton,
    Grid,
    Chip,
    Tooltip,
    Breadcrumbs,
    Link,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BrandStatus } from "../../../config";




export default function ViewBrandDetails({ setShowViewBrandForm, brand }) {
    const onClickCancelButton = () => {
        setShowViewBrandForm(false);
    };

    return (
        <>
            <Grid container mb={4} className='listHeader'>
                <Grid
                    item
                    md={12}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <IconButton
                        color="secondary"
                        onClick={() => onClickCancelButton()}
                        size="medium"
                    >
                        <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                    </IconButton>
                    <Grid role="presentation" display={"flex"}>
                        <Breadcrumbs aria-label="breadcrumb" fontSize="large" sx={{ display: "flex !important" }}>
                            <Link
                                underline="hover"
                                color="inherit"
                                className='breadcrumbItem'
                                // href="/rcs-management/manage-bots/"
                                onClick={onClickCancelButton}
                            >
                                Brands
                            </Link>

                            <Grid container>
                                <Grid item display={'flex'} >
                                    <Typography
                                        className='breadcrumbItem'
                                        color="text.primary"
                                    >
                                        &nbsp; {brand?.brandName}
                                    </Typography>
                                </Grid>
                                <Grid item marginX={5} justifyContent={'space-between'}>
                                    <Chip
                                        variant="outlined"
                                        className="chip"
                                        label={
                                            brand.brandStatus.replaceAll("_", " ").toUpperCase()
                                        }
                                        color={
                                            (brand.brandStatus.toUpperCase() === BrandStatus.NotVerified || brand.brandStatus.toUpperCase().split(" ").join("") === "NOTVERIFIED") ? "error" : "success"
                                        }
                                    />
                                    {(brand.brandStatus.toUpperCase() === BrandStatus.NotVerified || brand.brandStatus.toUpperCase().split(" ").join("") === "NOTVERIFIED") &&
                                        <Tooltip title={<Typography style={{ margin: "0px", padding: "2px" }}>Brand is not verified. Please complete bot verification to verify your brand details.</Typography>}>
                                            <InfoOutlinedIcon sx={{ opacity: "50%", marginX: "5px!important", cursor: "pointer" }} />
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        </Breadcrumbs>
                    </Grid>

                </Grid>
            </Grid>


            <Card className="border border-secondary-50 m-2 p-4">
                <Grid sx={{ display: "flex", paddingBottom: 3 }}>
                    <Typography variant="h3" fontWeight={"bold"}>
                        Brand Details
                    </Typography>
                </Grid>

                <Box paddingBottom={2} display={"flex"} gap={5}>
                    {brand?.brandName && (
                        <FormControl className="w-50">
                            <Typography variant="h6">Brand Name</Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                name="brandName"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.brandName}
                            />
                        </FormControl>
                    )}
                    {brand?.industryType && (
                        <FormControl className="w-50">
                            <Typography variant="h6">Industry Type</Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.industryType}
                            />
                        </FormControl>
                    )}
                </Box>
                <Box paddingBottom={2} display={"flex"} gap={5}>
                    {brand?.brandLogo?.url && (
                        <FormControl className="w-50">
                            <Typography variant="h6">Brand Logo </Typography>

                            <img src={brand.brandLogo.url} height={100} width={100} />
                        </FormControl>
                    )}

                </Box>
                <Box paddingBottom={2} display={"flex"} gap={5}>
                    {brand?.contactPersonDetails?.designation && (
                        <FormControl className="w-50">
                            <Typography variant="h6">Designation </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.contactPersonDetails?.designation}
                            />
                        </FormControl>
                    )}

                    {brand?.officialBrandWebsite && (
                        <FormControl className="w-50">
                            <Typography variant="h6">
                                Official Website
                            </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                name="brandName"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.officialBrandWebsite}
                            />
                        </FormControl>
                    )}
                </Box>

                {brand?.companyAddressDetails && (
                    <Grid sx={{ display: "flex", paddingBlock: 3 }}>
                        <Typography variant="h3" fontWeight={"bold"}>
                            Company Address
                        </Typography>
                    </Grid>
                )}
                <Box paddingBottom={2} display={"flex"} gap={5}>
                    {brand?.companyAddressDetails?.addressLine1 && (
                        <FormControl className="w-50">
                            <Typography variant="h6">
                                Company Address 1{" "}
                            </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                name="brandName"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={
                                    brand?.companyAddressDetails?.addressLine1
                                }
                            />
                        </FormControl>
                    )}
                    {brand?.companyAddressDetails?.addressLine2 && (
                        <FormControl className="w-50">
                            <Typography variant="h6">
                                Company Address 2{" "}
                            </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={
                                    brand?.companyAddressDetails?.addressLine2
                                }
                            />
                        </FormControl>
                    )}
                </Box>
                <Box paddingBottom={2} display={"flex"} gap={5}>
                    {brand?.companyAddressDetails?.country && (
                        <FormControl className="w-50">
                            <Typography variant="h6">Country</Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                name="brandName"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.companyAddressDetails?.country}
                            />
                        </FormControl>
                    )}
                    {brand?.companyAddressDetails?.state && (
                        <FormControl className="w-50">
                            <Typography variant="h6">State </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.companyAddressDetails?.state}
                            />
                        </FormControl>
                    )}
                </Box>
                <Box paddingBottom={2} display={"flex"} gap={5}>
                    {brand?.companyAddressDetails?.city && (
                        <FormControl className="w-50">
                            <Typography variant="h6">City </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                name="brandName"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.companyAddressDetails?.city}
                            />
                        </FormControl>
                    )}
                </Box>
                {brand?.contactPersonDetails && (
                    <Grid sx={{ display: "flex", paddingBlock: 3 }}>
                        <Typography variant="h3" fontWeight={"bold"}>
                            Contact Details
                        </Typography>
                    </Grid>
                )}
                <Box paddingBottom={2} display={"flex"} gap={5}>
                    {brand?.contactPersonDetails?.firstName && (
                        <FormControl className="w-50">
                            <Typography variant="h6">First Name </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                name="brandName"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.contactPersonDetails?.firstName}
                            />
                        </FormControl>
                    )}
                    {brand?.contactPersonDetails?.lastName && (
                        <FormControl className="w-50">
                            <Typography variant="h6">Last Name </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.contactPersonDetails?.lastName}
                            />
                        </FormControl>
                    )}
                </Box>
                <Box paddingBottom={2} display={"flex"} gap={5}>
                    {brand?.contactPersonDetails?.emailId && (
                        <FormControl className="w-50">
                            <Typography variant="h6">Email Id </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                name="brandName"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brand?.contactPersonDetails?.emailId}
                            />
                        </FormControl>
                    )}
                    {brand?.contactPersonDetails?.mobileNumber && (
                        <FormControl className="w-50">
                            <Typography variant="h6">Mobile Number </Typography>

                            <TextField
                                disabled
                                className="mt-2"
                                fullWidth
                                varient="outlined"
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={
                                    brand?.contactPersonDetails?.mobileNumber
                                }
                            />
                        </FormControl>
                    )}
                </Box>
            </Card>
            {/* </CardContent> */}
            {/* <CardActions sx={{ padding: "0px" }}>



                </CardActions> */}
            {/* </Card> */}
        </>
    );
}
