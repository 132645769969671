import { Box, Button, Card, Divider, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ImagePlaceHolder from "../../../../assets/images/image-placeholder.jpg"
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';


import SuggestionSimulatorComponent from "./SuggestionSimulatorComponent";
import { useState } from "react";

export default function RichCardCarouselDetailsComponent({ richCardCarouselDetails }) {
    const [index, setIndex] = useState(0);




    return (
        <div style={{ minWidth: "100%", display: "flex" }} >



            {richCardCarouselDetails.cardsList[index] &&

                <Grid item
                    sx={{
                        minWidth: richCardCarouselDetails.cardWidth === "MEDIUM_WIDTH" ? "70%" : "50%",
                        maxWidth: richCardCarouselDetails.cardWidth === "MEDIUM_WIDTH" ? "70%" : "50%"
                    }}


                    minHeight={"30vh"} maxHeight={"40vh"} padding={2}>
                    <Grid item display={"flex"} justifyContent={"start"} alignItems={"center"} style={{ position: "absolute", top: "37%", left: "3%" }}>
                        {index > 0 && <Box sx={{ zIndex: "1", height: "1.5rem", minWidth: "1.5rem", maxWidth: "1.5rem", backgroundColor: "white", border: "3px solid white", borderRadius: "50px" }} onClick={() => { setIndex(index - 1) }} ><svg focusable="false" aria-hidden="true" viewBox="0 0 27 27" data-testid="KeyboardArrowLeftIcon"><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6z"></path></svg></Box>}
                    </Grid>
                    <Card sx={{ height: "100%", backgroundColor: "#e9e6e6", borderRadius: "10px" }} >



                        <Box >
                            {richCardCarouselDetails.cardsList[index].cardMedia.type === "video/mp4" ? <>

                                {/* {(richCardCarouselDetails.cardWidth !== "MEDIUM_WIDTH" && richCardCarouselDetails.mediaHeight !== "MEDIUM_HEIGHT") &&
                                    <PlayCircleOutlinedIcon style={{ position: "absolute", left: "14%", top: "20%", fontSize: "80px" }} />}

                                {(richCardCarouselDetails.cardWidth === "MEDIUM_WIDTH" && richCardCarouselDetails.mediaHeight !== "MEDIUM_HEIGHT") &&
                                    <PlayCircleOutlinedIcon style={{ position: "absolute", left: "24%", top: "20%", fontSize: "80px" }} />}

                                {(richCardCarouselDetails.cardWidth !== "MEDIUM_WIDTH" && richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT") &&
                                    <PlayCircleOutlinedIcon style={{ position: "absolute", left: "13%", top: "22%", fontSize: "90px" }} />}

                                {(richCardCarouselDetails.cardWidth === "MEDIUM_WIDTH" && richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT") &&
                                    <PlayCircleOutlinedIcon style={{ position: "absolute", left: "21%", top: "22%", fontSize: "100px" }} />} */}

                                {(richCardCarouselDetails.cardsList[index].cardThumbnail?.url !== null && richCardCarouselDetails.cardsList[index].cardThumbnail?.url !== "") ?
                                    <img
                                        src={richCardCarouselDetails.cardsList[index].cardThumbnail?.url}

                                        style={{
                                            height: richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT" ? "150px" : "100px",
                                            width: "100%"
                                        }
                                        }
                                    /> : <img src={ImagePlaceHolder} style={{ height: "100px", width: "100%" }} />
                                }
                            </> :
                                <>
                                    {(richCardCarouselDetails.cardsList[index].cardMedia.url !== null && richCardCarouselDetails.cardsList[index].cardMedia.url !== "") ?
                                        <img
                                            src={richCardCarouselDetails.cardsList[index].cardMedia.url}

                                            style={{
                                                height: richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT" ? "150px" : "100px",
                                                width: "100%"
                                            }
                                            }
                                        /> : <img src={ImagePlaceHolder} style={{ height: "100px", width: "100%" }} />
                                    }
                                </>

                            }
                        </Box>

                        <Box padding={1}>
                            <Typography variant="h6" fontWeight={"bold"} sx={{ lineBreak: "auto", wordBreak: "break-all" }} >

                                {richCardCarouselDetails.cardsList[index].cardTitle && richCardCarouselDetails.cardsList[index].cardTitle.length > 38 ? `${richCardCarouselDetails.cardsList[index].cardTitle.substr(0, 25)}...` : richCardCarouselDetails.cardsList[index].cardTitle}

                            </Typography>
                            <Typography variant="body2" sx={{ lineBreak: "auto", wordBreak: "break-all" }} >
                                {/* {richCardCarouselDetails.cardsList[index].cardDescription} */}
                                {richCardCarouselDetails.cardsList[index].cardDescription.length > 25 ? `${richCardCarouselDetails.cardsList[index].cardDescription.substr(0, 25)}...` : richCardCarouselDetails.cardsList[index].cardDescription}



                            </Typography>

                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>


                                {richCardCarouselDetails.cardsList[index].suggestionsList.map((suggestions) => (
                                    <Box marginTop={1}>
                                        <SuggestionSimulatorComponent suggestion={suggestions} />
                                        <Divider />
                                    </Box>
                                ))}
                            </Box>

                        </Box>



                    </Card>
                </Grid>}


            {richCardCarouselDetails.cardsList[index + 1] &&

                <Grid item
                    sx={{
                        minWidth: richCardCarouselDetails.cardWidth === "MEDIUM_WIDTH" ? "70%" : "50%",
                        maxWidth: richCardCarouselDetails.cardWidth === "MEDIUM_WIDTH" ? "70%" : "50%"
                    }}


                    minHeight={"30vh"} maxHeight={"40vh"} padding={2}>
                    <Grid item display={"flex"} justifyContent={"end"} alignItems={"center"} style={{ position: "absolute", top: "37%", right: "3%" }}>
                        <Box sx={{ zIndex: "1", height: "1.5rem", minWidth: "1.5rem", maxWidth: "1.5rem", backgroundColor: "white", border: "3px solid white", borderRadius: "50px" }} onClick={() => { setIndex(index + 1) }} >
                            <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="KeyboardArrowRightIcon"><path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z"></path></svg>
                        </Box>
                    </Grid>
                    <Card sx={{ height: "100%", backgroundColor: "#e9e6e6", borderRadius: "10px" }} >



                        <Box >

                            {/* {(richCardCarouselDetails.cardsList[index + 1].cardMedia.url !== null && richCardCarouselDetails.cardsList[index + 1].cardMedia.url !== "") ?
                                <img
                                    src={richCardCarouselDetails.cardsList[index + 1].cardMedia.url}

                                    style={{
                                        height: richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT" ? "150px" : "100px",
                                        width: "100%", aspectRatio: 3 / 1
                                    }
                                    }
                                /> : <img src={ImagePlaceHolder} style={{ height: "100px", width: "100%" }} />
                            } */}

                            {richCardCarouselDetails.cardsList[index + 1].cardMedia.type === "video/mp4" ? <>

                                {/* {(richCardCarouselDetails.cardWidth !== "MEDIUM_WIDTH" && richCardCarouselDetails.mediaHeight !== "MEDIUM_HEIGHT") &&
                                    <PlayCircleOutlinedIcon style={{ position: "absolute", left: "62%", top: "20%", fontSize: "80px" }} />}

                                {(richCardCarouselDetails.cardWidth === "MEDIUM_WIDTH" && richCardCarouselDetails.mediaHeight !== "MEDIUM_HEIGHT") &&
                                    <PlayCircleOutlinedIcon style={{ position: "absolute", left: "90%", top: "20%", fontSize: "80px"}} />}

                                {(richCardCarouselDetails.cardWidth !== "MEDIUM_WIDTH" && richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT") &&
                                    <PlayCircleOutlinedIcon style={{ position: "absolute", left: "13%", top: "22%", fontSize: "90px" }} />}

                                {(richCardCarouselDetails.cardWidth === "MEDIUM_WIDTH" && richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT") &&
                                    <PlayCircleOutlinedIcon style={{ position: "absolute", left: "21%", top: "22%", fontSize: "100px" }} />} */}

                                {(richCardCarouselDetails.cardsList[index + 1].cardThumbnail?.url !== null && richCardCarouselDetails.cardsList[index + 1].cardThumbnail?.url !== "") ?
                                    <img
                                        src={richCardCarouselDetails.cardsList[index + 1].cardThumbnail?.url}

                                        style={{
                                            height: richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT" ? "150px" : "100px",
                                            width: "100%",

                                        }
                                        }
                                    /> : <img src={ImagePlaceHolder} style={{ height: "100px", width: "100%" }} />
                                }
                            </> :
                                <>
                                    {(richCardCarouselDetails.cardsList[index + 1].cardMedia.url !== null && richCardCarouselDetails.cardsList[index + 1].cardMedia.url !== "") ?
                                        <img
                                            src={richCardCarouselDetails.cardsList[index + 1].cardMedia.url}

                                            style={{
                                                height: richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT" ? "150px" : "100px",
                                                width: "100%"
                                            }
                                            }
                                        /> : <img src={ImagePlaceHolder} style={{ height: "100px", width: "100%" }} />
                                    }
                                </>

                            }
                        </Box>

                        <Box padding={1}>
                            <Typography variant="h6" fontWeight={"bold"} sx={{ lineBreak: "auto", wordBreak: "break-all" }} >

                                {richCardCarouselDetails.cardsList[index + 1].cardTitle && richCardCarouselDetails.cardsList[index + 1].cardTitle.length > 38 ? `${richCardCarouselDetails.cardsList[index + 1].cardTitle.substr(0, 25)}...` : richCardCarouselDetails.cardsList[index + 1].cardTitle}

                            </Typography>
                            <Typography variant="body2" sx={{ lineBreak: "auto", wordBreak: "break-all" }} >
                                {/* {richCardCarouselDetails.cardsList[index+1].cardDescription} */}
                                {richCardCarouselDetails.cardsList[index + 1].cardDescription.length > 25 ? `${richCardCarouselDetails.cardsList[index + 1].cardDescription.substr(0, 25)}...` : richCardCarouselDetails.cardsList[index + 1].cardDescription}



                            </Typography>

                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>


                                {richCardCarouselDetails.cardsList[index + 1].suggestionsList.map((suggestions) => (
                                    <Box marginTop={1}>
                                        <SuggestionSimulatorComponent suggestion={suggestions} />
                                        <Divider />
                                    </Box>
                                ))}
                            </Box>

                        </Box>



                    </Card>
                </Grid>}
        </div>
    )
}



export function videoPlayLogo({ }) {
    return (
        <PlayCircleOutlinedIcon style={{ position: "absolute", left: "17%", top: "20%", fontSize: "60px" }} />
    )
}


