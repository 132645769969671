import React from 'react'
import PdfIcon from '../../../vendor/pdf.png';
import { Box, Button, Grid, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';


export default function FileMessageSimulator({ documentData }) {
    return (
        <div>{true &&
            <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <Box sx={{ border: "1px solid grey", borderRadius: "10px", paddingInline: 2 }}>
                    <Grid container>
                        <Grid item md={2} padding={1} alignItems={'center'} justifyContent={'center'} display={'flex'} >
                            <img height={"25px"} style={{ alignItems: "center" }} src={PdfIcon} />
                        </Grid>
                        {documentData?.fileUrl &&
                            <Grid item md={10} padding={1} sx={{ display: "flex", alignItems: "center" }}>
                                <a href={documentData?.fileUrl} style={{ textDecoration: "none" }} target="_blank">
                                    View Document </a>

                            </Grid>}
                    </Grid>
                </Box>
            </Grid>
        }</div>
    )
}
