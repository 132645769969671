import { Cached, Download } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
    tooltipClasses
} from "@mui/material";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { saveAs } from 'file-saver';
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ERoles } from "../../config";
import useAuth from "../../hooks/useAuth";
import { apiGatewayAxiosInstance } from "../../utils/axios";
import TablePaginationShowPage from "../../utils/tablePagination";
import AlertMessageComponent from "../components/AlertMessageComponent";
import FetchingSpinner from "../components/FetchingSpinner";
import NoRecords from "../components/NoRecords";
import SuccessMessageComponent from "../components/SuccessMessageComponent";
import FilterMISModel from "./filterBillingMisModal";

dayjs.extend(utc);

const StyledTableCellSticky = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
        backgroundColor: theme.palette.common.white,
    },
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontWeight: 600,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: "10px !important",
        width: "20vw",
    },
    [`&.${tableCellClasses.body}`]: {
        padding: "10px !important",
        fontSize: 14,
    },
    [`&.${tableCellClasses.head.tr}`]: {
        backgroundColor: "#79E0EE",
        width: "45vw",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
        fontSize: 14
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: 14
    },
}));



const BillingMIS = () => {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const [openFilter, setOpenFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [misData, setMisData] = useState([]);
    const [searchParams, setSearchParams] = useState({
        organizationName: "",
        reportDate: null,
        fromDate: dayjs().startOf('month').set('hour', 0).set('minute', 0).set('second', 0).toString(),
        toDate: dayjs().toString(),
    });
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [operationStatus, setOperationStatus] = useState({
        success: false,
        failed: false,
        message: ""
    });
    const [reload, setReload] = useState(false);


    const handleClickOpenFilterModel = () => {
        setOpenFilter(true);
    };

    const handleCloseFilterModel = () => {
        setOpenFilter(false);
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const getMISReports = async (newSearchParams) => {
        try {
            setLoading(true);
            if (newSearchParams) {
                setSearchParams(newSearchParams);
            }
            setCurrentPage(0);
            const result = await apiGatewayAxiosInstance.get(`/conversation_logger/mis/get-all-billing-mis-reports`, {
                params: {
                    currentPage: 1,
                    pageSize,
                    organizationName: searchParams.organizationName,
                    fromDate: searchParams.fromDate,
                    toDate: searchParams.toDate,
                },
            });
            setMisData(result.data.misData);
            setTotalCount(result.data.totalCount);
            setLoading(false);
        } catch (error) {
            let errorMessage = "Something Went Wrong!";
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
                dispatch(setOperationStatus({ failed: true, message: errorMessage }));
            } else {
                dispatch(setOperationStatus({ failed: true, message: errorMessage }))
            }
            setLoading(false);
        }
    };

    const handleClose = async ({ fieldName }) => {
        setOperationStatus({
            failed: false,
            success: false,
            message: ""
        })
    }

    const handleUpdateData = async () => {
        try {
            const startDate = moment(new Date(searchParams.fromDate)).format('YYYY-MM-DD');
            const endDate = moment(new Date(searchParams.toDate)).format('YYYY-MM-DD');
            setLoading(true);
            setCurrentPage(0);
            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/mis/update-billing-mis-reports`, { startDate, endDate });
            setLoading(false);
            setOperationStatus({ success: true, message: response.data });
            reloadList();
        } catch (error) {
            let errorMessage = "Something Went Wrong!";
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
                setOperationStatus({ failed: true, message: errorMessage });
            } else {
                setOperationStatus({ failed: true, message: errorMessage });
            }
            setLoading(false);
        }
    }

    const handleDownloadReport = async () => {
        try {

            setLoading(true);
            const result = await apiGatewayAxiosInstance.post(`/conversation_logger/mis/download-billing-mis-report`, {
                organizationName: searchParams.organizationName,
                fromDate: searchParams.fromDate,
                toDate: searchParams.toDate
            });
            const downloadData = result.data;
            let includeFields = [];
            let headersMap = {};

            if (user?.roleName.toLowerCase() === ERoles.SUPER_ADMIN) {
                includeFields = ['orgName', 'executedAt', 'BASIC_MESSEGE', 'A2P_SINGLE_MESSEGE', 'A2P_CONVERSATION', 'P2A_CONVERSATION', 'SENT_REVOKED', 'TRANS24', 'SMS_DELIVERED', 'SMS_FAILED'];
                headersMap = {
                    orgName: 'Organization Name',
                    executedAt: 'Date',
                    BASIC_MESSEGE: 'Basic Message',
                    A2P_SINGLE_MESSEGE: 'A2P Single Message',
                    A2P_CONVERSATION: 'A2P Conversation',
                    P2A_CONVERSATION: 'P2A Conversation',
                    SENT_REVOKED: 'Sent Revoked',
                    TRANS24: 'Trans24',
                    SMS_DELIVERED: 'SMS Delivered',
                    SMS_FAILED: 'SMS Failed',
                };
            } else if (user?.roleName.toLowerCase() === ERoles.ORG_ADMIN) {
                includeFields = ['executedAt', 'BASIC_MESSEGE', 'A2P_SINGLE_MESSEGE', 'A2P_CONVERSATION', 'P2A_CONVERSATION', 'SENT_REVOKED', 'TRANS24', 'SMS_DELIVERED', 'SMS_FAILED'];
                headersMap = {
                    executedAt: 'Date',
                    BASIC_MESSEGE: 'Basic Message',
                    A2P_SINGLE_MESSEGE: 'A2P Single Message',
                    A2P_CONVERSATION: 'A2P Conversation',
                    P2A_CONVERSATION: 'P2A Conversation',
                    SENT_REVOKED: 'Sent Revoked',
                    TRANS24: 'Trans24',
                    SMS_DELIVERED: 'SMS Delivered',
                    SMS_FAILED: 'SMS Failed',
                };
            }

            const filteredData = downloadData.map(item => {
                let filteredItem = {};
                includeFields.forEach(key => {
                    if (item.hasOwnProperty(key)) {
                        if (key === 'executedAt' && item[key]) {
                            const date = new Date(item[key]);
                            filteredItem[key] = date.toISOString().split('T')[0];
                        } else {
                            filteredItem[key] = item[key];
                        }
                    }
                });
                return filteredItem;
            });

            const convertToCSV = (data) => {
                const headers = includeFields.map(field => headersMap[field]);
                const array = [headers].concat(data);

                return array.map(row => Object.values(row).join(',')).join('\n');
            };

            const csvData = convertToCSV(filteredData);

            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `${moment().format('DD-MM-YYYY')}_Billing_MIS_Report.csv`);
            setOperationStatus({ success: true, message: "Download Successful!" });
            setLoading(false);
        } catch (error) {
            let errorMessage = "Something Went Wrong!";
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
                setOperationStatus({ failed: true, message: errorMessage });
            } else {
                setOperationStatus({ failed: true, message: errorMessage });
            }
            setLoading(false);
        }
    }

    const reloadList = () => {
        setReload(!reload);
    };

    useEffect(() => {
        getMISReports();
    }, [currentPage, pageSize, searchParams, reload]);

    return (
        <>
            <Box>
                <Grid container mb={4} className='listHeader' justifyContent={"space-between"}>
                    <Grid item >
                        <Typography variant='h3'>Billing MIS</Typography>
                    </Grid>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                        gap={2}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <IconButton
                                title='Refresh'
                                onClick={reloadList}
                                sx={{
                                    animation: loading ? "spin 0.5s linear infinite" : "",
                                    "@keyframes spin": {
                                        "0%": {
                                            transform: "rotate(360deg)",
                                        },
                                        "100%": {
                                            transform: "rotate(0deg)",
                                        },
                                    },
                                    // marginInline: "20px",
                                }}
                            >
                                <Cached />
                            </IconButton>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <BootstrapTooltip placement="top" title="Click to update Billing MIS Reports in selected Date range!" >
                                <span>
                                    {
                                        user?.roleName === ERoles.SUPER_ADMIN &&
                                        (

                                            <Button sx={{ marginRight: "10px" }} variant='contained' onClick={handleUpdateData}>
                                                Update
                                            </Button>
                                        )
                                    }
                                </span>
                            </BootstrapTooltip>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {misData.length > 0 ? (
                                <BootstrapTooltip placement="top" title="Downloads Report in a CSV file!">
                                    <span>
                                        <Button
                                            sx={{
                                                marginRight: "10px",
                                                whiteSpace: "nowrap"
                                            }}
                                            // variant="outlined"
                                            onClick={handleDownloadReport}
                                        ><Download />
                                            &nbsp; Download Report
                                        </Button>
                                    </span>
                                </BootstrapTooltip>
                            ) : (
                                <BootstrapTooltip placement="top" title="No Data to Download Report in a CSV file!">
                                    <span>
                                        <Button
                                            disabled={true}
                                            sx={{
                                                marginRight: "10px",
                                                whiteSpace: "nowrap"
                                            }}
                                            // variant="outlined"
                                            onClick={handleDownloadReport}
                                        ><Download />
                                            &nbsp; Download Report
                                        </Button>
                                    </span>
                                </BootstrapTooltip>
                            )
                            }
                        </Grid>

                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button variant='contained' onClick={handleClickOpenFilterModel}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <div>
                    <Card className='border border-secondary-50 '>
                        <CardContent sx={{
                            padding: 0,
                        }}>
                            <Grid
                                sx={{
                                    display: "flex ",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                {loading ? (
                                    <FetchingSpinner />
                                ) : (
                                    <>
                                        {misData.length > 0 ? (
                                            <TableContainer
                                                component={Paper}
                                                className='table-container'
                                                sx={{
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                    width: "auto !important",
                                                    maxHeight: "72vh"
                                                }}
                                            >
                                                <Table className='center' aria-label='table with sticky header' stickyHeader>
                                                    <TableHead className='p-3 mb-2 row'>
                                                        <TableRow>
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>Sr No</Typography>
                                                            </StyledTableCell>
                                                            {user?.roleName.toLowerCase() === ERoles.SUPER_ADMIN &&
                                                                <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                    <Typography fontSize={14}>Organization Name</Typography>
                                                                </StyledTableCell>}
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>Date</Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>Basic Message</Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>A2P Single Messege</Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>A2P Conversation</Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>P2A Conversation</Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>Sent Revoked</Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>Trans 24</Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>SMS Delivered</Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center' className='col-1 tableHeaderFont'>
                                                                <Typography fontSize={14}>SMS Failed</Typography>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {misData.map((data, index) => (
                                                            <StyledTableRow key={data.id || index}>
                                                                <StyledTableCell align='center'>
                                                                    {(currentPage * pageSize) + index + 1}
                                                                </StyledTableCell>
                                                                {user?.roleName.toLowerCase() === ERoles.SUPER_ADMIN &&
                                                                    <StyledTableCell align='center'>
                                                                        {data.orgName}
                                                                    </StyledTableCell>}
                                                                <StyledTableCell align='center'>
                                                                    {data.executedAt.split('T')[0]}
                                                                </StyledTableCell>
                                                                <StyledTableCell align='center'>
                                                                    {data.BASIC_MESSEGE}
                                                                </StyledTableCell>
                                                                <StyledTableCell align='center'>
                                                                    {data.A2P_SINGLE_MESSEGE}
                                                                </StyledTableCell>
                                                                <StyledTableCell align='center'>
                                                                    {data.A2P_CONVERSATION}
                                                                </StyledTableCell>
                                                                <StyledTableCell align='center'>
                                                                    {data.P2A_CONVERSATION}
                                                                </StyledTableCell>
                                                                <StyledTableCell align='center'>
                                                                    {data.SENT_REVOKED}
                                                                </StyledTableCell>
                                                                <StyledTableCell align='center'>
                                                                    {data.TRANS24}
                                                                </StyledTableCell>
                                                                <StyledTableCell align='center'>
                                                                    {data.SMS_DELIVERED}
                                                                </StyledTableCell>
                                                                <StyledTableCell align='center'>
                                                                    {data.SMS_FAILED}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <NoRecords />
                                        )}
                                    </>
                                )}
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    placeContent: "space-between"
                                }}
                            >
                                <Grid
                                    style={{
                                        marginTop: "20px",
                                        marginLeft: "10px"
                                    }}
                                >
                                    Total Records: {totalCount}
                                </Grid>
                                {misData.length > 0 && (<Grid mt={5} paddingLeft={5}>
                                    <TablePaginationShowPage
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        colSpan={3}
                                        count={totalCount}
                                        rowsPerPage={pageSize}
                                        page={currentPage}
                                        slotProps={{
                                            select: {
                                                "aria-label": "Rows per page",
                                            },
                                            actions: {
                                                showFirstButton: true,
                                                showLastButton: true,
                                                slots: {
                                                    firstPageIcon: FirstPageRoundedIcon,
                                                    lastPageIcon: LastPageRoundedIcon,
                                                    nextPageIcon: ChevronRightRoundedIcon,
                                                    backPageIcon: ChevronLeftRoundedIcon,
                                                },
                                            },
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>)}
                            </Grid>
                        </CardContent>
                        {openFilter && (
                            <FilterMISModel
                                open={openFilter}
                                searchParams={searchParams}
                                handleCloseFilterModel={handleCloseFilterModel}
                                getMISReports={getMISReports}
                            />
                        )}
                        {operationStatus.failed && (
                            <AlertMessageComponent
                                message={operationStatus.message}
                                fieldName={operationStatus.message}
                                handleClose={handleClose}
                                show={operationStatus.exist}
                            />
                        )}
                        {operationStatus.success && (
                            <SuccessMessageComponent
                                message={operationStatus.message}
                                fieldName={operationStatus.message}
                                handleClose={handleClose}
                                show={operationStatus.success}
                            />
                        )}
                    </Card>
                </div>
            </Box >
        </>
    );
};


export default BillingMIS