import styled from '@emotion/styled'
import React, { useState } from 'react'

import { Avatar, Paper } from '@mui/material'

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { ReactComponent as Logo } from '../../vendor/PinnacleTeleservicesLogo.svg'
// import PinnacleLogo from "../../assets/icons/pinnacle-logo.png";
import { useParams } from 'react-router-dom'
import SetClientPasswordComponent from '../../components/auth/SetClientPassword'

const Brand = styled(Logo)`
    fill: ${props => props.theme.palette.primary.main};
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
`

const Wrapper = styled(Paper)`
    padding: ${props => props.theme.spacing(6)};

    ${props => props.theme.breakpoints.up('md')} {
        padding: ${props => props.theme.spacing(10)};
    }
`

const BigAvatar = styled(Avatar)`
    width: 92px;
    height: 92px;
    text-align: center;
    margin: 0 auto ${props => props.theme.spacing(5)};
`

function SetClientPassword() {
    let param = useParams()
    // const token = a.token.trim()
    const [expiredIn, setExpiredIn] = useState('')
    const [verificationCode, setVerificationCode] = useState('')


    return (
        <>
            {/* <React.Fragment> */}
            {/* <Brand /> */}
            {/* <img src={PinnacleLogo} className="pinnacleLogo" /> */}
            {/* <Wrapper> */}
                {/* <Helmet title="Sign In" /> */}
                {/* <BigAvatar alt="User" src="/static/img/avatars/user-placeholder.jpg" /> */}
                {/* <Typography
                    component='h1'
                    variant='h4'
                    align='center'
                    gutterBottom
                >
                    <b> Hello Client Name </b>
                </Typography>
                <Typography component='h2' variant='body1' align='center'>
                    Set Your Password
                </Typography> */}
                <SetClientPasswordComponent param={param} />
            {/* </Wrapper> */}
            {/* </React.Fragment> */}
        </>
    )
}

export default SetClientPassword
