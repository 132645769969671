import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PlainTextMessageTemplate from './PlainTextMessageTemplate';
import { BotConversationLogSimulator, TextMessageCampaignSimulator } from '../simulatorComponents/ReportSimulator';
import GppGoodIcon from '@mui/icons-material/GppGood';




export default function UserChats({ selectedUserDetails, selectedBotId, selectedUserNumber, botDetails, loadingMessage, pageSize, pageNumber, setPageNumber, onScrollTop }) {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    const [loadMoreChats, setLoadMoreChats] = useState(false);

    const calculateTime = (value) => {
        const date = new Date(value);
        let newTime = date.setHours(date.getHours() + 5);
        newTime = date.setMinutes(date.getMinutes() + 30);
        const time = new Date(newTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        return time;
    };

    const switchTemplate = (message, index) => {
        const payload = message.messagePayload.payload;
        switch (message.messagePayload.type) {
            case "text_message":
                {
                    if (payload.contentMessage.text !== "") {
                        return <PlainTextMessageTemplate message={payload.contentMessage.text} index={index} />
                    } else {
                        break;
                    }
                }
            case "text":
                {
                    if (payload.text !== "") {
                        return <PlainTextMessageTemplate message={payload.text} index={index} />
                    }
                }
            case "carousel":
                {

                    break;
                }

            default:
                break;
        }
    };




    const handleChatsOnScroll = async (e) => {

        const chatListNode = e.currentTarget;
        if (!loadMoreChats) {
            let updatedPageNumber = pageNumber + 1;
            // let totalPages = parseInt(totalCount / pageSize) + (totalCount % pageSize > 0 ? 1 : 0);

            //scroll when scroll bar(position) at top and have more pages/chats to load
            if (chatListNode.scrollTop === 0) {
                setLoadMoreChats(true);
                await setPageNumber(updatedPageNumber);
                await onScrollTop(updatedPageNumber);
                // setTimeout(() => {
                setLoadMoreChats(false);

                // }, 2000);
                // await scrollToLastMessage(e);
            }
        }
    };


    useEffect(async () => {

        if (loadingMessage === false) {

            await scrollToBottom();
        }

    }, [loadingMessage])

    return (
        <Grid>
            <Grid padding={2} display={"flex"}>
                <Typography paddingLeft={2} display={"flex"} variant='h4'>
                    {selectedUserNumber &&
                        <PersonOutlineOutlinedIcon />
                    }
                    <Typography marginLeft={2} variant='h4'>
                        {selectedUserNumber}
                    </Typography>
                </Typography>
            </Grid>
            {(selectedBotId !== "" && selectedUserNumber !== "") ?
                <>
                    <hr style={{ padding: "0px", margin: "0px" }} />

                    <div paddingX={2} style={{ overflowY: "scroll", maxWidth: "100%", height: "54vh" }} onScroll={(e) => handleChatsOnScroll(e)} >
                        <Grid fullWidth sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>

                            <div className="play-button-container" style={{ position: "relative" }}>
                                <Box sx={{
                                    borderRadius: "50%", overflow: "hidden", marginRight: 2, boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
                                    height: "50px", width: "50px"
                                }}>
                                    <img src={botDetails.botLogoImage.url} height={"50px"} width={"50px"} display={'block'} />
                                </Box>
                                <div className="play-button-overlay" style={{ position: "absolute", top: "80%", left: "80%", transform: "translate(-50%, -50%)" }}>
                                    {/* You can replace the content inside the overlay div with your own play button logo */}
                                    <GppGoodIcon color="primary" />
                                </div>
                            </div>


                            <Typography level="body-xs"
                                textColor="var(--joy-palette-success-plainColor)"

                                sx={{ opacity: '50%' }}
                                align="center"
                                padding={1}
                            >
                                {botDetails.shortDescription}
                            </Typography>

                        </Grid>

                        {loadMoreChats && (
                            <Stack alignItems="center" justifyContent="center">
                                <CircularProgress />
                            </Stack>
                        )}

                        {loadingMessage ?
                            <Grid sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                <CircularProgress
                                    size={50}
                                />
                            </Grid> :
                            <>
                                {selectedUserDetails.length > 0 &&
                                    selectedUserDetails.map((message, index) => (
                                        <>{message.messagePayload?.payload &&


                                            <BotConversationLogSimulator campaignDetails={message} contentMessage={message.messagePayload.payload.contentMessage} botLogoImage={botDetails.botLogoImage.url} status={message.messageStatus} />

                                        }</>


                                        // <Grid className="d-flex flex-column" style={{ marginBottom: "10px", marginTop: "10px" }}>
                                        //     <Grid key={index} className={message.messageType === "botMessage" ? "left" : "right"}>
                                        //         {message.messageType === "botMessage" && <SmartToyOutlinedIcon />}
                                        //         {switchTemplate(message, index)}
                                        //         {message.messageType === "userMessage" && <PersonOutlineOutlinedIcon />}
                                        //     </Grid>
                                        //     <Grid className={message.messageType === "botMessage" ? "left_date " : "right_date"}>
                                        //         <span style={{ fontSize: "0.7rem" }}>{calculateTime(message.updatedAt)}</span>
                                        //     </Grid>
                                        // </Grid>
                                    ))

                                }</>
                        }
                        <div ref={messagesEndRef} />

                    </div>

                </>
                :
                <Grid alignItems={"center"} display={"flex"} justifyContent={"center"} height={"100%"}>
                    <Typography variant='h5'>Select Bot and User</Typography>
                </Grid>
            }

        </Grid>
    )
}
