import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMessagesOfBrands, setShowAddBrandForm } from "../../../redux/brandManagement/brandManagementSlice";
import * as Yup from "yup";
import { addNewBrandThunk } from "../../../redux/brandManagement/brandManagementThunk";
import { useFormik } from "formik";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import AlertMessageComponent from "../../components/AlertMessageComponent";
function AddBrand() {
  const state = useSelector((state) => state.brandManagementSlice);
  const dispatch = useDispatch();

  const onClickCancelButton = () => {
    dispatch(setShowAddBrandForm(false));
  };

  const formik = useFormik({
    initialValues: {
      brandName: "",
      submit: false,
    },
    validationSchema: Yup.object().shape({
      brandName: Yup.string().max(225).required("Please Enter the Brand Name"),
    }),
    onSubmit: async (
      values,
      { setErrors, setStatus, setSubmitting, resetForm }
    ) => {
      try {
        const dataToSend = {
          brandName: values.brandName,
        };
        dispatch(
          addNewBrandThunk({ data: dataToSend })
        );
      } catch (error) {
        const message = formik.errors.message || "Something went wrong";
        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
    },
  });

  const onCloseErrorMessage = ({ fieldName }) => {
    dispatch(clearErrorMessagesOfBrands({ fieldName }));
  }

  return (
    <Box sx={{ boxShadow: 6 }}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Card className="card">
          <CardContent sx={{ padding: "10px" }}>
            <Typography variant="h4">
              <b className="text-primary">Add New Brand</b>
            </Typography>
            <hr />
            <Grid container>
              <Grid item>
                <TextField
                  disabled={state.controls.isCreateBrandLoading}
                  name="brandName"
                  fullWidth
                  label="Brand Name"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.brandName && formik.errors.brandName
                  )}
                  helperText={
                    formik.touched.brandName && formik.errors.brandName
                  }
                  value={formik.values.brandName}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ padding: "0px" }}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item>
                {state.controls.isCreateBrandLoading ?
                  <Button variant="contained">
                    <CircularProgress size={20} className="spinner" />
                    Creating Brand..
                  </Button>
                  :
                  <Button
                    type="submit"
                    variant="contained"
                    className="m-2"
                  >
                    Create Brand
                  </Button>
                }
                <Button
                  disabled={state.controls.isCreateBrandLoading}
                  variant="contained"
                  color="error"
                  onClick={onClickCancelButton}
                  className="m-2"
                >
                  Cancel
                </Button>
              </Grid></Grid>
          </CardActions>
        </Card>
        <AlertMessageComponent
          message={state.controls.addBrandErrorMessage}
          fieldName={"addBrandErrorMessage"}
          handleClose={onCloseErrorMessage}
          show={Boolean(state.controls.addBrandErrorMessage)}
        />
      </form>
    </Box>
  );
}

export default AddBrand;
