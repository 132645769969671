

import React from 'react'
import { Grid } from 'react-feather'

export default function RcsSentIcon() {
    return (
        <div >
            <svg viewBox="0 0 10 10" width="18" height="20" style={{ display: "grid", marginBottom: "3", marginRight: "3" }}>

                <path fill='#1984d9' d="M5.802 1.68A4.322 4.322 0 0 0 1.482 6a4.322 4.322 0 0 0 4.32 4.32A4.322 4.322 0 0 0 10.123 6a4.322 4.322 0 0 0-4.32-4.32Zm0 7.777A3.461 3.461 0 0 1 2.346 6a3.461 3.461 0 0 1 3.456-3.457A3.461 3.461 0 0 1 9.26 6a3.461 3.461 0 0 1-3.457 3.457Z">
                </path>
                <path fill='#1984d9' d="m7.487 4.401-2.549 2.55-.82-.821a.425.425 0 0 0-.606 0 .425.425 0 0 0 0 .605l1.12 1.119a.43.43 0 0 0 .609 0l2.851-2.848a.425.425 0 0 0 0-.605.425.425 0 0 0-.605 0Z">
                </path></svg>








        </div>


    )
}
