
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { Box, Card, Grid } from "@mui/material";

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { SuggestionSimulatorComponentForCampaign } from './suggestionSimulatorComponentForCampaign';



export function RichCardCarouselCardCampaignSimulator({ richCardCarouselCard }) {
    return (
        <>  <Grid style={{ minWidth: "100%", display: "flex", overflow: "auto", width: "100%" }} >






            {richCardCarouselCard.cardContents.map((cardContent) => (<Grid item
                sx={{
                    minWidth: richCardCarouselCard.cardWidth !== "SMALL" ? "70%" : "45%",
                    maxWidth: richCardCarouselCard.cardWidth !== "SMALL" ? "70%" : "45%"
                }}


                minHeight={"40vh"} maxHeight={"50vh"} padding={2}>

                <Card sx={{ height: "100%", border: "1px solid grey", borderRadius: "10px" }} >



                    <Box >
                        {cardContent.media.contentInfo.thumbnailUrl ? <>

                            <Grid className="play-button-container" style={{ position: "relative" }}>
                                <img src={cardContent.media.contentInfo.thumbnailUrl} style={{
                                    height: cardContent.media.height === "SHORT" ? "100px" : "130px",
                                    width: "100%",
                                    display: "block"
                                }
                                } className="image" />
                                <Grid className="play-button-overlay" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                    {/* You can replace the content inside the overlay Grid with your own play button logo */}
                                    <PlayCircleIcon sx={{ fontSize: 40, color: "white" }} />
                                </Grid>
                            </Grid>


                            {/* <img
                                                              src={cardContent.media.contentInfo.thumbnailUrl}

                                                              style={{
                                                                  height: cardContent.media.height === "SHORT" ? "100px" : "130px",
                                                                  width: "100%"
                                                              }
                                                              }
                                                          /> */}

                        </> :
                            <>
                                <img
                                    src={cardContent.media.contentInfo.fileUrl}

                                    style={{
                                        height: cardContent.media.height === "SHORT" ? "100px" : "130px",
                                        width: "100%"
                                    }
                                    }
                                />

                            </>
                        }

                    </Box>
                    <Box padding={2}>
                        <Typography variant="h6" fontWeight={"bold"} sx={{ lineBreak: "auto", wordBreak: "break-all", padding: 1 }} >

                            {cardContent.title && cardContent.title.length > 38 ? `${cardContent.title.substr(0, 25)}...` : cardContent.title}

                        </Typography>
                        <Typography variant="body2" sx={{ lineBreak: "auto", wordBreak: "break-word", padding: 1 }} >
                            {/* {cardContent.cardDescription} */}
                            {cardContent.description.length > 25 ? `${cardContent.description.substr(0, 25)}...` : cardContent.description}



                        </Typography>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column", paddingTop: 4 }}>


                            {cardContent.suggestions.map((suggestion, index) => (
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <SuggestionSimulatorComponentForCampaign suggestion={suggestion} />
                                    {index + 1 < cardContent.suggestions.length &&

                                        <hr style={{ width: "60%", border: "1px solid black", margin: 3 }} />
                                    }
                                </Box>
                            ))}
                        </Box>

                    </Box>





                </Card>
            </Grid>))}



        </Grid></>
    )
}