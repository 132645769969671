import { useDispatch, useSelector } from "react-redux";
import AddTestDevice from "./addTestDevice";
import ListTestDevices from "./listTestDevices";

function ManageTestDevices() {
    const state = useSelector((state) => state.botManagementSlice);
    const dispatch = useDispatch();






    return (
        <div>
            {(state.controls.showAddTestDeviceForm.status === true &&
                state.controls.showAddTestDeviceForm.botId !== "") &&

                <> <AddTestDevice botId={state.controls.showAddTestDeviceForm.botId} />
                    {/* {(state.controls.isNewTestDeviceAdded) && < ListTestDevices botId={state.controls.showAddTestDeviceForm.botId} />} */}

                </>

            }
        </div>
    );
}
export default ManageTestDevices;
