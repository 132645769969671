
import * as React from 'react';

import { Grid } from "@mui/material";

import CallIcon from '@mui/icons-material/Call';
import PublicIcon from '@mui/icons-material/Public';


export function SuggestionSimulatorComponentForCampaign({ suggestion }) {
    return (


        <Grid sx={{ color: "#0854e7", display: "flex", alignItems: "center" }}>
            {suggestion.reply &&
                <Grid paddingLeft={1}>
                    {suggestion.reply.text}
                </Grid>
            }


            {suggestion.action &&
                <Grid display={"flex"}>

                    {suggestion.action.dialAction && <CallIcon />}
                    {suggestion.action.openUrlAction && <PublicIcon />}

                    <Grid paddingLeft={1}>
                        {suggestion.action.text}
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}
