import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, Grid, IconButton as MuiIconButton, Chip } from "@mui/material";

const InvalidNumberReasonsModal = ({ open, setOpen, campaignData }) => {
    const handleCloseModal = () => {
        setOpen(false);
    };
 

    return (
        <Dialog
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Reasons"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <ul>
                        {campaignData.reason.map((data, index) => (
                                <li>{data}</li>
                        ))}
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseModal()} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvalidNumberReasonsModal;
