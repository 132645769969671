
// Where 1 = whatsapp, 2 = messenger , 3 = telegram
export const supportedChannels = [1, 2, 3];

/**
 * 
 * @param {*} channelId 
 * @param {*} nodeType 
 * @param {*} nodeData 
 * @returns 
 * This methods check if user can add more button for the given specific channel
 */
export function validateButtonNode(channelId, buttons) {
    try {
        // check if user is allowed to add more button in this particular channel
        const buttonNodeContents = buttons;
        if (channelId === 1) {
            if(buttonNodeContents.length < 3){
                return {
                    flag:true
                }
            }else{
                return {
                    flag:false,
                    message:'Whatsapps not support more than 3 buttons'
                }
            }
        }

        if (channelId === 2) {
            if(buttonNodeContents.length < 5){
                return {
                    flag:true
                }
            }else{
                return {
                    flag:false,
                    message:'Messenger not support more than 5 buttons'
                }
            }
        }

        if (channelId === 3) {
            if(buttonNodeContents.length < 8){
                return {
                    flag:true
                }
            }else{
                return {
                    flag:false,
                    message:'Telegram not support more than 8 buttons'
                }
            }
        }

        return false;
    } catch (error) {
        console.log(error);
    }

}

// return edges of nodes
export const getConnectedEdges = (nodes, edges) => {
    const nodeIds = nodes.map((node) => node.id);
    return edges.filter((edge) => nodeIds.includes(edge.source) || nodeIds.includes(edge.target));
};

/**
 * 
 * @param {*} sourceNode 
 * @param {*} sourceHandle 
 * @returns channelId
 */
const findChannelIdFromChannelSwitch = (sourceNode, sourceHandle) => {

    // all invalid arguments
    if (!sourceNode || !sourceNode.data || !Array.isArray(sourceNode.data.channels)) {
        return {
            //  connection is not from channel id and 
            channelId: null,
            status: false,
            reason: "source node invalid"
        }
    }

    
    // For Valid Arguments Continue...
    let selectedChannelId = sourceNode.data.channels.find( channel => channel.handleId === sourceHandle);

    return selectedChannelId !== null ?
        {
            status: true,
            channelId: selectedChannelId.channelId
        }
        :
        {
            //  connection is not from channel id and 
            channelId: null,
            status: false
        }
}

/**
 * 
 * @param {*} params 
 * @param {*} nodes 
 * @param {*} edges 
 * this methods will return the channel id it belongs to
 */
export function getChannelFromTheChannelNode(sourceNode, sourceNodeId, sourceHandle, targetNodeId, targetHandle) {

    if (!sourceHandle || typeof sourceHandle !== 'string' || sourceHandle.trim().length === 0) {
        return {
            status: false,
            channelId: null,
            reason: "source handle invalid"
        };
    }

    let result = findChannelIdFromChannelSwitch(sourceNode, sourceHandle);

    return result != null && result.status === true
        ?
        {
            status: true,
            channelId: result.channelId
        }

        : {
            status: false,
            channelId: null
        }

}

export function channelButtonValidation(data){
    let res={flag:false,message:''};
    data.belongsToChannel.forEach((channel)=>{
        let result=validateButtonNode(channel.channelId,data.contents);
        if(result.flag==false)
            res={flag:true,message:result.message}
    });
    return res;
}