import { createAsyncThunk } from "@reduxjs/toolkit";
import userAxiosInstance, { apiGatewayAxiosInstance } from "../../utils/axios";

//---------------------------------Manage Branches----------------------------------------------------------------------------

export const addNewBranchThunk = createAsyncThunk("add-new-branch", async ({ data }) => {
    try {
        const response = await userAxiosInstance.post(`branches/create`, data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllBranchesThunk = createAsyncThunk("get-all-branches,", async () => {
    try {
        const response = await userAxiosInstance.get(`branches/get-all`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        console.dir(error);
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllBranchesByPageSizeThunk = createAsyncThunk("get-all-branches-by-pageSize", async ({ pagesize, currentPage }) => {
    try {
        const response = await userAxiosInstance.get(`branches/get-all-by-pageSize`, {
            params: {
                pagesize,
                currentPage,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        console.dir(error);
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});
export const getAllBranchesByFilterThunk = createAsyncThunk("get-all-branches-by-filter,", async ({ pageSize, currentPage, searchParams }) => {
    try {
        const response = await userAxiosInstance.get(`branches`, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                pageSize,
                currentPage,
                branchName: searchParams.branchName,
                branchCode: searchParams.branchCode,
                status: searchParams.status,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------------------------manage Exeutive_---------------------------------------------------------------------------------------

export const addNewExecutiveThunk = createAsyncThunk("add-new-executive", async ({ data }) => {
    try {
        const response = await userAxiosInstance.post(`executives/create`, data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllExecutiveThunk = createAsyncThunk("get-all-executive,", async () => {
    try {
        const response = await userAxiosInstance.get(`executives/get-all`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllExecutiveByPageSizeThunk = createAsyncThunk("get-all-executive-by-pagesize,", async ({ pagesize, currentPage }) => {
    try {
        const response = await userAxiosInstance.get(`executives/get-all-by-pagesize`, {
            params: {
                pagesize,
                currentPage,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------------------------Manage Managers ----------------------------------------------------------------------------------------------------

export const addNewManagerThunk = createAsyncThunk("add-new-manager", async ({ data }) => {
    try {
        const response = await userAxiosInstance.post(`branch-managers/create`, data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllManagersThunk = createAsyncThunk("get-all-managers,", async () => {
    try {
        const response = await userAxiosInstance.get(`branch-managers/get-all`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllManagersByPageSizeThunk = createAsyncThunk("get-all-managers-by-pagesize,", async ({ pagesize, currentPage }) => {
    try {
        const response = await userAxiosInstance.get(`branch-managers/get-all-by-pagesize`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------Clients Maneger------------------------------------------------------------------------------

export const getAllClientsThunk = createAsyncThunk("get-all-clients", async () => {
    try {
        const response = await userAxiosInstance.get(`clients/get-all`);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllParentClientsThunk = createAsyncThunk("get-all-parent-clients", async () => {
    try {
        const response = await userAxiosInstance.get(`clients/get-all-parent-client`);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllClientsByPageSizeThunk = createAsyncThunk(
    "get-all-clients-by-pagesize,",
    async ({ pagesize, currentPage, searchedClientName, branchId, executiveId, managerId, status }) => {
        try {
            const response = await userAxiosInstance.get(`clients/get-all-by-pagesize`, {
                params: {
                    pagesize,
                    currentPage,
                    searchedClientName,
                    branchId,
                    executiveId,
                    managerId,
                    status,
                },
            });

            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            throw errorMessage;
        }
    },
);

export const getAllClientByFilterThunk = createAsyncThunk("get-all-client-by-filter,", async ({ pageSize, currentPage, searchParams }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/user_management/clients`, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                pageSize,
                currentPage,
                name: searchParams.name,
                email: searchParams.email,
                orgName: searchParams.orgName,
                mobilenumber: searchParams.mobileNumber,
                status: searchParams.status,

            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});
export const addNewClientThunk = createAsyncThunk("add-new-client", async ({ formDataToSend }) => {
    try {
        const response = await apiGatewayAxiosInstance.post(`user_management/clients/create`, formDataToSend, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------Manage Roles --------------------------------------------------------

export const getAllRolesThunk = createAsyncThunk("get-all-roles,", async () => {
    try {
        const response = await userAxiosInstance.get(`roles/get-all`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllRolesByPageSizeThunk = createAsyncThunk("get-all-roles-by-pagesize", async ({ pagesize, currentPage, searchedRoleName }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/user_management/roles/get-all-by-pagesize`, {
            params: {
                pagesize,
                currentPage,
                searchedRoleName,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllRolesByFilterThunk = createAsyncThunk("get-all-roles-by-filter,", async ({ pageSize, currentPage, searchParams }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/user_management/roles`, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                pageSize,
                currentPage,
                rolename: searchParams.rolename,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});
export const addNewRolesThunk = createAsyncThunk("add-new-role", async ({ data }) => {
    try {
        const response = await userAxiosInstance.post(`roles/create`, data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

// ----------------------------------Manage Users---------------------------------------------

export const getAllUsersThunk = createAsyncThunk("get-all-users,", async () => {
    try {
        const response = await userAxiosInstance.get(`users/get-all`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllUsersByPageSizeThunk = createAsyncThunk("get-all-users-by-pagesize,", async ({ pageSize, currentPage }) => {
    try {
        const response = await userAxiosInstance.get(`users/get-all-by-pagesize`, {
            params: {
                pageSize,
                currentPage,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllUsersByFilterThunk = createAsyncThunk("get-all-users-by-filter,", async ({ pageSize, currentPage, searchParams }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/user_management/users`, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                pageSize,
                currentPage,
                name: searchParams.name,
                email: searchParams.email,
                mobilenumber: searchParams.mobileNumber,
                status: searchParams.status,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const addUpdateUserThunk = createAsyncThunk("add-update-user", async ({ data, userId }) => {
    try {
        const response = await userAxiosInstance.put(`users/update-user/${userId}`, data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getMasterRatesThunk = createAsyncThunk("get-base-rate", async ({ providerName, pageSize, currentPage }) => {
    try {
        const serverResponse = await apiGatewayAxiosInstance.get(`/user_management/rate-card/get-all`, {
            params: {
                providerName: providerName,
                pageSize,
                currentPage,
            },
        });
        if (serverResponse.status === 200) {
            return serverResponse.data.data;
        } else {
            throw new Error(serverResponse);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

// export const updateBaseRateThunk = createAsyncThunk(
//     "add-base-rate",
//     async ({ data }) => {
//         try {
//             const response = await userAxiosInstance.post(
//                 `rate-card/update`,
//                 data
//             );

//             if (response.status === 200) {
//                 return response.data;
//             } else {
//                 throw new Error(response);
//             }
//         } catch (error) {
//             if (error.response) {
//                 throw error.response.data;
//             } else {
//                 throw "server not reachable";
//             }
//         }
//     }
// );

export const getAllCustomizedRate = createAsyncThunk("get-customized-rate,", async () => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/user_management/customized-rate/get-all`,);

        if (response.status === 200) {
            return response.data.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        console.dir(error);
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const fetchCurrentBalanceByClientId = createAsyncThunk("get-current-balance-by-clientId", async () => {
    try {
        const token = sessionStorage.getItem("accessToken");

        const getCurrentBalanceResponse = await apiGatewayAxiosInstance.get(`/user_management/customized-rate/get-current-balance`, { headers: { token } });

        if (getCurrentBalanceResponse.status === 200) {
            return getCurrentBalanceResponse.data;
        } else {
            throw new Error(getCurrentBalanceResponse);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});
