import React, { useState } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

function FilterAllRolesModel({
    open,
    searchParams,
    setSearchParams,
    handleClickOpen,
    handleClose,
    handleClickSearch,
}) {

    const [search , setSearch] = useState(searchParams)
    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setSearch({ ...search, [name]: value });
    };



    const onClickClear = () => {
        setSearchParams({
            rolename: "",
        });

        handleClickSearch({ rolename: "", });


        handleClose();
    };
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5">User Filter</Typography>
                    <IconButton onClick={() => handleClose()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent
                    sx={{
                        overflowY: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                        width: "400px",
                    }}
                >
                    <Grid container sx={{ minWidth: "100%" }}>
                        <TextField
                            name="rolename"
                            value={search.rolename}
                            onChange={handleChange}
                            label="Roles"
                            fullWidth
                            placeholder="Enter Rolename"
                        />
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => onClickClear()}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClickSearch(search)}
                        autoFocus
                    >
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default FilterAllRolesModel;
