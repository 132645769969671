import { Box, Grid, TextField } from "@mui/material";
import SuggestionSimulatorComponent from "./SuggestionSimulatorComponent";

export default function TextMessageComponent({ textMessageDetails }) {


    return (
        <Box sx={{ maxHeight: "100%", width: "100%", margin: 3 }}>
            <TextField
                multiline


                size="small"
                fullWidth
                label={null}
                variant="outlined"
                value={textMessageDetails.messageContent}
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, lineBreak: "auto", wordBreak: "break-all", margin: 1 }} />
            <Grid container>

                {textMessageDetails.suggestionsList.map((suggestions) => (
                    <Grid item sx={{ border: "1px solid Grey", borderRadius: "50px" }} padding={1} paddingInline={3} margin={1}>
                        <SuggestionSimulatorComponent suggestion={suggestions} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
