import { createSlice } from "@reduxjs/toolkit";
import {
    addNewBranchThunk,
    addNewClientThunk,
    addNewExecutiveThunk,
    addNewManagerThunk,
    addNewRolesThunk,
    addUpdateUserThunk,
    fetchCurrentBalanceByClientId,
    getAllBranchesByFilterThunk,
    getAllBranchesByPageSizeThunk,
    getAllBranchesThunk,
    getAllClientByFilterThunk,
    getAllClientsByPageSizeThunk,
    getAllClientsThunk,
    getAllCustomizedRate,
    getAllExecutiveByPageSizeThunk,
    getAllExecutiveThunk,
    getAllManagersByPageSizeThunk,
    getAllManagersThunk,
    getAllParentClientsThunk,
    getAllProvidersThunk,
    getAllRoles,
    getAllRolesByFilterThunk,
    getAllRolesByPageSizeThunk,
    getAllRolesThunk,
    getAllUsersByFilterThunk,
    getAllUsersByPageSizeThunk,
    getAllUsersThunk,
    getMasterRatesThunk,
} from "../userManagement/userManagementThunk";

export const userManagementSlice = createSlice({
    name: "userManagementSlice",
    initialState: {
        user: {
            name: "",
            role: "",
            email: "",
            mobileNumber: "",
            profilePicture: "",
        },
        selectedManager: {},

        branchList: [],
        branchListByPageSize: [],
        totalBranchCount: 0,

        executiveList: [],
        executiveListByPageSize: [],
        totalExecutiveCount: 0,

        branchManagersList: [],
        branchManagersListByPageSize: [],
        totalBranchManagerCount: 0,

        clientsList: [],
        parentClientsList: [],
        clientsListByPageSize: [],
        totalClientsCount: 0,

        usersList: [],
        userListByPageSize: [],
        totalUsersCount: 0,

        rolesList: [],
        rolesListByPageSize: [],
        totalRolesListCount: 0,

        masterRatesList: [],
        masterRatesTotalCount: 0,
        customizedRate: [],

        userCurrentBalance: "NA",
        postpaidUserMaxLimit: "NA",
        postpaidUserCapcheck: "NA",
        userBillingType: "NA",



        controls: {
            showAddBranchForm: false,
            showAddExecutiveForm: false,
            showAddBranchManagerForm: false,
            showAddClientForm: false,
            showAssignExecutiveForm: false,
            showEditExecutiveForm: false,
            showAddUserForm: false,
            showAddRolesForm: false,

            // ----------------------Spinners------------------------
            isGetAllUsersLoading: false,
            isUpdateUsersLoading: false,
            isGetAllBranchesLoading: false,
            isAddBranchLoading: false,
            isGetAllClientsLoading: false,
            isAddClientLoading: false,
            isGetAllExecutivesLoading: false,
            isAddExecutiveLoading: false,
            isGetAllManagersLoading: false,
            isAddManagerLoading: false,
            isGetAllRatesLoading: false,

            //-----------Error Messages -------------------------------------
            addNewBranchErrorMessage: "",
            addNewExecutiveErrorMessage: "",
            addNewBranchManagerErrorMessage: "",
            addNewClientErrorMessage: "",
            getAllBranchesErrorMessage: "",
            getAllBranchMangersErrorMessage: "",
            getAllExecutivesErrorMessage: "",
            getAllClientsErrorMessage: "",
            getAllParentClientsErrorMessage: "",
            addNewUsersErrorMessage: "",
            getAllUsersErrorMessage: "",
            getAllRolesErrorMessage: "",
            addNewRolesErrorMessage: "",
            getMasterRateListErrorMessage: "",
            updateMasterRateErrorMessage: "",
            updateCustomizedRateErrorMessage: "",
            updateMasterRateForAllCountryErrorMessage: "",
            addMasterRateForCountryErrorMessage: "",
            getClientEditDetailsErrorMessages: "",
        },
    },
    reducers: {
        setShowAddBranchForm: (state, action) => {
            state.controls.showAddBranchForm = action.payload;
            state.controls.addNewBranchErrorMessage = "";
        },
        setShowAddExecutiveForm: (state, action) => {
            state.controls.showAddExecutiveForm = action.payload;
            state.controls.addNewExecutiveErrorMessage = "";
        },
        setShowAddBranchManagerForm: (state, action) => {
            state.controls.showAddBranchManagerForm = action.payload;
            state.controls.addNewBranchManagerErrorMessage = "";
        },
        setShowAddClientForm: (state, action) => {
            state.controls.showAddClientForm = action.payload;
            state.controls.addNewClientErrorMessage = "";
        },
        setShowAssignExecutiveForm: (state, action) => {
            state.controls.showAssignExecutiveForm = action.payload;
            state.controls.addNewExecutiveErrorMessage = "";
        },
        setSelectedManager: (state, action) => {
            state.selectedManager = action.payload;
        },
        setShowEditExecutiveForm: (state, action) => {
            state.controls.showEditExecutiveForm = action.payload;
        },

        setShowAddUserForm: (state, action) => {
            state.controls.showAddUserForm = action.payload;
            state.controls.addNewUsersErrorMessage = "";
        },

        setShowAddRoleForm: (state, action) => {
            state.controls.showAddRolesForm = action.payload;
        },
        clearErrorMessagesForAllUsers: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = "";
        },
        setErrorMessageForUser: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = action.payload.errorMessage;
        },
        setAllCustomizedData: (state, action) => {
            state.customizedRate = action.payload;
        },
        clearErrorMessageForBaseRate: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = "";
        },
    },

    extraReducers: builder => {
        //-----------------------Add New Branch--------------------------------------
        builder.addCase(addNewBranchThunk.pending, (state, action) => {
            state.controls.isAddBranchLoading = true;
        });
        builder.addCase(addNewBranchThunk.fulfilled, (state, action) => {
            state.controls.isAddBranchLoading = false;
            state.controls.showAddBranchForm = false;
            state.controls.addNewBranchErrorMessage = "";
        });

        builder.addCase(addNewBranchThunk.rejected, (state, action) => {
            state.controls.isAddBranchLoading = false;
            state.controls.addNewBranchErrorMessage = action.error.message;
        });

        //--------------------------------Get All Branch--------------------------------------
        builder.addCase(getAllBranchesThunk.pending, (state, action) => { });
        builder.addCase(getAllBranchesThunk.fulfilled, (state, action) => {
            state.branchList = action.payload.data;
        });
        builder.addCase(getAllBranchesThunk.rejected, (state, action) => { });

        builder.addCase(getAllBranchesByPageSizeThunk.pending, (state, action) => {
            state.controls.isGetAllBranchesLoading = true;
            state.controls.getAllBranchesErrorMessage = "";
        });
        builder.addCase(getAllBranchesByPageSizeThunk.fulfilled, (state, action) => {
            state.controls.isGetAllBranchesLoading = false;
            state.branchListByPageSize = action.payload.allBranchData;
            state.totalBranchCount = action.payload.totalCount;
            state.controls.getAllBranchesErrorMessage = "";
        });
        builder.addCase(getAllBranchesByPageSizeThunk.rejected, (state, action) => {
            state.controls.isGetAllBranchesLoading = false;
            state.controls.getAllBranchesErrorMessage = action.error.message;
            state.branchListByPageSize = [];
            state.totalBranchCount = 0;
        });

        builder.addCase(getAllBranchesByFilterThunk.pending, (state, action) => {
            state.controls.isGetAllBranchesLoading = true;
            state.controls.getAllBranchesErrorMessage = "";
        });
        builder.addCase(getAllBranchesByFilterThunk.fulfilled, (state, action) => {
            state.controls.isGetAllBranchesLoading = false;
            state.branchListByPageSize = action.payload.allBranchData;
            state.totalBranchCount = action.payload.totalCount;
            state.controls.getAllBranchesErrorMessage = "";
        });
        builder.addCase(getAllBranchesByFilterThunk.rejected, (state, action) => {
            state.controls.isGetAllBranchesLoading = false;
            state.controls.getAllBranchesErrorMessage = action.error.message;
            state.branchListByPageSize = [];
            state.totalBranchCount = 0;
        });

        //--------------Add new Executive--------------------------------------------
        builder.addCase(addNewExecutiveThunk.pending, (state, action) => {
            state.controls.isAddExecutiveLoading = true;
            state.controls.addNewExecutiveErrorMessage = "";
        });
        builder.addCase(addNewExecutiveThunk.fulfilled, (state, action) => {
            state.controls.isAddExecutiveLoading = false;
            state.controls.showAddExecutiveForm = false;
            state.controls.addNewExecutiveErrorMessage = "";
        });
        builder.addCase(addNewExecutiveThunk.rejected, (state, action) => {
            state.controls.isAddExecutiveLoading = false;
            state.controls.addNewExecutiveErrorMessage = action.error.message;
        });

        //----------------get All Executives--------------------------------------------

        builder.addCase(getAllExecutiveThunk.pending, (state, action) => {
            state.controls.getAllExecutivesErrorMessage = "";
        });
        builder.addCase(getAllExecutiveThunk.fulfilled, (state, action) => {
            state.executiveList = action.payload.allExecutivesData;
            state.controls.getAllExecutivesErrorMessage = "";
        });
        builder.addCase(getAllExecutiveThunk.rejected, (state, action) => {
            state.executiveList = [];
            state.controls.getAllExecutivesErrorMessage = action.error.message;
        });

        //----------------get All Executives BY page Size--------------------------------------------

        builder.addCase(getAllExecutiveByPageSizeThunk.pending, (state, action) => {
            state.controls.isGetAllExecutivesLoading = true;
            state.controls.getAllExecutivesErrorMessage = "";
        });
        builder.addCase(getAllExecutiveByPageSizeThunk.fulfilled, (state, action) => {
            state.controls.isGetAllExecutivesLoading = false;
            state.executiveListByPageSize = action.payload.allExecutivesData;
            state.totalExecutiveCount = action.payload.totalCount;
            state.controls.getAllExecutivesErrorMessage = "";
        });
        builder.addCase(getAllExecutiveByPageSizeThunk.rejected, (state, action) => {
            state.controls.isGetAllExecutivesLoading = false;
            state.controls.getAllExecutivesErrorMessage = action.error.message;
            state.executiveListByPageSize = [];
            state.totalExecutiveCount = 0;
        });

        //-------------------addNew Branch Manager--------------------------------------------

        builder.addCase(addNewManagerThunk.pending, (state, action) => {
            state.controls.isAddManagerLoading = true;
        });
        builder.addCase(addNewManagerThunk.fulfilled, (state, action) => {
            state.controls.isAddManagerLoading = false;
            state.controls.showAddBranchManagerForm = false;
            state.controls.addNewBranchManagerErrorMessage = "";
        });
        builder.addCase(addNewManagerThunk.rejected, (state, action) => {
            state.controls.isAddManagerLoading = false;
            state.controls.addNewBranchManagerErrorMessage = action.error.message;
        });

        //----------------get All Branch Mangers--------------------------------------------

        builder.addCase(getAllManagersThunk.pending, (state, action) => { });
        builder.addCase(getAllManagersThunk.fulfilled, (state, action) => {
            state.branchManagersList = action.payload.allBranchManagerData;
            state.controls.getAllBranchMangersErrorMessage = "";
        });
        builder.addCase(getAllManagersThunk.rejected, (state, action) => {
            state.controls.getAllBranchMangersErrorMessage = action.error.message;
        });

        //----------------get All Branch Mangers By PageSize--------------------------------------------

        builder.addCase(getAllManagersByPageSizeThunk.pending, (state, action) => {
            state.controls.isGetAllManagersLoading = true;
        });
        builder.addCase(getAllManagersByPageSizeThunk.fulfilled, (state, action) => {
            state.controls.isGetAllManagersLoading = false;
            state.branchManagersListByPageSize = action.payload.allBranchManagerData;
            state.totalBranchManagerCount = action.payload.totalCount;
            state.controls.getAllBranchMangersErrorMessage = "";
        });
        builder.addCase(getAllManagersByPageSizeThunk.rejected, (state, action) => {
            state.controls.isGetAllManagersLoading = false;
            state.controls.getAllBranchMangersErrorMessage = action.error.message;
            state.branchManagersListByPageSize = [];
            state.totalBranchManagerCount = 0;
        });

        //----------------------------get All clients ------------------------------------------

        builder.addCase(getAllClientsThunk.pending, (state, action) => { });

        builder.addCase(getAllClientsThunk.fulfilled, (state, action) => {
            state.clientsList = action.payload.allClientsData;
            state.controls.getAllClientsErrorMessage = "";
        });

        builder.addCase(getAllClientsThunk.rejected, (state, action) => {
            state.controls.getAllClientsErrorMessage = action.error.message;
        });

        //----------------------------get All parent clients ------------------------------------------

        builder.addCase(getAllParentClientsThunk.pending, (state, action) => { });

        builder.addCase(getAllParentClientsThunk.fulfilled, (state, action) => {
            state.parentClientsList = action.payload.allParentClientsData;
            state.controls.getAllParentClientsErrorMessage = "";
        });

        builder.addCase(getAllParentClientsThunk.rejected, (state, action) => {
            state.controls.getAllParentClientsErrorMessage = action.error.message;
        });

        //----------------------------get All clients By page Size ------------------------------------------

        builder.addCase(getAllClientsByPageSizeThunk.pending, (state, action) => {
            state.controls.isGetAllClientsLoading = true;
            state.controls.getAllClientsErrorMessage = "";
        });

        builder.addCase(getAllClientsByPageSizeThunk.fulfilled, (state, action) => {
            state.controls.isGetAllClientsLoading = false;
            state.clientsListByPageSize = action.payload.allClientsData;
            state.totalClientsCount = action.payload.totalCount;
            state.controls.getAllClientsErrorMessage = "";
        });

        builder.addCase(getAllClientsByPageSizeThunk.rejected, (state, action) => {
            state.controls.isGetAllClientsLoading = false;
            state.controls.getAllClientsErrorMessage = action.error.message;
            state.clientsListByPageSize = [];
            state.totalClientsCount = 0;
        });

        //----------------------------get All clients Filter ------------------------------------------

        builder.addCase(getAllClientByFilterThunk.pending, (state, action) => {
            state.controls.isGetAllClientsLoading = true;
            state.controls.getAllClientsErrorMessage = "";
        });

        builder.addCase(getAllClientByFilterThunk.fulfilled, (state, action) => {
            state.controls.isGetAllClientsLoading = false;
            state.clientsListByPageSize = action.payload.allClientsData;
            state.totalClientsCount = action.payload.totalCount;
            state.controls.getAllClientsErrorMessage = "";
        });

        builder.addCase(getAllClientByFilterThunk.rejected, (state, action) => {
            state.controls.isGetAllClientsLoading = false;
            state.controls.getAllClientsErrorMessage = action.error.message;
            state.clientsListByPageSize = [];
            state.totalClientsCount = 0;
        });
        //----------------------------add new Client --------------------------------------------------

        builder.addCase(addNewClientThunk.pending, (state, action) => {
            state.controls.isAddClientLoading = true;
        });

        builder.addCase(addNewClientThunk.fulfilled, (state, action) => {
            state.controls.isAddClientLoading = false;
            state.controls.showAddClientForm = false;
            state.controls.addNewClientErrorMessage = "";
        });

        builder.addCase(addNewClientThunk.rejected, (state, action) => {
            state.controls.isAddClientLoading = false;
            state.controls.addNewClientErrorMessage = action.error.message;
        });

        //----------------------------add new Role --------------------------------------------------

        builder.addCase(addNewRolesThunk.pending, (state, action) => { });

        builder.addCase(addNewRolesThunk.fulfilled, (state, action) => {
            state.controls.showAddRolesForm = false;
            state.controls.addNewRolesErrorMessage = "";
        });

        builder.addCase(addNewRolesThunk.rejected, (state, action) => {
            state.controls.addNewRolesErrorMessage = action.error.message;
        });

        //--------------------------get All Roles -------------------------------------------------------------

        builder.addCase(getAllRolesThunk.pending, (state, action) => { });

        builder.addCase(getAllRolesThunk.fulfilled, (state, action) => {
            state.rolesList = action.payload.data;
        });

        builder.addCase(getAllRolesThunk.rejected, (state, action) => { });

        //----------------------------------get all roles by page Size ----------------------------------------

        builder.addCase(getAllRolesByPageSizeThunk.pending, (state, action) => { });

        builder.addCase(getAllRolesByPageSizeThunk.fulfilled, (state, action) => {
            state.rolesListByPageSize = action.payload.allRolesData;
            state.totalRolesListCount = action.payload.totalCount;
            state.controls.getAllRolesErrorMessage = "";
        });

        builder.addCase(getAllRolesByPageSizeThunk.rejected, (state, action) => {
            state.controls.getAllRolesErrorMessage = action.error.message;
        });
        //----------------------------------get all roles by filter ----------------------------------------

        builder.addCase(getAllRolesByFilterThunk.pending, (state, action) => { });

        builder.addCase(getAllRolesByFilterThunk.fulfilled, (state, action) => {
            state.rolesListByPageSize = action.payload.allRolesData;
            state.totalRolesListCount = action.payload.totalCount;
            state.controls.getAllRolesErrorMessage = "";
        });

        builder.addCase(getAllRolesByFilterThunk.rejected, (state, action) => {
            state.controls.getAllRolesErrorMessage = action.error.message;
        });

        //----------------get All Users--------------------------------------------

        builder.addCase(getAllUsersThunk.pending, (state, action) => { });
        builder.addCase(getAllUsersThunk.fulfilled, (state, action) => {
            state.usersList = action.payload.allUsersData;
            state.controls.getAllUsersErrorMessage = "";
        });
        builder.addCase(getAllUsersThunk.rejected, (state, action) => {
            state.controls.getAllUsersErrorMessage = action.error.message;
        });

        //----------------get All Users By Page Size--------------------------------------------

        builder.addCase(getAllUsersByPageSizeThunk.pending, (state, action) => {
            state.controls.isGetAllUsersLoading = true;
        });
        builder.addCase(getAllUsersByPageSizeThunk.fulfilled, (state, action) => {
            state.controls.isGetAllUsersLoading = false;
            state.userListByPageSize = action.payload.allUsersData;
            state.totalUsersCount = action.payload.totalCount;
            state.controls.getAllUsersErrorMessage = "";
        });
        builder.addCase(getAllUsersByPageSizeThunk.rejected, (state, action) => {
            state.controls.isGetAllUsersLoading = false;
            state.controls.getAllUsersErrorMessage = action.error.message;
        });

        //----------------get All Users By Filter--------------------------------------------

        builder.addCase(getAllUsersByFilterThunk.pending, (state, action) => {
            state.controls.isGetAllUsersLoading = true;
        });
        builder.addCase(getAllUsersByFilterThunk.fulfilled, (state, action) => {
            state.controls.isGetAllUsersLoading = false;
            state.userListByPageSize = action.payload.allUsersData;
            state.totalUsersCount = action.payload.totalCount;
            state.controls.getAllUsersErrorMessage = "";
        });
        builder.addCase(getAllUsersByFilterThunk.rejected, (state, action) => {
            state.controls.isGetAllUsersLoading = false;
            state.controls.getAllUsersErrorMessage = action.error.message;
        });

        //-----------------------------Add New User------------------------------------------------------------

        builder.addCase(addUpdateUserThunk.pending, (state, action) => {
            state.controls.isUpdateUsersLoading = true;
        });

        builder.addCase(addUpdateUserThunk.fulfilled, (state, action) => {
            state.controls.isUpdateUsersLoading = false;
            state.controls.showAddUserForm = false;
            state.controls.addNewUsersErrorMessage = "";
        });

        builder.addCase(addUpdateUserThunk.rejected, (state, action) => {
            state.controls.isUpdateUsersLoading = false;
            state.controls.addNewUsersErrorMessage = action.error.message;
        });

        //-----------------------------Get Master & Customize Rates------------------------------------------------------------

        builder.addCase(getMasterRatesThunk.pending, (state, action) => {
            state.controls.isGetAllRatesLoading = true;
        });

        builder.addCase(getMasterRatesThunk.fulfilled, (state, action) => {
            state.controls.isGetAllRatesLoading = false;
            state.masterRatesList = action.payload.allMasterRatesList;
            state.masterRatesTotalCount = action.payload.totalCount;
            state.controls.getMasterRateListErrorMessage = "";
        });

        builder.addCase(getMasterRatesThunk.rejected, (state, action) => {
            state.controls.isGetAllRatesLoading = false;
            state.controls.getMasterRateListErrorMessage = action.error.message;
        });

        builder.addCase(getAllCustomizedRate.pending, (state, action) => {
            state.controls.isGetAllRatesLoading = true;
        });

        builder.addCase(getAllCustomizedRate.fulfilled, (state, action) => {
            state.controls.isGetAllRatesLoading = false;
            state.customizedRate = action.payload;
            state.controls.addNewUsersErrorMessage = "";
        });

        builder.addCase(getAllCustomizedRate.rejected, (state, action) => {
            state.controls.isGetAllRatesLoading = false;
            state.controls.addNewUsersErrorMessage = action.error.message;
        });

        // ----------------------------get-current-balance-by-clientId----------------------------

        builder.addCase(fetchCurrentBalanceByClientId.pending, (state, action) => { });

        builder.addCase(fetchCurrentBalanceByClientId.fulfilled, (state, action) => {


            state.postpaidUserMaxLimit = action.payload.data.maxBalanceLimit;
            state.postpaidUserCapcheck = action.payload.data.capCheck;
            state.userBillingType = action.payload.data.billingPaymentType;
            if (action.payload.data.billingPaymentType === "Postpaid") {

                state.userCurrentBalance = action.payload.data.currentUsage;
            } else {
                state.userCurrentBalance = action.payload.data.currentBalance;
            }


        });

        builder.addCase(fetchCurrentBalanceByClientId.rejected, (state, action) => {
            state.userCurrentBalance = "NA";
        });
    },
});

export const {
    setShowAddBranchForm,
    setShowAddExecutiveForm,
    setShowAddBranchManagerForm,
    setShowAddClientForm,
    setShowAssignExecutiveForm,
    setSelectedManager,
    setShowEditExecutiveForm,
    setShowAddUserForm,
    setShowAddRoleForm,
    clearErrorMessagesForAllUsers,
    setAllCustomizedData,
    clearErrorMessageForBaseRate,
    setErrorMessageForUser,
} = userManagementSlice.actions;

export default userManagementSlice;
