import React, { useState, useEffect } from 'react'
import { useDebounce } from '../../../hooks/useDebounce';
import { cssClass } from '../../../utils/workflow-utils/cssClasses';
import { TextEditor } from '../TextEditor/TextEditor';

const allowedExtensionForMediaTypes = {
    image: "image/png, image/gif, image/jpeg",
    video: "video/mp4",
    documents: ""
}

function SingleMediaCard({ data, index, saveCondition, deleteCondition, variableNamespace }) {

    const updateObject = () => {
        saveCondition(index, mediaState);
    }

    const handleUpdate = useDebounce(updateObject, 80);
    const [mediaState, setMediaState] = useState({ mediaType: "select", fileUrl: "" });

    const [mediaTypeExtension, setMediaTypeExtension] = useState();

    const [errors, setError] = useState({});

    const getAcceptParams = (mediaType) => {
        // const
        //     setMediaTypeExtension();
    }

    const changeHandler = (event) => {
        const { value, name, id } = event.target;
        setMediaState({
            ...mediaState,
            [name]: value,
        });
    }

    const onFileSelect = (event) => {
        try {
            // extract file
            const { file, files } = event.target;

            // since we are allowing only one file per media card
            if (files.length === 1) {
                const selectedFile = files[0];

                // create a new global url object and pass it to the fileurl
                const url = new URL.createObjectURL(selectedFile);



            } else {
                throw new Error("incorrect file count");
            }

        } catch (error) {
            console.log(error);
        }
    }


    const removeCondition = () => {
        deleteCondition(index);
    }

    useEffect(() => {
        handleUpdate();
    }, [mediaState]);

    return (
        <React.Fragment>
            <div className="w-full mt-2">
                <div className="block p-6 rounded-lg shadow-lg bg-white ">
                    {/* <h5 className={`${cssClass['card-title']}`}>Card title</h5> */}
                    <div className='mb-3'>
                        <label htmlFor="mediaType"> Select Media Type </label>
                        <select
                            name="mediaType"
                            id="mediaType"
                            onChange={changeHandler}
                            value={mediaState.mediaType}
                            className={`${cssClass['pin-bot-form-select']}`}
                        >
                            <option value="select" selected={true}>Select Media</option>
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                            <option value="document">Document</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="formFile"
                            className={`${cssClass['pin-bot-form-label']}`}
                        >
                            Select Media
                        </label>
                        <input
                            className={`${cssClass['pin-bot-form-file']}`}
                            type="file"
                            id="formFile"
                            onChange={onFileSelect}
                            multiple={false}
                        // accept="image/png, image/gif, image/jpeg"
                        />
                    </div>

                    <div className='flex justify-end'>
                        <button type="button" className={`${cssClass['btn-primary']}`} onClick={removeCondition}>Remove</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default function MediaNodePropertyMenu({ node, variableNamespace, updateMessageNode }) {


    const [medias, setMedias] = useState([]);
    const [selecttedMediaType, setSelectedMediaType] = useState("");

    const addMedia = () => {
        setMedias([...medias, {}]);
    }

    const deleteMedia = () => {

    }

    const saveMediaCard = () => {

    }


    return (
        <>
            <div className='flex flex-col'>

                <div className='flex flex-row justify-between'>
                    <h2> Media </h2>
                    <button className={`${cssClass['btn-primary']}`} onClick={addMedia}> Add Media </button>
                </div>
                <hr />



                {
                    medias && medias.map((media, idx) => {
                        return <SingleMediaCard
                            data={media}
                            index={idx}
                            deleteCondition={deleteMedia}
                            saveCondition={saveMediaCard}
                            variableNamespace={variableNamespace}
                            key={`single-media-card-${idx}`}
                        />
                    })
                }
            </div>
        </>
    )
}
