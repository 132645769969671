import { CardActions, CardContent, Card, Typography, TextField, Alert, Button } from '@mui/material'
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { setShowAddRoleForm } from '../../../redux/userManagement/userManagementSlice';
import { addNewRolesThunk } from '../../../redux/userManagement/userManagementThunk';

export default function AddRoles() {
    const state = useSelector((state) => state.userManagementSlice);
    const dispatch = useDispatch();

    const onClickCancelButton = () => {
        dispatch(setShowAddRoleForm(false));
    }



    const formik = useFormik({
        initialValues: {

            roleName: "", 
            submit: false,
        },
        validationSchema: Yup.object().shape({
            roleName: Yup.string().max(255).required("Role Title is required"),
        }),

        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {

                const dataToSend = {

                    roleName: values.roleName,

                };
                dispatch(addNewRolesThunk({ data: dataToSend }));
                
            } catch (error) {

                const message = formik.errors.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
            }
        },
    });
 

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Card>
                <CardContent sx={{ padding: "10px" }}>
                    <Typography variant='h4'><b className='text-primary'>Add New Role</b></Typography>
                    <hr />
                    <div className='row mt-3'>
                        <div className='col-6'>
                            <div> <TextField
                                name='roleName'
                                fullWidth
                                label="Role Name"
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.roleName && formik.errors.roleName)}
                                helperText={formik.touched.roleName && formik.errors.roleName}
                                value={formik.values.roleName}
                                onChange={formik.handleChange}
                            />
                            </div>
                        </div>
                    </div>
                </CardContent>
                <CardActions sx={{ padding: "0px" }}>
                    <div className='d-flex '>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={formik.isSubmitting}
                            className='m-2'
                        >
                            Save
                        </Button>
                         
                        <Button
                            variant='contained'
                            color="error"
                            onClick={onClickCancelButton}
                            className='m-2'
                        >
                            Cancel
                        </Button>
                    </div>
                </CardActions>
            </Card>
            {state.controls.addNewRolesErrorMessage && (
                <Alert mt={2} mb={3} severity="error">
                    {state.controls.addNewRolesErrorMessage}
                </Alert>
            )}
        </form>
    );
}
