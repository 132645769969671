import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

import { DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from "react";
import FetchingSpinner from '../components/FetchingSpinner';
import { useSelector } from "react-redux";

//import { DatePicker, DateTimePicker } from "@mui/lab";





export default function CancelScheduleCampaignModel({
    open,
    setOpen,
    handleClose,

    campaign,
    cancelScheduleCampaign,

}) {
    const [loading, setLoading] = useState(false);




    const calculateTime = value => {
        const time = new Date(value).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
        const day = new Date(value).toLocaleDateString([], { day: "2-digit" });
        const month = new Date(value).toLocaleDateString([], { month: "2-digit" });
        const year = new Date(value).toLocaleDateString([], { year: "numeric" });

        return (
            // <Typography className='tableContentFontForDateAndTime'>
            <>
                {day}-{month}-{year} {time}
            </>
            // </Typography>
        );
        // return (day + "-" + month + "-" + year + " , " + time);
    };

    const state = useSelector((slice) => slice.userManagementSlice);

    const onClickCancelScheduleCampaign = async () => {

        setLoading(true);
        try {

            const response = await cancelScheduleCampaign(campaign._id)
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (error) {
            setLoading(false);

        }



    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // sx={{ minWidth: "100px !important" }}
                sx={{ position: "absolute", top: "150px" }}
            >
                <DialogTitle id="alert-dialog-title">Cancel Schedule Campaign</DialogTitle>
                <DialogContent  >



                    {/* <Grid>
                        <Typography>
                            scheduleCampaignTime  :  {calculateTime(campaign.scheduleCampaignTime)}
                        </Typography>

                    </Grid> */}

                    <Typography variant="h6">
                        Sure do you want to Cancel "{campaign?.campaignName}" Campaign ?
                    </Typography>


                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                    >
                        Back
                    </Button>
                    <Button
                        onClick={() => onClickCancelScheduleCampaign()}
                        aria-label="sent"
                        disabled={loading}
                        variant="contained"
                        color="error"

                    >

                        {loading && <CircularProgress
                            size={20}
                        />} &nbsp;
                        Cancel&nbsp;
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}