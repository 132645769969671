import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { CampaignOutlined, Category } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import FetchingSpinner from "../components/FetchingSpinner";
import { useSelector } from "react-redux";

export default function RunCampaignModel ({
    open,
    setOpen,
    handleClose,
    campaign,
    handleRunCampaign,
    isRunCampaignLoading,
    isGetAllocatedAmountLoading,
}) {
    const state = useSelector(slice => slice.userManagementSlice);
    const runCampaign = () => {
        handleRunCampaign(campaign);
    };

    useEffect(() => {
        if (isRunCampaignLoading.status === "success" && campaign._id === isRunCampaignLoading?.id) {
            handleClose();
        }
    }, [isRunCampaignLoading]);


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                sx={{ minWidth: "100px !important" }}
            >
                <DialogTitle id='alert-dialog-title'>
                    <Typography variant='h3'>
                        Run <b>{campaign.campaignName}</b> Campaign
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {isGetAllocatedAmountLoading ? (
                        <Grid padding={15}>
                            <FetchingSpinner />
                        </Grid>
                    ) : (
                        <>
                            <Grid>
                                <Typography m={1}>Total RCS enabled numbers to process : &nbsp; {campaign.totalRcsEnabledNumbers}</Typography>
                                <Typography m={1}>
                                    Total RCS messages that can be sent in available balance : &nbsp; {campaign.messageCountAbleToSend}
                                </Typography>
                                <Typography variant='body1' m={1}>
                                    Estimated balance to run campaign : &nbsp; Rs.{campaign.allocatedAmount?.toFixed(2)}/-
                                </Typography>

                                {state.userBillingType === "Postpaid" ? (
                                    <Typography variant='body1' m={1}>
                                        Total usage will be : &nbsp; Rs.{campaign?.remainingCurrentBalanceAfterDeduction.toFixed(2)}/-
                                    </Typography>
                                ) : (
                                    <Typography variant='body1' m={1}>
                                        Remaining Balance : &nbsp; Rs.{campaign?.remainingCurrentBalanceAfterDeduction.toFixed(2)}/-
                                    </Typography>
                                )}
                            </Grid>
                            <hr />
                            

                            {campaign?.messageCountAbleToSend < campaign?.totalRcsEnabledNumbers && state.userBillingType === "Prepaid" && (
                                <Grid>
                                    <Typography variant='h6' color={"red"} m={1}>
                                        Your current balance is low, all messages will not be processed.
                                    </Typography>
                                </Grid>
                            )}


                            {Number(campaign?.totalRcsEnabledNumbers) === 0 && 
                            <Grid>
                            <Typography variant='h6' color={"red"} m={1}>
                            In this campaign, there are no RCS-enabled numbers.
                            </Typography>
                        </Grid>
                            }
                            {campaign?.messageCountAbleToSend < campaign?.totalRcsEnabledNumbers &&
                                state.postpaidUserCapcheck &&
                                state.userBillingType === "Postpaid" && (
                                    <Grid>
                                        <Typography variant='h6' color={"red"} m={1}>
                                            You're exceeding max balance limit, all messages will not be processed.
                                        </Typography>
                                    </Grid>
                                )}

                            {campaign.messageCountAbleToSend != 0 && (
                                <DialogContentText id='alert-dialog-description' m={1}>
                                    Sure do you want to run the campaign ?
                                </DialogContentText>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {isRunCampaignLoading.status === "pending" && campaign._id === isRunCampaignLoading.id ? (
                        <Button aria-label='pending' color='primary' variant='contained'>
                            &nbsp; <CircularProgress size={20} /> &nbsp; &nbsp;Running........ &nbsp;
                        </Button>
                    ) : (
                        <Button
                            aria-label='sent'
                            color='primary'
                            disabled={isGetAllocatedAmountLoading || campaign.messageCountAbleToSend === 0}
                            variant='contained'
                            title='Run Campaign'
                            onClick={() => runCampaign(campaign)}
                        >
                            <CampaignOutlined fontSize='small' /> &nbsp;Run
                        </Button>
                    )}
                    <Button onClick={handleClose} variant='outlined' color='error'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
