import { useDispatch, useSelector } from "react-redux";
import ListTemplates from "./ListTemplates";
import { useEffect, useState } from "react";
import CardComponent from "./CardComponent";
import { setShowAddTemplateForm } from "../../../redux/templateManagement/templateManagementSlice";
import AddTemplate from "./addTemplate/AddTemplate";
import { Grid } from "@mui/material";

function ManageTemplates() {
    const state = useSelector((state) => state.templateManagementSlice);
    const [templateDetails, setTemplateDetails] = useState(null);
    const [selectedBotName, setSelectedBotName] = useState("");
    const [searchParams, setSearchParams] = useState({
        templateType: "",
        templateName: "",
        startDate: "",
        // endDate: "",
        status: "",
    });
    const [editable, setEditable] = useState(true);
    const dispatch = useDispatch();
    const handleTemplateChange = ({ templateData, val }) => {
        setTemplateDetails(templateData);
        setEditable(val);
        dispatch(setShowAddTemplateForm(true));
    };

    const handleIsEditable = () => {
        setEditable(true);
    };
    useEffect(() => {
        dispatch(setShowAddTemplateForm(false));
        setTemplateDetails(null);
    }, []);


    return (
        <Grid>
            {state.controls.showAddTemplateForm ? (
                <AddTemplate
                    templateData={templateDetails}
                    setTemplateDetails={setTemplateDetails}
                    isTempalteEditable={editable}
                    setEditable={setEditable}
                    selectedBotName={selectedBotName}
                />
            ) : (
                <ListTemplates
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    handleViewDetailsChange={handleTemplateChange}
                    selectedBotName={selectedBotName}
                    setSelectedBotName={setSelectedBotName}
                />
            )}
        </Grid>
    );
}

export default ManageTemplates;
