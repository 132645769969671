import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Grid, Paper, Typography } from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { ReactComponent as Logo } from "../../vendor/PinnacleTeleservicesLogo.svg";
// import PinnacleLogo from "../../assets/icons/pinnacle-logo.png";
import SignInComponent from "../../components/auth/SignIn";
import { REACT_APP_WHITELABEL_LOGO_URL } from "../../utils/axios";
import FetchingSpinner from "../components/FetchingSpinner";

const Brand = styled(Logo)`
    fill: ${props => props.theme.palette.primary.main};
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
`;

const Wrapper = styled(Paper)`
    padding: ${props => props.theme.spacing(6)};

    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(10)};
    }
`;

const BigAvatar = styled(Avatar)`
    width: 92px;
    height: 92px;
    text-align: center;
    margin: 0 auto ${props => props.theme.spacing(5)};
`;

function SignIn () {
    const [logoURL, setLogoURL] = useState(REACT_APP_WHITELABEL_LOGO_URL);
    let intervalID = null;

    const [IsLoginPageLoading, setIsLoginPageLoading] = useState(true);

    // useEffect(() => {
    //     setIsLoginPageLoading(true);
    //     setLogoURL(REACT_APP_WHITELABEL_LOGO_URL);
    //     console.log(REACT_APP_WHITELABEL_LOGO_URL, "REACT_APP_WHITELABEL_LOGO_URL");
    //     setIsLoginPageLoading(false);
    // }, []);

    useEffect(() => {
        if (logoURL) {
            setIsLoginPageLoading(false);
            intervalID = null;
        } else {
            intervalID = setInterval(() => {
                setLogoURL(REACT_APP_WHITELABEL_LOGO_URL);
            }, 500);
        }
    }, [logoURL]);

    // if (!logoURL) {
    //     intervalID = setTimeout(() => {
    //         setLogoURL(REACT_APP_WHITELABEL_LOGO_URL);
    //     }, 500);
    // }

    return (
        <>
            {IsLoginPageLoading ? (
                <FetchingSpinner />
            ) : (
                <Grid container className='loginPage'>
                    {/* <Grid item md={2} alignContent={"flex-start"}>
        </>
                <Brand />
            </Grid> */}
                    <Grid item md={2} alignContent={"flex-start"}>
                        <img src={logoURL} className='login-page-logo-img' />
                    </Grid>
                    <Grid alignItems={"center"} md={10}>
                        <Wrapper>
                            <Helmet title='Sign In' />
                            {/* <BigAvatar alt="User" src="/static/img/avatars/user-placeholder.jpg" /> */}

                            {/* <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome back, Lucy!
        </Typography> */}
                            <Typography variant='h2' align='center'>
                                Sign In
                            </Typography>

                            <SignInComponent />
                        </Wrapper>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default SignIn;
