import React, { useEffect, useState } from 'react'
import { useDebounce } from '../../../hooks/useDebounce';
import { generateUniqueIdForCard } from '../../../utils/workflow-utils/helpers';

import closeIcon from '../../../assets/icons/close-icon.svg';
import { TextEditor } from '../TextEditor/TextEditor';
import { validateButtonNode } from '../../../utils/workflow-utils/channelValidations';

export default function ButtonNodePropertyMenu({ node, variableNamespace, updateButtonNode, deleteButtonsNode }) {


    const [buttons, setButtons] = useState(node.data.contents ?? []);
    const [errors, setErrors] = useState({});
    const [buttonName, setButtonName] = useState("");


    const buttonNameChangeHandler = (event) => {
        const { value } = event.target;
        setButtonName(value);
    }

    const addButton = () => {
        // const validationResult = validateButtonNode(1, node.type, buttons);
        // if (validationResult === true) {

        // }

        setErrors({});
        if (buttonName && typeof buttonName === 'string' && buttonName.trim().length > 0) {
            // const newButtonItemId=generateUniqueIdForCard();
            // const newButton = {
            //     id: newButtonItemId,
            //     value: buttonName,
            //     handleId:`${node.id}_${newButtonItemId}`
            // }
            // const newButtons = [...buttons, newButton];
            updateButtonNode(node.id, buttonName);
            setButtonName("");
        } else {
            setErrors({ buttonName: "Please Enter Button Name" });
        }
    }

    const deleteButton = (id) => {

        let newButtons = buttons.filter((con) => con.id !== id);
        deleteButtonsNode(node.id, id);
        updateButtonNode(node.id, newButtons);
        setButtons(newButtons);
    }

    // TODO : add styles to button

    return (
        <div key={`button_node_${node.id}`}>
            <div>
                <div className='flex align-item-center justify-around'>
                    <input
                        className='border-2 border-gray-300 text-base rounded px-1'
                        type="text"
                        placeholder='Button Name'
                        name={"buttonName"}
                        onChange={buttonNameChangeHandler}
                        value={buttonName}
                    />
                    <button className='bg-yellow-300 rounded w-35 p-1 px-2 text-base border-2 border-gray-400' onClick={addButton}> Add Button </button>
                </div>
                {
                    errors && errors.buttonName &&
                    <p className='text-xs px-2' style={{ color: '#F96363' }}>  {errors.buttonName} </p>
                }
            </div>


            {
                buttons && buttons.length > 0 && buttons.map((btn, idx) => {
                    return (
                        <div className='p-2 flex justify-between' key={`button_node_item_${idx}`}>
                            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' key={`button-node${idx}`}> {btn.value} </button>

                            <button title="Delete" className='card-icon-button text-right' onClick={() => deleteButton(btn.id)}><img className='card-icon' src={closeIcon} /></button>
                        </div>
                    )
                })
            }


        </div>
    )
}
