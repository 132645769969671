import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";
import { purple } from "@mui/material/colors";

const createTheme = (name) => {
  let themeConfig = variants.find((variant) => variant.name === name);
  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-ignore
      components: components,
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    },
    {
      palette: {
        primary: {
          main: '#1984d9',
        },
        secondary: {
          main: '#9a9898',
        },
        warning: {
          // main: '#ffca28',
          main: '#ffa500',
        },
        info: {
          main: "#8e24aa"
        },
        error: {
          main: '#e90e0ede',
        },
        success: {
          main: '#43a047'
        },
        pending: {
          main: "#ffa500"
        }
      },
    }
  );
};

export default createTheme;
