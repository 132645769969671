import React, { useEffect } from 'react'
import { getAllVerifiedBotsToRunCampaignThunk } from '../../../redux/botManagement/botManagementThunk';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Chip, Grid, IconButton, MenuItem, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { BotStatus, ERoles } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import SubjectIcon from '@mui/icons-material/Subject';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 20,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 17,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function BotListApiReport({ setShowBotReport, setSelectedBot, selectedBot }) {
  const dispatch = useDispatch();

  const state = useSelector(state => state.botManagementSlice);

  const { user } = useAuth();

  const onClickViewReport = (bot) => {
    setSelectedBot(bot);
    setShowBotReport(true);
  }

  useEffect(() => {
    // dispatch(getAllVerifiedBotsThunk());
    dispatch(getAllVerifiedBotsToRunCampaignThunk());

  }, []);
  return (
    <Grid>
      <Grid container mb={4} className='listHeader'>
        <Grid item md={6} className='align-items-center'>
          <Typography variant='h3'>
            <b>API Report</b>
          </Typography>
        </Grid>

        {/* <Grid item md={6} className="listHeaderFilter">
                    <Grid display={"flex"} flexDirection={"row"} gap={2}>
                        <IconButton
                            title='Refresh'
                            onClick={reloadList}
                            sx={{
                                animation: state.controls.isGetAllCampaignsLoading ? "spin 0.5s linear infinite" : "",
                                "@keyframes spin": {
                                    "0%": {
                                        transform: "rotate(360deg)",
                                    },
                                    "100%": {
                                        transform: "rotate(0deg)",
                                    },
                                },
                                // marginInline: "20px",
                            }}
                        >
                            <CachedIcon />
                        </IconButton>
                        <Button variant='contained' onClick={handleClickOpenFilterModel}>
                            Filter
                        </Button>
                        {user && user.roleName.toLowerCase() !== ERoles.SUPER_ADMIN && (
                            <>
                                <Button
                                    variant='contained'
                                    onClick={onClickAddNewCampaign}
                                // disabled={
                                //     state.totalCampaignsCount &&
                                //     state.totalCampaignsCount >=
                                //     50
                                // }
                                >
                                    Add New Campaign
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid> */}
      </Grid>



      <Card className='border border-secondary-50'>
        <CardContent>
          <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "70vh" }}>
            <Table stickyHeader aria-label='simple table'>
              <TableHead className='p-3 mb-2 row'>
                <TableRow>
                  <StyledTableCell className='tableHeaderFont' align='center'>
                    Sr No
                  </StyledTableCell>
                  <StyledTableCell className='tableHeaderFont' align='left'>
                    Bot
                  </StyledTableCell>
                  {user?.roleName.toLowerCase() == ERoles.SUPER_ADMIN && (
                    <StyledTableCell className='tableHeaderFont' align='left'>
                      Client Name
                    </StyledTableCell>
                  )}

                  <StyledTableCell className='tableHeaderFont' align='left'>
                    Brand
                  </StyledTableCell>

                  <StyledTableCell className='tableHeaderFont' align='center'>
                    Bot Message Type
                  </StyledTableCell>



                  <StyledTableCell className='tableHeaderFont' align='center'>
                    View Report
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.verifiedBotListToRunCampaign?.map((bot, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className='tableContentFont' align='center'>
                      <b>{index + 1}</b>
                    </StyledTableCell>
                    <StyledTableCell className='listBotTableContentFont' align='left'>

                      <Grid container sx={{ display: "flex", alignItems: "center" }} >
                        <Grid item md={3}>

                          {bot.botLogoImage.url ?

                            <img src={bot.botLogoImage.url} height={"40px"} width={"40px"} display={'block'} />
                            :
                            <SmartToyOutlinedIcon />

                          }
                        </Grid>
                        <Grid item md={9}>

                          {bot.botName}
                        </Grid>
                      </Grid>
                    </StyledTableCell>

                    {user?.roleName.toLowerCase() == ERoles.SUPER_ADMIN && (
                      <StyledTableCell className='tableContentFont' align='left'>
                        {bot.clientName}
                      </StyledTableCell>
                    )}

                    <StyledTableCell className='listBotTableContentFont' align='left'>
                      {bot.brandName}
                    </StyledTableCell>

                    <StyledTableCell className='listBotTableContentFont' align='center'>
                      {bot.botMessageType}
                    </StyledTableCell>

                    <StyledTableCell className='listBotTableContentFont' align='center'>

                      <IconButton onClick={() => onClickViewReport(bot)}>

                        <SubjectIcon />
                      </IconButton>
                    </StyledTableCell>

                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  )
}
