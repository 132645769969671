import React, { useEffect } from 'react';
import { getBezierPath, getMarkerEnd, getEdgeCenter, Background } from 'react-flow-renderer';
import { ContextMenu } from '../ContextMenu';
import closeIcon from '../../../assets/icons/close-icon.svg';



const foreignObjectSize = 20;

export function UnidirectionEdge(props) {

    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        data,
        markerEnd,
        selected
    } = props;


    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

 

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            {selected === true &&
                <foreignObject
                    width={foreignObjectSize}
                    height={foreignObjectSize}
                    x={sourceX+5}
                    y={sourceY+5}
                >
                    <button title="Delete" className='card-icon-button' onClick={(event) => { data.methods.onDelete(event, id) }}><img className='w-20 bg-white border-2 border-black rounded-full z-10' src={closeIcon} /></button>
                </foreignObject>
            }
        </>
    );
}
