// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const PHONE_NUMBER_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const PHONE_NUMBER_OBJECT = {
  countryCode: "+91",
  country: "India",
  number: "",
  label: "",
};

export const EMAIL_OBJECT = {
  email: "",
  label: "",
};

export const WEBSITE_OBJECT = {
  url: "",
  label: "",
};
