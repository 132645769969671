import React, { useState } from 'react';
import { MasterCardLayout } from './MasterCardLayout';
import { ContextMenu } from '../ContextMenu';
import { Handle, Position } from 'react-flow-renderer';
import deleteImage from '../../../assets/icons/delete-icon.svg'
import { generateUniqueIdForCard } from '../../../utils/workflow-utils/helpers';


export function ConditionCard(props) {
    const { selected, data, type, id: ifElseCardId } = props;
    const methods = data.methods;
    // const [conditionInput,setConditionInput]=useState();
    const [contents, setContents] = useState({
        checkType: null,
        conditions: [{
            id: generateUniqueIdForCard(),

            isCompareToText: true,
            compareToType: 'userInput',
            compareToValue: '',

            conditionType: 'less than',

            isCompareWithText: true,
            compareWithType: 'userInput',
            compareWithValue: ''
        }]
    });

    return (
        <MasterCardLayout
            title={data.title}
            tailwindClass={data.cardTailwindStyle}
            key={`simple-condition-master-card-${ifElseCardId}`}
            startNode={data?.isStartCard}
            nodeData={data}
        >
            {
                selected === true &&
                // Show context menu
                <ContextMenu
                    onDuplicate={(event) => { methods.onDuplicate(event, ifElseCardId) }}
                    onDelete={methods.onDelete}
                    nodeId={ifElseCardId}
                    methods={methods}
                    isStartCard={data.isStartCard}
                />
            }
            <div className="text-updater-node" id={`${ifElseCardId}`}>
                <Handle className='target-handle' type="target" position={Position.Left} />
                <div className=''>
                    <div className='p-2 text-center border-2 border-gray-100 m-2 rounded bg-teal-100'>
                        <label>True</label>
                        <Handle id={`${ifElseCardId}_true`} type="source" className='source-handle' position={Position.Right} style={{ top: `65px` }} />
                    </div>
                    <div className='p-2 text-center border-2 border-gray-100 m-2 rounded bg-red-100'>
                        <label  >False</label>
                        <Handle id={`${ifElseCardId}_false`} type="source" className='source-handle' position={Position.Right} style={{ top: `120px` }} />
                    </div>
                </div>
            </div>
        </MasterCardLayout>
    );
}

