import React, { useState } from "react";
import { MasterCardLayout } from "./MasterCardLayout";
import { ContextMenu } from "../ContextMenu";
import { Handle, Position } from "react-flow-renderer";
import deleteImage from "../../../assets/icons/delete-icon.svg";
import { generateUniqueIdForCard } from "../../../utils/workflow-utils/helpers";

export function SwitchConditionNode(props) {
  const { selected, data, type, id: ifElseCardId } = props;
  const methods = data.methods;
  // const [conditionInput,setConditionInput]=useState();
  const [contents, setContents] = useState({
    checkType: null,
    conditions: [
      {
        id: generateUniqueIdForCard(),

        isCompareToText: true,
        compareToType: "userInput",
        compareToValue: "",

        conditionType: "less than",

        isCompareWithText: true,
        compareWithType: "userInput",
        compareWithValue: "",
      },
    ],
  });
  const [bindConditionsButton, setBindConditionsButton] = useState(true);

  const setCompareToValue = (e, con) => {
    const value = e.target.value;

    let newConditionsArray = contents.conditions.map((condition) => {
      if (condition.id === con.id) {
        if (value === "compareTo") {
          return {
            ...condition,
            isCompareToText: true,
            compareToType: "userInput",
            compareToValue: "",
          };
        } else {
          return {
            ...condition,
            isCompareToText: false,
            compareToType: "variable",
            compareToValue: value,
          };
        }
      }
      return condition;
    });
    setContents({ ...contents, conditions: newConditionsArray });
  };

  //   const setCompareToValue = (e, con) => {
  //       const value = e.target.value;

  //   let newConditionArray = contents.conditions.map((condition) => {
  //     if (condition.id === con.id) {
  //       if (value === "compareWith") {
  //         return {
  //           ...condition,
  //           isCompareWithText: true,
  //           compareWithType: "userInput",
  //           compareWithValue: "",
  //         };
  //       } else {
  //         return {
  //           ...condition,
  //           isCompareWithText: false,
  //           compareWithType: "variable",
  //           compareWithValue: value,
  //         };
  //       }
  //     }
  //     return condition;
  //   });
  //   setContents({ ...contents, conditions: newConditionArray });
  // };

  const updateCompareInput = (e, con, type) => {
    const value = e.target.value;

    let newConditionArray = contents.conditions.map((condition) => {
      if (condition.id === con.id) {
        if (type === "compareTo") {
          return {
            ...condition,
            compareToValue: value,
          };
        } else if (type === "compareWith") {
          return {
            ...condition,
            compareWithValue: value,
          };
        }
      }
    });
    setContents({ ...contents, conditions: newConditionArray });
  };

  const setConditionType = (e, con) => {
    const value = e.target.value;

    let newConditionArray = contents.conditions.map((condition) => {
      if (condition.id === con.id) {
        return {
          ...condition,
          conditionType: value,
        };
      }
    });
    setContents({ ...contents, conditions: newConditionArray });
  };

  const addCondition = () => {
    setContents({
      ...contents,
      conditions: [
        ...contents.conditions,
        {
          id: generateUniqueIdForCard(),

          isCompareToText: true,
          compareToType: "userInput",
          compareToValue: "",

          conditionType: "less than",

          isCompareWithText: true,
          compareWithType: "userInput",
          compareWithValue: "",
        },
      ],
    });
    setBindConditionsButton(false);
  };

  const deleteCondition = (index) => {
    let newCondition = [];
    contents.conditions.forEach((con, idx) => {
      if (idx !== index + 1) {
        newCondition.push(con);
      }
    });

    setBindConditionsButton(true);
    setContents({ ...contents, conditions: newCondition });
  };

  return (
    <MasterCardLayout
      title={data.title}
      tailwindClass={data.cardTailwindStyle}
      key={`switch-master-card-${ifElseCardId}`}
      startNode={data?.isStartCard}
      nodeData={data}
    >
      {selected === true && (
        // Show context menu
        <ContextMenu
          onDuplicate={(event) => {
            methods.onDuplicate(event, ifElseCardId);
          }}
          onDelete={methods.onDelete}
          nodeId={ifElseCardId}
          methods={methods}
          isStartCard={data.isStartCard}
        />
      )}
      <div className="text-updater-node" id={`${ifElseCardId}`}>
        <Handle
          className="target-handle"
          type="target"
          position={Position.Left}
        />
        <div style={{ padding: "10px" }}>
          <div>
            {contents.conditions.map((con, idx) => {
              return (
                <React.Fragment key={`condition-card-${ifElseCardId}-${idx}`}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {con.isCompareToText && (
                        <input
                          value={con.compareToValue}
                          onChange={(e) => {
                            updateCompareInput(e, con, "compareTo");
                          }}
                          className="condition-card-input-box"
                          type="text"
                          placeholder="type here..."
                        />
                      )}
                      <select
                        onChange={(e) => {
                          setCompareToValue(e, con);
                        }}
                        className="select-option-box"
                      >
                        <option>compareTo</option>
                        <option>$variant1</option>
                        <option>$variant2</option>
                        <option>$variant3</option>
                      </select>
                    </div>
                    <select
                      defaultValue={con.condition}
                      onChange={(e) => {
                        setConditionType(e, con);
                      }}
                      className="select-option-box"
                      style={{ width: "110px" }}
                    >
                      <option>less than</option>
                      <option>less than equal to</option>
                      <option>greater than</option>
                      <option>greater than equal to</option>
                      <option>equal to</option>
                      <option>not equal to</option>
                    </select>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {con.isCompareWithText && (
                        <input
                          value={con.compareWithValue}
                          onChange={(e) => {
                            updateCompareInput(e, con, "compareWith");
                          }}
                          className="condition-card-input-box"
                          type="text"
                          placeholder="type here..."
                        />
                      )}
                      <select
                        onChange={(e) => {
                          setCompareToValue(e, con);
                        }}
                        className="select-option-box"
                      >
                        <option>compareWith</option>
                        <option>$variant1</option>
                        <option>$variant2</option>
                        <option>$variant3</option>
                      </select>
                    </div>
                  </div>

                  {!bindConditionsButton && idx === 0 && (
                    <>
                      <label
                        className="and-or-condition"
                        style={{ border: "none" }}
                      >
                        <select className="select-option-box">
                          <option>AND</option>
                          <option>OR</option>
                        </select>
                        <button
                          className="card-icon-button"
                          onClick={() => {
                            deleteCondition(idx);
                          }}
                        >
                          <img
                            className="card-icon"
                            src={deleteImage}
                            alt="delete"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </button>
                      </label>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {bindConditionsButton && (
            <input
              className="button"
              onClick={addCondition}
              type="button"
              value="Add Condition"
            />
          )}
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <label className="label-type">True</label>
            <Handle
              id={`${ifElseCardId}_true`}
              type="source"
              className="source-handle"
              position={Position.Right}
              style={{ left: `150px` }}
            />

            <label className="label-type">False</label>
            <Handle
              id={`${ifElseCardId}_false`}
              type="source"
              className="source-handle"
              position={Position.Right}
              style={{ left: `245px` }}
            />
          </div>
        </div>
      </div>
    </MasterCardLayout>
  );
}
