import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar } from '@mui/material';
import React, { Fragment } from 'react';


export default function SuccessMessageComponent({ message, fieldName, handleClose, show }) {
    const onCloseClick = () => {
        handleClose({ fieldName });
    }
    return (
        <Snackbar
            open={show}
            autoHideDuration={4000}
            onClose={onCloseClick}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            action={
                <Fragment>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={onCloseClick}
                    >
                        <CloseIcon />
                    </IconButton>
                </Fragment>
            }
        >
            <Alert severity="success" sx={{ width: "100%" }} onClose={onCloseClick}>{message}</Alert>
        </Snackbar>
    )
}
