import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddBrand from "./addBrand";
import ListBrands from "./listBrands";
import { useEffect } from "react";
import { setShowAddBrandForm } from "../../../redux/brandManagement/brandManagementSlice";
import { useFormik } from "formik";
import ViewBrandDetails from "./viewBrandDetails";

export default function ManageBrands() {
  const state = useSelector((state) => state.brandManagementSlice);
  const dispatch = useDispatch();
  const [brand, setBrand] = useState({});
  const [showViewBrandForm, setShowViewBrandForm] = useState(false);
  const [searchParams, setSearchParams] = React.useState({
    brandName: "",
    brandStatus: "",
  });
  const handleViewBrandDetails = (BrandDetails) => {
    setBrand(BrandDetails)
    setShowViewBrandForm(true);
  }

  useEffect(() => {
    dispatch(setShowAddBrandForm(false));
  }, []);

  return (
    <div >{
      state.controls.showAddBrandForm ? <AddBrand /> :

        showViewBrandForm ? <ViewBrandDetails setShowViewBrandForm={setShowViewBrandForm} brand={brand} /> :
          <ListBrands searchParams={searchParams} setSearchParams={setSearchParams} handleViewBrandDetails={handleViewBrandDetails} />


    }</div>
  );
}
