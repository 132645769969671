// import React, { useState, useRef, useEffect, useMemo } from 'react';
// import '../../../assets/editorstyles.css';
// import ShortTextIcon from '@mui/icons-material/ShortText';
// import TitleIcon from '@mui/icons-material/Title';
// import ListAltIcon from '@mui/icons-material/ListAlt';
// import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
// import FormatBoldIcon from '@mui/icons-material/FormatBold';
// import FormatItalicIcon from '@mui/icons-material/FormatItalic';
// import { TextEditorReadOnly } from './TextEditorReadOnly';
// import { useDebounce } from '../../../hooks/useDebounce';
// import data from '@emoji-mart/data'
// import { Picker } from 'emoji-mart';
// // Import the Slate editor factory.
// import { createEditor } from 'slate';
// // Import the Slate components and React plugin.
// import { Slate, Editable, withReact } from 'slate-react'
// import { cssClass } from '../../../utils/workflow-utils/cssClasses';



// function EmojiPickerCp(props) {
//     const ref = useRef()

//     useEffect(() => {
//         new Picker({ ...props, data, ref })
//     }, [])

//     return <div ref={ref} />
// }


export function TextEditor({ getEditorDataOnChange, data }) {


//     const editor = useMemo(() => withReact(createEditor()), [])

//     const [editorState, setEditorState] = useState([]);
//     const [editorHtml, setEditorHtml] = useState([]);
//     const [showPreview, setShowPreview] = useState();
//     const [contentEditableText, setContentEditableText] = useState("");
//     const [chosenEmoji, setChosenEmoji] = useState(null);
//     const [showEmojiPicker, setShowEmojiPicker] = useState(false);
//     const [slateEditorState, setSlateEditorState] = useState(data.length > 0 ? data : [{
//         type: 'paragraph',
//         children: [{ text: 'Hello User' }],
//     }]);

//     const selectEmoji = (emojiObject) => {
//         setChosenEmoji(emojiObject);
//         setShowEmojiPicker(false);
//         setEditorState([...editorState, { type: "emoji", ...emojiObject }]);
//     };

//     const toggleEmojiPicker = () => {
//         setShowEmojiPicker(!showEmojiPicker);
//     }

//     const updateEditorState = () => {

//     };


//     const updateEditorStateDebounce = useDebounce(updateEditorState, 300);

//     const addFeature = (type) => {

//     };

//     // const onChange = (event) => {
//     //     if (editorState.length === 0) {

//     //         const clonedEditorState = [...editorState];
//     //         clonedEditorState.push({ type: 'text', message: event.target.value });
//     //         setEditorState(clonedEditorState);

//     //     } else if (editorState.length === 1) {
//     //         const clonedEditorState = [...editorState];
//     //         //  check type of editor and likewise update the values.
//     //         if (editorState[0].type === 'text') {
//     //             clonedEditorState[0].message = event.target.value;
//     //             setEditorState(clonedEditorState);
//     //         } else {
//     //             clonedEditorState.push({ type: 'text', message: event.target.value });
//     //             setEditorState(clonedEditorState);
//     //         }
//     //     } else {
//     //         let clonedEditorState = [...editorState];

//     //         //  here editor state is greater than one
//     //         // so insert the text into last elememt
//     //         // check if last element is text then only prepent text to it else add a new object and add the text
//     //         if (editorState[editorState.length - 1].type === 'text') {
//     //             clonedEditorState[editorState.length - 1].message = event.target.value;
//     //             setEditorState(clonedEditorState);
//     //         } else {
//     //             clonedEditorState.push({ type: 'text', message: event.target.value });
//     //             setEditorState(clonedEditorState);
//     //         }
//     //     }

//     //     setContentEditableText()
//     // };

//     const onChange = (event) => {
//         setEditorHtml(event.target.value);
//     }

//     const onSelect = (event) => {
//         if (editorHtml && editorHtml.trim().length !== 0) {
//             //  this method will be triggered whenever a user click on text area
//             const { selectionEnd, selectionStart } = event.target;

//             const str = "csdbajkdbn";

//             // from the selection point update the document.
//             const removedString = editorHtml.substring(selectionStart, selectionEnd);

//             // check for select and feature
//             // TODO : regex to check element exist in editor state;
//             const clonedEditorState = [...editorState];
//             const textObject = {
//                 type: 'normalText',
//                 text: removedString,
//             }
//             clonedEditorState.push(textObject);
//             setEditorState(clonedEditorState);
//         }



//         // setEditorHtml(event.target.value);
//     }

//     useEffect(() => {
//         console.log(editorState);
//     }, [editorState]);


//     return (
//         <div className='editor-wrapper shadow'>
//             <p> {chosenEmoji && chosenEmoji.native} </p>
//             <div className="emoji-div" style={{ display: `${showEmojiPicker === true ? 'block' : 'none'}` }}>
//                 {
//                     showEmojiPicker === true &&
//                     <EmojiPickerCp onEmojiSelect={selectEmoji} />
//                 }
//             </div>
//             <div className='editor-buttons'>
//                 {/* <button onClick={() => addFeature('header')} title="header"> <TitleIcon /> </button>
//                 <button onClick={() => addFeature('list')} title="list"> <ListAltIcon /> </button>
//                 <button onClick={toggleEmojiPicker} title="emoticon"> <EmojiEmotionsIcon /> </button>
//                 <button onClick={() => addFeature('bold')} title="list"> <FormatBoldIcon /> </button>
//                 <button onClick={() => addFeature('italic')} title="list"> <FormatItalicIcon /> </button> */}
//             </div>

//             <div className={`editor-editable-area`}>
//                 <Slate
//                     editor={editor}
//                     value={slateEditorState}
//                     onChange={getEditorDataOnChange}
//                 >
//                     <Editable

//                     />
//                 </Slate>
//             </div>


//             <div className='editor-editable-preview'>
//                 {
//                     showPreview === true && <TextEditorReadOnly
//                         key={"text-editor-read-only"}
//                         contents={editorState}
//                     />
//                 }
//             </div>
//         </div>
//     )
}

