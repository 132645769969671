import { TaskAltOutlined } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiGatewayAxiosInstance } from "../../utils/axios";
import FetchingSpinner from "../components/FetchingSpinner";
import AlertMessageComponent from "../components/AlertMessageComponent";

export default function SendMailToVerifyUser() {
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [clientData, setClientData] = useState({});
    const [pageLoading, setPageLoading] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");
    let param = useParams();
    const id = param.id;
    const email = param.email;
    const validateEmail = async () => {
        try {
            setPageLoading(true);
            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/validate-user-verification-link`, { params: { id } });
            if (response.status === 200) {
                setClientData(response.data.data);

                await updateEmailAddress(response.data.data);

            }
        } catch (error) {
            setPageLoading(false);
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    const updateEmailAddress = async (clientData) => {
        try {
            setPageLoading(true);
            if (clientData.isVerified) {
                setIsTokenValid(true);
                setPageLoading(false);
                return;
            }
            const response = await apiGatewayAxiosInstance.post(`/user_management/clients/update-verified-email`, { id: clientData._id, email });
            if (response.status === 200) {
                setIsTokenValid(true);
                setPageLoading(false);

            }
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };
    useEffect(() => {
        validateEmail();
    }, []);
    return (
        <div>
            {pageLoading ? (
                <FetchingSpinner />
            ) : isTokenValid ? (

                clientData.isVerified ?
                    <Grid align='center'>

                        <Grid style={{ color: "green" }} align='center'>
                            <IconButton style={{ color: "red" }}>
                                <TaskAltOutlined />
                            </IconButton>
                            <Typography component='h2' variant='h4' color={"red"}>
                                Email is already Verified
                            </Typography>
                        </Grid>
                    </Grid>
                    :
                    <Grid align='center'>

                        <Grid style={{ color: "green" }} align='center'>
                            <IconButton style={{ color: "green" }}>
                                <TaskAltOutlined />
                            </IconButton>
                            <Typography component='h2' variant='h4'>
                                Email Verified Successfully
                            </Typography>
                        </Grid>
                    </Grid>
            ) : (
                <Grid align='center'>
                    <Typography style={{ color: "red" }} component='h2' variant='h4'>
                        Failed to verify Email
                    </Typography>
                    {/* <Typography
                    className='mb-2'
                    component='h2'
                    variant='body1'
                    align='center'
                >
                    Connect with Super Admin for further process
                </Typography> */}
                </Grid>
            )}
            <AlertMessageComponent
                message={errorResponse}
                fieldName={"errorResponse"}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />
        </div>
    );
}
