import { DriveFileRenameOutline } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VerifiedIcon from "@mui/icons-material/Verified";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    IconButton,

    MenuItem,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BotStatus, CanShowBotMenuAction, ERoles } from "../../config";
import useAuth from "../../hooks/useAuth";
import FetchingSpinner from "../../pages/components/FetchingSpinner";
import {
    clearErrorMessagesForBots,
    setShowAddBotForm,
    setShowAddTestDeviceForm,
    setShowVerifyClientSecretForm,
    setShowViewVerifyBotDetailsForm,
} from "../../redux/botManagement/botManagementSlice";
import {
    autoFillBotDetailsThunk,
    changeBotStatusThunk,
    getAllBotByFilterThunk
} from "../../redux/botManagement/botManagementThunk";
import TablePaginationShowPage from "../../utils/tablePagination";
import AlertMessageComponent from "../components/AlertMessageComponent";
import NoRecords from "../components/NoRecords";
import StyledTableCell from "../components/StyledTableCell";
import StyledTableRow from "../components/StyledTableRow";
import StyledMenu from "../tables/StyledMenu";
import FilterAllBotModel from "./FilterBotModel";
import AddTestDeviceModel from "./addTestDeviceModel";
import BotStatusUpdate from "./botStatusUpdateModel";
import { calculateTime } from "../../utils/calculateTime";


const ListBots = ({ searchParams, setSearchParams, handleCurrentBotDetails, handleVerifyBotDetails, verifyBotDetails }) => {
    const state = useSelector(state => state.botManagementSlice);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = anchorEl?.id.split("@")[1];
    const { user } = useAuth();
    const [openBotUpdateStatus, setOpenBotUpdateStatus] = React.useState(false);
    const [openAddTestDevice, setOpenAddTestDevice] = React.useState(false);

    const [botStatusData, setBotStatusData] = useState({});
    const [openFilter, setOpenFilter] = React.useState(false);

    const [botDetailsTestDevice, setBotDetailsTestDevice] = React.useState({});

    // const [page, setPage] = React.useState(2);
    const [pageSize, setPageSize] = React.useState(10);

    const handleChangePage = (event, newPage) => {


        setBotDetails({ ...botDetails, currentPage: newPage + 1 });
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setBotDetails({ ...botDetails, currentPage: 1 });
    };

    const handleClickOpenFilterModel = () => {
        setOpenFilter(true);
    };

    const handleCloseFilterModel = () => {
        setOpenFilter(false);
    };
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [botDetails, setBotDetails] = useState({
        botId: "",
        userName: "",
        botName: "",
        category: "",
        type: "",
        language: "",
        status: "Active",
        currentPage: 1,
    });

    const onClickAddNewBot = () => {
        dispatch(setShowAddBotForm(true));
    };
    const handleVerifyClientSecret = id => {
        dispatch(setShowVerifyClientSecretForm({ status: true, botId: id }));
    };

    const onChangeUserName = e => {
        setBotDetails({ ...botDetails, userName: e.target.value });
    };

    const onChangeBotId = e => {
        setBotDetails({ ...botDetails, botId: e.target.value });
    };

    const onSelectStatus = e => {
        setBotDetails({ ...botDetails, status: e.target.value });
    };

    const onChangeBotName = e => {
        setBotDetails({ ...botDetails, botName: e.target.value });
    };

    const onChangeCategory = e => {
        setBotDetails({ ...botDetails, category: e.target.value });
    };

    const onChangeType = e => {
        setBotDetails({ ...botDetails, type: e.target.value });
    };

    const onChangeLanguage = e => {
        setBotDetails({ ...botDetails, language: e.target.value });
    };

    const handlePageChange = (event, page) => {
        setBotDetails({ ...botDetails, currentPage: page + 1 });
    };

    const onClickChangePendingStatus = id => {
        const data = {
            botId: id,
            status: BotStatus.VerificationInProcess,
            currentPage: botDetails.currentPage,
        };
        dispatch(changeBotStatusThunk({ data }));
    };

    const onClickLaunchBotButton = id => {
        const data = {
            botId: id,
            status: BotStatus.Verified,
            currentPage: botDetails.currentPage,
        };
        dispatch(changeBotStatusThunk({ data }));
    };

    const handleShowVerifyBotDetails = ({ id, name, clientName }) => {
        // verifyBotDetails({ id });
        dispatch(
            setShowViewVerifyBotDetailsForm({
                status: true,
                botId: id,
                botName: name,
                clientName: clientName,
                isEditable: false,
            }),
        );
    };

    const handleEditVerifyBotDetails = ({ id, name, botStatus, brandId }) => {
        verifyBotDetails({ id, botStatus, name, isEditable: true, brandId });
    };

    const handleFillDetailsForm = async botDetails => {
        dispatch(autoFillBotDetailsThunk({ botDetails }));
        setAnchorEl(null);
    };

    const onClickViewDetails = botDetails => {
        handleCurrentBotDetails({ botDetails, val: false });
    };

    const handleAddTestDevice = bot => {

        setBotDetailsTestDevice(bot)
        dispatch(setShowAddTestDeviceForm({ status: true, botId: bot._id }));
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForBots({ fieldName }));
    };

    const onClickEditDetails = botDetails => {
        handleCurrentBotDetails({ botDetails, val: true });
    };

    const onClickChangeBotStatus = (botId, status) => {
        const data = {
            botId: botId,
            status: status,
            currentPage: botDetails.currentPage,
        };
        setBotStatusData(data);
        setOpenBotUpdateStatus(true);
        handleClose();
        // dispatch(changeBotStatusThunk({ data }));
    };

    useEffect(() => {

        if (!openBotUpdateStatus && !state.controls.showAddTestDeviceForm.status) {
            // dispatch(
            //     getAllBotsThunkByPageSize({
            //         currentPage: botDetails.currentPage,
            //         pageSize,
            //     }),
            // );

            dispatch(
                getAllBotByFilterThunk({
                    currentPage: botDetails.currentPage,
                    pageSize,
                    searchParams,
                }),
            );
        }
    }, [botDetails.currentPage, pageSize, openBotUpdateStatus, state.controls.showAddTestDeviceForm.status]);

    const handleClickSearch = (searchParams) => {
        setPageSize(10);
        setBotDetails({ ...botDetails, currentPage: 1 });
        setSearchParams(searchParams)
        dispatch(
            getAllBotByFilterThunk({
                currentPage: 1,
                pageSize,
                searchParams,
            }),
        );
        handleCloseFilterModel();
    };

    return (
        <Grid>
            <Grid container mb={4} className='listHeader'>
                {/* <Grid item className='ms-2 me-2'>
                    <LocalOfferOutlined />
                </Grid> */}
                <Grid item md={6}>
                    <Typography variant='h3'>Bots</Typography>
                </Grid>
                <Grid item md={6} width={"100%"} className='listHeaderFilter'>
                    <Button sx={{ marginRight: "10px" }} variant='contained' onClick={handleClickOpenFilterModel}>
                        Filter
                    </Button>
                    {/* {user && user.roleName.toLowerCase() !== ERoles.SUPER_ADMIN && ( */}
                    <Button variant='contained' onClick={onClickAddNewBot}>
                        Add New Bot
                    </Button>
                    {/* )} */}
                </Grid>
            </Grid>
            <Grid>
                <Card className='border border-secondary-50'>
                    <CardContent sx={{
                        padding: 0,
                    }}>
                        <Grid
                            container
                            className='text-primary'
                            sx={{
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            {/* <Grid item sx={{ display: "flex" }}>
                                <Grid item className='ms-2 me-2'>
                                    <SmartToyOutlinedIcon />
                                </Grid>
                                <Grid item>
                                    <Typography variant='h4'>
                                        <b>Bots List</b>
                                    </Typography>
                                </Grid>
                            </Grid> */}
                        </Grid>
                        {state.controls.isGetAllBotsLoading ? (
                            <FetchingSpinner />
                        ) : state.botListByPageSize.length > 0 ? (
                            <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "70vh" }}>
                                <Table stickyHeader aria-label='simple table'>
                                    <TableHead className='p-3 mb-2 row'>
                                        <TableRow>
                                            <StyledTableCell className='tableHeaderFont' align='center'>
                                                Sr No
                                            </StyledTableCell>
                                            <StyledTableCell className='tableHeaderFont' align='left'>
                                                Bot Name
                                            </StyledTableCell>
                                            <StyledTableCell className='tableHeaderFont' align='center'>
                                                Created At
                                            </StyledTableCell>
                                            {user?.roleName.toLowerCase() == ERoles.SUPER_ADMIN && (
                                                <StyledTableCell className='tableHeaderFont' align='left'>
                                                    Client Name
                                                </StyledTableCell>
                                            )}

                                            <StyledTableCell className='tableHeaderFont' align='left'>
                                                Brand
                                            </StyledTableCell>

                                            <StyledTableCell className='tableHeaderFont' align='center'>
                                                Bot Message Type
                                            </StyledTableCell>

                                            <StyledTableCell className='tableHeaderFont' align='center'>
                                                Status
                                            </StyledTableCell>

                                            <StyledTableCell className='tableHeaderFont' align='center'>
                                                Action
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.botListByPageSize?.map((bot, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <b>{(botDetails.currentPage - 1) * pageSize + index + 1}</b>
                                                </StyledTableCell>
                                                <StyledTableCell className='listBotTableContentFont' align='left'>
                                                    {bot.botName}
                                                </StyledTableCell>
                                                <StyledTableCell className='listBotTableContentFont' align='center'>
                                                    {bot.createdAt ? calculateTime(bot.createdAt) : "-"}
                                                </StyledTableCell>
                                                {user?.roleName.toLowerCase() == ERoles.SUPER_ADMIN && (
                                                    <StyledTableCell className='tableContentFont' align='left'>
                                                        {bot.clientName}
                                                    </StyledTableCell>
                                                )}

                                                <StyledTableCell className='listBotTableContentFont' align='left'>
                                                    {bot.brandName}
                                                </StyledTableCell>

                                                <StyledTableCell className='listBotTableContentFont' align='center'>
                                                    {bot.botMessageType}
                                                </StyledTableCell>

                                                <StyledTableCell className='listBotTableContentFont' align='center'>
                                                    {bot.status ? (
                                                        bot.status === BotStatus.New ? (
                                                            <Typography
                                                                color='primary'
                                                            >
                                                                {bot.status.replaceAll("_", " ").toUpperCase()}

                                                            </Typography>
                                                        ) : bot.status === BotStatus.Development ? (
                                                            <Typography
                                                                color='purple'
                                                            >
                                                                {bot.status.replaceAll("_", " ").toUpperCase()}

                                                            </Typography>
                                                        ) : bot.status === BotStatus.VerificationInProcess ? (
                                                            <Typography
                                                                color='orange'
                                                            >
                                                                {bot.status.replaceAll("_", " ").toUpperCase()}

                                                            </Typography>
                                                        ) : bot.status === BotStatus.Verified ? (
                                                            <Typography
                                                                color='green'
                                                            >
                                                                {bot.status.replaceAll("_", " ").toUpperCase()}

                                                            </Typography>
                                                        ) : bot.status === BotStatus.Launched ? (
                                                            <Typography
                                                                color='green'
                                                            >
                                                                {bot.status.replaceAll("_", " ").toUpperCase()}

                                                            </Typography>
                                                        ) : bot.status === BotStatus.VerificationSubmitted ? (
                                                            <Typography
                                                                color='#6c757d'
                                                            >
                                                                {bot.status.replaceAll("_", " ").toUpperCase()}

                                                            </Typography>
                                                        ) : bot.status === BotStatus.LaunchingInProcess ? (
                                                            <Typography
                                                                color='orange'
                                                            >
                                                                {bot.status.replaceAll("_", " ").toUpperCase()}

                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                color='error'
                                                            >
                                                                {bot.status.replaceAll("_", " ").toUpperCase()}

                                                            </Typography>
                                                        )
                                                    ) : (
                                                        "-"
                                                    )}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <IconButton
                                                        id={`menu-button@${bot._id}`}
                                                        aria-controls={open === bot._id ? `customized-menu@${bot._id}` : undefined}
                                                        aria-haspopup='true'
                                                        aria-expanded={open === bot._id ? "true" : undefined}
                                                        variant='contained'
                                                        disableElevation
                                                        onClick={handleClick}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <StyledMenu
                                                        id={`customized-menu@${bot._id}`}
                                                        MenuListProps={{
                                                            "aria-labelledby": `menu-button@${bot._id}`,
                                                        }}
                                                        anchorEl={anchorEl}
                                                        open={open === bot._id}
                                                        onClose={handleClose}
                                                    >
                                                        <MenuItem onClick={() => onClickViewDetails(bot)}>
                                                            <IconButton title='View Details'>
                                                                <VisibilityIcon className='text-primary' />
                                                            </IconButton>
                                                            View Bot Details
                                                        </MenuItem>
                                                        {CanShowBotMenuAction.EditBot(bot.status) && (<MenuItem onClick={() => onClickEditDetails(bot)}>
                                                            <IconButton title='Edit Details'>
                                                                <DriveFileRenameOutline className='text-primary' />
                                                            </IconButton>
                                                            Edit Bot Details
                                                        </MenuItem>)
                                                        }
                                                        {!(bot.status === BotStatus.Development || bot.status === BotStatus.New) &&
                                                            user &&
                                                            user.roleName.toLowerCase() === ERoles.SUPER_ADMIN && (
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleShowVerifyBotDetails({
                                                                            id: bot._id,
                                                                            name: bot.botName,
                                                                            clientName: bot.clientName,
                                                                        })
                                                                    }
                                                                >
                                                                    <IconButton title='Show Bot Verification Details'>
                                                                        <VerifiedIcon className='text-success' />
                                                                    </IconButton>
                                                                    Show Bot Verification Details
                                                                </MenuItem>
                                                            )
                                                        }
                                                        {CanShowBotMenuAction.ShowAddTestDevice(bot.status) && (
                                                            <>
                                                                <MenuItem onClick={() => handleAddTestDevice(bot)}>
                                                                    <IconButton title='Add Test Device'>
                                                                        <VerifiedIcon className='text-success' />
                                                                    </IconButton>
                                                                    Add Test Device
                                                                </MenuItem>
                                                            </>
                                                        )}
                                                        {
                                                            user && user.roleName.toLowerCase() === ERoles.SUPER_ADMIN ? (
                                                                <>
                                                                    {(bot.status === BotStatus.Development || bot.status === BotStatus.New) && (
                                                                        <MenuItem onClick={() => handleVerifyClientSecret(bot._id)}>
                                                                            <IconButton title='Verify Client Secret'>
                                                                                <KeyRoundedIcon className='text-danger' />
                                                                            </IconButton>
                                                                            Verify Client Secret
                                                                        </MenuItem>
                                                                    )}

                                                                    {bot.status !== BotStatus.Launched &&
                                                                        bot.status !== BotStatus.Development &&
                                                                        bot.status !== BotStatus.LaunchFailed && (
                                                                            <MenuItem
                                                                                onClick={() =>
                                                                                    onClickChangeBotStatus(
                                                                                        bot._id,
                                                                                        // BotStatus.VerificationInProcess
                                                                                        bot.status,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <IconButton title='Verify Client Secret'>
                                                                                    <VerifiedUserIcon className='text-danger' />
                                                                                </IconButton>
                                                                                Change status to
                                                                            </MenuItem>
                                                                        )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {CanShowBotMenuAction.VerifyBot(bot.status) && (
                                                                        <>
                                                                            <MenuItem onClick={() => handleVerifyBotDetails(bot._id, bot.brandId)}>
                                                                                <IconButton title='Verify Bot'>
                                                                                    <VerifiedIcon className='text-success' />
                                                                                </IconButton>
                                                                                Verify Bot
                                                                            </MenuItem>
                                                                        </>
                                                                    )}


                                                                    {
                                                                        bot.status !== BotStatus.Development &&
                                                                        bot.status !== BotStatus.New && (
                                                                            <>
                                                                                <MenuItem
                                                                                    onClick={() =>
                                                                                        handleShowVerifyBotDetails({
                                                                                            id: bot._id,
                                                                                            name: bot.botName,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <IconButton title='Show Bot Verification Details'>
                                                                                        <VerifiedIcon className='text-success' />
                                                                                    </IconButton>
                                                                                    Show Bot Verification Details
                                                                                </MenuItem>

                                                                                {CanShowBotMenuAction.EditBotVerificationDetails(bot.status) &&
                                                                                    <MenuItem
                                                                                        onClick={() =>
                                                                                            handleEditVerifyBotDetails({
                                                                                                id: bot._id,
                                                                                                name: bot.botName,
                                                                                                botStatus: bot.status,
                                                                                                brandId: bot.brandId,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <IconButton title='Edit Bot Verification Details'>
                                                                                            <EditNoteIcon className='text-success' />
                                                                                        </IconButton>
                                                                                        Edit Bot Verification Details
                                                                                    </MenuItem>
                                                                                }
                                                                            </>
                                                                        )}

                                                                    {/* <MenuItem
                                                                    onClick={() =>
                                                                        handleEditVerifyBotDetails({
                                                                            id: bot._id,
                                                                            name: bot.botName,
                                                                        })
                                                                    }
                                                                >
                                                                    <IconButton title='Edit Bot Verification Details'>
                                                                        <VerifiedIcon className='text-success' />
                                                                    </IconButton>
                                                                    Edit Bot Verification Details
                                                                </MenuItem> */}
                                                                </>
                                                            )
                                                        }
                                                    </StyledMenu>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <NoRecords />
                        )}
                        {state.totalBotsCount > 0 && (
                            <Grid
                                className='my-2'
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: 10
                                }}
                            >
                                <Grid
                                    style={{
                                        marginTop: "15px",
                                    }}
                                >
                                    Total Bots: {state.totalBotsCount}
                                </Grid>
                                <Grid>
                                    {/* <TablePagination
                                        count={
                                            state.totalBotsCount
                                            /// rowsPerPage
                                        }
                                        component='Grid'
                                        variant='outlined'
                                        color='primary'
                                        page={botDetails.currentPage - 1}
                                        // onChange={handlePageChange}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={pageSize}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    /> */}

                                    <TablePaginationShowPage
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        colSpan={3}
                                        count={state.totalBotsCount}
                                        rowsPerPage={pageSize}
                                        page={botDetails.currentPage - 1}
                                        slotProps={{
                                            select: {
                                                "aria-label": "Rows per page",
                                            },
                                            actions: {
                                                showFirstButton: true,
                                                showLastButton: true,
                                                slots: {
                                                    firstPageIcon: FirstPageRoundedIcon,
                                                    lastPageIcon: LastPageRoundedIcon,
                                                    nextPageIcon: ChevronRightRoundedIcon,
                                                    backPageIcon: ChevronLeftRoundedIcon,
                                                },
                                            },
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                    <AlertMessageComponent
                        message={state.controls.getAllBotsByErrorMessage}
                        fieldName={"getAllBotsByErrorMessage"}
                        handleClose={onCloseErrorMessage}
                        show={Boolean(state.controls.getAllBotsByErrorMessage)}
                    />
                    {openFilter && (
                        <FilterAllBotModel
                            open={openFilter}
                            searchParams={searchParams}
                            handleClickOpenFilterModel={handleClickOpenFilterModel}
                            handleCloseFilterModel={handleCloseFilterModel}
                            setSearchParams={setSearchParams}
                            handleClickSearch={handleClickSearch}
                        />
                    )}
                </Card>
            </Grid>
            {state.controls.showAddTestDeviceForm.status && <AddTestDeviceModel open={state.controls.showAddTestDeviceForm.status} botId={botDetailsTestDevice._id} botDetails={botDetailsTestDevice} handleCloseMenuItems={() => setAnchorEl(null)} />}
            {openBotUpdateStatus && (
                <BotStatusUpdate
                    open={openBotUpdateStatus}
                    setOpen={setOpenBotUpdateStatus}
                    data={botStatusData}
                    setBotStatusData={setBotStatusData}
                />
            )}
        </Grid>
    );
};

export default ListBots;
