

import React, { useCallback, useState, useEffect } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { MasterCardLayout } from './MasterCardLayout';
import { ContextMenu } from '../ContextMenu';
import { generateUniqueIdForCard } from '../../../utils/workflow-utils/helpers';
import closeIcon from '../../../assets/icons/close-icon.svg';

const handleStyle = { left: 10 };

export function ListCard(props) {
    const { data, id: listCardId, selected, type } = props;
    const methods = data.methods;

    return (
        <MasterCardLayout
            title={data.title}
            tailwindClass={data.cardTailwindStyle}
            key={`list-master-card-${listCardId}`}
            startNode={data?.isStartCard}
            nodeData={data}
            isStartCard={data.isStartCard}
        >
            {
                selected === true &&
                // Show context menu
                <ContextMenu
                    onDuplicate={(event) => { methods.onDuplicate(event, listCardId) }}
                    onDelete={methods.onDelete}
                    nodeId={listCardId}
                    methods={methods}

                />
            }
            <div className="text-updater-node" id={`${listCardId}`}>
                <Handle type="target" className='target-handle' position={Position.Left} />
                <div style={{ margin: '10px', fontSize: '10px' }}>
                    <div>
                        <input className='text-base px-1 py-0.5 border-2 border-indigo-200 rounded' type="text" placeholder='title' />
                    </div>
                    <div>
                        {
                            data && data.contents && data.contents.map((ele, idx) => {
                                return (
                                    <div key={`list_card_cmp_${idx}`}>
                                        <div className="text-updater-node"  >
                                            <div className='flex justify-between' style={{ margin: '10px', fontSize: '10px' }}>
                                                <input disabled value={ele.value} className='input-box p-px text-sm' type='text' />
                                            </div>
                                        </div>
                                        <Handle id={ele.handleId} type="source" className='source-handle' position={Position.Right} style={{ top: `${(33 * idx) + 100}px` }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* <button className='px-1 py-0.25 mt-2 bg-indigo-300 rounded w-20 text-base border-2' onClick={addItem}>Add</button> */}
                </div>
            </div>
        </MasterCardLayout>
    );
}
