import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import ResetPassword from "./pages/auth/ResetPassword";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";

// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Projects from "./pages/pages/Projects";

// Table components
import AdvancedTable from "./pages/tables/AdvancedTable";
import SimpleTable from "./pages/tables/SimpleTable";

// Documentation
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Welcome from "./pages/docs/Welcome";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
// import JWT from "./pages/docs/auth/JWT";
import Deployment from "./pages/docs/Deployment";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import Guards from "./pages/docs/Guards";
import Theming from "./pages/docs/Theming";
// import APICalls from "./pages/docs/APICalls";
import Changelog from "./pages/docs/Changelog";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import Internationalization from "./pages/docs/Internationalization";
import Redux from "./pages/docs/Redux";
import Support from "./pages/docs/Support";

// Landing

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

//Admin Components
import CustomizeRate from "./pages/admin/CustomiseRateCard/CustomizeRates";
import DailyReports from "./pages/admin/DailyReports";
import ManageApiKeys from "./pages/admin/ManageApiKeys";
import PrepaidRecharge from "./pages/admin/PrepaidRecharge";
// ---------------------------------------------------------------------------
import ManageClients from "./pages/admin/ClientsComponents/ManageClients";
import ManageRateMaster from "./pages/admin/RateCardComponents/ManageRateMaster";
import ManageRoles from "./pages/admin/RolesComponents/ManageRoles";
import ManageUsers from "./pages/admin/UsersManagementComponents/ManageUsers";
import WhiteLabelDetails from "./pages/admin/WhiteLabelComponents/WhiteLabelDetails";
import SendMailToVerifyUser from "./pages/auth/SendMailToVerifyUser";
import SetClientPassword from "./pages/auth/SetClientPassword";
import VerifyMobileNumber from "./pages/auth/VerifyMobileNumber";
import VerifyBot from "./pages/botBuilder/actions/VerifyBot";
import VerifyClientSecret from "./pages/botBuilder/actions/VerifyClientSecret";
import AddBot from "./pages/botBuilder/addBot";
import ManageBrands from "./pages/botBuilder/brands/manageBrands";
import ManageBots from "./pages/botBuilder/manageBots";
import ManageCampaigns from "./pages/campaignManagment/ManageCampaigns";
import BillingMIS from "./pages/mis/BillingMIS";
import ManageTemplates from "./pages/rcsManagement/rcsTemplates/ManageTemplates";
import ManageApiReport from "./pages/reports/apiReports/ManageApiReport";
import ManageCampaignReports from "./pages/reports/campaignReportsComponents/ManageCampaignReports";
import ConversationLogs from "./pages/reports/conversationLogsComponents/ConversationLogs";
import { WorkFlowEditor } from "./pages/workflowEditor/WorkFlowEditor";
// import CampaignMIS from "./pages/mis/CampaignMIS";

//----------------------------------------------------------------------------------------

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));
const ApexCharts = async(() => import("./pages/charts/ApexCharts"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const defaultRoutes = [
    {
        path: "/",
        element: <DashboardLayout />,
        children: [
            {
                path: "",
                element: (
                    <AuthGuard>
                        <Default />
                    </AuthGuard>
                ),
            },
        ],
    },
    {
        path: "auth/",
        element: <AuthLayout />,
        children: [
            {
                path: "login",
                element: <SignIn />,
            },
            {
                path: "verify/:token/:id",
                element: <SetClientPassword />,
            },
            {
                path: "verify-user/:id/:email",
                element: <SendMailToVerifyUser />,
            },
            {
                path: "verify-number/:id/",
                element: <VerifyMobileNumber />,
            },
            {
                path: "sign-up",
                element: <SignUp />,
            },
            {
                path: "reset-password",
                element: <ResetPassword />,
            },

            {
                path: "404",
                element: <Page404 />,
            },
            {
                path: "500",
                element: <Page500 />,
            },
        ],
    },
    {
        path: "dashboard",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "",
                element: <Default />,
            },
            // {
            //   path: "analytics",
            //   element: <Analytics />,
            // },
            // {
            //   path: "saas",
            //   element: <SaaS />,
            // },
        ],
    },

    {
        path: "admin",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "manage-users",
                element: <ManageUsers />,
            },
            // {
            //   path: "manage-branches",
            //   element: <ManageBranches />,
            // },
            // {
            //   path: "manage-executives",
            //   element: <ManageExecutives />,
            // },
            // {
            //   path: "manage-BM-TL",
            //   element: <ManageBmTl />,
            // },
            // {
            //     path: "whiteLabel-details",
            //     element: <WhiteLabelDetails />,
            // },
            {
                path: "manage-clients",
                element: <ManageClients />,
            },
            {
                path: "manage-roles",
                element: <ManageRoles />,
            },
            {
                path: "manage-api-keys",
                element: <ManageApiKeys />,
            },
            {
                path: "daily-reports",
                element: <DailyReports />,
            },
            {
                path: "prepaid-recharge",
                element: <PrepaidRecharge />,
            },
        ],
    },
    {
        path: "rcs-management",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "manage-brands",
                element: <ManageBrands />,
            },
            {
                path: "manage-bots",
                element: <ManageBots />,
            },

            {
                path: "templates/manage-templates",
                element: <ManageTemplates />,
            },
            {
                path: "verify-bot/:id",
                element: <VerifyBot />,
            },
            {
                path: "new-bots",
                element: <AddBot />,
            },

            {
                path: "manage-bots/:id",
                element: <VerifyClientSecret />,
            },
            {
                path: "manage-campaigns",
                element: <ManageCampaigns />,
            },
        ],
    },
    {
        path: "reports",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "conversation-logs",
                element: <ConversationLogs />,
            },
            {
                path: "campaigns-reports",
                element: <ManageCampaignReports />,
            },
            {
                path: "api-conversation-logs",
                element: <ManageApiReport />,
            },
        ],
    },
    {
        path: "billing",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "master-rates",
                element: <ManageRateMaster />,
            },
            {
                path: "customized-rates",
                element: <CustomizeRate />,
            },
            {
                path: "applicable-rates",
                element: <CustomizeRate />,
            },
        ],
    },
    {
        path: "mis",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "billing-mis",
                element: <BillingMIS />,
            },
            // {
            //     path: "campaign-mis",
            //     element: <CampaignMIS />,
            // }
        ],
    },
    {
        path: "projects",
        element: <DashboardLayout />,
        children: [
            {
                path: "",
                element: <Projects />,
            },
        ],
    },
    {
        path: "invoices",
        element: <DashboardLayout />,
        children: [
            {
                path: "",
                element: <InvoiceList />,
            },
            {
                path: "detail",
                element: <InvoiceDetails />,
            },
        ],
    },
    {
        path: "orders",
        element: <DashboardLayout />,
        children: [
            {
                path: "",
                element: <Orders />,
            },
        ],
    },
    {
        path: "tasks",
        element: <DashboardLayout />,
        children: [
            {
                path: "",
                element: <Tasks />,
            },
        ],
    },
    {
        path: "calendar",
        element: <DashboardLayout />,
        children: [
            {
                path: "",
                element: <Calendar />,
            },
        ],
    },
    {
        path: "components",
        element: <DashboardLayout />,
        children: [
            {
                path: "accordion",
                element: <Accordion />,
            },
            {
                path: "alerts",
                element: <Alerts />,
            },
            {
                path: "avatars",
                element: <Avatars />,
            },
            {
                path: "badges",
                element: <Badges />,
            },
            {
                path: "buttons",
                element: <Buttons />,
            },
            {
                path: "cards",
                element: <Cards />,
            },
            {
                path: "chips",
                element: <Chips />,
            },
            {
                path: "dialogs",
                element: <Dialogs />,
            },
            {
                path: "lists",
                element: <Lists />,
            },
            {
                path: "menus",
                element: <Menus />,
            },
            {
                path: "pagination",
                element: <Pagination />,
            },
            {
                path: "progress",
                element: <Progress />,
            },
            {
                path: "snackbars",
                element: <Snackbars />,
            },
            {
                path: "tooltips",
                element: <Tooltips />,
            },
        ],
    },
    {
        path: "forms",
        element: <DashboardLayout />,
        children: [
            {
                path: "pickers",
                element: <Pickers />,
            },
            {
                path: "selection-controls",
                element: <SelectionCtrls />,
            },
            {
                path: "selects",
                element: <Selects />,
            },
            {
                path: "text-fields",
                element: <TextFields />,
            },
            {
                path: "editors",
                element: <Editors />,
            },
            {
                path: "formik",
                element: <Formik />,
            },
        ],
    },
    {
        path: "tables",
        element: <DashboardLayout />,
        children: [
            {
                path: "simple-table",
                element: <SimpleTable />,
            },
            {
                path: "advanced-table",
                element: <AdvancedTable />,
            },
            {
                path: "data-grid",
                element: <DataGrid />,
            },
        ],
    },
    {
        path: "icons",
        element: <DashboardLayout />,
        children: [
            {
                path: "material-icons",
                element: <MaterialIcons />,
            },
            {
                path: "feather-icons",
                element: <FeatherIcons />,
            },
        ],
    },
    {
        path: "charts",
        element: <DashboardLayout />,
        children: [
            {
                path: "chartjs",
                element: <Chartjs />,
            },
            {
                path: "apexcharts",
                element: <ApexCharts />,
            },
        ],
    },
    {
        path: "maps",
        element: <DashboardLayout />,
        children: [
            {
                path: "google-maps",
                element: <GoogleMaps />,
            },
            {
                path: "vector-maps",
                element: <VectorMaps />,
            },
        ],
    },
    {
        path: "documentation",
        element: <DocLayout />,
        children: [
            {
                path: "welcome",
                element: <Welcome />,
            },
            {
                path: "getting-started",
                element: <GettingStarted />,
            },
            {
                path: "routing",
                element: <Routing />,
            },
            {
                path: "auth/auth0",
                element: <Auth0 />,
            },
            {
                path: "auth/cognito",
                element: <Cognito />,
            },
            {
                path: "auth/firebase",
                element: <Firebase />,
            },
            // {
            // 	path: "auth/jwt",
            // 	element: <JWT />,
            // },
            {
                path: "guards",
                element: <Guards />,
            },
            {
                path: "environment-variables",
                element: <EnvironmentVariables />,
            },
            {
                path: "deployment",
                element: <Deployment />,
            },
            {
                path: "theming",
                element: <Theming />,
            },
            {
                path: "redux",
                element: <Redux />,
            },
            {
                path: "internationalization",
                element: <Internationalization />,
            },
            {
                path: "eslint-and-prettier",
                element: <ESLintAndPrettier />,
            },
            {
                path: "support",
                element: <Support />,
            },
        ],
    },
    {
        path: "changelog",
        element: <DocLayout />,
        children: [
            {
                path: "",
                element: <Changelog />,
            },
        ],
    },
    {
        path: "private",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "",
                element: <ProtectedPage />,
            },
        ],
    },
    {
        path: "*",
        element: <AuthLayout />,
        children: [
            {
                path: "*",
                element: <Page404 />,
            },
        ],
    },
];

const routes = [
    {
        path: "/",
        element: (
            <>
                {" "}
                <h1>
                    <a href='/workflow-editor'>workflow-editor</a>{" "}
                </h1>{" "}
            </>
        ),
    },
    {
        path: "/workflow-editor",
        element: <WorkFlowEditor />,
    },
];

export default defaultRoutes;
