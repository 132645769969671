import { TablePagination, tablePaginationClasses as classes, styled } from "@mui/material";
// import { blue, grey } from "@mui/material/colors";

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      width: 100%;
      background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      box-shadow: 0px 4px 16px ${theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.3)" : grey[200]};
      border-radius: 12px;
      border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
      overflow: hidden;
    }

    td,
    th {
      padding: 16px;
    }

    th {
      background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    }
    `,
);

export const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }

    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }

    & .${classes.selectLabel} {
      margin: 0;
    }

    & .${classes.select}{
      font-family: 'IBM Plex Sans', sans-serif;
      padding: 2px 0 2px 4px;
      border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
      border-radius: 6px;
      background-color: transparent;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      transition: all 100ms ease;

      &:hover {
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
      }

      &:focus {
        outline: 3px solid ${theme.palette.mode === "dark" ? blue[400] : blue[200]};
        border-color: ${blue[400]};
      }
    }

    & .${classes.displayedRows} {
      margin: 0;

      @media (min-width: 768px) {
        margin-left: auto;
      }
    }

    & .${classes.actions} {
      display: flex;
      gap: 6px;
      border: transparent;
      text-align: center;
    }

    & .${classes.actions} > button {
      display: flex;
      align-items: center;
      padding: 0;
      border: transparent;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      transition: all 100ms ease;

      > svg {
        font-size: 22px;
      }

      &:hover {
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
      }

      &:focus {
        outline: 3px solid ${theme.palette.mode === "dark" ? blue[400] : blue[200]};
        border-color: ${blue[400]};
      }

      &:disabled {
        opacity: 0.3;
        &:hover {
          border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
          background-color: transparent;
        }
      }
    }
    `,
);
