import React, { useState } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { BrandStatus } from "../../../config";

function FilterAllBrandModel({ open, searchParams, setSearchParams, handleClickOpenModel, handleCloseFilterModel, handleClickSearch }) {
    const [search ,setSearch] = useState(searchParams)
    const handleChange = e => {
        const value = e.target.value;
        const name = e.target.name;
        setSearch({ ...search, [name]: value });
    };

    const onClickClear = () => {
        setSearchParams({
            brandName: "",
            brandStatus: "",
        });

        handleClickSearch({
            brandName: "",
            brandStatus: "",
        });
        


    };
    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleCloseFilterModel} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant='h5'> Brands Filter</Typography>
                    <IconButton onClick={() => handleCloseFilterModel()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent
                    sx={{
                        overflowY: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                        width: "500px",
                    }}
                >
                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            <TextField
                                value={search.brandName}
                                name='brandName'
                                onChange={handleChange}
                                label='Brand Name'
                                fullWidth
                                placeholder=' Enter Brand Name'
                            />
                        </Grid>
                        <Grid item md={5.5}>
                            <FormControl fullWidth>
                                <InputLabel id='demo-controlled-open-select-label'>Status</InputLabel>
                                <Select
                                    labelId='demo-controlled-open-select-label'
                                    id='demo-controlled-open-select'
                                    name='brandStatus'
                                    value={search.brandStatus}
                                    label='Status'
                                    onChange={handleChange}
                                >
                                    {Object.keys(BrandStatus).map((brandStatus) => (
                                        <MenuItem value={BrandStatus[brandStatus]}>{BrandStatus[brandStatus].replace('_', " ")}</MenuItem>
                                    ))}
                                    {/* <MenuItem value={BrandStatus.Verified}>
                                        {BrandStatus.Verified}
                                    </MenuItem>
                                    <MenuItem value={BrandStatus.NotVerified}>
                                        {BrandStatus.NotVerified}
                                    </MenuItem>
                                    <MenuItem value={BrandStatus.UnderReview}>
                                        {BrandStatus.UnderReview}
                                    </MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                    }}
                >
                    <Button variant='contained' color='error' onClick={() => onClickClear()}>
                        Clear
                    </Button>
                    <Button variant='contained' color='primary' onClick={() => handleClickSearch(search)}>
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default FilterAllBrandModel;
