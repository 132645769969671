import { TaskAltOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiGatewayAxiosInstance } from "../../utils/axios";

export default function InvalidEmailToken({ id, resetDisable, isTokenValid, clientData }) {
    const [resendEmail, setResendEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");
    const [LinkExpired, setLinkExpired] = useState(false);

    const handleResentLink = async () => {
        try {
            setLoading(true);
            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/resend-email-to-client`, { params: { id } });

            if (response.status === 200) {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // setResendEmail(true)
            let errorMessage = "Failed to resend mail.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
        setResendEmail(true);
    };

    useEffect(() => {
        const currentDateAndTime = new Date();
        const expiredDate = new Date(clientData.expiredIn);


        if (clientData.expiredIn) {
            if (currentDateAndTime > expiredDate) {
                setLinkExpired(true);
            }
        }
    }, [clientData]);

    return (
        <div>
            {resendEmail ? (
                errorResponse ? (
                    <Grid align='center'>
                        <Typography style={{ color: "red" }} component='h2' variant='h4'>
                            Resend Count has Exceeded!
                        </Typography>
                        <Typography className='mb-2' component='h2' variant='body1' align='center'>
                            Connect with Super Admin for further process
                        </Typography>
                    </Grid>
                ) : (
                    <Grid style={{ color: "green" }} align='center'>
                        <IconButton style={{ color: "green" }}>
                            <TaskAltOutlined />
                        </IconButton>
                        <Typography component='h2' variant='h4'>
                            Email sent Successfully
                        </Typography>
                    </Grid>
                )
            ) : LinkExpired ? (
                <Grid>
                    <Typography component='h1' variant='h4' align='center' gutterBottom>
                        <b>This link has been Expired</b>
                    </Typography>
                    <Typography className='mb-2' component='h2' variant='body1' align='center'>
                        Click below link to resend verification mail
                    </Typography>
                    {loading ? (
                        <Button fullWidth variant='outlined'>
                            <CircularProgress size={20} className='spinner' />
                            Resending...
                        </Button>
                    ) : (
                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            disabled={resendEmail || resetDisable}
                            onClick={() => {
                                handleResentLink();
                            }}
                        >
                            Resend
                        </Button>
                    )}
                </Grid>
            ) : (
                <Grid>
                    <Typography style={{ color: "red" }} component='h1' variant='h4' align='center' gutterBottom>
                        <b>Invalid Link</b>
                    </Typography>
                    <Typography className='mb-2' component='h2' variant='body1' align='center'>
                        Check the link
                    </Typography>
                </Grid>
            )}
        </div>
    );
}

//      errorResponse ? (
//     <Grid align='center'>
//         <Typography  style={{ color: 'red' }} component='h2' variant='h4'>
//             Invalid Id
//         </Typography>
//         <Typography
//         className='mb-2'
//         component='h2'
//         variant='body1'
//         align='center'
//     >
//         {/* Connect with Super Admin for further process */}
//     </Typography>
//     </Grid>
// ):
