import SuggestionSimulatorComponent from "./SuggestionSimulatorComponent";
import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { useEffect } from "react";



export default function RichCardStandAloneDetailsComponent({ richCardStandAloneDetails }) {


    useEffect(() => { }, [richCardStandAloneDetails.mediaHeight, richCardStandAloneDetails.cardOrientation, richCardStandAloneDetails.media.type])
    return (
        <>


            {richCardStandAloneDetails.cardOrientation === "VERTICAL" &&
                <Card sx={{ border: "1px solid Grey", minHeight: "6vh", width: "100%", display: "flex", flexDirection: "column", margin: 3 }}>
                    <Grid fullWidth >
                        {richCardStandAloneDetails.media.type !== "video/mp4" ?
                            <>
                                {
                                    richCardStandAloneDetails.mediaHeight === "SHORT_HEIGHT" &&
                                    <img src={richCardStandAloneDetails.media.url} style={{ height: "100px", width: "100%" }} />
                                }
                                {richCardStandAloneDetails.mediaHeight === "MEDIUM_HEIGHT" &&
                                    <img src={richCardStandAloneDetails.media.url} style={{ height: "150px", width: "100%" }} />
                                }
                            </>
                            :
                            <>
                                <PlayCircleOutlinedIcon style={{
                                    position: "absolute"
                                    , left: "35%",
                                    top: "20%",
                                    fontSize: "90px"
                                }} />
                                {
                                    richCardStandAloneDetails.mediaHeight === "SHORT_HEIGHT" &&
                                    <div style={{ position: "relative", height: "100px", width: "100%", alignItems: "center" }}>
                                        <img src={richCardStandAloneDetails.thumbnail?.url} style={{ opacity: "0.8", height: "100%", width: "100%" }} />
                                    </div>
                                }
                                {richCardStandAloneDetails.mediaHeight === "MEDIUM_HEIGHT" &&
                                    <div style={{ position: "relative", height: "150px", width: "100%", alignItems: "center" }}>
                                        <img src={richCardStandAloneDetails.thumbnail?.url} style={{ opacity: "0.8", height: "100%", width: "100%" }} />
                                    </div>

                                }</>
                        }

                    </Grid>
                    <Grid padding={2}>
                        <Typography variant="h6" fontWeight={"bold"} sx={{ lineBreak: "auto", wordBreak: "break-all" }} >
                            {richCardStandAloneDetails.cardTitle}
                        </Typography>
                        <Typography variant="body2" sx={{ lineBreak: "auto", wordBreak: "break-all" }} >
                            {richCardStandAloneDetails.cardDescription}
                        </Typography>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>


                            {richCardStandAloneDetails.suggestionsList.map((suggestions) => (
                                <Box marginTop={1}>
                                    <SuggestionSimulatorComponent suggestion={suggestions} />
                                    <Divider />
                                </Box>
                            ))}
                        </Box>


                    </Grid>
                </Card>}

            {richCardStandAloneDetails.cardOrientation === "HORIZONTAL" &&
                <Card sx={{ border: "1px solid Grey", minHeight: "12vh", width: "100%", display: "flex", margin: 3 }}>
                    <Grid container>

                        {richCardStandAloneDetails.cardAlignment === "LEFT" &&
                            <Grid item md={4} >
                                {richCardStandAloneDetails.media.type !== "video/mp4" ?

                                    <img src={richCardStandAloneDetails.media.url} style={{ height: "100%", width: "100%" }} />
                                    :
                                    <>
                                        {
                                            richCardStandAloneDetails.thumbnail.url &&
                                            <>
                                                <PlayCircleOutlinedIcon style={{
                                                    position: "absolute", left: "12%",
                                                    top: "25%"
                                                    , fontSize: "50px",
                                                    zIndex: 1
                                                }} />
                                                <div style={{ position: "relative", height: "100%", width: "100%", alignItems: "center" }}>

                                                    <img src={richCardStandAloneDetails.thumbnail.url} style={{ height: "100%", width: "100%", zIndex: 0 }} />
                                                </div>
                                            </>}
                                    </>
                                }

                            </Grid>
                        }


                        <Grid item md={8} padding={2} >
                            <Typography variant="h6" fontWeight={"bold"} sx={{ lineBreak: "auto", wordBreak: "break-all" }}>

                                {richCardStandAloneDetails.cardTitle}
                            </Typography>
                            <Typography variant="body2" sx={{ lineBreak: "auto", wordBreak: "break-all" }} >
                                {richCardStandAloneDetails.cardDescription}
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>


                                {richCardStandAloneDetails.suggestionsList.map((suggestions) => (
                                    <Box marginTop={1}>
                                        <SuggestionSimulatorComponent suggestion={suggestions} />
                                        <Divider />
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                        {richCardStandAloneDetails.cardAlignment === "RIGHT" &&
                            <Grid item md={4}  >
                                {richCardStandAloneDetails.media.type !== "video/mp4" ?
                                    <img src={richCardStandAloneDetails.media.url} style={{ height: "100%", width: "100%" }} />
                                    :
                                    <>
                                        {richCardStandAloneDetails.thumbnail.url && <>
                                            <PlayCircleOutlinedIcon style={{ position: "absolute", left: "73%", top: "25%", fontSize: "50px", zIndex: 1 }} />
                                            <div style={{ position: "relative", height: "100%", width: "100%", alignItems: "center" }}>
                                                <img src={richCardStandAloneDetails.thumbnail.url} style={{ height: "100%", width: "100%", zIndex: 0 }} />
                                            </div>
                                        </>
                                        }
                                    </>
                                }

                            </Grid>
                        }
                    </Grid>
                </Card>}

        </>
    )
}