import React from 'react'
import { Grid } from 'react-feather'

export default function RcsReadIcon() {
    return (
        <div >
            <svg viewBox="0 0 16 10" width="25" height="20" style={{ display: "grid", marginBottom: "3", marginRight: "3" }}>

                <g clip-path="url(#a)">
                    <path fill='darkblue' d="m6.27 7.08-.847.848a.457.457 0 0 1-.638 0l-1.17-1.163a.448.448 0 0 1 0-.63.448.448 0 0 1 .63 0l.855.855 1.05-1.05c.022-1.59.765-3 1.928-3.922A4.503 4.503 0 0 0 6 1.508 4.492 4.492 0 0 0 1.5 6c0 2.483 2.018 4.5 4.5 4.5.75 0 1.455-.187 2.078-.51a5.106 5.106 0 0 1-1.815-2.902l.007-.008Z">
                    </path>
                    <path fill='darkblue' d="M11.25 1.5c-.225 0-.443.022-.652.052-.038 0-.075.008-.12.015A4.5 4.5 0 0 0 6.75 6a4.504 4.504 0 0 0 3.734 4.432c.038 0 .068.008.105.015.217.03.435.053.66.053 2.482 0 4.5-2.018 4.5-4.5s-2.018-4.5-4.5-4.5Zm-.773 6.54a.462.462 0 0 1-.284 0 .403.403 0 0 1-.12-.075c-.016-.008-.03-.015-.038-.022l-.863-.863-.307-.308-.037-.06c-.023-.03-.045-.052-.06-.09a.428.428 0 0 1-.03-.165c0-.022.007-.052.014-.074 0-.03 0-.06.023-.09.015-.03.037-.06.06-.09.015-.016.022-.038.037-.053a.508.508 0 0 1 .136-.098c.022-.007.045-.007.067-.014a.461.461 0 0 1 .203 0c.022 0 .044 0 .06.014a.469.469 0 0 1 .15.098l.854.855 2.655-2.655.075-.052a.406.406 0 0 1 .256-.075c.052 0 .097.007.15.03.007 0 .014.014.022.014a.429.429 0 0 1 .12.083c.015.015.015.03.03.038.03.037.06.067.075.104.008.023.008.053.015.075a.53.53 0 0 1 0 .218c0 .015 0 .03-.008.045a.469.469 0 0 1-.097.15l-2.963 2.962a.469.469 0 0 1-.15.098c-.014 0-.03 0-.045.008V8.04Z">
                    </path>

                </g>
                <defs>
                    <clipPath id="a">
                        <path fill='darkblue' d="M1.5 1.5h14.25v9H1.5z">
                        </path>
                    </clipPath>
                </defs>
            </svg>






        </div>


    )
}
