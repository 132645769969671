import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BadgeIcon from "@mui/icons-material/Badge";
import LaunchIcon from "@mui/icons-material/Launch";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import {
    Alert,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Link,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEditViewVerifyBotDetailsForm, setShowViewVerifyBotDetailsForm } from "../../../redux/botManagement/botManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import FetchingSpinner from "../../components/FetchingSpinner";
import useAuth from "../../../hooks/useAuth";
import { BotStatus, ERoles } from "../../../config";
export default function BotVerificationDetails() {
    const state = useSelector(state => state.botManagementSlice);
    const [botVerificationDetails, setBotVerificationDetails] = useState({});
    const [brandVerificationDetails, setBrandVerificationDetails] = useState({});
    const [botDetailsEditable, setBotDetailsEditable] = useState(false);

    const [businessVerificationDetails, setBusinessVerificationDetails] = useState({});
    const [chatbotWebhook, setChatbotWebhook] = useState("");

    const [loadingDetailsSpinner, setLoadingDetailsSpinner] = useState(true);
    const [loadingDetailsErrorMessage, setLoadingDetailsErrorMessage] = useState(null);
    const [botName, setBotName] = useState(state.controls.showViewVerifyBotDetailsForm.botName);
    const dispatch = useDispatch();

    const [showVerifyBotDetails, setShowVerifyBotDetails] = useState(null);
    const [payment, setPayment] = useState(null);
    const { user } = useAuth();


    // const initialValues = {
    //     actionsToTriggersMessagesToUsers :botVerificationDetails.actionsToTriggersMessagesToUsers,
    //     typeOfActionsBotWillHaveWithUsers : botVerificationDetails.typeOfActionsBotWillHaveWithUsers,
    //     respondP2AMessages : botVerificationDetails.respondP2AMessages,
    //     keywordsBotRecognizesAndResponds : botVerificationDetails.keywordsBotRecognizesAndResponds,
    //     ensureConsent : botVerificationDetails.ensureConsent,
    //     optOutKeywordsForBot : botVerificationDetails.optOutKeywordsForBot,
    //     messageBotSendWhenUserOptsOut : botVerificationDetails.messageBotSendWhenUserOptsOut,
    //     keywordsUserSendToRevokeOptOut : botVerificationDetails.keywordsUserSendToRevokeOptOut,
    //     platformWhenUserRevokesOptout : botVerificationDetails.platformWhenUserRevokesOptout,
    //     messageBotSendWhenUserRevokesOptout : botVerificationDetails.messageBotSendWhenUserOptsOut,
    //     botAccessInstructions : botVerificationDetails.botAccessInstructions,
    //     chatbotWebhook : botVerificationDetails.chatbotWebhook,

    // }

    const verifyBotDetails = async () => {
        try {
            setLoadingDetailsErrorMessage("");
            setLoadingDetailsSpinner(true);
            const botId = state.controls.showViewVerifyBotDetailsForm.botId
                ? state.controls.showViewVerifyBotDetailsForm.botId
                : state.controls.showEditVerifyBotDetailsForm.botId;
            const response = await apiGatewayAxiosInstance.get(`/bot_management/bot/get-verify-bot`, { params: { botId } });
            if (response.status === 200) {
                setLoadingDetailsSpinner(false);

                setBotVerificationDetails(response.data.data.verifyBotDetails.verificationDetails);
                if (response.data.data.verifyBotDetails.verificationDetails.paymentStatus) {

                    setPayment(response.data.data.verifyBotDetails.verificationDetails.paymentStatus);
                } else {
                    setPayment("5000")
                }
                setChatbotWebhook(response.data.data.verifyBotDetails.chatbotWebhook);
                setBrandVerificationDetails(response.data.data.brandDetails);
                setBusinessVerificationDetails(response.data.data.brandDetails.businessVerificationDetails);
                setShowVerifyBotDetails(response.data.data.verifyBotDetails);

            } else {
                throw new Error(response);
            }
        } catch (error) {
            setLoadingDetailsSpinner(false);
            let errorMessage = "Failed to schedule campaign.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setLoadingDetailsErrorMessage(errorMessage);
        }
    };

    const onClickCancelButton = () => {
        botDetailsEditable ? dispatch(setEditViewVerifyBotDetailsForm(false)) : dispatch(setShowViewVerifyBotDetailsForm(false));
    };

    useEffect(() => {
        verifyBotDetails();
        state.controls.showEditVerifyBotDetailsForm.isEditable && setBotDetailsEditable(true);
    }, []);

    return (
        <Grid>
            {loadingDetailsSpinner ? (
                <FetchingSpinner />
            ) : (
                <>
                    <Grid container className='listHeader'>
                        <Grid item md={6} display={"flex"} alignItems={"center"}>
                            <IconButton color='secondary' onClick={onClickCancelButton} size='medium'>
                                <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                            </IconButton>
                            <Grid role='presentation'>
                                <Breadcrumbs aria-label='breadcrumb' fontSize='large'>
                                    <Link
                                        underline='hover'
                                        color='inherit'
                                        className='breadcrumbItem'
                                        // href="/rcs-management/manage-bots/"
                                        onClick={onClickCancelButton}
                                    >
                                        Bots
                                    </Link>
                                    <Typography className='breadcrumbItem' color='text.primary'>
                                        {botName} Verification Details
                                    </Typography>
                                </Breadcrumbs>
                            </Grid>

                            <Grid margin={2}>
                                {showVerifyBotDetails?.status ? (
                                    showVerifyBotDetails?.status === BotStatus.New ? (
                                        <Chip
                                            size='small'
                                            variant='outlined'
                                            className='chip'
                                            label={showVerifyBotDetails?.status.replaceAll("_", " ").toUpperCase()}
                                            color='primary'
                                        />
                                    ) : showVerifyBotDetails?.status === BotStatus.Development ? (
                                        <Chip
                                            size='small'
                                            variant='outlined'
                                            className='chip'
                                            label={showVerifyBotDetails?.status.replaceAll("_", " ").toUpperCase()}
                                            color='info'
                                        />
                                    ) : showVerifyBotDetails?.status === BotStatus.VerificationInProcess ? (
                                        <Chip
                                            size='small'
                                            variant='outlined'
                                            className='chip'
                                            label={showVerifyBotDetails?.status.replaceAll("_", " ").toUpperCase()}
                                            color='warning'
                                        />
                                    ) : showVerifyBotDetails?.status === BotStatus.Verified ? (
                                        <Chip
                                            size='small'
                                            variant='outlined'
                                            className='chip'
                                            label={showVerifyBotDetails?.status.replaceAll("_", " ").toUpperCase()}
                                            color='success'
                                        />
                                    ) : showVerifyBotDetails?.status === BotStatus.Launched ? (
                                        <Chip
                                            size='small'
                                            variant='outlined'
                                            className='chip'
                                            label={showVerifyBotDetails?.status.replaceAll("_", " ").toUpperCase()}
                                            color='success'
                                        />
                                    ) : showVerifyBotDetails?.status === BotStatus.VerificationSubmitted ? (
                                        <Chip
                                            size='small'
                                            variant='outlined'
                                            className='chip'
                                            label={showVerifyBotDetails?.status.replaceAll("_", " ").toUpperCase()}
                                            color='secondary'
                                        />
                                    ) : showVerifyBotDetails?.status === BotStatus.LaunchingInProcess ? (
                                        <Chip
                                            size='small'
                                            variant='outlined'
                                            className='chip'
                                            label={showVerifyBotDetails?.status.replaceAll("_", " ").toUpperCase()}
                                            color='warning'
                                        />
                                    ) : (
                                        <Chip
                                            size='small'
                                            variant='outlined'
                                            className='chip'
                                            label={showVerifyBotDetails?.status.replaceAll("_", " ").toUpperCase()}
                                            color='error'
                                        />
                                    )
                                ) : (
                                    "-"
                                )}
                            </Grid>
                        </Grid>
                        {user?.roleName && user?.roleName == ERoles.SUPER_ADMIN && (
                            <Grid display={"flex"} alignItems={"center"} justifyContent={"end"}>
                                <Typography variant='button' style={{ fontWeight: "600" }}>
                                    Organization Name :&nbsp;
                                </Typography>
                                <Typography variant='button'>{showVerifyBotDetails?.clientName}</Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Card sx={{ paddingX: "30px" }}>
                        {/* <Grid alignItems={"center"} display={"flex"} padding={2}>
                            <Grid item md={6} display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                <IconButton color='secondary' onClick={onClickCancelButton} size='medium'>
                                    <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                                </IconButton>
                            </Grid>
                            <Typography display={"flex"} justifyContent={"center"} alignItems={"center"} variant='h2'>
                                {botName} Verification Details
                            </Typography>
                        </Grid> */}
                        <BotDetailsAndExperienceComponent
                            botVerificationDetails={botVerificationDetails}
                            botName={botName}
                            botDetailsEditable={botDetailsEditable}
                            chatbotWebhook={chatbotWebhook}
                        />
                        <BrandDetails brandVerificationDetails={brandVerificationDetails} botDetailsEditable={botDetailsEditable} />

                        <BusinessVerification businessVerificationDetails={businessVerificationDetails} botDetailsEditable={botDetailsEditable} />
                        <PaymentComponent payment={payment} />

                        <Grid paddingBottom={5} gap={2} sx={{ display: "flex" }}>
                            <Button variant='contained' color='error' onClick={() => onClickCancelButton()}>
                                Back
                            </Button>

                            {botDetailsEditable && (
                                <Button
                                    variant='contained'
                                    color='primary'
                                // onClick={() => onClickCancelButton()}
                                >
                                    Update
                                </Button>
                            )}
                        </Grid>
                    </Card>
                </>
            )}
            {loadingDetailsErrorMessage && (
                <Alert mt={2} mb={3} severity='warning'>
                    {loadingDetailsErrorMessage}
                </Alert>
            )}
        </Grid>
    );
}

export function BotDetailsAndExperienceComponent({ botVerificationDetails, botName, botDetailsEditable, chatbotWebhook }) {
    const { user } = useAuth();

    return (
        <FormControl>
            <Box paddingTop={5} display={"flex"} alignItems={"center"} className='text-primary'>
                <SmartToyIcon />
                <Typography paddingLeft={2} variant='h5'>
                    Bot Details & Experience
                </Typography>
                <hr />
            </Box>
            {/* Bot Interactions */}
            <Box paddingY={5}>
                <Typography variant='h6' className='p-2'>
                    Bot Interactions
                </Typography>
                <hr />
                <Typography paddingBottom={3} className='text-muted'>
                    This section seeks details on how your bot interacts with users.
                </Typography>

                <FormControl fullWidth className='py-2'>
                    <Typography variant='h6' fontWeight={"bold"}>
                        What actions trigger messages to users?
                    </Typography>
                    <TextField
                        disabled={botDetailsEditable ? false : true}
                        id='outlined-read-only-input'
                        className='mt-3'
                        name='actionsToTriggersMessagesToUsers'
                        fullWidth
                        varient='outlined'
                        value={botVerificationDetails.actionsToTriggersMessagesToUsers}
                        multiline
                        rows={3}
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                    />
                </FormControl>
                <FormControl fullWidth className='py-2'>
                    <Typography variant='h6' fontWeight={"bold"}>
                        What types of interactions will the bot have with users?
                    </Typography>
                    <TextField
                        disabled={botDetailsEditable ? false : true}
                        className='mt-3'
                        name='typeOfActionsBotWillHaveWithUsers'
                        fullWidth
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                        varient='outlined'
                        multiline
                        rows={3}
                        value={botVerificationDetails.typeOfActionsBotWillHaveWithUsers}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </FormControl>
                <FormControl fullWidth className='py-2'>
                    {/* <FormLabel>
                        Does the bot support Conversational flow? Does it respond to
                        P2A messages from users?
                    </FormLabel> */}

                    <Typography variant='h6' fontWeight={"bold"}>
                        Does the bot support Conversational flow? Does it respond to P2A messages from users?
                    </Typography>
                    <RadioGroup
                        aria-labelledby='respondP2AMessages'
                        name='respondP2AMessages'
                        id='respondP2AMessages'
                        row
                        value={botVerificationDetails.respondP2AMessages}
                    >
                        <FormControlLabel
                            value='Yes'
                            disabled={botDetailsEditable ? false : true}
                            control={<Radio checked={botVerificationDetails.respondP2AMessages == "Yes" ? true : false} />}
                            label='Yes'
                        />
                        <FormControlLabel
                            disabled={botDetailsEditable ? false : true}
                            value='No'
                            control={<Radio checked={botVerificationDetails.respondP2AMessages == "No" ? true : false} />}
                            label='No'
                        />
                    </RadioGroup>
                </FormControl>
                {botVerificationDetails.respondP2AMessages == "Yes" && (
                    <FormControl fullWidth className='py-2'>
                        <Box display={"flex"} alignItems={"center"} gap={2}>
                            <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                                Please share a list of of some of the keywords that the bot recognizes and responds to.
                            </Typography>
                        </Box>
                        <TextField
                            disabled={botDetailsEditable ? false : true}
                            className='mt-3'
                            name='keywordsBotRecognizesAndResponds'
                            fullWidth
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            varient='outlined'
                            multiline
                            rows={3}
                            value={botVerificationDetails.keywordsBotRecognizesAndResponds}
                        />
                    </FormControl>
                )}
            </Box>

            {/* Consent Management */}

            <Box paddingY={5}>
                <Typography variant='h5' className='p-2'>
                    Consent Management
                </Typography>
                <hr />
                <FormControl fullWidth className='py-2'>
                    <Typography variant='h6' fontWeight={"bold"}>
                        How do you ensure consent, so that only the users who have opted-in will receive messages from your bot?
                    </Typography>
                    <TextField
                        disabled={botDetailsEditable ? false : true}
                        className='mt-3'
                        name='ensureConsent'
                        fullWidth
                        varient='outlined'
                        InputProps={{
                            readOnly: true,
                        }}
                        multiline
                        rows={4}
                        value={botVerificationDetails.ensureConsent}
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                    />
                </FormControl>

                <FormControl fullWidth className='py-2'>
                    <Typography variant='h6' fontWeight={"bold"}>
                        What are the opt-out keywords for the bot?
                    </Typography>
                    <TextField
                        disabled={botDetailsEditable ? false : true}
                        className='mt-3'
                        name='optOutKeywordsForBot'
                        fullWidth
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                        varient='outlined'
                        multiline
                        rows={3}
                        value={botVerificationDetails.optOutKeywordsForBot}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </FormControl>
                <FormControl fullWidth className='py-2'>
                    <Typography variant='h6' fontWeight={"bold"}>
                        What message does the bot send when a user opts out?
                    </Typography>

                    <TextField
                        disabled={botDetailsEditable ? false : true}
                        className='mt-3'
                        name='messageBotSendWhenUserOptsOut'
                        fullWidth
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                        varient='outlined'
                        multiline
                        rows={3}
                        value={botVerificationDetails.messageBotSendWhenUserOptsOut}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </FormControl>

                <FormControl fullWidth className='py-2'>
                    <Typography variant='h6' fontWeight={"bold"}>
                        What keywords can the user send to revoke the opt-out and start receiving messages again?
                    </Typography>
                    <TextField
                        disabled={botDetailsEditable ? false : true}
                        className='mt-3'
                        name='keywordsUserSendToRevokeOptOut'
                        fullWidth
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                        varient='outlined'
                        InputProps={{
                            readOnly: true,
                        }}
                        multiline
                        rows={3}
                        value={botVerificationDetails.keywordsUserSendToRevokeOptOut}
                    />
                </FormControl>

                <FormControl fullWidth className='py-2'>
                    {/* <FormLabel>
                        Do you want the platform to handle when a user revokes
                        opt-out?
                    </FormLabel> */}

                    <Typography variant='h6' fontWeight={"bold"}>
                        Do you want the platform to handle when a user revokes opt-out?
                    </Typography>
                    <RadioGroup
                        aria-labelledby='platformWhenUserRevokesOptout'
                        name='platformWhenUserRevokesOptout'
                        id='platformWhenUserRevokesOptout'
                        row
                        value={botVerificationDetails.platformWhenUserRevokesOptout}
                    >
                        <FormControlLabel
                            value='Default'
                            disabled={botDetailsEditable ? false : true}
                            control={<Radio checked={botVerificationDetails.platformWhenUserRevokesOptout == "Default" ? true : false} />}
                            label='Default: Platform sends the Confirmation message'
                        />
                        <FormControlLabel
                            value='Custom'
                            disabled={botDetailsEditable ? false : true}
                            control={<Radio checked={botVerificationDetails.platformWhenUserRevokesOptout == "Custom" ? true : false} />}
                            label='Custom: Bot (Aggregator) sends the Confirmation message'
                        />
                    </RadioGroup>
                </FormControl>
                {botVerificationDetails?.platformWhenUserRevokesOptout === "Default" && (
                    <FormControl fullWidth className='py-2'>
                        <Typography variant='h6' fontWeight={"bold"}>
                            What message does the bot send when a user revokes opt-out?
                        </Typography>
                        <TextField
                            disabled={botDetailsEditable ? false : true}
                            className='mt-3'
                            name='messageBotSendWhenUserRevokesOptout'
                            fullWidth
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            value={botVerificationDetails.messageBotSendWhenUserRevokesOptout}
                            varient='outlined'
                            multiline
                            rows={3}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>
                )}
            </Box>

            {/* Bot Interactions */}
            <Box paddingY={5}>
                <Typography variant='h5' className='p-2'>
                    Agent Preview
                </Typography>
                <hr />
                <Typography marginY={3} className='text-muted'>
                    The RCS Business Messaging team needs to review your agent before it launches. Reviewers can test your agent with their own
                    devices, or you can provide a video showing core functionality and functioning opt-out capabilities.
                </Typography>
                <FormControl fullWidth className='py-2'>
                    <Typography variant='h6' fontWeight={"bold"}>
                        Bot Access Instructions
                    </Typography>
                    <TextField
                        disabled={botDetailsEditable ? false : true}
                        className='mt-3'
                        name='botAccessInstructions'
                        fullWidth
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                        varient='outlined'
                        InputProps={{
                            readOnly: true,
                        }}
                        multiline
                        rows={3}
                        value={botVerificationDetails.botAccessInstructions}
                    />
                </FormControl>
                {user?.roleName === ERoles.SUPER_ADMIN &&
                    <FormControl fullWidth className='py-2'>
                        <Typography variant='h6' fontWeight={"bold"}>
                            Chatbot Webhook
                        </Typography>
                        <TextField
                            disabled={botDetailsEditable ? false : true}
                            className='mt-3'
                            name='chatbotWebhook'
                            fullWidth
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            varient='outlined'
                            InputProps={{
                                readOnly: true,
                            }}
                            multiline
                            value={"https://rcsgateway.pinnacle.in:444/"}
                        />
                    </FormControl>

                }
                <FormControl fullWidth className='py-2'>
                    <Typography paddingY={1} variant={"h6"}>
                        Screenshots
                    </Typography>
                    <Box>
                        <Box className='p-2 border rounded'>
                            <Box sx={{ display: "flex" }}>
                                <Grid container>
                                    {botVerificationDetails?.screenshots?.map((screenshot, index) => (
                                        <Grid item md={1} border='1px solid grey' margin={2}>
                                            <Grid>
                                                <img src={screenshot.url} height={"100%"} width={"100%"} />
                                            </Grid>
                                            <hr style={{ margin: "0px" }} />
                                            <Grid display={"flex"} justifyContent={"center"}>
                                                <IconButton
                                                    aria-label='close'
                                                    color='primary'
                                                    sx={{ p: 0.5 }}
                                                    onClick={() => {
                                                        window.open(screenshot.url);
                                                    }}
                                                >
                                                    <LaunchIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </FormControl>

                <FormControl fullWidth className='py-2'>
                    <Typography paddingY={1} variant={"h6"}>
                        Video URLs
                    </Typography>
                    <TextField
                        disabled={botDetailsEditable ? false : true}
                        className='mt-3'
                        name='videoUrls'
                        fullWidth
                        rows={3}
                        label='Bot Video Urls'
                        varient='outlined'
                        InputProps={{
                            readOnly: true,
                        }}
                        multiline
                        value={botVerificationDetails.videoUrl}
                    />
                </FormControl>
            </Box>

            {/* <Box paddingY={5}>
                <Typography variant="h2">Brand Details</Typography>
                <hr />
            </Box> */}

            {/* <Box paddingY={5}>

                <Grid container>
                    <Grid item md={6}>
                        <FormControl fullWidth className="py-2">
                            <TextField
                             disabled={botDetailsEditable ? false : true}
                                id='outlined-read-only-input'
                                className="mt-3"
                                name="actionsToTriggersMessagesToUsers"
                                fullWidth
                                label="Brand Name"
                                varient="outlined"
                                value={botVerificationDetails.actionsToTriggersMessagesToUsers}
                                multiline
                                rows={3}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box> */}
        </FormControl>
    );
}

export function BrandDetails({ brandVerificationDetails, botDetailsEditable }) {
    return (
        <>
            <Box paddingTop={10}>
                <hr />
            </Box>
            <Box>
                <Typography variant='h5' className='p-2 mb-3'>
                    Brand Information
                </Typography>
                <Box paddingBottom={2} display={"flex"} gap={5}>
                    <FormControl className='w-50'>
                        <Typography variant='h6' fontWeight={"bold"}>
                            Brand Name
                        </Typography>

                        <TextField
                            disabled={botDetailsEditable ? false : true}
                            className='mt-2'
                            name='brandName'
                            fullWidth
                            varient='outlined'
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            value={brandVerificationDetails?.brandName}
                        />
                    </FormControl>
                    <FormControl className='w-50'>
                        <Typography variant='h6' fontWeight={"bold"}>
                            Industry Type
                        </Typography>

                        <TextField
                            disabled={botDetailsEditable ? false : true}
                            className='mt-2'
                            fullWidth
                            varient='outlined'
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            value={brandVerificationDetails?.industryType}
                        />
                    </FormControl>
                </Box>

                <Box paddingBottom={2} display={"flex"} gap={5}>
                    <FormControl className='w-50'>
                        <Typography variant='h6' fontWeight={"bold"}>
                            Official Brand Website
                        </Typography>
                        <TextField
                            disabled={botDetailsEditable ? false : true}
                            className='mt-3'
                            type='url'
                            name='officialBrandWebsite'
                            fullWidth
                            varient='outlined'
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            value={brandVerificationDetails?.officialBrandWebsite}
                        />
                    </FormControl>
                    <FormControl className='w-50'>
                        <Typography variant='h6' fontWeight={"bold"}>
                            Brand Logo
                        </Typography>

                        <Grid item border='1px solid grey' margin={2} height={"120px"} width={"90px"}>
                            <Grid>
                                <img src={brandVerificationDetails?.brandLogo?.url} height={"100%"} width={"100%"} />
                            </Grid>
                            <hr style={{ margin: "0px" }} />
                            <Grid display={"flex"} justifyContent={"center"}>
                                <IconButton
                                    aria-label='close'
                                    color='primary'
                                    sx={{ p: 0.5 }}
                                    onClick={() => {
                                        window.open(brandVerificationDetails?.brandLogo?.url);
                                    }}
                                >
                                    <LaunchIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Box>

                <Box paddingBottom={2} display={"flex"} flexDirection={"column"} gap={3}>
                    <Typography variant='h5' className='p-2 mb-1'>
                        Contact Person
                    </Typography>
                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Contact Person First Name{" "}
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                name='contactPersonFirstName'
                                fullWidth
                                varient='outlined'
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brandVerificationDetails?.contactPersonDetails?.firstName}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Contact Person Last Name
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                name='contactPersonLastName'
                                fullWidth
                                varient='outlined'
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brandVerificationDetails?.contactPersonDetails?.lastName}
                            />
                        </FormControl>
                    </Box>

                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Contact Person Designation
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                name='contactPersonDesignation'
                                fullWidth
                                varient='outlined'
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brandVerificationDetails?.contactPersonDetails?.designation}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Contact Person Email Id
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                type='email'
                                name='contactPersonEmailId'
                                fullWidth
                                varient='outlined'
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brandVerificationDetails?.contactPersonDetails?.emailId}
                            />
                        </FormControl>
                    </Box>
                    <FormControl fullWidth>
                        <Typography variant='h6' mb={3} fontWeight={"bold"}>
                            Contact Person Phone Number
                        </Typography>

                        <Box display={"flex"} gap={5}>
                            <Grid container>
                                <Grid item md={2} paddingInline={2}>
                                    <TextField
                                        disabled={botDetailsEditable ? false : true}
                                        fullWidth
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                        value={brandVerificationDetails?.companyAddressDetails?.country}
                                    />
                                </Grid>
                                <Grid item md={1} paddingInline={2}>
                                    <TextField
                                        disabled={botDetailsEditable ? false : true}
                                        name='contactPersonPhoneNumber.code'
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                        value={brandVerificationDetails?.contactPersonDetails?.code ?? "+91"}
                                    />
                                </Grid>
                                <Grid item md={3} paddingInline={2}>
                                    <TextField
                                        disabled={botDetailsEditable ? false : true}
                                        name='contactPersonDetails.number'
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                        value={brandVerificationDetails?.contactPersonDetails?.mobileNumber}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </FormControl>
                </Box>

                <Box paddingBottom={7} display={"flex"} flexDirection={"column"} gap={3}>
                    <Typography variant='h5' className='p-2 my-1'>
                        Company Address
                    </Typography>
                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Address Line 1
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                name='companyAddress?.addressLine1'
                                fullWidth
                                varient='outlined'
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brandVerificationDetails?.companyAddressDetails?.addressLine1}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Address Line 2
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                name='companyAddress?.addressLine2'
                                fullWidth
                                varient='outlined'
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brandVerificationDetails.companyAddressDetails?.addressLine2}
                            />
                        </FormControl>
                    </Box>

                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Country
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                fullWidth
                                varient='outlined'
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brandVerificationDetails?.companyAddressDetails?.country}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                State
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                name='companyAddress?.state'
                                fullWidth
                                varient='outlined'
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brandVerificationDetails?.companyAddressDetails?.state}
                            />
                        </FormControl>
                    </Box>

                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                City
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                name='companyAddress?.city'
                                fullWidth
                                varient='outlined'
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                value={brandVerificationDetails?.companyAddressDetails?.city}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Zip Code
                            </Typography>
                            <TextField
                                disabled={botDetailsEditable ? false : true}
                                className='mt-2'
                                name='companyAddress?.zipCode'
                                fullWidth
                                varient='outlined'
                                value={brandVerificationDetails?.companyAddressDetails?.zipCode}
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export function BusinessVerification({ businessVerificationDetails, botDetailsEditable }) {
    return (
        <>
            <Box paddingTop={5} display={"flex"} alignItems={"center"} className='text-primary'>
                <BadgeIcon />
                <Typography paddingLeft={2} variant='h2'>
                    Business Verification
                </Typography>
            </Box>
            <hr />
            <Box marginTop={2}>
                <Box>
                    <Typography variant='h6' fontWeight={"bold"}>
                        Verify the Legal Business Name
                    </Typography>

                    <Box className='d-flex item-center justify-center flex-column'>
                        {businessVerificationDetails?.verifyBusinessName?.map((business, index) => (
                            <Box key={`${business.documentType}-${index}`} display={"flex"} gap={2} className='mt-3'>
                                <FormControl className='w-50'>
                                    <Typography className='fw-semibold mb-2'>Document Type</Typography>

                                    <TextField
                                        disabled={botDetailsEditable ? false : true}
                                        className='mt-2'
                                        varient='outlined'
                                        value={business.documentType}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                    />
                                </FormControl>

                                <FormControl className='w-50'>
                                    <Typography className='fw-semibold mb-2'>Document</Typography>
                                    <Grid
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 2,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                border: "1px solid gray",
                                                height: "80%",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 2,
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <Typography variant='subtitle2'>{business?.selectedFile.url.name ?? business?.selectedFile.fileName ?? "Open Document"}</Typography>
                                        </Box>

                                        <IconButton
                                            aria-label='close'
                                            color='primary'
                                            sx={{ p: 2 }}
                                            onClick={() => {
                                                window.open(business.selectedFile?.url);
                                            }}
                                        >
                                            <LaunchIcon />
                                        </IconButton>
                                    </Grid>
                                </FormControl>
                            </Box>
                        ))}
                    </Box>
                </Box>
                {/* Verify the Business Address */}
                <Box marginY={5}>
                    <Typography variant='h6' fontWeight={"bold"}>
                        Verify the Business Address
                    </Typography>

                    <Box className='d-flex item-center justify-center flex-column'>
                        {businessVerificationDetails?.verifyBusinessAddress?.map((business, index) => (
                            <Box key={index} display={"flex"} gap={2} className='mt-3'>
                                <FormControl className='w-50'>
                                    <Typography className='fw-semibold mb-2'>Document Type</Typography>

                                    <TextField
                                        disabled={botDetailsEditable ? false : true}
                                        className='mt-2'
                                        varient='outlined'
                                        value={business?.documentType}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                    />
                                </FormControl>

                                <FormControl className='w-50'>
                                    <Typography className='fw-semibold mb-2'>Document</Typography>
                                    <Grid
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 2,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                border: "1px solid gray",
                                                height: "80%",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 2,
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <Typography variant='subtitle2'>{business?.selectedFile.url.name ?? business?.selectedFile.fileName ?? "Open Document"}</Typography>
                                        </Box>
                                        <IconButton
                                            aria-label='close'
                                            color='primary'
                                            sx={{ p: 2 }}
                                            onClick={() => {
                                                window.open(business.selectedFile?.url);
                                            }}
                                        >
                                            <LaunchIcon />
                                        </IconButton>
                                    </Grid>
                                </FormControl>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export function PaymentComponent({ payment }) {
    return (
        <>
            <Box paddingTop={5} display={"flex"} alignItems={"center"} className='text-primary'>
                <AccountBalanceWalletIcon />
                <Typography paddingLeft={2} variant='h2'>
                    Summary
                </Typography>
            </Box>
            <hr />
            <Box className='mx-auto d-flex justify-content-center'>
                <Box className='d-flex flex-column w-50 gap-2 mb-5'>
                    <Box className='d-flex justify-content-between'>
                        <Typography fontSize={18}>Verification Fee</Typography>
                        <Typography fontSize={18} fontWeight={"bold"}>
                            ₹ {`${payment}.00`}
                        </Typography>
                    </Box>
                    <Box className='d-flex justify-content-between'>
                        <Typography fontSize={18}>Launch Fee</Typography>
                        <Typography fontSize={18} fontWeight={"bold"}>
                            ₹ {"0.00"}
                        </Typography>
                    </Box>
                    <hr />
                    <Box className='d-flex justify-content-between'>
                        <Typography fontSize={20} fontWeight={"bold"}>
                            Total
                        </Typography>
                        <Typography fontSize={20} fontWeight={"bold"}>
                            ₹ {`${payment}.00`}
                        </Typography>
                    </Box>
                    <Box className='d-flex gap-2'>
                        <Typography className='fw-bold text-secondary'>Note:</Typography>
                        <Box className='mb-3'>
                            <Typography className='text-secondary'>
                                Additional taxes may be applicable.
                                <span className='text-danger'>* </span>
                            </Typography>
                            <Typography className='text-secondary'>
                                (This amount would be debited at the time of Bot approval and would be charged once per Brand.)
                            </Typography>
                        </Box>
                    </Box>
                    <Box className='d-flex justify-content-between'>
                        <Typography fontSize={18}>Maintenance Fee (to be paid after 12 Months)</Typography>
                        <Typography fontSize={18} fontWeight={"bold"}>
                            ₹ {"0.00"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
