import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactFlow, {
    applyNodeChanges,
    applyEdgeChanges,
    Controls,
    Background,
    getConnectedEdges,
    MarkerType,
    MiniMap
} from 'react-flow-renderer';
import { ListCard } from './Cards/ListCard';
import PropertyMenu from './PropertyMenu';
import { MessageCard } from './Cards/MessageCard';
import { UnidirectionEdge } from './Edges/UnidirectionEdge';
import { useEventListenerTwo } from '../../hooks/useEventListners';
import { Sidebar } from './Sidebar';
import { generateUniqueIdForCard, readFromLocalStorge, setItemInLocalStorage } from '../../utils/workflow-utils/helpers';
import { ConditionCard } from './Cards/ConditionCard';
import { ButtonNode } from './Cards/ButtonNode';
import { MediaNode } from './Cards/MediaNode';
import { ChannelSwitchConditionNode } from './Cards/ChannelSwitchConditionNode';
import { SwitchConditionNode } from './Cards/SwitchConditionNode';
import { WebhookNode } from './Cards/WebhookNode';
import { useLocation } from 'react-router-dom';
import { channelButtonValidation, traverseNodes } from '../../utils/workflow-utils/channelValidations';
import { SwitchNode } from './Cards/SwitchNode';
import { getNodeContent } from '../../utils/workflow-utils/generateContentForNode';
import { getChannelFromTheChannelNode } from '../../utils/workflow-utils/channelValidations';
import { Queue } from '../../utils/workflow-utils/Queue';
import { logger } from '../../utils/workflow-utils/logger';

// custom node types
const nodeType = {
    "messageCard": MessageCard,
    "listCard": ListCard,
    // "listCardItem":ListCardItem,
    "conditionCard": ConditionCard,
    "button": ButtonNode,
    "media": MediaNode,
    "webhook": WebhookNode,
    "switchCondition": SwitchConditionNode,
    "channelSwitch": ChannelSwitchConditionNode,
    "switchNode": SwitchNode
}

const edgeTypes = {
    "unidirectionEdge": UnidirectionEdge,
};


export const channelsArray = [
    {
        channelName: 'Whatsapp',
        channelId: 1,
        handleId: null,
        isActive: true,
    },
    {
        channelName: 'Messenger',
        channelId: 2,
        handleId: null,
        isActive: true,
    },
    {
        channelName: 'Telegram',
        channelId: 3,
        handleId: null,
        isActive: false,
    }
];


const generateId = () => `${generateUniqueIdForCard()}`;

export const WorkFlowEditor = () => {

    const location = useLocation();

    const [botId, setBotId] = useState("");

    const reactFlowWrapper = useRef(null);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const [nodeToBeDeleted, setNodeToBeDeleted] = useState(null);
    const [nodeToBeDuplicated, setNodeToBeDuplicated] = useState(null);
    const [edgeToBeDeleted, setEdgeToBeDeleted] = useState(null);
    const [startNodeId, setStartNodeId] = useState(null);

    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    // ------------ for storing the state of start node while edge is dragged 
    const [startConnectionNodeDataOnConnectionStart, setStartConnectionNodeDataOnConnectionStart] = useState({});

    // ------------ for storing the state of end node when edge drag is over
    const [endConnectionNodeDataOnMouseHover, setEndConnectionNodeDataOnMouseHover] = useState({});

    //------------------ to detch if the edge has been dropped over a specific node
    const [isMouseDropedOnNode, setIsMouseDropedOnNode] = useState({});

    //---------------------- Property Menu ----------------------
    const [nodeValuesForPropertyMenu, setNodeValuesToPropertyMenu] = useState({});
    const [isPropertyMenuVisible, setPropertyMenuVisibity] = useState(false);

    //  This state will manage all the variable used in a workflow
    const [variableNamespace, setVariableNamespace] = useState([]);


    const saveData = () => {
        // implemented autosave in localstorage done
        setItemInLocalStorage({ keyname: `data-${botId}`, value: { nodes, edges } });
    }

    const undo = () => {
        //TODO:- unimplemented function
    }
    const redo = () => {
        //TODO:- unimplemented function
    }

    // undo redo handler
    const undoRedoHandler = (event) => {
        const { ctrlKey, code } = event;
        if (ctrlKey === true && code === 'KeyZ') {
            undo();
        }

        if (ctrlKey === true && code === 'KeyY') {
            redo();
        }
    }

    // attach event listener to key press combination 
    useEventListenerTwo('keydown', undoRedoHandler);



    const addNewNode = (newNode) => {
        setNodes([...nodes, newNode]);
    };

    const deleteNode = () => {
        const nodeId = nodeToBeDeleted;
        if (nodeId) {
            const nodeToBeDeleted = nodes.filter((ndx) => ndx.id === nodeId);

            let newNodeArray = traverseToAllNodesForDeleteChannelIdToBelongToChannel(nodeToBeDeleted[0].id, nodeToBeDeleted[0].data.belongsToChannel);

            newNodeArray = newNodeArray.filter((ndx) => ndx.id !== nodeId);

            const connectedEdgesOfDeletedNode = getConnectedEdges(nodeToBeDeleted, edges);

            const edgesOfAllNodesExceptTheDeletedNode = edges.filter((edge) => !findEdgeInConnectedInArray(connectedEdgesOfDeletedNode, edge.id));

            setNodeToBeDeleted(null);
            setNodes(newNodeArray);
            setEdges(edgesOfAllNodesExceptTheDeletedNode);

            // delete node reference from property menu
            // and hide node
            setPropertyMenuVisibity(false);
            setNodeValuesToPropertyMenu(null);
        }
    };

    const duplicateNode = () => {

        if (nodeToBeDuplicated) {
            const nodeToDuplicate = nodes.filter((node) => node.id === nodeToBeDuplicated);
            if (nodeToDuplicate.length === 1) {

                const { type, data, methods, position: positionOfNode } = nodeToDuplicate[0];

                const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

                const position = reactFlowInstance.project({
                    x: positionOfNode.x + reactFlowBounds.left,
                    y: positionOfNode.y + reactFlowBounds.top,
                });

                // before duplicating the mark the node property isStartCard as false; 

                const newDataObject = {
                    ...data,
                    belongsToChannel: [],
                    error: {},
                    isStartCard: false,
                }

                const newNode = {
                    id: generateId(),
                    type: type,
                    position,
                    data: newDataObject,
                    methods: methods
                };

                addNewNode(newNode);
                // remove the duplicated node property from state
                setNodeToBeDuplicated(null);
            }
        }
    }

    const updateNode = (changes) => {
        setNodes(applyNodeChanges(changes, nodes));
    };

    const addNewEdge = (newEdge) => {
        setEdges([...edges, newEdge]);
    };


    const traverseToAllNodesForDeleteChannelIdToBelongToChannel = (nodeId, belongsToChannelArrayToDelete) => {

        let visited = {};
        let newNodeArray = [...nodes];

        let queue = new Queue();

        visited[nodeId] = true;

        queue.enqueue(nodeId);

        while (!queue.isEmpty()) {
            let currentNodeId = queue.dequeue();

            let isChannelSwitchNode = false;

            newNodeArray = newNodeArray.map(node => {
                if (currentNodeId === node.id) {

                    if (node.type === 'channelSwitch') {
                        isChannelSwitchNode = true;
                        return node;
                    } else {

                        let groupChannelByChannelId = {};


                        [...node.data.belongsToChannel, ...belongsToChannelArrayToDelete].forEach((item) => {
                            if (groupChannelByChannelId[item.channelId]) {


                                if (groupChannelByChannelId[item.channelId] <= 1) {
                                    delete groupChannelByChannelId[item.channelId];
                                } else {
                                    groupChannelByChannelId[item.channelId] = groupChannelByChannelId[item.channelId] - item.refCount;
                                }
                            }
                            else {
                                groupChannelByChannelId[item.channelId] = item.refCount;
                            }
                        });

                        const newBelongsToChannelArray = [];

                        for (const key in groupChannelByChannelId) {
                            if (Object.hasOwnProperty.call(groupChannelByChannelId, key)) {
                                newBelongsToChannelArray.push({
                                    channelId: Number(key),
                                    refCount: groupChannelByChannelId[key]
                                });
                            }
                        }
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                belongsToChannel: [...newBelongsToChannelArray]
                            }
                        };
                    }
                } else {
                    return node;
                }
            });

            if (isChannelSwitchNode === false) {
                const sourceNodeArray = edges.filter((edge) => edge.source === currentNodeId);

                sourceNodeArray.map((edge) => {
                    if (!visited[edge.target]) {
                        visited[edge.target] = true;
                        queue.enqueue(edge.target);
                    }
                });
            }
        }
        return newNodeArray;
    }

    const deleteChannelIdToBelongingArray = (params) => {
        const { source: sourceNodeId, sourceHandle, target: targetNodeId, targetHandle } = params;
        const sourceNode = nodes.filter(node => node.id === sourceNodeId)[0];
        if (sourceNode.type === 'channelSwitch') {
            const result = getChannelFromTheChannelNode(sourceNode, sourceNodeId, sourceHandle, targetNodeId, targetHandle);
            if (result.status === true) {
                const newNodeArray = traverseToAllNodesForDeleteChannelIdToBelongToChannel(targetNodeId, [{ channelId: result.channelId, refCount: 1 }]);
                setNodes(newNodeArray);
            }
        } else {

            if (sourceNode.type === 'button' || sourceNode.type === 'listCard' || sourceNode.type === 'conditionCard'
                || sourceNode.type === 'switchCondition' || sourceNode.type === 'switchNode') {
                const edgesArray = edges.filter(edge => edge.source === sourceNodeId && edge.target === targetNodeId);
                if (edgesArray.length === 1) {
                    const newNodeArray = traverseToAllNodesForDeleteChannelIdToBelongToChannel(targetNodeId, sourceNode.data.belongsToChannel);
                    setNodes(newNodeArray);
                }
            } else {
                const newNodeArray = traverseToAllNodesForDeleteChannelIdToBelongToChannel(targetNodeId, sourceNode.data.belongsToChannel);
                setNodes(newNodeArray);
            }



        }
    }


    const deleteEdge = () => {
        if (edgeToBeDeleted) {
            const edgeData = edges.filter((edge) => edge.id == edgeToBeDeleted);

            const sourceNode = nodes.filter(node => node.id === edgeData[0].source);


            const targetNode = nodes.filter(node => node.id === edgeData[0].target);

            setEdges(edges.filter((edge) => edge.id !== edgeToBeDeleted));

            deleteChannelIdToBelongingArray({ source: sourceNode[0].id, target: targetNode[0].id, sourceHandle: edgeData[0].sourceHandle, targetHandle: edgeData[0].targetHandle });

            setEdgeToBeDeleted(null);
        }
    };

    const updateEdge = (connection) => {
        setEdges(applyEdgeChanges(connection, edges));
    };

    const onNodesChange = (changes) => {
        updateNode(changes);
    }

    const onEdgesChange = (connection) => {
        updateEdge(connection)
    }

    const makeConnectedNodeBelongToChannelForNormalCard = (nodeId, belongsToChannelArray) => {

        // stores the information of which node has been visited
        let visited = {};

        // clone of nodes array
        let clonedNodesArray = [...nodes];

        // init queue
        let queue = new Queue();

        visited[nodeId] = true;

        queue.enqueue(nodeId);

        while (!queue.isEmpty()) {
            let currentNodeId = queue.dequeue();
            let isChannelSwitchNode = false;

            clonedNodesArray = clonedNodesArray.map((node, idx) => {

                // find current node in nodes array
                // if the the current node is channel switch node then do not traverse
                if (currentNodeId === node.id) {
                    if (node.type === 'channelSwitch') {
                        isChannelSwitchNode = true;
                        return node;
                    } else {    // current node is not a switch channel node

                        const newTargetBelongToChannelArray = [];

                        // group by channel object
                        const channelGroupObj = {};

                        // TODO: optimise it later

                        [...node.data.belongsToChannel, ...belongsToChannelArray].forEach((item) => {
                            if (channelGroupObj[item.channelId]) {
                                channelGroupObj[item.channelId] = channelGroupObj[item.channelId] + item.refCount;
                            } else {
                                channelGroupObj[item.channelId] = item.refCount;
                            }
                        });

                        // convert object to array
                        for (const key in channelGroupObj) {
                            if (Object.hasOwnProperty.call(channelGroupObj, key)) {
                                newTargetBelongToChannelArray.push({
                                    channelId: Number(key), // for now
                                    refCount: channelGroupObj[key]
                                });
                            }
                        }

                        return {
                            ...node, data: {
                                ...node.data, belongsToChannel: [...newTargetBelongToChannelArray]
                            }
                        };
                    }
                } else {
                    return node;
                }
            });
            if (isChannelSwitchNode === false) {
                const sourceNodeArray = edges.filter((edge) => edge.source === currentNodeId);
                sourceNodeArray.map((edge) => {
                    if (!visited[edge.target]) {
                        visited[edge.target] = true;
                        queue.enqueue(edge.target);
                    }
                });
            }
        }
        setNodes([...clonedNodesArray]);
    }




    /**
     * this method modifies the nodes data object
     * @param {*} params 
     * returns null
     */
    const addChannelIdToBelongToChannelArray = (params) => {
        const { source: sourceNodeId, sourceHandle, target: targetNodeId, targetHandle } = params;
        const sourceNode = nodes.filter(node => node.id === sourceNodeId)[0];
        const targetNode = nodes.filter(node => node.id === targetNodeId)[0];
        if (sourceNode.type === 'channelSwitch') {
            //  get channel id
            const result = getChannelFromTheChannelNode(sourceNode, sourceNodeId, sourceHandle, targetNodeId, targetHandle);
            if (result.status === true) {
                // based on channel id update belongToChannel 
                //if we encounter a channelswitch node then do not traverse the array just pass the channel id to belongToChannel array
                //  this method should return me new array of nodes
                makeConnectedNodeBelongToChannelForNormalCard(targetNodeId, [{ channelId: result.channelId, refCount: 1 }]);
            } else { // if we encounter a node except the channel switch node we then take the ancestor node belongToChannel array and pass to next node 
                logger.error("Error in addChannelIdToBelongingArray: " + result?.reason);
            }
        } else {
            if (sourceNode.type === 'button' || sourceNode.type === 'listCard' || sourceNode.type === 'conditionCard'
                || sourceNode.type === 'switchCondition' || sourceNode.type === 'switchNode') {
                const edgesArray = edges.filter(edge => edge.source === sourceNodeId && edge.target === targetNodeId);
                if (edgesArray.length === 0)
                    makeConnectedNodeBelongToChannelForNormalCard(targetNodeId, sourceNode.data.belongsToChannel);
            } else {
                makeConnectedNodeBelongToChannelForNormalCard(targetNodeId, sourceNode.data.belongsToChannel);
            }
        }
    }



    //  when egdes are connected
    const onConnect = (params) => {


        // ==== this check if the particular node handle is creating a connection to same node twice
        const edgesOfSourceNode = edges.filter((edge) => edge.source === params.source); // get all the edges of source node 
        let flagToCheckIfNodeIsConnectedToSameNodeAndHandle = false; // 
        if (edgesOfSourceNode.length === 0) { // this means the source node is not connected to any other node
            flagToCheckIfNodeIsConnectedToSameNodeAndHandle = true;
        }
        else {
            const lastArray = edgesOfSourceNode.filter((ele) => ele.sourceHandle === params.sourceHandle);
            if (lastArray.length === 0) {
                flagToCheckIfNodeIsConnectedToSameNodeAndHandle = true;
            }
        }

        // ====
        if (flagToCheckIfNodeIsConnectedToSameNodeAndHandle) {
            var edge = {
                id: generateId(),
                source: params.source ?? '',
                target: params.target ?? '',
                sourceHandle: params.sourceHandle ?? '',
                type: 'unidirectionEdge',
                data: {
                    methods: {
                        onDelete: onDeleteEdge
                    },
                },

                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    color: '#4c85ff'
                },
            };
            addNewEdge(edge);
            addChannelIdToBelongToChannelArray(params);
        }

    }

    const findEdgeInConnectedInArray = (edgesArray, edgeId) => {
        return edgesArray.findIndex((edge) => edge.id === edgeId) === -1 ? false : true;
    }

    //  when node is removed from view
    const onDeleteNode = (nodeId) => {
        setNodeToBeDeleted(nodeId);
    }

    const onDuplicateNode = (event, nodeId) => {
        setNodeToBeDuplicated(nodeId);
    };

    const onDeleteEdge = (event, edgeId) => {
        setEdgeToBeDeleted(edgeId);
    }

    const markNodeAsStartNode = () => {
        // this function gets trigger wheneven startNodeId state changes
        if (startNodeId !== null) {
            setNodes(nodes.map((node) => {
                if (node.id === startNodeId) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            isStartCard: true,
                        }
                    }
                } else {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            isStartCard: false,
                        }
                    }
                }
            }));
        }
    }

    const onClickMarkNodeAsStartFlag = (nodeId) => {
        setStartNodeId(nodeId);
    }


    // called from property menu
    const deleteHandlerFromPropertyMenu = (edgeHandlerId) => {
        deleteEdge(edgeHandlerId);
    }

    const showPropertyMenu = (node) => {
        // set node values to property component state
        setNodeValuesToPropertyMenu(node.id);
        // set visibility of property menu
        setPropertyMenuVisibity(true);
    }

    const getSelectedNodeValue = () => {
        return nodes.filter((node) => node.id === nodeValuesForPropertyMenu)[0];
    };

    const nodeClickEvent = (event, node) => {
        // set nodes state to localstate so that we can access all the features of it
        showPropertyMenu(node);
    }



    const closePropertyMenu = () => {

        // set visibility of property menu
        setPropertyMenuVisibity(false);
        setNodeValuesToPropertyMenu(null);
    }

    // ======================================================================================================================


    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);


    const onDrop = (event) => {
        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow/card-type');
        const cardTitle = event.dataTransfer.getData('application/reactflow/card-title');
        const cardTailwindStyle = event.dataTransfer.getData('application/reactflow/tailwind-class');

        const generatedNodeId = generateId();

        // check if the dropped element is valid
        if (typeof type === 'undefined' || !type) {
            return;
        }

        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left - 150, // 150 is width of card [horizontally]
            y: event.clientY - reactFlowBounds.top - 10, // 10 is a fix number [ verticaly ]
        });


        const nodeContents = getNodeContent(type);
        const dataObject = {
            label: `${type} node`,
            title: cardTitle,
            cardTailwindStyle: cardTailwindStyle,
            contents: nodeContents,
            methods: {
                onDuplicate: onDuplicateNode.bind(this),
                onDelete: onDeleteNode.bind(this),
                markAsStartCard: onClickMarkNodeAsStartFlag.bind(this),
            },
            supportedChannels: [1, 2, 3],
            belongsToChannel: [],
            isStartCard: false,
            error: {
                flag: false,
                message: ""
            }
        };


        if (type === 'channelSwitch') {
            //  generate handlerId for channel switch
            let channels = channelsArray.map((channel) => {
                return {
                    ...channel,
                    handleId: `${generatedNodeId}_${channel.channelId}`
                }
            });
            dataObject['channels'] = channels;
        }

        const newNode = {
            id: generatedNodeId,
            type,
            position,
            data: dataObject,
        };

        addNewNode(newNode);
        showPropertyMenu(newNode);
    }

    const extractAllVariantFromNodes = (nodesFromServer) => {
        let variantList = [];
        // map function to every individual nodes
        nodesFromServer.forEach((ndx) => {
            variantList.push({ nodeId: ndx.id, variant: ndx.data.variant });
        });

        return variantList;
    }

    const addMethodsToAllNodes = (nodesFromServer) => {
        // map function to every individual nodes
        nodesFromServer.forEach((ndx) => {
            ndx.data.methods.onDelete = onDeleteNode;
            ndx.data.methods.onDuplicate = onDuplicateNode;
            ndx.data.methods.markAsStartCard = onClickMarkNodeAsStartFlag;
        });

        return nodesFromServer;
    }

    const addMethodsToAllEdges = (edgesFromServer) => {
        // map delete to every individual edge
        edgesFromServer.forEach((edge) => {
            edge.data.methods.onDelete = onDeleteEdge;
        });

        return edgesFromServer;
    }

    const onEdgeClick = (event, edge) => {

    }

    const getData = () => {
        const searchParams = new URLSearchParams(location.search);
        const botId = searchParams.get('botId');
        setBotId(botId);
        let data = readFromLocalStorge({ keyname: `data-${botId}` });
        if (data.nodes.length === 0) {
            data.nodes = [{
                id: "acPXPzf4nwhLsEgZhw5aE8",
                type: "channelSwitch",
                position: {
                    // x: 728,
                    // y: 224.16666666666669
                    x: 60,
                    y: 60
                },
                data: {
                    label: "channelSwitch node",
                    title: "Channel Switch Condition",
                    cardTailwindStyle: "bg-red-500",
                    channels: channelsArray.map((channel) => {
                        return {
                            ...channel,
                            handleId: `acPXPzf4nwhLsEgZhw5aE8_${channel.channelId}`
                        }
                    }),
                    contents: [],
                    methods: {},
                    supportedChannels: [1, 2, 3],
                    belongsTo: [],
                    belongsToChannel: [],
                    isStartCard: true,
                    error: {
                        flag: false,
                        message: ""
                    }
                },
                width: 302,
                height: 212,
                selected: true
            }]
        }

        const edgesWithMethods = addMethodsToAllEdges(data.edges);
        const nodesWithMethods = addMethodsToAllNodes(data.nodes);
        setNodes(nodesWithMethods);
        setEdges(edgesWithMethods);
    }

    const onNodeContextMenu = (eventFromContextMenu, node) => {
        eventFromContextMenu.preventDefault()
        return false;
    }

    // -------------- EDGE DRAG HANDLER for Dropping the connection on node except handle of node --------------------------------------------------
    const onMouseStartOnNode = (event, node) => {
        setEndConnectionNodeDataOnMouseHover(node);
    }
    const onMouseStartOfEdgeConnection = (event, node) => {
        setStartConnectionNodeDataOnConnectionStart(node);
    }
    const onMouseEndOfEdgeConnection = (event) => {
        setIsMouseDropedOnNode({});
    }

    const onNodesDelete = (deletedNodesArray) => {
        setPropertyMenuVisibity(false);
        setNodeValuesToPropertyMenu(null);
    }

    // useeffect to listen for if edge has been dropped
    useEffect(() => {
        // when user drop the connection on node except the handle it is dragging
        // so here we made a programmitically connection between start node and end node
        // where start node is a node where user starts the connection ( edge drag start ) 
        // end node is a node where user drop the connection (edge drag stop)
        if (startConnectionNodeDataOnConnectionStart.nodeId !== undefined && endConnectionNodeDataOnMouseHover.id !== undefined && endConnectionNodeDataOnMouseHover.id !== startConnectionNodeDataOnConnectionStart.nodeId) {
            let params = {};
            if (startConnectionNodeDataOnConnectionStart.handleType === 'source') {
                params = {
                    source: startConnectionNodeDataOnConnectionStart.nodeId,
                    sourceHandle: startConnectionNodeDataOnConnectionStart.handleId ?? '',
                    target: endConnectionNodeDataOnMouseHover.id,
                    targetHandle: endConnectionNodeDataOnMouseHover.id
                }
                onConnect(params);
                setStartConnectionNodeDataOnConnectionStart({});
                setEndConnectionNodeDataOnMouseHover({});
            }
        }
    }, [isMouseDropedOnNode]);
    // -----------------------------------------------------------------------------------------------------------------

    // =========================================== UPDATE NODE METHODS ==================================================

    const updateMessageNode = (nodeId, contents) => {
        setNodes(nodes.map((node) => {
            if (nodeId === node.id) {
                return { ...node, data: { ...node.data, contents } };
            } else {
                return node;
            }
        }));
    }

    const updateButtonNode = (nodeId, buttonName) => {
        setNodes(nodes.map((node) => {
            if (nodeId === node.id) {

                let newButtonItemId = generateUniqueIdForCard();
                const newButtonObject = {
                    id: newButtonItemId,
                    value: buttonName,
                    handleId: `${node.id}_${newButtonItemId}`
                }
                const clonedContents = [...node.data.contents, newButtonObject];
                // check for card limitations

                let error = {};
                let result = channelButtonValidation(node.data);
                if (result.flag === true) {
                    error.flag = result.flag;
                    error.message = result.message;
                }
                return { ...node, data: { ...node.data, contents: clonedContents, error } };
            } else {
                return node;
            }
        }));
    }

    const updateButtonData = (nodeId, nodeData) => {
        setNodes(nodes.map((node) => {
            if (nodeId === node.id) {
                return { ...node, data: nodeData };
            } else {
                return node;
            }
        }));
    }

    const updateListNode = (nodeId, contents) => {
        setNodes(nodes.map((node) => {
            if (nodeId === node.id) {
                return { ...node, data: { ...node.data, contents } };
            } else {
                return node;
            }
        }));
    }

    const updateConditionNode = (nodeId, contents) => {
        setNodes(nodes.map((node) => {
            if (nodeId === node.id) {
                return { ...node, data: { ...node.data, contents } };
            } else {
                return node;
            }
        }));
    }


    const updateMediaNode = (nodeId, contents) => {
        setNodes(nodes.map((node) => {
            if (nodeId === node.id) {
                return { ...node, data: { ...node.data, contents } };
            } else {
                return node;
            }
        }));
    }

    const deleteListNodeItem = (nodeId, listNodeItemId) => {
        const handleId = `${nodeId}_${listNodeItemId}`;
        const newEdges = edges.filter((edge) => edge.sourceHandle !== handleId);
        setEdges(newEdges);
    }

    const deleteButtonsNode = (nodeId, buttonsNodeId) => {
        const handleId = `${nodeId}_${buttonsNodeId}`;
        const newEdges = edges.filter((edge) => edge.sourceHandle !== handleId);
        setEdges(newEdges);
    }

    const updateNodeCoreProperties = (nodeId, title) => {
        // TODO: validate the string length for title
        setNodes(nodes.map((node) => {
            if (nodeId === node.id) {
                return { ...node, data: { ...node.data, title } };
            } else {
                return node;
            }
        }));
    }



    // =========================================== UPDATE NODE METHODS ==================================================


    // =========================================== VARIABLE NAMESPACE ==================================================

    const addVariantToNameSpace = (nodeId, variableName) => {

        const result = variableNamespace.filter((varName) => varName.variant === variableName);
        if (result.length > 0) {
            return { result: false, message: "Message Create variable With same name" }
        } else {
            setVariableNamespace([...variableNamespace, { nodeId, variant: variableName }]);
            return { result: true, message: "" }
        }
    }

    const removeVariableToNameSpace = () => {

    }

    const updateNodeVariant = (nodeId, nodeVariantName) => {
        setNodes(nodes.map((node) => {
            if (node.id === nodeId) {
                return { ...node, data: { ...node.data, requiredUserInput: true, variantName: nodeVariantName } };
            } else {
                return node;
            }
        }));
    }

    const removeNodeVariant = (nodeId, nodeVariantName) => {
        setNodes(nodes.map((node) => {
            if (node.id === nodeId) {
                return { ...node, data: { ...node.data, requiredUserInput: false, variantName: "" } };
            } else {
                return node;
            }
        }));
    }

    const updateSwitchNode = (nodeId, contents) => {
        setNodes(nodes.map((node) => {
            if (node.id === nodeId) {
                return { ...node, data: { ...node.data, contents } };
            } else {
                return node;
            }
        }));
    }

    const deleteButtonFromSwitchNode = (nodeId, buttonsNodeId) => {
        const handleId = `${nodeId}_${buttonsNodeId}`;
        const newEdges = edges.filter((edge) => edge.sourceHandle !== handleId);
        setEdges(newEdges);
    }

    // =========================================== VARIABLE NAMESPACE ==================================================

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        deleteNode();
    }, [nodeToBeDeleted]);

    useEffect(() => {
        duplicateNode();
    }, [nodeToBeDuplicated]);

    useEffect(() => {
        deleteEdge();
    }, [edgeToBeDeleted]);

    useEffect(() => {
        saveData();
    }, [nodes, edges]);

    useEffect(() => {

        markNodeAsStartNode();

    }, [startNodeId])

    useEffect(() => {
        
    }, [variableNamespace]);

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <div className="dndflow">
                <Sidebar
                    onSave={saveData}
                />
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        // onConnect={onConnect}
                        onInit={setReactFlowInstance}
                        onDrop={onDrop}
                        onNodeMouseEnter={onMouseStartOnNode}
                        onConnectEnd={onMouseEndOfEdgeConnection}
                        onConnectStart={onMouseStartOfEdgeConnection}
                        onDragOver={onDragOver}
                        onNodesDelete={onNodesDelete}
                        autoSave=''
                        fitView={false}
                        deleteKeyCode={null}
                        // minZoom={0.1}
                        maxZoom={15}
                        nodeTypes={nodeType}
                        edgeTypes={edgeTypes}
                        onNodeContextMenu={onNodeContextMenu} // disables node right click menu
                        onContextMenu={onNodeContextMenu} // disables node right click menu
                        onNodeClick={nodeClickEvent}
                        onEdgeClick={onEdgeClick}
                    >
                        <Background variant='lines' color='#cbbfbf' />
                        <Controls />
                    </ReactFlow>
                </div>
                {
                    isPropertyMenuVisible &&
                    nodeValuesForPropertyMenu !== null &&
                    <div className="property-menu">
                        <PropertyMenu
                            Key={`selected-node-property${nodeValuesForPropertyMenu}`}
                            node={getSelectedNodeValue()}
                            closePropertyMenu={closePropertyMenu}
                            updateMessageNode={updateMessageNode}
                            updateButtonNode={updateButtonNode}
                            updateButtonData={updateButtonData}
                            updateListNode={updateListNode}
                            updateConditionNode={updateConditionNode}
                            updateMediaNode={updateMediaNode}
                            deleteListNodeItem={deleteListNodeItem}
                            deleteButtonsNode={deleteButtonsNode}
                            addVariantToNameSpace={addVariantToNameSpace}
                            updateVariantInfoInNode={updateNodeVariant}
                            removeVariantInfoFromNode={removeNodeVariant}
                            updateNodeCoreProperties={updateNodeCoreProperties}
                            getSelectedNodeValue={getSelectedNodeValue}
                            variableNamespace={variableNamespace}
                            updateSwitchNode={updateSwitchNode}
                            deleteButtonFromSwitchNode={deleteButtonFromSwitchNode}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

