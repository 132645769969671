import { createSlice } from "@reduxjs/toolkit";
import {
    VerifyClientSecretThunk,
    addNewBotThunk,
    addNewTestDeviceThunk,
    changeBotStatusThunk,
    getAllAnalyticsDataThunk,
    getAllBotByFilterThunk,
    getAllBotsThunk,
    getAllBotsThunkByPageSize,
    getAllProvidersThunk,
    getAllTestDeviceListByBotIdByPageSize,
    getAllVerifiedBotsThunk,
    getAllVerifiedBotsToRunCampaignThunk,
    verifyBotThunk,
} from "./botManagementThunk";

const botManagementSlice = createSlice({
    name: "botManagementSlice",
    initialState: {
        botType: "Domestic",
        botName: "",
        brandName: "",
        botMessageType: "",
        botLogoImageUrl: "",
        botBannerImageUrl: "",
        shortDescription: "",
        color: "",
        phoneNumbers: [{}],
        websites: [{}],
        emails: [{}],
        termsOfUseUrl: "",
        privacyPolicyUrl: "",
        developmentPlatform: "",
        chatbotWebhook: "",
        languagesSupported: "",
        botListByPageSize: [{}],
        totalBotsCount: 0,
        botList: [],
        botRecipientsList: [],
        verifiedBotList: [],
        verifiedBotListToRunCampaign: [],
        botLogoUrl: "",
        botBannerImageUrl: "",
        campaignListByBotId: [{}],

        //#region test devices
        testDeviceList: [],
        testDeviceListByPageSize: [],
        totalTestDeviceCount: 0,
        // Analytics Data
        analyticsData: [],
        todaysLogData: [],
        //#endregion
        providersList: [],


        controls: {
            showAddBotForm: false,
            showVerifyBotForm: { status: false, botId: "" },
            showViewVerifyBotDetailsForm: { status: false, botId: "" },
            showEditVerifyBotDetailsForm: { status: false, botId: "", isEditable: false },
            showVerifyClientSecretForm: { status: false, botId: "" },
            showAddTestDeviceForm: { status: false, botId: "" },
            areBotsLoaded: false,
            isCreateBotLoading: false,
            isGetAllBotsLoading: false,
            isAddingTestDevice: false, // used to show spinner on the add test device button
            isLoadingTestDevices: false, // used to show spinner on when loading test devices in the table
            isNewTestDeviceAdded: true, // used to reload the list device and update the table after a new test device is added

            // Error Messages
            addNewBotErrorMessage: "",
            getAllBotsErrorMessage: "",
            getAllBotsByErrorMessage: "",
            verifyBotErrorMessage: "",
            verifyClientSecretErrorMessage: "",
            uploadBotLogoErrorMessage: "",
            uploadBannerImageErrorMessgae: "",
            addTestDeviceErrorMessage: "",
            getAllTestDevicesErrorMessage: "",
            getVerifiedBrandDetailsErrorMessage: "",
            getAllProvidersErrorMessage: ""

        },
    },
    reducers: {
        setShowAddBotForm: (state, action) => {
            state.controls.showAddBotForm = action.payload;
            state.controls.addNewBotErrorMessage = "";
        },
        setShowVerifyBotForm: (state, action) => {
            state.controls.showVerifyBotForm = action.payload;
            state.controls.verifyBotErrorMessage = "";
        },
        setShowViewVerifyBotDetailsForm: (state, action) => {
            state.controls.showViewVerifyBotDetailsForm = action.payload;
            state.controls.verifyBotErrorMessage = "";
        },
        setEditViewVerifyBotDetailsForm: (state, action) => {
            state.controls.showEditVerifyBotDetailsForm = action.payload;
            state.controls.verifyBotErrorMessage = "";
        },

        setShowVerifyClientSecretForm: (state, action) => {
            state.controls.showVerifyClientSecretForm = action.payload;
            state.controls.verifyClientSecretErrorMessage = "";
        },

        setShowAddTestDeviceForm: (state, action) => {
            state.controls.showAddTestDeviceForm = action.payload;
            state.controls.isAddingTestDevice = false;

            state.controls.addTestDeviceErrorMessage = "";
            state.controls.getAllTestDevicesErrorMessage = "";
        },

        setshowIsTestDeviceAdding: (state, action) => {
            state.controls.isAddingTestDevice = action.payload;
            state.controls.addTestDeviceErrorMessage = "";
            state.controls.getAllTestDevicesErrorMessage = "";
        },
        clearErrorMessagesForBots: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = "";
        },

        onFailureGetVerifiedBotDetails: (state, action) => {
            state.controls.getVerifiedBrandDetailsErrorMessage = action.payload;
        }
    },
    extraReducers: (builder) => {
        //#region ---------------- Add new bot thunk -------------------------------
        builder.addCase(addNewBotThunk.pending, (state, action) => {
            state.controls.isCreateBotLoading = true;
        });
        builder.addCase(addNewBotThunk.fulfilled, (state, action) => {
            state.controls.isCreateBotLoading = false;
            state.controls.showAddBotForm = false;
            state.controls.addNewBotErrorMessage = "";
        });
        builder.addCase(addNewBotThunk.rejected, (state, action) => {
            state.controls.isCreateBotLoading = false;
            state.controls.addNewBotErrorMessage = action.error.message;
        });
        //#endregion

        //#region ---------------- Verify bot thunk -------------------------------
        builder.addCase(verifyBotThunk.fulfilled, (state, action) => {
            state.controls.verifyBotErrorMessage = false;
            state.controls.showVerifyBotForm = { status: false, botId: "" };
            state.controls.verifyBotErrorMessage = "";
        });
        builder.addCase(verifyBotThunk.rejected, (state, action) => {
            state.controls.verifyBotErrorMessage = action.error.message;
        });
        builder.addCase(verifyBotThunk.pending, (state, action) => { });
        //#endregion

        //#region ---------------- Get all bots thunk -------------------------------

        builder.addCase(getAllBotsThunk.pending, (state, action) => { });

        builder.addCase(getAllBotsThunk.fulfilled, (state, action) => {
            state.botList = action.payload.data;
        });

        builder.addCase(getAllBotsThunk.rejected, (state, action) => {
            state.controls.getAllBotsErrorMessage = action.error.message;
        });
        //#endregion


        //#region ---------------- Get all verified bots thunk -----------------------

        builder.addCase(getAllVerifiedBotsThunk.pending, (state, action) => { });
        builder.addCase(getAllVerifiedBotsThunk.fulfilled, (state, action) => {
            state.verifiedBotList = action.payload.data;
        });
        builder.addCase(
            getAllVerifiedBotsThunk.rejected,
            (state, action) => { }
        );
        //#endregion


        //#region ---------------- Get all verified bots to run campaign thunk -----------------------

        builder.addCase(getAllVerifiedBotsToRunCampaignThunk.pending, (state, action) => { });
        builder.addCase(getAllVerifiedBotsToRunCampaignThunk.fulfilled, (state, action) => {
            state.verifiedBotListToRunCampaign = action.payload.data;
        });
        builder.addCase(
            getAllVerifiedBotsToRunCampaignThunk.rejected,
            (state, action) => { }
        );
        //#endregion


        //#region ---------------- Get all bots by page Size ------------t------------

        builder.addCase(getAllBotsThunkByPageSize.pending, (state, action) => {
            state.controls.isGetAllBotsLoading = true;
        });

        builder.addCase(
            getAllBotsThunkByPageSize.fulfilled,
            (state, action) => {
                state.controls.isGetAllBotsLoading = false;
                state.botListByPageSize = action.payload.allBotsData;
                state.totalBotsCount = action.payload.totalCount;
            }
        );

        builder.addCase(getAllBotsThunkByPageSize.rejected, (state, action) => {
            state.controls.isGetAllBotsLoading = false;
            state.controls.getAllBotsByErrorMessage = action.error.message;
            state.botListByPageSize = [];
            state.totalBotsCount = 0;
        });
        //#endregion

        //#region ---------------- Get all bots by page Size ------------t------------

        builder.addCase(getAllBotByFilterThunk.pending, (state, action) => {
            state.controls.isGetAllBotsLoading = true;
        });

        builder.addCase(getAllBotByFilterThunk.fulfilled, (state, action) => {
            state.controls.isGetAllBotsLoading = false;
            state.botListByPageSize = action.payload.allBotsData;
            state.totalBotsCount = action.payload.totalCount;
        });

        builder.addCase(getAllBotByFilterThunk.rejected, (state, action) => {
            state.controls.isGetAllBotsLoading = false;
            state.controls.getAllBotsByErrorMessage = action.error.message;
            state.botListByPageSize = [];
            state.totalBotsCount = 0;
        });
        //#endregion

        //#region ---------------- Verify client secret--------------------------------
        builder.addCase(VerifyClientSecretThunk.pending, (state, action) => { });
        builder.addCase(VerifyClientSecretThunk.fulfilled, (state, action) => {
            state.controls.showVerifyClientSecretForm = {
                status: false,
                botId: "",
            };
        });
        builder.addCase(VerifyClientSecretThunk.rejected, (state, action) => {
            state.controls.verifyClientSecretErrorMessage =
                action.error.message;
        });
        //#endregion

        //#region ---------------- Change Bot Status -----------------------------------

        builder.addCase(changeBotStatusThunk.pending, (state, action) => { });
        // builder.addCase(changeBotStatusThunk.fulfilled, (state, action) => {
        //     state.botListByPageSize = action.payload.data;
        // });
        builder.addCase(changeBotStatusThunk.rejected, (state, action) => { });
        //#endregion

        //#region ---------------- Upload to S3-------------------------------------------
        // builder.addCase(uploadToS3Thunk.pending, (state, action) => {});
        // builder.addCase(uploadToS3Thunk.fulfilled, (state, action) => {});
        // builder.addCase(uploadToS3Thunk.rejected, (state, action) => {});
        //#endregion

        //#region ---------------- Add New Test Device-----------------------------------

        builder.addCase(addNewTestDeviceThunk.pending, (state, action) => {
            state.controls.isAddingTestDevice = true;
            state.controls.isNewTestDeviceAdded = false;
        });
        builder.addCase(addNewTestDeviceThunk.fulfilled, (state, action) => {
            state.controls.isAddingTestDevice = false;
            state.controls.isNewTestDeviceAdded = false;
            state.controls.showAddTestDeviceForm = false;
        });

        builder.addCase(addNewTestDeviceThunk.rejected, (state, action) => {
            state.controls.addTestDeviceErrorMessage = action.error.message;
            state.controls.isAddingTestDevice = false;
            state.controls.isNewTestDeviceAdded = false;
        });
        //#endregion

        //#region ---------------- Get test devices by bot id and page size --------------

        builder.addCase(
            getAllTestDeviceListByBotIdByPageSize.pending,
            (state, action) => {
                state.controls.isLoadingTestDevices = true;
            }
        );
        builder.addCase(
            getAllTestDeviceListByBotIdByPageSize.fulfilled,
            (state, action) => {
                state.controls.isLoadingTestDevices = false;
                state.testDeviceListByPageSize = action.payload.testDevices;
                state.totalTestDeviceCount = action.payload.totalCount;
            }
        );

        builder.addCase(
            getAllTestDeviceListByBotIdByPageSize.rejected,
            (state, action) => {
                state.controls.isLoadingTestDevices = false;
                state.testDeviceListByPageSize = [];

                state.controls.getAllTestDevicesErrorMessage =
                    action.error.message;
            }
        );

        //# Analytics Data

        builder.addCase(
            getAllAnalyticsDataThunk.pending,
            (state, action) => {
                state.controls.isLoadingTestDevices = true;
            }
        );
        builder.addCase(
            getAllAnalyticsDataThunk.fulfilled,
            (state, action) => {
                state.controls.isLoadingTestDevices = false;
                state.todaysLogData = action.payload.todaysLog;
                state.analyticsData = action.payload.campaignData;
                state.totalTestDeviceCount = action.payload.totalCount;
            }
        );

        builder.addCase(
            getAllAnalyticsDataThunk.rejected,
            (state, action) => {
                state.controls.isLoadingTestDevices = false;
                state.testDeviceListByPageSize = [];

                state.controls.getAllTestDevicesErrorMessage =
                    action.error.message;
            }
        );
        //#endregion

        // ----------------------------get-all-providers----------------------------

        builder.addCase(getAllProvidersThunk.pending, (state, action) => {

        });

        builder.addCase(getAllProvidersThunk.fulfilled, (state, action) => {
            state.providersList = action.payload.data;
        });

        builder.addCase(getAllProvidersThunk.rejected, (state, action) => {
            state.controls.getAllProvidersErrorMessage = action.error.message;
        });
    },
});

export const {
    setShowAddBotForm,
    setShowVerifyBotForm,
    setShowVerifyClientSecretForm,
    setShowAddTestDeviceForm,
    clearErrorMessagesForBots,
    setshowIsTestDeviceAdding,
    setShowViewVerifyBotDetailsForm,
    onFailureGetVerifiedBotDetails,
    setEditViewVerifyBotDetailsForm
} = botManagementSlice.actions;

export default botManagementSlice;
