import React, { useState, useEffect } from 'react';
import closeIcon from '../../assets/icons/close-icon.svg';
import MessageCardPropertyMenu from './PropertyMenu/MessageCardPropertyMenu';
import ButtonNodePropertyMenu from './PropertyMenu/ButtonNodePropertyMenu';
import MediaNodePropertyMenu from './PropertyMenu/MediaNodePropertyMenu';
import ListNodePropertyMenu from './PropertyMenu/ListNodePropertyMenu';
import VariableNamespace from './PropertyMenu/VariableNamespace';
import { useDebounce } from '../../hooks/useDebounce';
import ConditionNodePropertyMenu from './PropertyMenu/ConditionNodePropertyMenu';
import SwitchNodePropertyMenu from './PropertyMenu/SwitchNodePropertyMenu';

const PropertyMenu = (props) => {

    const { node,
        closePropertyMenu,
        updateMessageNode,
        updateButtonNode,
        updateListNode,
        updateConditionNode,
        updateMediaNode,
        deleteListNodeItem,
        deleteButtonsNode,
        variableNamespace,
        addVariantToNameSpace,
        updateVariantInfoInNode,
        removeVariantInfoFromNode,
        updateNodeCoreProperties,
        getSelectedNodeValue,
        updateButtonData,
        updateSwitchNode,
        deleteButtonFromSwitchNode
    } = props;


    const updateCardName = () => {
        updateNodeCoreProperties(node.id, cardName);
    }

    const debounceToUpdateName = useDebounce(updateCardName, 10);

    const [cardName, setCardName] = useState(node?.data?.title);

    const onChangeHandleForNodeProperties = (event) => {
        const { value } = event.target;
        setCardName(value);
        debounceToUpdateName();
    }

    useEffect(() => {
        setCardName(node?.data?.title);
    }, [node]);


    return (
        <>

            <div>
                <button onClick={closePropertyMenu} className='card-icon-button'>
                    <img alt='close' className='card-icon' src={closeIcon} style={{ width: '20px', height: '20px' }} />
                </button>
            </div>

            <div className="property-menu-heading">
                <h4>Property Menu</h4>
            </div>

            <table>
                <thead></thead>
                <tbody>
                    <tr>
                        <td>
                            <label className='property-menu-label'>Type:</label>
                        </td>
                        <td>
                            <input className='property-menu-inputBox' type="text" disabled value={node.type} readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className='property-menu-label'>Id:</label>
                        </td>
                        <td>
                            <input className='property-menu-inputBox' type="text" disabled value={node.id} readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className='property-menu-label'>PositionX:</label>
                        </td>
                        <td>
                            <input className='property-menu-inputBox' type="text" value={node.position.x} readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className='property-menu-label'>PositionY:</label>
                        </td>
                        <td>
                            <input className='property-menu-inputBox' type="text" value={node.position.y} readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className='property-menu-label'>Name:</label>
                        </td>
                        <td>
                            <input
                                className='property-menu-inputBox'
                                type="text"
                                value={cardName}
                                onChange={onChangeHandleForNodeProperties}
                            />
                        </td>
                    </tr>
                </tbody>

            </table>
            <br />
            <hr />
            <br />

            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <VariableNamespace
                    addVariantToNameSpace={addVariantToNameSpace}
                    node={node}
                    removeVariantInfoFromNode={removeVariantInfoFromNode}
                    updateVariantInfoInNode={updateVariantInfoInNode}
                />
            </div>
            {
                node.type === 'messageCard' && <MessageCardPropertyMenu
                    node={node}
                    variableNamespace={variableNamespace}
                    updateMessageNode={updateMessageNode}
                    updateVariantInfoInNode={updateVariantInfoInNode}
                    removeVariantInfoFromNode={removeVariantInfoFromNode}
                />
            }

            {
                node.type === 'button' && <ButtonNodePropertyMenu
                    variableNamespace={variableNamespace}
                    updateButtonNode={updateButtonNode}
                    deleteButtonsNode={deleteButtonsNode}
                    node={node}
                    updateButtonData={updateButtonData}
                />
            }

            {
                node.type === 'listCard' && <ListNodePropertyMenu
                    variableNamespace={variableNamespace}
                    updateListNode={updateListNode}
                    deleteListNodeItem={deleteListNodeItem}
                    node={node}
                />
            }
            {
                node.type === 'conditionCard' && <ConditionNodePropertyMenu
                    variableNamespace={variableNamespace}
                    updateConditionNode={updateConditionNode}
                    node={node}
                />
            }

            {
                node.type === 'media' && <MediaNodePropertyMenu
                    variableNamespace={variableNamespace}
                    updateMediaNode={updateMediaNode}
                    deleteListNodeItem={deleteListNodeItem}
                    node={node}
                />
            }

            {
                node.type === 'switchNode' && <SwitchNodePropertyMenu
                    variableNamespace={variableNamespace}
                    updateSwitchNode={updateSwitchNode}
                    deleteButtonFromSwitchNode={deleteButtonFromSwitchNode}
                    node={node}
                />
            }

        </>
    );
}

export default PropertyMenu;
