import {
    createSlice
} from "@reduxjs/toolkit";


export const campaignReportsManagementSlice = createSlice({
    name: "campaignReportsManagementSlice",
    initialState: {
        campaignListWithReportDetails: [],
        totalRunCampaignsCount: 0,
        botConversationLogsReportDetails: [],

        controls: {
            showCampaignReport: { status: false, campaignData: null },
            isCampaignDetailsLoading: false,
            isGetAllRunCampaignListLoading: false,
            isGetBotConversationLogsReportLoading: false,

            addNewCampaignErrorMessage: "",
            getAllCampaignsByPageSizeErrorMessage: "",
            getCampaignReportDetailsErrorMessage: "",
            getBotConversationLogsReportDetailsErrorMessage: ""
        },
    },
    reducers: {
        clearErrorMessagesForReports: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = "";
        },
        setIsCampaignDetailsLoading: (state, action) => {
            state.controls.isCampaignDetailsLoading = action.payload;
            if (action.payload === true) {
                state.controls.getCampaignReportDetailsErrorMessage = "";
            }
        },
        setShowCampaignReport: (state, action) => {
            state.controls.showCampaignReport = action.payload;
        },
        setCampaignReportDetails: (state, action) => {
            state.campaignListWithReportDetails = action.payload;
        },
        onRejectGetCampaignDetails: (state, action) => {
            state.controls.isCampaignDetailsLoading = false;
            state.controls.getCampaignReportDetailsErrorMessage = action.payload;
        },


        onPendingBotConversationLogsReportDetailsLoading: (state, action) => {
            state.controls.isGetBotConversationLogsReportLoading = action.payload;
            if (action.payload === true) {
                state.controls.getBotConversationLogsReportDetailsErrorMessage = "";
            }
        },
        onSuccessBotConversationLogReportDetails: (state, action) => {
            state.controls.isGetBotConversationLogsReportLoading = false;
            state.botConversationLogsReportDetails = action.payload;
        },
        onRejectGetBotConversationLogsReportDetails: (state, action) => {
            state.controls.isGetBotConversationLogsReportLoading = false;
            state.controls.getBotConversationLogsReportDetailsErrorMessage = action.payload;
        },

    },

    extraReducers: (builder) => {



    }
})

export const {
    setShowCampaignReport,
    setIsCampaignDetailsLoading,
    setCampaignReportDetails,
    onRejectGetCampaignDetails,
    onRejectGetBotConversationLogsReportDetails,
    onSuccessBotConversationLogReportDetails,
    onPendingBotConversationLogsReportDetailsLoading,
    clearErrorMessagesForReports
} = campaignReportsManagementSlice.actions;