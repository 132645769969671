import React, { useState } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

function FilterAllClientModel({
    open,
    searchParams,
    setSearchParams,
    handleClickOpen,
    handleClose,
    handleClickSearch,
}) {

    const [search, setSearch] = useState(searchParams);
    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setSearch({ ...search, [name]: value });
    };

    const onClickClear = () => {
        setSearchParams({
            orgName: "",
            name: "",
            email: "",
            mobileNumber: "",
            branchName: "",
            status: "",
        });
        handleClickSearch({
            orgName: "",
            name: "",
            email: "",
            mobileNumber: "",
            branchName: "",
            status: "",
        })


    };
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5">Clients Filter</Typography>
                    <IconButton onClick={() => handleClose()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent
                    sx={{
                        overflowY: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                        width: "500px",
                    }}
                >

                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            <TextField
                                value={search.orgName}
                                name="orgName"
                                onChange={handleChange}
                                label="Organization Name"
                                fullWidth
                                placeholder="Enter Organization Name"
                            />
                        </Grid>
                        <Grid item md={5.5}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-controlled-open-select-label">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    name="status"
                                    value={search.status}
                                    label="Status"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"Active"}>Active</MenuItem>
                                    <MenuItem value={"InActive"}>
                                        InActive
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            <TextField
                                value={search.name}
                                name="name"
                                onChange={handleChange}
                                label="Admin Name"
                                fullWidth
                                placeholder=" Enter Client Name"
                            />
                        </Grid>
                        <Grid item md={5.5}>
                            <TextField
                                name="email"
                                value={search.email}
                                onChange={handleChange}
                                label="Email"
                                fullWidth
                                placeholder="Enter Email Id"
                            />
                        </Grid>
                    </Grid>

                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            {" "}
                            <TextField
                                name="mobileNumber"
                                value={search.mobileNumber}
                                onChange={handleChange}
                                label="Mobile"
                                fullWidth
                                placeholder="Enter Mobile Number"
                            />
                        </Grid>
                        {/* <Grid item md={5.5}>
                            {" "}
                            <TextField
                                name="branchName"
                                value={search.branchName}
                                onChange={handleChange}
                                label="Branch"
                                fullWidth
                                placeholder="Enter Branch Name"
                            />
                        </Grid> */}

                    </Grid>

                </DialogContent>

                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => onClickClear()}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClickSearch(search)}
                        autoFocus
                    >
                        Search{" "}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default FilterAllClientModel;
