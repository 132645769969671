import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowAddRoleForm } from "../../../redux/userManagement/userManagementSlice";
import ListRoles from "./ListRoles";
import AddRoles from "./AddRoles";

function ManageRoles() {

    const state = useSelector((state) => state.userManagementSlice);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useState({
        roleName: "",
    });

    useEffect(() => {
        dispatch(setShowAddRoleForm(false));
    }, []);
    return (
        <div>
            {state.controls.showAddRolesForm ?
                <AddRoles />
                :
                <ListRoles
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
            }
        </div>
    );
}

export default ManageRoles;