/**
 * 
 * @param {string} data 
 * takes in a string with underscore and formats to show in the table listing or ui
 * right now used for:
 * Template type 
 * input -> rich_card
 * output -> Rich Card
 * 
 * input -> text_Message
 * output -> Text Message
 * 
 * input -> carousel
 * output -> Carousel
 * 
 * **will return the original data in case of any error**
 */
export function formatDataToShowInUI(data) {

    try {
        const stringWithoutUnderScore = data.replace("_", " ");
        const words = stringWithoutUnderScore.split(" ");


        const output = words.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");
        return output;
    } catch (error) {
        // some error occurred, return the original data
        return data;
    }
}

