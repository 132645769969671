import React, { useEffect } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    Typography,
    IconButton,
    CircularProgress,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { DateTimePicker } from "@mui/lab";
import dayjs from "dayjs";
import { getStatusToDisplay, MessageStatus } from "../../../config";

function FilterAllCampaignsReportsModel({
    open,
    searchParams,
    setSearchParams,
    handleClickOpenModel,
    handleCloseFilterModel,
    handleClickSearch,
    campaignReportLoader
}) {
    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setSearchParams({ ...searchParams, [name]: value });
    };
    const [value, setValue] = React.useState(dayjs("2022-04-17T15:30"));
    const handleOnChange = (value) => {
        const initialDate = new Date(value);
        setSearchParams({
            ...searchParams,
            sendTime: initialDate.toISOString(),
        });
    };

    // useEffect(() => {
    //     setSearchParams((prevSearchParams) => ({
    //         ...prevSearchParams,
    //         sendTime: new Date("your_date_value_here"),
    //     }));
    // }, [searchParams.sendTime]);


    const onClickClear = () => {
        setSearchParams({
            messageStatus: "",
            userNumber: "",
            sendTime: "",
            provider: "",
        });
    };
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleCloseFilterModel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5">
                        {" "}
                        Campaigns Report Filter
                    </Typography>
                    <IconButton onClick={() => handleCloseFilterModel()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent
                    sx={{
                        overflowY: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                        width: "500px",
                    }}
                >
                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.8}>
                            <TextField
                                value={searchParams.userNumber}
                                name="userNumber"
                                onChange={handleChange}
                                label="  User Number"
                                fullWidth
                                placeholder=" Enter user Number"
                            />
                        </Grid>
                        {/* <Grid item md={5.8}>
                            <DateTimePicker
                                value={searchParams.sendTime}
                                onChange={(newValue) =>
                                    handleOnChange(newValue)
                                }
                                label="Send Time"
                                // fullWidth
                                // inputFormat="yyyy-MM-dd HH:mm:ss"
                                renderInput={(params) => (
                                    <TextField {...params} name="sendTime" />
                                )}
                            />
                        </Grid> */}

                        <Grid item md={5.8}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-controlled-open-select-label">
                                    Status
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    name="messageStatus"
                                    value={searchParams.messageStatus}
                                    label="Status"
                                    onChange={handleChange}
                                >

                                    <MenuItem value={MessageStatus.Retry}>
                                        {MessageStatus.Retry}
                                    </MenuItem>
                                    <MenuItem value={MessageStatus.Failed}>
                                        {MessageStatus.Failed}
                                    </MenuItem>
                                    <MenuItem value={MessageStatus.Delivered}>
                                        {MessageStatus.Delivered}
                                    </MenuItem>
                                    <MenuItem value={MessageStatus.Pending}>
                                        {MessageStatus.Pending}
                                    </MenuItem>
                                    <MenuItem value={MessageStatus.Sent}>
                                        {MessageStatus.Sent}
                                    </MenuItem>
                                    <MenuItem value={MessageStatus.Read}>
                                        {MessageStatus.Read}
                                    </MenuItem>
                                    <MenuItem value={MessageStatus.TTLExpirationRevoked}>
                                        {MessageStatus.TTLExpirationRevoked}
                                    </MenuItem>
                                    <MenuItem value={MessageStatus.DELIVRD}>
                                        {getStatusToDisplay(MessageStatus.DELIVRD)}
                                    </MenuItem>
                                    <MenuItem value={MessageStatus.SMSFailed}>
                                        {getStatusToDisplay(MessageStatus.SMSFailed)}
                                    </MenuItem>
                                    <MenuItem value={""}>All</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={5.8} marginTop={4}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">
                                    Provider
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    name="provider"
                                    value={searchParams.provider}
                                    label="Provider"
                                    onChange={handleChange}
                                >
                                    {["VI", "SMS"].map((provider) => (
                                        <MenuItem value={provider}>
                                            {provider}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => onClickClear()}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClickSearch(searchParams)}
                        disabled={campaignReportLoader}
                        autoFocus
                    >
                        {campaignReportLoader && <CircularProgress size={15} className='spinner' />}&nbsp;{" "}
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default FilterAllCampaignsReportsModel;
