import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProvidersThunk } from "../../../redux/botManagement/botManagementThunk";
import { getMasterRatesThunk } from "../../../redux/userManagement/userManagementThunk";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AddMasterRateModal from "./AddMasterRateModal";
import ListMasterRate from "./ListMasterRate";
import UpdateAllCountryRatesCard from "./UpdateAllCountryRatesCard";
import AlertMessageComponent from "../../components/AlertMessageComponent";

export default function ManageRateMaster() {
    // const [loading, setLoading] = useState(false);
    const [rateForm, setRateForm] = useState(false);
    const [updateRateCard, setUpdateRateCard] = useState(false);
    const [countriesToCustomizeRateCard, setCountriesToCustomizeRateCard] = useState([]);
    const [selectProviderName, setSelectProviderName] = useState({});
    const [providerDetailsByProviderName, setProviderDetailsByProviderName] = useState();
    const botState = useSelector(state => state.botManagementSlice);
    const [otherFields, setOtherFields] = useState({});
    const [errorResponse, setErrorResponse] = ("");
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const dispatch = useDispatch();

    const getProviderFieldsDetails = async name => {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/config/get-provider-details-by-provider`, {
            params: { providerName: name },
        });

        if (response.status === 200) {
            setProviderDetailsByProviderName(response.data.data);
        }
    };

    const getAllMasterRates = async ({ providerName }) => {
        try {
            // setLoading(true);
            dispatch(getMasterRatesThunk({ providerName, currentPage: currentPage + 1, pageSize }));

        } catch (error) {
            let errorMessage = "Failed to get master rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    useEffect(() => {
        if (botState.providersList.length > 0) {
            const providerDetails = botState.providersList.filter(provider => provider.name === "VI")[0];
            setSelectProviderName(providerDetails);
            getAllMasterRates({ providerName: providerDetails.name });
            getProviderFieldsDetails(providerDetails.name);
        } else {
            dispatch(getAllProvidersThunk());
        }
    }, [botState.providersList, currentPage, pageSize]);
    return (
        <div>
            {rateForm ? (
                <AddMasterRateModal
                    setRateForm={setRateForm}
                    selectProviderName={selectProviderName}
                    providerDetailsByProviderName={providerDetailsByProviderName}
                    otherFields={otherFields}
                    getAllMasterRates={getAllMasterRates}
                />
            ) : (
                <>
                    {updateRateCard ? (
                        <UpdateAllCountryRatesCard
                            setUpdateRateCard={setUpdateRateCard}
                            countriesToCustomizeRateCard={countriesToCustomizeRateCard}
                            getAllMasterRates={getAllMasterRates}
                            selectProviderName={selectProviderName}
                        // setSelectProviderName={setSelectProviderName}
                        />
                    ) : (
                        <ListMasterRate
                            otherFields={otherFields}
                            setOtherFields={setOtherFields}
                            setRateForm={setRateForm}
                            setUpdateRateCard={setUpdateRateCard}
                            countriesToCustomizeRateCard={countriesToCustomizeRateCard}
                            setCountriesToCustomizeRateCard={setCountriesToCustomizeRateCard}
                            selectProviderName={selectProviderName}
                            setSelectProviderName={setSelectProviderName}
                            providerDetailsByProviderName={providerDetailsByProviderName}
                            getAllMasterRates={getAllMasterRates}
                            setCurrentPage={setCurrentPage}
                            setPageSize={setPageSize}
                            currentPage={currentPage}
                            pageSize={pageSize}
                        />
                    )}
                </>
            )}

            <AlertMessageComponent
                message={errorResponse}
                fieldName={"errorResponse"}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />

        </div>

    );
}
