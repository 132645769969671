import { CurrencyRupee, Edit } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERoles, OtherFields } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { getAllProvidersThunk } from "../../../redux/botManagement/botManagementThunk";
import { clearErrorMessagesForAllUsers, setAllCustomizedData, setShowAddClientForm } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import TablePaginationShowPage from "../../../utils/tablePagination";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import FetchingSpinner from "../../components/FetchingSpinner";
import NoRecords from "../../components/NoRecords";
import IncreaseDecreaseCustomizeRateForSelectedClients from "./IncreaseDecreaseCustomizeRateForSelectedClients";
import UpdateRateByClientIdModal from "./UpdateRateByClientIdModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableCellSticky = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
        backgroundColor: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function CustomizeRates() {
    const state = useSelector(state => state.userManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [clientData, setClientData] = useState({});
    const [updateCustomizedRateModel, setUpdateCustomizedRateModel] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [errorResponse, setErrorResponse] = useState("");
    const [selectProviderName, setSelectProviderName] = useState("");
    const [providerDetailsByProviderName, setProviderDetailsByProviderName] = useState();
    const [otherFields, setOtherFields] = useState({});
    const [searchedClientName, setSearchedClientName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openUpdateRateCardForSelectedClient, setOpenUpdateRateCardForSelectedClient] = useState(false);
    const { user } = useAuth();

    const [searchParams, setSearchParams] = React.useState({
        name: "",
        email: "",
        mobileNumber: "",
        branchName: "",
        status: "",
    });
    const [pageSize, setPageSize] = React.useState(10);
    const [selected, setSelected] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const [customizeRateCardForClients, setCustomizeRateCardForClients] = useState([]);
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // setSearchParams({});
        setOpen(false);
    };

    const onChangeSearchedClientName = e => {
        setSearchedClientName(e.target.value);
    };

    // const onSelectStatus = e => {
    //     setStatus(e.target.value);
    // };

    const onClickAddNewClient = () => {
        dispatch(setShowAddClientForm(true));
    };

    const onClickEditCustomizedRate = client => {
        setUpdateCustomizedRateModel(true);
        setClientData(client);
    };

    const handleCloseEditCustomizedRate = () => {
        setUpdateCustomizedRateModel(false);
    };
    const getAllCustomizedRate = async providerName => {
        try {
            setIsLoading(true);
            const token = sessionStorage.getItem("accessToken");

            const response = await apiGatewayAxiosInstance.get(`/user_management/customized-rate/get-all`, {
                headers: { token },
                params: {
                    providerName, pageSize, currentPage: currentPage + 1
                },
            });

            if (response.status === 200) {

                dispatch(setAllCustomizedData(response.data.data.clientData));
                setTotalCount(response.data.data.totalCount)
                setIsLoading(false);
            } else {
                // setIsLoading(false);
                throw new Error(response.data);
            }
        } catch (error) {
            setIsLoading(false);
            let errorMessage = "Failed to get all customised rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };
    useEffect(() => {
        if (botState.providersList.length === 0) {
            dispatch(getAllProvidersThunk());
        } else {
            if (updateCustomizedRateModel === false) {
                const providerDetails = botState.providersList.filter(provider => provider.name === "VI")[0];
                setSelectProviderName(providerDetails);
                onChangeSelectProvider(providerDetails);
            }
        }
    }, [updateCustomizedRateModel, botState.providersList, currentPage, pageSize]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };

    const handleSelectAllClick = () => {
        if (selected.length === state?.customizedRate?.length) {
            setSelected([]);
            setCustomizeRateCardForClients([]);
            for (let index = 0; index < state?.customizedRate.length; index++) {
                const element = state?.customizedRate[index];
            }
        } else {
            setSelected(Array.from({ length: state?.customizedRate?.length }, (_, index) => index));
            setCustomizeRateCardForClients([...state?.customizedRate]);
        }
    };

    const handleSelect = (index, client, e) => {
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        if (e.target.checked) {
            setCustomizeRateCardForClients([...customizeRateCardForClients, client]);
        } else {
            const a = customizeRateCardForClients.filter(selectedItem => selectedItem._id !== client._id);
            setCustomizeRateCardForClients(a);
        }
    };
    const getProviderFieldsDetails = async name => {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/config/get-provider-details-by-provider`, {
            params: { providerName: name },
        });

        if (response.status === 200) {
            setProviderDetailsByProviderName(response.data.data);
            let providerOtherFieldToNumber = {};
            if (Object.keys(response.data.data?.otherFields).length > 0) {
                Object.keys(response.data.data?.otherFields).map((fieldElement, index) => {
                    providerOtherFieldToNumber[fieldElement] = 0;
                });
            }

            setOtherFields(providerOtherFieldToNumber);
        }
    };

    const onChangeSelectProvider = value => {
        setSelectProviderName(value);
        getProviderFieldsDetails(value.name);
        getAllCustomizedRate(value.name);
    };

    // if (loading) {
    //     return <FetchingSpinner />;
    // }
    const updateRateForSelectedClients = () => {
        setOpenUpdateRateCardForSelectedClient(true);
    };
    const handleCloseUpdateRateCardForSelectedClient = () => {
        setOpenUpdateRateCardForSelectedClient(false);
    };


    return (
        <>
            <Box width={"100%"}>
                <Grid container mb={4} className='listHeader'>
                    <Grid item md={6}>
                        <Typography variant='h3'>  {user?.roleName === ERoles.SUPER_ADMIN ? "Customized Rates" : "Applicable Rates"}</Typography>
                    </Grid>
                    <Grid item md={6} className='listHeaderFilter'>
                        <Grid display={"flex"} gap={3}>
                            <Grid display={"flex"}>
                                {user?.roleName === ERoles.SUPER_ADMIN && (

                                    customizeRateCardForClients.length > 0 ? (
                                        <Button sx={{ marginRight: "10px" }} variant='contained' onClick={() => updateRateForSelectedClients()}>
                                            Increase / Decrease Rates
                                        </Button>
                                    ) : (
                                        <Button disabled sx={{ marginRight: "10px" }} variant='contained' onClick={() => updateRateForSelectedClients()}>
                                            Increase / Decrease Rates
                                        </Button>
                                    )
                                )}

                                <FormControl
                                    size='small'
                                    sx={{
                                        display: "flex",
                                        width: "225px",
                                        // marginLeft: '390px'
                                    }}
                                >
                                    <InputLabel id='provider-label'>Select Provider</InputLabel>
                                    <Select
                                        name='provider_name'
                                        labelId='provider_name'
                                        id='provider_name'
                                        label='Select Provider'
                                        value={selectProviderName}
                                        onChange={e => onChangeSelectProvider(e.target.value)}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                },
                                            },
                                        }}
                                    >
                                        {botState?.providersList?.map((provider, index) => (
                                            <MenuItem key={index} value={provider}>
                                                {provider.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Card className='border border-secondary-50'>
                        <CardContent sx={{
                            padding: 0,
                        }}>
                            {selectProviderName ? (
                                isLoading ? (
                                    <FetchingSpinner />
                                ) : (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            minWidth: "70vw",
                                            maxWidth: "100rem",
                                            overflowX: "scroll",
                                        }}
                                    >
                                        {state.customizedRate.length > 0 ? (

                                            <TableContainer component={Paper} className='border border-secondary-50'>
                                                <Table aria-label='customized table'>
                                                    <>
                                                        <TableHead className='p-3 mb-2 row'>
                                                            <TableRow>
                                                                {user?.roleName === ERoles.SUPER_ADMIN &&

                                                                    <StyledTableCell className='tableHeaderFont sticky'>
                                                                        <Checkbox
                                                                            sx={{
                                                                                color: "rgba(255, 255, 255, 0.7)", // Default color (when not checked)
                                                                                "&.Mui-checked": {
                                                                                    color: "white", // Color when checked
                                                                                    backgroundColor: "rgba(255, 255, 255, 0.1)", // Background color when checked
                                                                                    borderRadius: "4px", // Rounded corners
                                                                                },
                                                                            }}
                                                                            checked={customizeRateCardForClients.length === state.customizedRate.length}
                                                                            onChange={handleSelectAllClick}
                                                                            color='primary'
                                                                        />
                                                                    </StyledTableCell>
                                                                }
                                                                {user?.roleName === ERoles.SUPER_ADMIN &&
                                                                    <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                        Sr No
                                                                    </StyledTableCell>}
                                                                <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                    {user?.roleName === ERoles.SUPER_ADMIN && "Client"} Name
                                                                </StyledTableCell>
                                                                <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                    Country
                                                                </StyledTableCell>
                                                                <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                    Text Message Rate (INR)
                                                                </StyledTableCell>
                                                                <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                    Multi Media Message Rate (INR)
                                                                </StyledTableCell>
                                                                {user?.roleName === ERoles.SUPER_ADMIN &&
                                                                    <>
                                                                        <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                            A2P Session Conversation Rate (INR)
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                            Feedback Rate (INR)
                                                                        </StyledTableCell>

                                                                        {providerDetailsByProviderName &&
                                                                            Object.keys(providerDetailsByProviderName?.otherFields).map((provider, index) => (
                                                                                <StyledTableCell className='col-1 tableHeaderFont'>
                                                                                    {OtherFields[provider] ?? provider} (INR)
                                                                                </StyledTableCell>
                                                                            ))}
                                                                    </>}

                                                                <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                    Payment Type
                                                                </StyledTableCell>
                                                                {user?.roleName === ERoles.SUPER_ADMIN &&
                                                                    <>
                                                                        <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                            Edit
                                                                        </StyledTableCell>
                                                                    </>
                                                                }
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            <>
                                                                {state.customizedRate?.map((client, index) => (
                                                                    <StyledTableRow key={index}>
                                                                        {user?.roleName === ERoles.SUPER_ADMIN &&
                                                                            <StyledTableCellSticky className='tableContentFont sticky'>
                                                                                <Checkbox
                                                                                    checked={
                                                                                        customizeRateCardForClients.filter(
                                                                                            selectedClient => selectedClient._id === client._id,
                                                                                        ).length > 0
                                                                                    }
                                                                                    onChange={e => handleSelect(index, client, e)}
                                                                                    color='primary'
                                                                                />
                                                                            </StyledTableCellSticky>
                                                                        }
                                                                        {user?.roleName === ERoles.SUPER_ADMIN &&
                                                                            <StyledTableCell className='tableContentFont' align='center'>
                                                                                {currentPage * 10 + index + 1}
                                                                            </StyledTableCell>}
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            {client?.name}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            {client?.orgDetails?.countryName}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                            {client?.rate?.totalRate?.A2pMessageRate?.textMessageRate ?? "-"}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                            {client?.rate?.totalRate?.A2pMessageRate?.multiMediaMessageRate ?? "-"}
                                                                        </StyledTableCell>{" "}

                                                                        {user?.roleName === ERoles.SUPER_ADMIN &&
                                                                            <>
                                                                                <StyledTableCell className='tableContentFont' align='center'>
                                                                                    <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                                    {client?.rate?.totalRate?.A2pMessageRate?.A2pSessionConversationRate ??
                                                                                        "-"}
                                                                                </StyledTableCell>
                                                                                <StyledTableCell className='tableContentFont' align='center'>
                                                                                    <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                                    {client?.rate?.totalRate?.A2pMessageRate?.feedbackRate ?? "-"}
                                                                                </StyledTableCell>
                                                                                {providerDetailsByProviderName &&
                                                                                    Object.keys(providerDetailsByProviderName?.otherFields).map(
                                                                                        (provider, index) => (
                                                                                            <StyledTableCell className='tableContentFont' align='center'>
                                                                                                <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                                                {client?.rate?.totalRate?.otherFields[provider]}
                                                                                            </StyledTableCell>
                                                                                        ),
                                                                                    )}
                                                                            </>}
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            {client?.billingPaymentType ??
                                                                                client?.rate?.billingDetails?.billingPaymentType ??
                                                                                "-"}
                                                                        </StyledTableCell>
                                                                        {user?.roleName === ERoles.SUPER_ADMIN &&
                                                                            <StyledTableCell>
                                                                                <Tooltip title='Edit Customize Rate'>
                                                                                    <IconButton onClick={() => onClickEditCustomizedRate(client)}>
                                                                                        <Edit />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </StyledTableCell>
                                                                        }
                                                                    </StyledTableRow>
                                                                ))}
                                                            </>
                                                        </TableBody>
                                                    </>
                                                </Table>
                                            </TableContainer>

                                        ) : (
                                            <NoRecords />
                                        )}
                                    </Box>
                                )
                            ) : (
                                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography variant='h6'>
                                        <b>PLEASE SELECT PROVIDER</b>
                                    </Typography>
                                </Grid>
                            )}

                            {user?.roleName === ERoles.SUPER_ADMIN && totalCount > 0 && (
                                <Grid
                                    className='my-2'
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: 10
                                    }}
                                >
                                    <Grid
                                        style={{
                                            marginTop: "15px",
                                        }}
                                    >
                                        Total Bots: {totalCount}
                                    </Grid>
                                    <Grid>
                                        <TablePaginationShowPage
                                            rowsPerPageOptions={[5, 10, 25, 100]}
                                            colSpan={3}
                                            count={totalCount}
                                            rowsPerPage={pageSize}
                                            page={currentPage}
                                            slotProps={{
                                                select: {
                                                    "aria-label": "Rows per page",
                                                },
                                                actions: {
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                    slots: {
                                                        firstPageIcon: FirstPageRoundedIcon,
                                                        lastPageIcon: LastPageRoundedIcon,
                                                        nextPageIcon: ChevronRightRoundedIcon,
                                                        backPageIcon: ChevronLeftRoundedIcon,
                                                    },
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />


                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <AlertMessageComponent message={errorResponse} handleClose={() => setErrorResponse("")} show={Boolean(errorResponse)} />
            </Box>
            {updateCustomizedRateModel && (
                <UpdateRateByClientIdModal
                    open={onClickEditCustomizedRate}
                    handleClose={handleCloseEditCustomizedRate}
                    clientData={clientData}
                    selectProviderName={selectProviderName}
                    providerDetailsByProviderName={providerDetailsByProviderName}
                    otherFields={otherFields}
                    getAllCustomizedRate={getAllCustomizedRate}
                />
            )}
            <Dialog open={openUpdateRateCardForSelectedClient}>
                {openUpdateRateCardForSelectedClient && (
                    <IncreaseDecreaseCustomizeRateForSelectedClients
                        open={openUpdateRateCardForSelectedClient}
                        handleClose={handleCloseUpdateRateCardForSelectedClient}
                        setOpenUpdateRateCardForSelectedClient={setOpenUpdateRateCardForSelectedClient}
                        customizeRateCardForClients={customizeRateCardForClients}
                        otherFields={otherFields}
                        selectProviderName={selectProviderName}
                        getAllCustomizedRate={getAllCustomizedRate}
                    />
                )}
            </Dialog>
        </>
    );
}
