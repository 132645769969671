import React, { useState } from 'react'
import { useDebounce } from '../../../hooks/useDebounce';
import { TextEditor } from '../TextEditor/TextEditor';

export default function MessageCardPropertyMenu({ node, variableNamespace, updateMessageNode }) {

    const onChangeHandler = (editorData) => {
        updateMessageNode(node.id, editorData);
    }

    return (
        <>
            <div>
                <div className="title">
                    <h5> Response: </h5>
                </div>
                <div>
                    <TextEditor
                        key={`editor-for-node-${node.id}`}
                        data={node.data.contents}
                        getEditorDataOnChange={onChangeHandler}
                    />
                </div>
            </div>
        </>
    )
}
