import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import {
    CardContent,
    CardHeader,
    IconButton,
    Card as MuiCard,
    TableCell as MuiTableCell,
    TableRow as MuiTableRow
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
    height: 364px;
    position: relative;
`;

const DoughnutInner = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -22px;
    text-align: center;
    z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
    height: 42px;
`;

const TableCell = styled(MuiTableCell)`
    padding-top: 0;
    padding-bottom: 0;
`;

const GreenText = styled.span`
    color: ${() => green[400]};
    font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
    color: ${() => red[400]};
    font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const DoughnutChart = ({ theme, todayLogData, showFor }) => {
    let data = {};
    if (showFor == "RCS") {
        data = {
            labels: ['Pending', "Sent", "Delivered", "Failed"],
            datasets: [
                {
                    data: [todayLogData.todayPending, todayLogData.todaySentRCS, todayLogData.todayDelivered, todayLogData.todayFailed],
                    backgroundColor: ['#ffa500', '#1984d9', '#008000', '#e90e0ede'],
                    borderWidth: 5,
                    borderColor: theme.palette.background.paper,
                },
            ],
        };
    } else if (showFor == "SMS") {
        data = {
            labels: ["Pending", "Sent", "Delivered", "Failed"],
            datasets: [
                {
                    data: [todayLogData.todaySMSPending, todayLogData.todaySentSMS, todayLogData.todaySMSDelivered, todayLogData.todaySMSFailed],
                    backgroundColor: ['#ffa500', '#1984d9', '#008000', '#e90e0ede'],
                    borderWidth: 5,
                    borderColor: theme.palette.background.paper,
                },
            ],
        };
    }

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: "80%",
    };

    useEffect(() => { }, [todayLogData.todaySubmitted, todayLogData.todayDelivered, todayLogData.todayFailed, todayLogData.todayPending, todayLogData.todaySentRCS, todayLogData.todaySentSMS])

    return (
        <Card mb={6}>
            <CardHeader
                // action={
                //     <IconButton aria-label='settings' size='large'>
                //         <MoreVertical />
                //     </IconButton>
                // }
                title={`Today's ${showFor} Statistics`}
            />

            <CardContent>
                <ChartWrapper>
                    {/* <DoughnutInner> */}
                    {/* <Typography variant="h4">+27%</Typography>
            <Typography variant="caption">more sales</Typography> */}
                    {/* </DoughnutInner> */}
                    <Chart type='bar' data={data} options={options} />
                </ChartWrapper>
                {/* <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell align='center'>No. of Messages</TableCell>
                            <TableCell align='right'>Percentage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                Submitted
                            </TableCell>
                            <TableCell align='center'>{state.todaysLogData.todaysSubmitted}</TableCell>
                            <TableCell align='right'>
                                <GreenText>+0%</GreenText>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                Sent
                            </TableCell>
                            <TableCell align='center'>{state.todaysLogData.todaysSent}</TableCell>
                            <TableCell align='right'>
                                <GreenText>+0%</GreenText>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                Delivered
                            </TableCell>
                            <TableCell align='center'>{state.todaysLogData.todaysDelivered}</TableCell>
                            <TableCell align='right'>
                                <GreenText>+0%</GreenText>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                Failed
                            </TableCell>
                            <TableCell align='center'>{state.todaysLogData.todaysFailed}</TableCell>
                            <TableCell align='right'>
                                <GreenText>+0%</GreenText>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table> */}
            </CardContent>
        </Card>
    );
};

export default withTheme(DoughnutChart);
