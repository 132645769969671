import React, { useEffect, useState } from 'react'
import { useDebounce } from '../../../hooks/useDebounce';
import { generateUniqueIdForCard } from '../../../utils/workflow-utils/helpers';

import closeIcon from '../../../assets/icons/close-icon.svg';
import { TextEditor } from '../TextEditor/TextEditor';

export default function ButtonNodePropertyMenu({ node, variableNamespace, updateListNode, deleteListNodeItem }) {

    const [lists, setLists] = useState(node.data.contents ?? []);
    const [errors, setErrors] = useState({});
    const [listItemName, setListItemName] = useState("");

    const listItemNameChangeHandler = (event) => {
        const { value } = event.target;
        setListItemName(value);
    }

    const addItem = () => {
        setErrors({});
        if (listItemName && typeof listItemName === 'string' && listItemName.trim().length > 0) {
            const newListItemId=generateUniqueIdForCard();
            const newList = {
                id: newListItemId,
                value: listItemName,
                handleId:`${node.id}_${newListItemId}`
            }
            const newListArray = [...lists, newList];
            updateListNode(node.id, newListArray);
            setLists(newListArray);
            setListItemName("");
        } else {
            setErrors({ listItemName: "Please Enter Item Name" });
        }
    }

    const deleteButton = (id) => {
        let newListItem = lists.filter((con) => con.id !== id);
        setLists(newListItem);
        deleteListNodeItem(node.id, id);
        updateListNode(node.id, newListItem);
    }

    // TODO : add styles to button

    return (
        <div key={`list_node_${node.id}`}>
            <div>
                <div className='flex align-item-center justify-around'>
                    <input
                        className='border-2 border-gray-300 text-base rounded px-1'
                        type="text"
                        placeholder='Item'
                        name={"listItemName"}
                        onChange={listItemNameChangeHandler}
                        value={listItemName}
                    />
                    <button className='bg-yellow-300 rounded w-35 p-1 px-2 text-base border-2 border-gray-400' onClick={addItem}> Add Item </button>
                </div>
                {
                    errors && errors.listItemName &&
                    <p className='text-xs px-2' style={{ color: '#F96363' }}>  {errors.listItemName} </p>
                }
            </div>


            {
                lists && lists.length > 0 && lists.map((item, idx) => {
                    return (
                        <div className='p-2 flex justify-between' key={`list_node_item_${idx}`}>
                            <input disabled className='input-type text-base' key={`item-node${idx}`} value={item.value} />

                            <button title="Delete" className='card-icon-button text-right' onClick={() => deleteButton(item.id)}><img className='card-icon' src={closeIcon} /></button>
                        </div>
                    )
                })
            }


        </div>
    )
}
