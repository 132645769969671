import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Card, CardContent, Chip, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import CountryCode from "../../assets/CountryCode.json";
import { useFormik } from 'formik';
import { PHONE_NUMBER_OBJECT, PHONE_NUMBER_REGEX } from '../../constants';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { clearErrorMessagesForBots, setShowAddTestDeviceForm } from '../../redux/botManagement/botManagementSlice';
import { addNewTestDeviceThunk, getAllTestDeviceListByBotIdByPageSize } from '../../redux/botManagement/botManagementThunk';
import { useState } from "react";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function AddTestDeviceModel({ open, botId, botDetails, handleCloseMenuItems }) {

    const handleClose = () => {
        dispatch(setShowAddTestDeviceForm({ status: false, botId }));
    }


    // const state = useSelector((state) => state.botManagementSlice);

    const dispatch = useDispatch();
    const state = useSelector((state) => state.botManagementSlice);

    const phoneNumberSchema = Yup.object().shape({
        number: Yup.string()
            .matches(PHONE_NUMBER_REGEX, "Phone number is not valid!")
            .min(10, "Phone Number must contain at least 10 digits!")
            .max(10, "Phone Number should not exceed its limit!")
            .required("Phone Number is required"),
        countryCode: Yup.string().required("Country Code required"),
        country: Yup.string().required("Country required"),
    });

    const initialValues = {
        testDevices: [PHONE_NUMBER_OBJECT],
        testDeviceToAdd: PHONE_NUMBER_OBJECT,

    }

    const addTestDeviceEligibity = (countryCode, enteredNumber) => {
        const number = `${countryCode}`+`${enteredNumber}`
        const testDevices = botDetails.testDevices || [];
        return testDevices.includes(number) || testDevices.length > 9;
    };


    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            testDeviceToAdd: phoneNumberSchema
        }),
        onSubmit: async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
            try {

                const testDeviceNumber = `${values.testDeviceToAdd.countryCode}${values.testDeviceToAdd.number}`
                dispatch(addNewTestDeviceThunk({ botId, testDeviceNumber }))
                dispatch(setShowAddTestDeviceForm({ open: false, botId }));
                handleCloseMenuItems();
                formik.resetForm();
            } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submitError: message });
                setSubmitting(false);
                console.log(error);
            }
        },
    });



    const hanldeMobileNumberChange = (e) => {
        formik.setFieldValue(`testDeviceToAdd.number`, e.target.value);
    };


    const onChangeSelectCountry = (e) => {
        const foundCountry = CountryCode.find((country) => country.name === e.target.value);
        formik.setFieldValue(`testDeviceToAdd.countryCode`, foundCountry.dial_code)
        formik.setFieldValue(`testDeviceToAdd.country`, e.target.value)

    }


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby='alert-dialog-slide-description'
            fullWidth
            maxWidth={"sm"}
        // style={{width:"1000px"}}
        >
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{"Add Devices"}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ alignItems: "center" }} >


                        <Grid mb={5}>
                            {botDetails.testDevices?.map((item, index) => (
                                <Chip size="small" label={item} sx={{ margin: "4px" }} />
                            ))}
                        </Grid>


                        <Grid md={12} container gap={2} sx={{ display: "flex", flexDirection: "column" }}>

                            <Grid item md={3}>

                                <FormControl >
                                    <InputLabel id="country">Country</InputLabel>
                                    <Select
                                        name="country"
                                        label="Country"
                                        id="country"
                                        onChange={onChangeSelectCountry}

                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    boxShadow:
                                                        " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                },
                                            },
                                        }}
                                        defaultValue={"India"}
                                    >
                                        {CountryCode?.map((country, index) => (
                                            <MenuItem key={index} value={country.name}>
                                                {country.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container md={12} spacing={2} sx={{ display: "flex", flexDirection: "row" }}>
                                <Grid item md={4}>
                                    <TextField
                                        name="countryCode"
                                        fullWidth
                                        label={"code"}
                                        error={Boolean(
                                            formik.errors.testDeviceToAdd &&
                                            formik.errors.testDeviceToAdd
                                                ?.countryCode
                                        )}
                                        helperText={
                                            formik.errors.testDeviceToAdd &&
                                            formik.errors.testDeviceToAdd
                                                ?.countryCode
                                        }
                                        defaultValue={
                                            formik.values.testDeviceToAdd.countryCode
                                        }
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.testDeviceToAdd
                                                ?.countryCode
                                        }
                                    />
                                </Grid>
                                <Grid item md={8}>
                                    <TextField
                                        fullWidth
                                        name="testDeviceToAdd.number"
                                        label={"Mobile Number"}
                                        error={Boolean(
                                            formik.errors.testDeviceToAdd &&
                                            formik.errors.testDeviceToAdd
                                                ?.number
                                        )}
                                        // disabled={botDetails ? true : false}
                                        helperText={
                                            formik.errors.testDeviceToAdd &&
                                            formik.errors.testDeviceToAdd
                                                ?.number
                                        }
                                        defaultValue={
                                            formik.values.testDeviceToAdd.number
                                        }
                                        onBlur={formik.handleBlur}
                                        placeholder={formik.values.testDeviceToAdd
                                            ?.number}
                                        value={
                                            formik.values.testDeviceToAdd
                                                ?.number
                                        }
                                        onChange={hanldeMobileNumberChange}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    {state.controls.isAddingTestDevice ?
                        <Button variant="outlined">
                            <CircularProgress size={15} className="spinner" />
                            Adding..
                        </Button>
                        :
                        <Button
                            disabled={addTestDeviceEligibity(formik.values.testDeviceToAdd.countryCode, formik.values.testDeviceToAdd.number)}
                            variant="contained"
                            className="m-2"
                            type="submit"
                        >
                            Add Test Device
                        </Button>
                    }


                    <Button
                        variant='contained'
                        className="m-2 bg-danger"
                        onClick={() => {
                            dispatch(setShowAddTestDeviceForm({ status: false, botId }));
                            handleCloseMenuItems();
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </form>

        </Dialog>
    );
}
