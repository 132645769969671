import { Chip, FormControl, FormHelperText, Grid, IconButton, Input, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
export default function AddSMSApiKey({ formik, state, IsSmsCredentialsUsed }) {
    const [currValue, setCurrValue] = useState("");
    const handleChange = (event) => {
        const { value, name } = event.target;
        const maxCharacters = 6
        if (maxCharacters && typeof maxCharacters === "number") {
            if (value.length > maxCharacters) {
                formik.setFieldError("smsFallbackDetails.senderIds", `Only ${maxCharacters} characters are allowed.`);
                return;
            }


        }
        setCurrValue(event.target.value.toUpperCase());
    };
    const handleChangeMaxCharactersFields = (maxCharacters, event) => {
        const { value, name } = event.target;
        if (maxCharacters && typeof maxCharacters === "number") {
            if (value.length > maxCharacters) {
                formik.setFieldError(name, `Only ${maxCharacters} characters are allowed.`);
                return;
            }
        }
        if (name === "smsFallbackDetails.senderId") {
            formik.setFieldValue(name, value.toUpperCase());
        } else {
            formik.setFieldValue(name, value);
        }
    };

    const addSenderId = () => {
        // if(formik.values.smsFallbackDetails.senderId){

        if (formik.values.smsFallbackDetails.senderIds.includes(currValue)) {
            formik.setFieldError("smsFallbackDetails.senderIds", `SenderId already exist.`);
            return;
        }
        formik.setFieldValue("smsFallbackDetails.senderIds", [...formik.values.smsFallbackDetails.senderIds, currValue]);
        // }
        setCurrValue("")
    }


    const handleDelete = (item, index) => {
        let arr = [...formik.values.smsFallbackDetails.senderIds]
        arr.splice(index, 1)
        // setValues(arr)
        formik.setFieldValue("smsFallbackDetails.senderIds", arr);

    }

    return (
        <Grid container display={"flex"} width={"100%"}>
           
            <Grid item padding={2} md={6}>

                <Typography className='text-muted mb-2'>Sender ID should be 6 alpha characters or numeric.</Typography>

                <div >
                    {formik.values?.smsFallbackDetails?.senderIds.map((item, index) => (
                        <Chip size="small" onDelete={() => { handleDelete(item, index) }} label={item} sx={{ margin: "4px" }} />
                    ))}
                </div>
                <FormControl error={formik.errors?.smsFallbackDetails?.senderIds}  >
                    <Grid>

                        <Input
                            value={currValue}
                            onChange={handleChange}
                        // onKeyDown={handleKeyUp}
                        />

                        <IconButton onClick={addSenderId} disabled={!currValue}>
                            <AddIcon />
                        </IconButton>
                    </Grid>
                    <FormHelperText>{
                        formik.errors?.smsFallbackDetails?.senderIds}</FormHelperText>

                </FormControl>

            </Grid>
            <Grid item padding={2} md={6}>
                {/* <Typography className='subHeadings' paddingY={1} variant='h6' fontStyle={"bold"}>
                    API Key
                </Typography> */}
                <Typography className='text-muted mb-2'>Enter your SMS panel api key.</Typography>

                <TextField
                    label='API Key'
                    className='fw-bold'
                    name='smsFallbackDetails.sms_apiKey'
                    fullWidth
                    variant='outlined'
                    // InputProps={fontSize}
                    mb={4}
                    // disabled={IsSmsCredentialsUsed}
                    value={formik.values?.smsFallbackDetails?.sms_apiKey}
                    error={Boolean(formik.errors.smsFallbackDetails && formik.errors.smsFallbackDetails.sms_apiKey)}
                    helperText={formik.errors.smsFallbackDetails && formik.errors.smsFallbackDetails.sms_apiKey}
                    onChange={formik.handleChange}
                // sx={{
                //     "& legend": { display: "none" },
                //     "& fieldset": { top: 0 },
                // }}
                />
            </Grid>
        </Grid>
    );
}
