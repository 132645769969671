

import React, { useCallback } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { ContextMenu } from '../ContextMenu';
import { TextEditorReadOnly } from '../TextEditor/TextEditorReadOnly';
import { MasterCardLayout } from './MasterCardLayout';

export function MessageCard(props) {

    const { data, id, selected, type } = props;
    const methods = data.methods;

    let contents = data.contents.length === 0 ? [{ type: "paragraph", children: [{ text: "Hello User" }] }] : data.contents;

    return (
        <MasterCardLayout
            title={data.title}
            tailwindClass={data.cardTailwindStyle}
            key={`message-master-card-${id}`}
            startNode={data?.isStartCard}
            nodeData={data}
        >
            {
                selected === true &&
                // Show context menu
                <ContextMenu
                    nodeId={id}
                    methods={methods}
                    onDuplicate={(event) => { methods.onDuplicate(event, id) }}
                    onDelete={methods.onDelete}
                    isStartCard={data.isStartCard}
                />
            }

            <div className="text-updater-node" id={`${id}`}>
                <Handle type="target" position={Position.Left} className='target-handle' />
                <p></p>
                <div style={{ margin: '10px', fontSize: '10px' }}>
                    <TextEditorReadOnly
                        key={`text-editor-for-node-${id}`}
                        contents={contents}
                    />

                </div>
                <Handle type="source" position={Position.Right} className='source-handle' />
            </div>
        </MasterCardLayout>
    );
}
