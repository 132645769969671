import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowAddClientForm } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import AddClient from "./AddClient";
import ListClient from "./ListClient";

export default function ManageClients() {
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const [clientDetails, setClientDetails] = useState(null);
    const [editable, setEditable] = useState(false);
    const [customizeRatesByClientId, setCustomizeRatesByClientId] = useState(null);
    const [errorResponse, setErrorResponse] = useState("");
    const [isLoadingClientDetails, setIsLoadingClientDetails] = useState({ status: false, id: null });
    const [isBrandNotVerified, setIsBrandNotVerified] = useState(true);
    const [searchParams, setSearchParams] = React.useState({
        orgName:"",
        name: "",
        email: "",
        mobileNumber: "",
        branchName: "",
        status: "",
    });

    const handleEditDetailsChange = async ({ clientData, val, IsSmsCredentialsUsed }) => {
        setEditable(val);
        setIsLoadingClientDetails({ status: true, id: clientData._id });
        try {
            const response = await apiGatewayAxiosInstance.get(`/bot_management/brand/get-brand-by-orgId`, {
                headers: {
                    "Content-Type": "application/json",
                    brandOrgId: clientData.orgId,
                },
            });
            if (response.status === 200) {
                const brandDetails = response.data.brandDetails;
          
                if (brandDetails.brandStatus === "NOT_VERIFIED") {
                    setIsBrandNotVerified(true);
                } else {
                    setIsBrandNotVerified(false)
                }
                setClientDetails({ ...clientData, brandDetails, IsSmsCredentialsUsed });
                getCustomizeRateByClientId(clientData.orgDetails.supportedProviders[0], clientData.orgId);
            }
        } catch (error) {
            let errorMessage = "Failed to get brand details.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
            setIsLoadingClientDetails({ status: false, id: null });
        }
    };

    const getCustomizeRateByClientId = async (providerName, orgId) => {
        try {
            const token = sessionStorage.getItem("accessToken");
            const response = await apiGatewayAxiosInstance.get(`/user_management/customized-rate/get-by-client-id`, {
                headers: { orgid: orgId },
                params: { providerName },
            });

            if (response.status === 200) {
                const dataToSet = response.data.data;
                setCustomizeRatesByClientId(dataToSet);
                dispatch(setShowAddClientForm(true));
            } else {
                throw new Error(response.data);
            }
        } catch (error) {
            setIsLoadingClientDetails({ status: false, id: null });
            let errorMessage = "Failed to get customized rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        } finally {
            setIsLoadingClientDetails({ status: false, id: null });
        }
    };

    const onClickHandleClose = fieldName => {
        dispatch(onClickHandleClose(fieldName));
    };

    useEffect(() => {
        dispatch(setShowAddClientForm(false));
    }, []);

    return (
        <div >
            {state.controls.showAddClientForm ? (
                <>
                    <AddClient
                        clientData={clientDetails}
                        setClientDetails={setClientDetails}
                        isClientEditable={editable}
                        setEditable={setEditable}
                        isBrandNotVerified={isBrandNotVerified}
                        customizeRatesByClientId={customizeRatesByClientId}
                        setCustomizeRatesByClientId={setCustomizeRatesByClientId}
                        isLoadingClientDetails={isLoadingClientDetails}
                    />
                </>
            ) : (
                <ListClient
                searchParams={searchParams}
                 setSearchParams={setSearchParams}
                    setClientDetails={setClientDetails}
                    handleEditDetailsChange={handleEditDetailsChange}
                    isLoadingClientDetails={isLoadingClientDetails}
                    setIsBrandNotVerified={setIsBrandNotVerified}
                />
            )}

            {/* {state.controls.getClientEditDetailsErrorMessages && (
                <AlertMessageComponent
                    message={state.controls.getClientEditDetailsErrorMessages}
                    fieldName={"getClientEditDetailsErrorMessages"}
                    handleClose={onClickHandleClose}
                    show={Boolean(state.controls.getClientEditDetailsErrorMessages)}
                />
            )} */}

            <AlertMessageComponent
                message={errorResponse}
                fieldName={"errorResponse"}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />
        </div>
    );
}
