import axios from "axios";
import { config } from "../config";
import { useEffect } from "react";

export let orgId = "";
export let REACT_APP_API_URL = "";
export let REACT_APP_WHITELABEL_NAME = "";
export let REACT_APP_WHITELABEL_LOGO_URL = "";
export let REACT_APP_FRONTEND_URL = "";

export let domain = window.location.hostname;
const apiGatewayBaseUrl = config.APIGatewayServerUrl
const getEnvVariables = async () => {
    try {
        const response = await axios.get(`${apiGatewayBaseUrl}user_management/clients/get-env-variables`, { params: { domain } });
        orgId = response.data.data.orgId;
        REACT_APP_API_URL = response.data.data.whiteLabelBackendURL;
        REACT_APP_FRONTEND_URL = response.data.data.whiteLabelFrontendURL;
        REACT_APP_WHITELABEL_NAME = response.data.data.whiteLabelName;
        REACT_APP_WHITELABEL_LOGO_URL = response.data.data.whiteLabelLogo;

        setFavicon(REACT_APP_WHITELABEL_LOGO_URL);

    } catch (error) {
        console.log(error);
    }
};
getEnvVariables();

const setFavicon = (url) => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = url;

    // Remove old favicon if it exists
    const existingFavicon = document.querySelector("link[rel*='icon']");
    if (existingFavicon) {
        document.head.removeChild(existingFavicon);
    }
    document.head.appendChild(link);
};

export const useEnvVariables = () => {
    useEffect(() => {
        getEnvVariables();
    }, []);
};

const userManagementAxiosInstance = axios.create({
    baseURL: config.UserManagementServerUrl,
});

userManagementAxiosInstance.interceptors.response.use(
    response => response,
    error => Promise.reject(error),
);

userManagementAxiosInstance.interceptors.request.use(
    config => {
        const accessToken = window.sessionStorage.getItem("accessToken");
        const userId = window.sessionStorage.getItem("userId");
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers.orgId = userId;
        return config;
    },
    error => Promise.reject(error),
);

console.log({ domain });
export const apiGatewayAxiosInstance = axios.create({
    baseURL: apiGatewayBaseUrl,
    params: { domain }
});

// apiGatewayAxiosInstance.defaults.withCredentials = true;

apiGatewayAxiosInstance.interceptors.response.use(
    response => response,
    error => Promise.reject(error),
);

apiGatewayAxiosInstance.interceptors.request.use(
    config => {
        // console.log(config);
        // let accessToken = null;
        // if (config.params && config.params?.token) {
        //     accessToken = config.params.token;
        // } else {
        const accessToken = window.sessionStorage.getItem("accessToken");
        // }
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    },
    error => Promise.reject(error),
);

export const botManagementAxiosInstance = axios.create({
    baseURL: config.BotManagementServerUrl,
});

botManagementAxiosInstance.interceptors.response.use(
    response => response,

    error => Promise.reject(error),
);

botManagementAxiosInstance.interceptors.request.use(
    config => {
        const accessToken = window.sessionStorage.getItem("accessToken");
        // const accessToken = "fasd"
        const userId = window.sessionStorage.getItem("userId");
        config.headers.Authorization = `Bearer ${accessToken}`;
        // config.headers.orgId = userId;
        return config;
    },
    error => Promise.reject(error),
);

export default userManagementAxiosInstance;
