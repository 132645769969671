import DescriptionIcon from "@mui/icons-material/Description";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import { Box, Breadcrumbs, Button, Chip, Grid, Link, IconButton as MuiIconButton, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { darken } from "polished";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CampaignReportStatus, CampaignStatus, ERoles } from "../../config";
import { clearErrorMessagesForCampaigns, setErroMessageForField } from "../../redux/campaignManagment/campaignManagmentSlice";
import { apiGatewayAxiosInstance } from "../../utils/axios";
import AlertMessageComponent from "../components/AlertMessageComponent";
import FetchingSpinner from "../components/FetchingSpinner";
import InvalidNumberReasonsModal from "./InvalidNumberReasonsModal";
import ViewCampaignReport from "./ViewCampaignReport";
import DownloadIcon from "@mui/icons-material/Download";
import useAuth from "../../hooks/useAuth";
import FilterAllCampaignsReportsModel from "../reports/campaignReportsComponents/FilterCampaignReportsModel";
import CachedIcon from "@mui/icons-material/Cached";

import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import { getCampaignReportData } from "../../redux/campaignManagment/campaignManagmentThunk";
import Loader from "../components/Loader";


const calculateTime = value => {
    const time = new Date(value).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    });
    const day = new Date(value).toLocaleDateString([], { day: "2-digit" });
    const month = new Date(value).toLocaleDateString([], { month: "2-digit" });
    const year = new Date(value).toLocaleDateString([], { year: "numeric" });

    return day + "-" + month + "-" + year + " , " + time;
};



function CircularProgressWithLabel(
    props
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}
const Papa = require("papaparse");

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: "14px !important",
        fontWeight: "bold",
        backgroundColor: "#f4f4f4",
        padding: "5px 15px !important",
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "13px !important",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //     backgroundColor: theme.palette.action.hover,
    // },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const IconButton = styled(MuiIconButton)`
    &:hover {
        background-color: ${props => darken(0.05, props.theme.header.search.color)};
    }
`;

// phase : 2 method
export default function ViewCampaignDetails({ campaign, setShowViewCampaignForm }) {
    const [openModal, setOpenModal] = useState(false);
    const [campaignData, setCampaignData] = useState(campaign);
    const [loader, setLoader] = useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [errorResponse, setErrorResponse] = useState("");
    const [totalCountPageSize, setTotalCountPageSize] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [reload, setReload] = useState(false);
    const [loadDownload, setLoadDownload] = useState(false);
    const [campaignReportLoader, setCampaignReportLoader] = useState(false);

    const { user } = useAuth();
    const state = useSelector(state => state.campaignManagmentSlice);
    const campaignReportData = state.capaignReportDataState;
    const [openFilter, setOpenFilter] = React.useState(false);
    const [searchParams, setSearchParams] = React.useState({
        messageStatus: "",
        userNumber: "",
        // sendTime: "",
        provider: "",
    });
    const [campaignReports, setCampaignReports] = useState(
        campaignData?.otherDetails?.reportDetails ? [...campaignData?.otherDetails?.reportDetails] : [],
    );
    const dispatch = useDispatch();

    const onClickBackButton = () => {
        setShowViewCampaignForm(false);
    };

    const handleClickModal = campaign => {
        setCampaignData(campaign);
        setOpenModal(true);
    };
    const getCsvData = async (url, fileName) => {
        try {
            const responseJsonObject = await apiGatewayAxiosInstance.get(`/bot_management/campaign/read-s3file`, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    url: url,
                },
            });
            const JsonObject = await responseJsonObject.data;

            onClickDownloadCSV(JsonObject, fileName);
            // this.csvData = csvData;
        } catch (error) {

            let errorMessage = "Server not reachable";
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            }
            dispatch(
                setErroMessageForField({
                    fieldName: "getCampaignReportCSVDataErrorMessage",
                    errorMessage,
                }),
            );

            // throw new HttpException(
            //     500,
            //     "Error occurred while getting csv data"
            // );
        }
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForCampaigns({ fieldName }));
    };

    const onClickDownloadCSV = (object, fileName) => {
        const csvData = Papa.unparse(object);
        const csvBlob = new Blob([csvData], {
            type: "text/csv",
        });
        const csvUrl = URL.createObjectURL(csvBlob);

        const link = document.createElement("a");
        link.href = csvUrl;
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleShowReport = async campaigndetails => {
        try {

            const response = await apiGatewayAxiosInstance.get(`/conversation_logger/logger/get-campaign-reports-by-campaignId`, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    campaignId: campaigndetails._id,
                },
            });

            if (response.status === 200) {
                const otherDetails = { ...campaign['otherDetails'], ...response.data.campaignReportDetails }
                setTotalCount(response.data.campaignReportDetails.totalCount)
                setCampaignData({ ...campaign, otherDetails });

                // setShowViewCampaignReport(true);
                setLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErroMessageForField({
                    fieldName: "getCampaignReportDetailsErrorMessage",
                    errorMessage,
                }),
            );
        }

        // setCampaign(campaigndetails);
    };

    const handleShowReportThunk = async campaigndetails => {
        try {
            // type TQueryCampaignStatus = 'TOTAL_COUNT' | 'PENDING' | 'SENT' | 'DELIVERED' | 'READ' | 'RETRY' | 'FAILED' | 'SMS' | 'TTL_EXPIRATION_REVOKED';
            const botId = campaigndetails.otherDetails.botDetails.config.rbmClientId
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'TOTAL_COUNT', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'TTL_EXPIRATION_REVOKED', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'RETRY', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'FAILED', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'PENDING', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'SENT', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'DELIVERED', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'READ', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'SMS', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'CAMPAIGN_FIRST_TIME', }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'CAMPAIGN_LAST_TIME', }))


            // for SMS report
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'SMS-DELIVERED' }))
            // dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'SMS-EXPIRED' }))
            // dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'SMS-REJECTED' }))
            // dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'SMS-UNDELIVERED' }))
            // dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'SMS-BLACKLISTMSISD' }))
            // dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'SMS-DND' }))
            // dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'SMS-INVALID' }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'TOTAL_RCS' }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'TOTAL_SMS' }))
            dispatch(getCampaignReportData({ botId, campaignId: campaigndetails._id, executedDate: campaigndetails.executedTime, startDate: campaigndetails.startTime, fetchCampgainStatus: 'TOTAL_SMS_FAILED' }))
            setLoader(false);
        } catch (error) {

            setLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErroMessageForField({
                    fieldName: "getCampaignReportDetailsErrorMessage",
                    errorMessage,
                }),
            );
        }

        // setCampaign(campaigndetails);
    };

    const getCampaignReportDetailsByPageSize = async campaigndetails => {
        try {
            // setLoader(true);
            setCampaignReportLoader(true)
            const token = sessionStorage.getItem("accessToken");
            const botId = campaigndetails.otherDetails.botDetails.config.rbmClientId
            const response = await apiGatewayAxiosInstance.get(`/conversation_logger/logger/get-campaign-reports-details-page-size-by-campaignId`, {
                headers: { token },
                params: {
                    campaignId: campaigndetails._id,
                    executedTime: campaigndetails.executedTime,
                    startDate: campaigndetails.startTime,
                    pageSize,
                    currentPage: currentPage + 1,
                    messageStatus: searchParams.messageStatus,
                    userNumber: searchParams.userNumber,
                    botId,
                    // sendTime: "",
                    provider: searchParams.provider,
                },
            });

            if (response.status === 200) {
                const otherDetails = { ...campaign['otherDetails'], ...response.data.campaignReportDetails }

                // setCampaignData({ ...campaign, otherDetails });
                // debugger;
                setCampaignReports(response.data.campaignReportDetails.reportDetails);
                setTotalCountPageSize(response.data.campaignReportDetails.totalCount)
                // dispatch(setAllCustomizedData(response.data.data.clientData));
                // setTotalCountPageSize(response.data.data.totalCountPageSize)
                // setLoader(false);
                setCampaignReportLoader(false)
            } else {
                // setLoader(false);
                // setLoader(false);
                setCampaignReportLoader(false)
                throw new Error(response.data);
            }
        } catch (error) {
            setLoader(false);
            let errorMessage = "Failed to get all customised rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
            setCampaignReportLoader(false)
        }

    };




    function formatScheduledTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = getMonthName(date.getMonth());
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        hours %= 12;
        hours = hours || 12;
        const time = `${hours}:${minutes} ${ampm}`;
        const formattedTime = `${day} ${month} ${year}    ` + time;
        return formattedTime;
    }

    function getMonthName(monthIndex) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[monthIndex];
    }

    useEffect(async () => {
        // getCsvData(campaign.rcsEnabledNumbersJsonFile.csvFileUrl);
        // if (campaign.status === CampaignStatus.Executed || campaign.status === CampaignStatus.SMS_Executed) {
        setLoader(true);
        handleShowReportThunk(campaign);

        // }
        // setShowViewCampaignReport(true);
    }, [campaign, reload]);

    // useEffect(() => {
    //     if (campaignData?.otherDetails?.reportDetails) {
    //         setCampaignReports(campaignData?.otherDetails?.reportDetails);
    //     }
    // }, [campaignData?.otherDetails?.reportDetails]);


    const onClickDownloadReport = async () => {



        // const getCampaignReportDetailsByPageSize = async campaigndetails => {
        try {
            // setLoader(true);
            setLoadDownload(true);
            const token = sessionStorage.getItem("accessToken");
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

            const botId = campaign.otherDetails.botDetails.config.rbmClientId

            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/download-campaign-reports-by-campaignId`, { suggestionsTextList: campaign.suggestionsTextList }, {
                headers: { token },
                params: {
                    campaignId: campaign._id,
                    timeZone,
                    messageStatus: searchParams.messageStatus,
                    userNumber: searchParams.userNumber,
                    provider: searchParams.provider,
                    botId,
                    executedTime: campaign.executedTime,
                    startDate: campaign.startTime,
                    // PAGE NUMBER NOT INCLUDED

                },
            });

            if (response.status === 200) {

                const csvBlob = new Blob([response.data.CSVReport], {
                    type: "text/csv",
                });
                const csvUrl = URL.createObjectURL(csvBlob);

                const link = document.createElement("a");
                link.href = csvUrl;
                link.setAttribute("download", `${campaignData.campaignName}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // const otherDetails = { ...campaign['otherDetails'], ...response.data.campaignReportDetails }

                // setCampaignData({ ...campaign, otherDetails });
                // debugger;
                // setCampaignReports(response.data.campaignReportDetails.reportDetails);
                // setTotalCountPageSize(response.data.campaignReportDetails.totalCount)
                // dispatch(setAllCustomizedData(response.data.data.clientData));
                // setTotalCountPageSize(response.data.data.totalCountPageSize)
                // setLoader(false);
            } else {
                // setLoader(false);
                // setLoader(false);

                throw new Error(response.data);
            }
        } catch (error) {
            setLoader(false);
            let errorMessage = "Failed to get all customised rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
        setLoadDownload(false);



    };

    const handleClickOpenFilterModel = () => {
        setOpenFilter(true);
    };

    const handleCloseFilterModel = () => {
        // setSearchParams({});

        setOpenFilter(false);
    };

    const handleClickSearch = async () => {

        if (currentPage === 0) {
            await getCampaignReportDetailsByPageSize(campaign)

        }
        setCurrentPage(0);
        // let campaignList = campaignData?.otherDetails?.reportDetails.filter(user => user.messageStatus.includes(searchParams.messageStatus));
        // campaignList = campaignList.filter(user => user.receiverNumber.includes(searchParams.userNumber));
        // campaignList = campaignList.filter(user => user.provider.includes(searchParams.provider));
        // setCampaignReports(campaignList);
        handleCloseFilterModel();
    };

    const reloadList = () => {
        setReload(!reload);
    };

    return (
        <div>
            <Grid>
                <Grid container display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item md={6} display={"flex"} alignItems={"center"}>
                        <Grid display={"flex"} alignItems={"center"}>
                            {/* <Grid item md={1}> */}

                            <IconButton color='secondary' onClick={onClickBackButton} size='medium'>
                                <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                            </IconButton>
                            {/* </Grid>
                                    <Grid item md={11} display={"flex"} > */}
                            <Grid role='presentation'>
                                <Breadcrumbs aria-label='breadcrumb' fontSize='large'>
                                    <Link
                                        underline='hover'
                                        color='inherit'
                                        className='breadcrumbItem'
                                        // href="/rcs-management/manage-bots/"
                                        onClick={onClickBackButton}
                                    >
                                        Campaigns
                                    </Link>
                                    <Typography className='breadcrumbItem' color='text.primary'>
                                        {campaign.campaignName} Details
                                    </Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid display={"flex"}>
                                <Grid item display={"flex"} alignItems={"center"} justifyContent={"space-between"} paddingLeft={12}>
                                    {campaign.status === CampaignStatus.Created ? (
                                        <Chip variant='outlined' className='chip' label={campaign.status.toUpperCase()} color='success' />
                                    ) : campaign.status === CampaignStatus.Pending ? (
                                        <Chip variant='outlined' className='chip' label={campaign.status.toUpperCase()} color='warning' />
                                    ) : campaign.status === CampaignStatus.Running ? (
                                        <Chip variant='outlined' className='chip' label={campaign.status.toUpperCase()} color='info' />
                                    ) : campaign.status === CampaignStatus.Executed ? (
                                        <Chip variant='outlined' className='chip' label={campaign.status.toUpperCase()} color='secondary' />
                                    ) : CampaignStatus.Execution_Failed || campaign.status === CampaignStatus.Validation_Failed ? (
                                        <Chip variant='outlined' className='chip' label={campaign.status.toUpperCase()} color='error' />
                                    ) : (
                                        <Chip variant='outlined' className='chip' label={campaign.status.toUpperCase()} color='warning' />
                                    )}

                                    <Grid padding={2}>
                                        {campaign.status === CampaignStatus.Pending && (
                                            <Tooltip
                                                title={
                                                    <p
                                                        style={{
                                                            fontSize: ".7rem",
                                                        }}
                                                    >
                                                        Campaign CSV Validation is in process.
                                                    </p>
                                                }
                                                className='cursor-pointer'
                                            >
                                                <InfoOutlinedIcon sx={{ opacity: "50%" }} />
                                            </Tooltip>
                                        )}
                                        {campaign.status === CampaignStatus.Validation_Failed && (
                                            <Tooltip
                                                title={
                                                    <p
                                                        style={{
                                                            fontSize: ".7rem",
                                                        }}
                                                    >
                                                        CSV file is invalid.
                                                    </p>
                                                }
                                                className='cursor-pointer'
                                            >
                                                <InfoOutlinedIcon sx={{ opacity: "50%" }} />
                                            </Tooltip>
                                        )}
                                        {campaign.status === CampaignStatus.Execution_Failed && (
                                            <Tooltip
                                                title={
                                                    <p
                                                        style={{
                                                            fontSize: ".7rem",
                                                        }}
                                                    >
                                                        {campaign.executionFailedError}.
                                                    </p>
                                                }
                                                className='cursor-pointer'
                                            >
                                                <InfoOutlinedIcon sx={{ opacity: "50%" }} />
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            {(campaign.totalRcsDisabledNumbers || campaign.totalRcsEnabledNumbers) && campaignReportData[CampaignReportStatus.TotalCount] && <Grid sx={{ display: "flex", alignItems: 'center' }} >

                                {campaign.totalRcsDisabledNumbers ?
                                    <CircularProgressWithLabel value={(campaignReportData[CampaignReportStatus.TotalCount] / (campaign.totalRcsDisabledNumbers + campaign.totalRcsEnabledNumbers)) * 100} />
                                    :
                                    <CircularProgressWithLabel value={(campaignReportData[CampaignReportStatus.TotalCount] / (campaign.totalRcsEnabledNumbers)) * 100} />
                                }

                                {/* <CircularProgressWithLabel value={40} /> */}
                                <IconButton
                                    title='Refresh'
                                    onClick={reloadList}
                                    disabled={state.isLoadingCapaignReport[CampaignReportStatus.TotalCount]}
                                    sx={{
                                        margin: "8px",
                                        "&:hover": { backgroundColor: "gray" },
                                        animation: state.isLoadingCapaignReport[CampaignReportStatus.TotalCount] ? "spin 0.5s linear infinite" : "",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(360deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(0deg)",
                                            },
                                        },


                                        // marginInline: "20px",
                                    }}
                                >
                                    <CachedIcon />
                                </IconButton>

                            </Grid>

                            }
                            {/* </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item md={6} display={"flex"} flexDirection={"column"} justifyContent={"center"} justifyItems={"center"}>





                        {user?.roleName && user?.roleName == ERoles.SUPER_ADMIN && (
                            <Grid display={"flex"} justifyContent={"end"}>
                                {/* <Grid item md={6}> */}
                                <Typography variant='button' style={{ fontWeight: "600", display: "flex", justifyContent: "end" }}>
                                    Organization Name: &nbsp;
                                </Typography>
                                {/* </Grid>
                                <Grid item md={6}> */}
                                <Typography variant='button'>{campaign.orgName}</Typography>
                                {/* </Grid> */}
                            </Grid>
                        )}

                        <Grid display={"flex"} justifyContent={"end"}>
                            <Typography variant='button' style={{ fontWeight: "600" }}>
                                Bot Name :&nbsp;
                            </Typography>
                            <Typography variant='button'>{campaign.botName}</Typography>
                        </Grid>
                        <Grid display={"flex"} justifyContent={"end"}>
                            <Typography variant='button' style={{ fontWeight: "600" }}>
                                Template Name :&nbsp;
                            </Typography>
                            <Typography variant='button'>{campaign.templateName}</Typography>
                        </Grid>

                        {campaignReportData[CampaignReportStatus.CampaignFirstTime] !== 0 && <Grid display={"flex"} justifyContent={"end"}>
                            <Typography variant='button' style={{ fontWeight: "600" }}>
                                Started at :&nbsp;
                            </Typography>
                            <Typography variant='button'>
                                {state.isLoadingCapaignReport[CampaignReportStatus.CampaignFirstTime] && <Loader size={15} color="inherit" />}
                                {!state.isLoadingCapaignReport[CampaignReportStatus.CampaignFirstTime] && calculateTime(campaignReportData[CampaignReportStatus.CampaignFirstTime])}
                            </Typography>
                        </Grid>}


                        {campaignReportData[CampaignReportStatus.CampaignLastTime] !== 0 && <Grid display={"flex"} justifyContent={"end"}>
                            <Typography variant='button' style={{ fontWeight: "600" }}>
                                Last message processed at :&nbsp;
                            </Typography>
                            <Typography variant='button'>
                                {state.isLoadingCapaignReport[CampaignReportStatus.CampaignLastTime] && <Loader size={15} color="inherit" />}
                                {!state.isLoadingCapaignReport[CampaignReportStatus.CampaignLastTime] && calculateTime(campaignReportData[CampaignReportStatus.CampaignLastTime])}
                            </Typography>
                        </Grid>}


                        {campaign.scheduleCampaignTime && (
                            <Grid display={"flex"} justifyContent={"end"}>
                                <Typography
                                    variant='button'
                                    style={{
                                        fontWeight: "600",
                                    }}
                                >
                                    Scheduled on :&nbsp;
                                </Typography>

                                <Typography variant='button'>{formatScheduledTime(campaign.scheduleCampaignTime)}</Typography>
                            </Grid>
                        )}
                        {campaign.cancelScheduleTime && (
                            <Grid display={"flex"} justifyContent={"end"}>
                                <Typography
                                    variant='button'
                                    style={{
                                        fontWeight: "600",
                                    }}
                                >
                                    Scheduled Cancelled at :&nbsp;
                                </Typography>

                                <Typography variant='button'>{formatScheduledTime(campaign.cancelScheduleTime)}</Typography>
                            </Grid>
                        )}
                    </Grid>

                    {/* </Grid> */}


                </Grid>

            </Grid>

            <Card sx={{ marginTop: "20px" }}>
                <CardContent>

                    {/* <CircularProgressWithLabel value={40} /> */}

                    <Grid
                        alignItems={"center"}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >

                        <Grid display={"flex"} alignItems={"center"} paddingRight={3} gap={"1rem"}>
                            {campaign?.uploadedCSV?.csvFileUrl && (
                                <Grid md={3} display={"flex"}>
                                    <Button
                                        variant='outlined'
                                        title='Download Uploaded CSV'
                                        href={campaign?.uploadedCSV?.csvFileUrl}
                                        download
                                        color='secondary'
                                    >
                                        {" "}
                                        <DescriptionIcon /> &nbsp; Uploaded CSV
                                        <Typography
                                            variant='subtitle2'
                                            color='secondary'
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center !important",
                                            }}
                                        >
                                            &nbsp; [{campaign.totalUploadedNumbers}]
                                        </Typography>
                                    </Button>
                                </Grid>
                            )}

                            {campaign?.rcsEnabledNumbersJsonFile?.csvFileUrl && (
                                <Grid md={3} display={"flex"}>
                                    <Button
                                        disabled={campaign.totalRcsEnabledNumbers <= 0 ? true : false}
                                        color='success'
                                        variant='outlined'
                                        title='Download Rcs Enabled Numbers CSV file'
                                        onClick={() => {
                                            getCsvData(campaign.rcsEnabledNumbersJsonFile.csvFileUrl, "rcsEnabledNumbersJsonFile");
                                        }}
                                    >
                                        {" "}
                                        <MobileFriendlyIcon /> &nbsp; Rcs Enabled Numbers
                                        <Typography
                                            variant='subtitle2'
                                            color={campaign.totalRcsEnabledNumbers <= 0 ? 'secondary' : 'green'}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center !important",
                                            }}
                                        >
                                            &nbsp; [{campaign.totalRcsEnabledNumbers}]
                                        </Typography>
                                    </Button>
                                </Grid>
                            )}
                            {campaign?.rcsDisabledNumbersJsonFile?.csvFileUrl && (
                                <Grid md={3} display={"flex"}>
                                    <Button
                                        disabled={campaign.totalRcsDisabledNumbers <= 0 ? true : false}
                                        variant='outlined'
                                        color='primary'
                                        title='Download Rcs Disabled Numbers CSV file'
                                        onClick={() => {
                                            getCsvData(campaign.rcsDisabledNumbersJsonFile.csvFileUrl, "rcsDisabledNumbersJsonFile");
                                        }}
                                    >
                                        {" "}
                                        <MobileOffIcon /> &nbsp; Rcs Disabled Numbers
                                        <Typography
                                            variant='subtitle2'
                                            color={campaign.totalRcsDisabledNumbers <= 0 ? 'secondary' : 'primary'}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center !important",
                                            }}
                                        >
                                            &nbsp; [{campaign.totalRcsDisabledNumbers}]
                                        </Typography>
                                    </Button>
                                </Grid>
                            )}
                            {campaign?.inValidNumbersCSVFile?.csvFileUrl && (
                                <Grid md={3} display={"flex"}>
                                    <Button
                                        variant='outlined'
                                        title='Download invalid numbers CSV file'
                                        color='error'
                                        href={campaign?.inValidNumbersCSVFile?.csvFileUrl}
                                        download
                                    >
                                        {" "}
                                        <DoNotDisturbIcon /> &nbsp; In-Valid Numbers
                                        <Typography
                                            variant='subtitle2'
                                            color='error'
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center !important",
                                            }}
                                        >
                                            &nbsp; [{campaign.totalInValidNumbers}]
                                        </Typography>
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item md={3} display={"flex"} justifyContent={"end"}>
                            <Grid item>
                                {campaignReportData[CampaignReportStatus.TotalCount] > 0 && (
                                    <Button
                                        onClick={() => {
                                            onClickDownloadReport();
                                        }}

                                        disabled={loadDownload}
                                    >
                                        {loadDownload ? <CircularProgress size={15} className='spinner' /> : <DownloadIcon />}
                                        &nbsp; Download Report
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                <Button sx={{ marginRight: "10px" }} variant='contained' onClick={() => handleClickOpenFilterModel()}>
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <hr />
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        {(!loader) && (
                            // phase : 2
                            <ViewCampaignReport
                                reload={reload}
                                campaign={campaignData}
                                campaignReports={campaignReports}
                                getCampaignReportDetailsByPageSize={getCampaignReportDetailsByPageSize}
                                totalCountPageSize={totalCountPageSize}
                                totalCount={totalCount}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                campaignReportLoader={campaignReportLoader}

                            // setShowViewCampaignReport={setShowViewCampaignReport}
                            />
                        )}
                        {loader && <FetchingSpinner />}
                    </Grid>
                </CardContent>
            </Card>

            {
                openFilter && (
                    <FilterAllCampaignsReportsModel
                        open={openFilter}
                        searchParams={searchParams}
                        handleClickOpenFilterModel={handleClickOpenFilterModel}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setSearchParams={setSearchParams}
                        handleClickSearch={handleClickSearch}
                        campaignReportLoader={campaignReportLoader}
                    />
                )
            }
            {openModal && <InvalidNumberReasonsModal open={openModal} setOpen={setOpenModal} campaignData={campaignData} />}
            {
                state.controls.getCampaignReportDetailsErrorMessage && (
                    <AlertMessageComponent
                        message={state.controls.getCampaignReportDetailsErrorMessage}
                        fieldName={"getCampaignReportDetailsErrorMessage"}
                        handleClose={onCloseErrorMessage}
                        show={Boolean(state.controls.getCampaignReportDetailsErrorMessage)}
                    />
                )
            }
            {
                state.controls.getCampaignReportCSVDataErrorMessage && (
                    <AlertMessageComponent
                        message={state.controls.getCampaignReportCSVDataErrorMessage}
                        fieldName={"getCampaignReportCSVDataErrorMessage"}
                        handleClose={onCloseErrorMessage}
                        show={Boolean(state.controls.getCampaignReportCSVDataErrorMessage)}
                    />
                )
            }
        </div >
    );
}
