import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CampaignReport from "./CampaignReport";
import ListCampaignReports from "./ListCampaignReports";
import { setShowCampaignReport } from "../../../redux/campaignReportsManagment/campaignReportsManagmentSlice";


function ManageCampaignReports() {
    const campaignState = useSelector((state) => state.campaignReportsManagementSlice);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setShowCampaignReport({ status: false, campaignData: null }));
    }, []);

    return (
        <>
            {
                !campaignState.controls.showCampaignReport.status ?
                    <ListCampaignReports /> :
                    <CampaignReport />
            }
        </>
    );
}

export default ManageCampaignReports;
