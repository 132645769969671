import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowVerifyClientSecretForm
} from "../../../redux/botManagement/botManagementSlice";
import { VerifyClientSecretThunk } from "../../../redux/botManagement/botManagementThunk";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";

export default function VerifyClientSecret() {
  const state = useSelector((state) => state.botManagementSlice);
  const [botDetails, setBotDetails] = useState(null);
  const [errorResponse, setErrorResponse] = useState("");

  const dispatch = useDispatch();

  const onClickCancelButton = () => {
    dispatch(setShowVerifyClientSecretForm({ status: false, botId: "" }));
  };

  useEffect(() => {
    getBotDetails();
  }, [])


  const getBotDetails = async () => {
    try {
      const response = await apiGatewayAxiosInstance.get(`/bot_management/bot/get-bot-details`, {
        headers: {
          "Content-Type": "application/json",
          "botId": state.controls.showVerifyClientSecretForm.botId
        },
      });
      if (response.status === 200) {
        setBotDetails(response.data.botDetails);
        if (response.data.botDetails.config?.rbmClientId) {

          formik.setFieldValue("rbmClientId", response.data.botDetails.config?.rbmClientId);
        }
        if (response.data.botDetails.config?.rbmClientSecret) {
          formik.setFieldValue("rbmClientSecret", response.data.botDetails.config?.rbmClientSecret);
        }
      } else {
        throw new Error(response);
      }
    } catch (error) {
      let errorMessage = "Failed to get bot details.";

      if (error.response) {
        errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
      }
      setErrorResponse(errorMessage);
    }
  }

  const formik = useFormik({
    initialValues: {
      botId: state.controls.showVerifyClientSecretForm.botId,
      rbmClientId: "",
      rbmClientSecret: "",
    },
    // validationSchema: Yup.object().shape({
    //   rbmClientId: Yup.string()
    //     .required("Client Id is required!")
    //   // .min(12, "Max 12 characters can be used")
    //   // .max(255, "Max 225 characters can be used")
    //   ,
    //   rbmClientSecret: Yup.string()
    //     .required("Client Secret is required!")
    //   // .min(12, "Max 12 characters can be used")
    //   // .max(255, "Max 20 characters can be used")
    //   ,
    // }),

    onSubmit: async (
      values,
      { setErrors, setStatus, setSubmitting, resetForm }
    ) => {
      try {
        const validate = await validateDatingTemplate()
        if (validate) {

          const dataToSend = {

            botId: values.botId,
            config: {
              ...botDetails.config,
              rbmClientId: values.rbmClientId,
              rbmClientSecret: values.rbmClientSecret,
            }
          };
          const result = dispatch(
            VerifyClientSecretThunk({
              data: dataToSend,
            })
          );

        }

      } catch (error) {
        const message = formik.errors.message || "Something went wrong";
        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
    },
  });

  // const onClickOnSaveButton = () => {
  //   formik.handleSubmit();
  // };


  const validateDatingTemplate = async () => {
    // validateIfValueIsEmpty()
    let result = true;


    if (formik.values.rbmClientId === "") {
      result = false
      formik.setFieldError("rbmClientId", `Client Id  is required`);

    }
    if (formik.values.rbmClientSecret === "") {
      result = false
      formik.setFieldError("rbmClientSecret", `Client Secret  is required`);

    }

    return result
  }


  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardContent sx={{ padding: "10px" }}>
          <Typography variant="h4" display={"flex"} gap={2}>
            <b >Verify client secret for</b>
            <b className="text-primary">{botDetails?.botName}</b>
          </Typography>
          <hr />
          <Grid className="row mt-3" container>
            <Grid item md={6}>
              <TextField
                name="rbmClientId"
                fullWidth
                label="Client ID"
                variant="outlined"
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.errors.rbmClientId
                )}
                helperText={
                  formik.errors.rbmClientId
                }
                value={formik.values.rbmClientId}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid md={6}>
              <TextField
                name="rbmClientSecret"
                fullWidth
                label="Client Secret"
                variant="outlined"
                onBlur={formik.handleBlur}
                error={Boolean(

                  formik.errors.rbmClientSecret
                )}
                helperText={

                  formik.errors.rbmClientSecret
                }
                value={formik.values.rbmClientSecret}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ padding: "0px" }}>
          <Grid display={"flex"}>
            <Button
              variant="contained"
              type="submit"
              // onClick={onClickOnSaveButton}
              className="m-2"
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={onClickCancelButton}
              className="m-2"
            >
              Cancel
            </Button>
          </Grid>
        </CardActions>
      </Card>
      {state.controls.verifyClientSecretErrorMessage && (
        <Alert mt={2} mb={3} severity="error">
          {state.controls.verifyClientSecretErrorMessage}
        </Alert>
      )}
      <AlertMessageComponent
        message={errorResponse}
        fieldName={"errorResponse"}
        handleClose={() => setErrorResponse("")}
        show={Boolean(errorResponse)}
      />
    </form>
  );
}
