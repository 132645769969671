import ClearIcon from "@mui/icons-material/Clear";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from "@mui/material";
import { Form, Formik, useFormik } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CountryCode from "../../../assets/CountryCode.json";
import { setEditViewVerifyBotDetailsForm, setShowVerifyBotForm } from "../../../redux/botManagement/botManagementSlice";
import { verifyBotThunk } from "../../../redux/botManagement/botManagementThunk";
import FilePreviewComponent from "../FilePreviewComponent";

import { useEffect } from "react";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import { BusinessVerification } from "./BotVerificationDetails";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import { SmartToy } from "@mui/icons-material";
import { BrandStatus, ERoles } from "../../../config";
import useAuth from "../../../hooks/useAuth";
const countryCode = CountryCode;
const sleep = time => new Promise(acc => setTimeout(acc, time));

export default function VerifyBot({ brandDetails, brandVerified, botVerificationDetails, brandAndBusinessVerificationDetails }) {
    const [imageFormData, setImageFormData] = useState({});
    const [botDetailsEditable, setBotDetailsEditable] = useState(false);

    const [screenshotFormData, setScreenshotFormData] = useState([]);
    const state = useSelector(state => state.botManagementSlice);
    const botList = [...state.botList, ...state.botListByPageSize];
    const foundBot = botList.find(bot => bot._id === state.controls.showVerifyBotForm.botId);
    const dispatch = useDispatch();
    const fileRef = useRef(null);
    const fileRef1 = useRef({});
    const fileRef2 = useRef({});

    const verifyBusinessNameObjectToAdd = {
        documentType: "",
        selectedFile: {
            type: "file",
            url: "",
        },
    };

    const businessAddressObjectToAdd = {
        documentType: "",
        selectedFile: {
            type: "file",
            url: "",
        },
    };

    const previewFile = (targetFile, urlFieldName, typeFieldName) => {
        // Reading New File (open file Picker Box)
        const reader = new FileReader();
        if (targetFile) {
            reader.readAsDataURL(targetFile);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (targetFile.type.includes("image")) {
                formik.setFieldValue(urlFieldName, readerEvent.target.result);
                formik.setFieldValue(typeFieldName, targetFile.type);

                let mimeType = targetFile.type;
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg"];
                if (supportedMimeTypeArray.includes(mimeType)) {
                    //   setting both the images in imageFormData state on fn call
                    setImageFormData({
                        ...imageFormData,
                        [urlFieldName]: targetFile,
                    });
                }
            }
        };
    };

    const onChangeAddScreenshots = async ({ length, name, value }) => {
        // const screenshots = [...formik.values.screenshots,value]
        // formik.setFieldValue("screenshots", [...value]);
        const screenshotsLength = formik.values.screenshots.length;

        if (screenshotsLength + value.length > 8) {
            formik.setFieldError("screenshots", `Only 8 Screenshots `);
        } else {
            setScreenshotFormData([...screenshotFormData, ...value]);

            for (let index = 0; index < value.length; index++) {
                let file = value[index];

                const reader = new FileReader();
                if (file) {
                    reader.readAsDataURL(file);
                }
                // As the File loaded then set the stage as per the file type
                reader.onload = readerEvent => {
                    if (file.type.includes("image")) {
                        formik.setFieldValue(`screenshots[${screenshotsLength + index}].url`, readerEvent.target.result);
                        formik.setFieldValue(`screenshots[${screenshotsLength + index}].type`, file.type);
                    }
                };
            }
        }
    };

    const onChangeAddDocumentTypeForNameVerification = ({ index, value, name }) => {
        if (formik.values.verifyBusinessName.filter(document => document.documentType === value).length <= 0) {
            formik.setFieldValue(`verifyBusinessName[${index}].${name}`, value);
        } else {
            formik.setFieldError(`verifyBusinessName[${index}].${name}`, "Document Type Already Selected");
        }
    };
    const onChangeSelectDocumentForBusinessNameVerification = ({ index, name, value }) => {
        formik.setFieldValue(`verifyBusinessName[${index}].${name}.url`, value);

        setImageFormData({
            ...imageFormData,
            [`verifyBusinessName[${index}].${name}.url`]: value,
        });
    };
    const onChangeBusinessAddressVerification = ({ index, name, value }) => {
        formik.setFieldValue(`verifyBusinessAddress[${index}].${name}.url`, value);
        setImageFormData({
            ...imageFormData,
            [`verifyBusinessAddress[${index}].${name}.url`]: value,
        });
    };
    // add field in ui
    const addDocumentForBusinessNameVerification = () => {
        formik.setFieldValue("verifyBusinessName", [...formik.values.verifyBusinessName, verifyBusinessNameObjectToAdd]);
    };

    const deleteDocumentForBusinessNameVerification = index => {
        const newPhones = [...formik.values.verifyBusinessName];

        newPhones.splice(index, 1);
        formik.setFieldValue("verifyBusinessName", newPhones);
    };

    // document type
    const onChangeAddDocumentForAddressVerification = ({ index, value, name }) => {
        if (formik.values.verifyBusinessName.filter(document => document.documentType === value).length <= 0) {
            formik.setFieldValue(`verifyBusinessAddress[${index}].${name}`, value);
        } else {
            formik.setFieldError(`verifyBusinessAddress[${index}].${name}`, "Document Type Already Selected");
        }
    };
    // for addding new fields in ui
    const addDocument = () => {
        formik.setFieldValue("verifyBusinessAddress", [...formik.values.verifyBusinessAddress, verifyBusinessNameObjectToAdd]);
    };
    function deleteDocument(index) {
        const newPhones = [...formik.values.verifyBusinessAddress];
        newPhones.splice(index, 1);
        formik.setFieldValue("verifyBusinessAddress", newPhones);
    }

    const handleChangeCheckBox = e => {
        formik.setFieldValue(e.target.name, e.target.checked);
    };

    const onChangeSelectCountry = ({ name, value }) => {
        formik.setFieldValue(`contactPersonPhoneNumber.country`, value);

        const foundCountry = countryCode.find(country => country.name === value);
        formik.setFieldValue(`contactPersonPhoneNumber.code`, foundCountry.dial_code);
    };

    function onDeleteImage(url, mimeType) {
        formik.setFieldValue(url, null);
        formik.setFieldValue(mimeType, "");
    }

    function onDeleteScreenshot(index) {
        let screenshots = formik.values.screenshots;
        screenshots.splice(index, 1);
        formik.setFieldValue("screenshots", screenshots);
        let screenshotFile = screenshotFormData;
        screenshotFile.splice(index, 1);
        setScreenshotFormData(screenshotFile);
    }

    const mediaSchema = Yup.object().shape({
        url: Yup.string().required("url is required for media"),
        type: Yup.string().required("mimetype is required for media"),
    });

    const validateIfValueIsEmpty = fieldName => {
        const fieldValue = formik.values[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(fieldName, `Required`);
            return true;
        } else {
            return false;
        }
    };

    const validateForUrl = fieldName => {
        const websiteRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

        if (!formik.values[fieldName].match(websiteRegex)) {
            formik.setFieldError(fieldName, "Invalid url");
            return true;
        } else {
            return false;
        }
    };

    const validateForEmail = fieldName => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (!formik.values[fieldName].match(emailRegex)) {
            formik.setFieldError(fieldName, "Invalid Email");
            return true;
        } else {
            return false;
        }
    };

    const validateForPhoneNumber = fieldName => {
        if (formik.values[fieldName].number === "" || formik.values[fieldName].number === null) {
            formik.setFieldError(`contactPersonPhoneNumber.number`, `This field can’t be empty`);
            return true;
        }

        const numberRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        // const emailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (!formik.values[fieldName].number.match(numberRegex)) {
            formik.setFieldError(`contactPersonPhoneNumber.number`, "Invalid number");
            return true;
        } else {
            return false;
        }
    };

    const validateForCompanyAddress = () => {
        let result = true;

        // companyAddress
        // addressLine1
        if (formik.values.companyAddress.addressLine1 === "" || formik.values.companyAddress.addressLine1 === null) {
            formik.setFieldError(`companyAddress.addressLine1`, `This field can’t be empty`);
            result = false;
        }

        // addressLine2
        if (formik.values.companyAddress.addressLine2 === "" || formik.values.companyAddress.addressLine2 === null) {
            formik.setFieldError(`companyAddress.addressLine2`, `This field can’t be empty`);
            result = false;
        }

        // city
        if (formik.values.companyAddress.city === "" || formik.values.companyAddress.city === null) {
            formik.setFieldError(`companyAddress.city`, `This field can’t be empty`);
            result = false;
        }
        // country
        if (formik.values.companyAddress.country === "" || formik.values.companyAddress.country === null) {
            formik.setFieldError(`companyAddress.country`, `This field can’t be empty`);
            result = false;
        }
        // state
        if (formik.values.companyAddress.state === "" || formik.values.companyAddress.state === null) {
            formik.setFieldError(`companyAddress.state`, `This field can’t be empty`);
            result = false;
        }
        // zipCode
        if (formik.values.companyAddress.zipCode === "" || formik.values.companyAddress.zipCode === null) {
            formik.setFieldError(`companyAddress.zipCode`, `This field can’t be empty`);
            result = false;
        } else {
            const zipCodeRegex = /\b\d{6}\b/;
            if (!formik.values.companyAddress.zipCode.match(zipCodeRegex)) {
                formik.setFieldError(`companyAddress.zipCode`, "Invalid Zip Code");
                result = false;
            }
        }

        return result;
    };

    const validateBotDetails = () => {
        let result = true;

        if (validateIfValueIsEmpty("actionsToTriggersMessagesToUsers")) {
            result = false;
        }

        if (validateIfValueIsEmpty("typeOfActionsBotWillHaveWithUsers")) {
            result = false;
        }

        if (formik.values.respondp2aMessages === "Yes") {
            if (validateIfValueIsEmpty("keywordsBotRecognizesAndResponds")) {
                result = false;
            }
        }

        if (validateIfValueIsEmpty("ensureConsent")) {
            result = false;
        }

        if (validateIfValueIsEmpty("optOutKeywordsForBot")) {
            result = false;
        }

        if (validateIfValueIsEmpty("messageBotSendWhenUserOptsOut")) {
            result = false;
        }

        if (validateIfValueIsEmpty("keywordsUserSendToRevokeOptOut")) {
            result = false;
        }

        if (formik.values.platformWhenUserRevokesOptout === "Default") {
            if (validateIfValueIsEmpty("messageBotSendWhenUserRevokesOptout")) {
                result = false;
            }
        } else {
            formik.setFieldValue("messageBotSendWhenUserRevokesOptout", "");
        }

        if (validateIfValueIsEmpty("botAccessInstructions")) {
            result = false;
        }

        if (validateIfValueIsEmpty("chatbotWebhook")) {
            result = false;
        } else {
            if (validateForUrl("chatbotWebhook")) {
                result = false;
            }
        }

        if (formik.values.screenshots.length === 0) {
            formik.setFieldError("screenshots", `Select Image`);
            result = false;
        }

        if (validateIfValueIsEmpty("videoUrls")) {
            result = false;
        }

        return result;
    };

    const validateBrandDetails = () => {
        let result = true;

        if (validateIfValueIsEmpty("industryType")) {
            result = false;
        }

        if (validateIfValueIsEmpty("officialBrandWebsite")) {
            result = false;
        } else {
            if (validateForUrl("officialBrandWebsite")) {
                result = false;
            }
        }

        if (formik.values.brandLogo.url === "" || formik.values.brandLogo.url === null) {
            formik.setFieldError("brandLogo.url", "Select Image");
            result = false;
        }

        if (validateIfValueIsEmpty("contactPersonFirstName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("contactPersonLastName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("contactPersonDesignation")) {
            result = false;
        }

        if (validateIfValueIsEmpty("contactPersonEmailId")) {
            result = false;
        } else {
            if (validateForEmail("contactPersonEmailId")) {
                result = false;
            }
        }

        if (validateForPhoneNumber("contactPersonPhoneNumber")) {
            result = false;
        }

        if (!validateForCompanyAddress()) {
            result = false;
        }

        return result;
    };

    const validateBusinessVerification = () => {
        let result = true;

        if (formik.values.termsAndConditions === false) {
            formik.setFieldError(`termsAndConditions`, `Please check the checkbox`);
            result = false;
        }

        if (formik.values.agreeToPayVerificationFee === false) {
            formik.setFieldError(`agreeToPayVerificationFee`, `Please check the checkbox`);
            result = false;
        }

        formik.values.verifyBusinessName.map((document, index) => {
            if (document.documentType === "") {
                formik.setFieldError(`verifyBusinessName[${index}].documentType`, `This field can’t be empty`);
            }

            if (document.selectedFile.url === "" || document.selectedFile.url === null) {
                formik.setFieldError(`verifyBusinessName[${index}].selectedFile.url`, `Select File`);
            }
        });

        formik.values.verifyBusinessAddress.map((document, index) => {
            if (document.documentType === "") {
                formik.setFieldError(`verifyBusinessAddress[${index}].documentType`, `This field can’t be empty`);
            }

            if (document.selectedFile.url === "" || document.selectedFile.url === null) {
                formik.setFieldError(`verifyBusinessAddress[${index}].selectedFile.url`, `Select File`);
            }
        });

        return result;
    };

    const initialValues = {
        orgId: window.sessionStorage.getItem("userId"),
        botId: state.controls.showVerifyBotForm.botId ? state.controls.showVerifyBotForm.botId : state.controls.showEditVerifyBotDetailsForm.botId,
        actionsToTriggersMessagesToUsers: botVerificationDetails?.verificationDetails?.actionsToTriggersMessagesToUsers
            ? botVerificationDetails.verificationDetails.actionsToTriggersMessagesToUsers
            : "",
        typeOfActionsBotWillHaveWithUsers: botVerificationDetails?.verificationDetails?.typeOfActionsBotWillHaveWithUsers
            ? botVerificationDetails.verificationDetails.typeOfActionsBotWillHaveWithUsers
            : "",
        respondp2aMessages: botVerificationDetails?.verificationDetails?.respondP2AMessages
            ? botVerificationDetails.verificationDetails.respondP2AMessages
            : "No",
        keywordsBotRecognizesAndResponds: botVerificationDetails?.verificationDetails?.keywordsBotRecognizesAndResponds
            ? botVerificationDetails.verificationDetails.keywordsBotRecognizesAndResponds
            : "",
        ensureConsent: botVerificationDetails?.verificationDetails?.ensureConsent ? botVerificationDetails.verificationDetails.ensureConsent : "",
        optOutKeywordsForBot: botVerificationDetails?.verificationDetails?.optOutKeywordsForBot
            ? botVerificationDetails.verificationDetails.optOutKeywordsForBot
            : "",
        messageBotSendWhenUserOptsOut: botVerificationDetails?.verificationDetails?.messageBotSendWhenUserOptsOut
            ? botVerificationDetails.verificationDetails.messageBotSendWhenUserOptsOut
            : "",
        keywordsUserSendToRevokeOptOut: botVerificationDetails?.verificationDetails?.keywordsUserSendToRevokeOptOut
            ? botVerificationDetails.verificationDetails.keywordsUserSendToRevokeOptOut
            : "",
        platformWhenUserRevokesOptout: botVerificationDetails?.verificationDetails?.platformWhenUserRevokesOptout
            ? botVerificationDetails.verificationDetails.platformWhenUserRevokesOptout
            : "Default",
        messageBotSendWhenUserRevokesOptout: botVerificationDetails?.verificationDetails?.messageBotSendWhenUserRevokesOptout
            ? botVerificationDetails.verificationDetails.messageBotSendWhenUserRevokesOptout
            : "",
        botAccessInstructions: botVerificationDetails?.verificationDetails?.botAccessInstructions
            ? botVerificationDetails.verificationDetails.botAccessInstructions
            : "",
        screenshots: botVerificationDetails?.verificationDetails?.screenshots ? botVerificationDetails.verificationDetails.screenshots : [],
        chatbotWebhook: botVerificationDetails?.verificationDetails?.chatbotWebhook
            ? botVerificationDetails.verificationDetails.chatbotWebhook
            : "https://rcsgateway.pinnacle.in:444/",
        videoUrls: botVerificationDetails?.verificationDetails?.videoUrl ? botVerificationDetails.verificationDetails.videoUrl : "",
        // Brand Detailsl
        brandName: brandDetails?.brandName ? brandDetails?.brandName : foundBot?.brandName || "loading..",
        // brandName: "BrandName",
        industryType: brandDetails?.industryType ? brandDetails?.industryType : "",
        officialBrandWebsite: brandDetails?.officialBrandWebsite ? brandDetails?.officialBrandWebsite : "",
        brandLogo: brandDetails?.brandLogo
            ? brandDetails?.brandLogo
            : {
                type: "image",
                url: null,
            },

        contactPersonFirstName: brandDetails?.contactPersonDetails?.firstName ? brandDetails?.contactPersonDetails?.firstName : "",
        contactPersonLastName: brandDetails?.contactPersonDetails?.lastName ? brandDetails?.contactPersonDetails?.lastName : "",
        contactPersonDesignation: brandDetails?.contactPersonDetails?.designation ? brandDetails?.contactPersonDetails?.designation : "",
        contactPersonEmailId: brandDetails?.contactPersonDetails?.emailId ? brandDetails?.contactPersonDetails?.emailId : "",
        contactPersonPhoneNumber: {
            number: brandDetails?.contactPersonDetails?.mobileNumber ? brandDetails?.contactPersonDetails?.mobileNumber : "",
            code: brandDetails?.contactPersonDetails?.countryCode ? brandDetails?.contactPersonDetails?.countryCode : "+91",
            country: brandDetails?.companyAddressDetails?.country ? brandDetails?.companyAddressDetails?.country : "India",
        },
        companyAddress: {
            addressLine1: brandDetails?.companyAddressDetails?.addressLine1 ? brandDetails?.companyAddressDetails?.addressLine1 : "",
            addressLine2: brandDetails?.companyAddressDetails?.addressLine2 ? brandDetails?.companyAddressDetails?.addressLine2 : "",
            country: brandDetails?.companyAddressDetails?.country ? brandDetails?.companyAddressDetails?.country : "India",
            state: brandDetails?.companyAddressDetails?.state ? brandDetails?.companyAddressDetails?.state : "Maharashtra",
            city: brandDetails?.companyAddressDetails?.city ? brandDetails?.companyAddressDetails?.city : "",
            zipCode: brandDetails?.companyAddressDetails?.zipCode ? brandDetails?.companyAddressDetails?.zipCode : "",
        },

        // Business Verification
        // verifyBusinessName: brandAndBusinessVerificationDetails?.businessVerificationDetails?.verifyBusinessName
        //     ? brandAndBusinessVerificationDetails?.businessVerificationDetails?.verifyBusinessName
        //     : [verifyBusinessNameObjectToAdd],
        // verifyBusinessAddress: brandAndBusinessVerificationDetails?.businessVerificationDetails?.verifyBusinessAddress
        //     ? brandAndBusinessVerificationDetails?.businessVerificationDetails?.verifyBusinessAddress
        //     : [businessAddressObjectToAdd],
        // termsAndConditions: brandAndBusinessVerificationDetails?.businessVerificationDetails?.termsAndConditions
        //     ? brandAndBusinessVerificationDetails?.businessVerificationDetails?.termsAndConditions
        //     : false,
        // agreeToPayVerificationFee: brandAndBusinessVerificationDetails?.businessVerificationDetails?.agreeToPayVerificationFee
        //     ? brandAndBusinessVerificationDetails?.businessVerificationDetails?.agreeToPayVerificationFee
        //     : false,
    };

    const formik = useFormik({
        initialValues,
        // validationSchema: Yup.object().shape({
        //     actionsToTriggersMessagesToUsers:
        //         Yup.string().required("Field is required!"),
        //     brandName: Yup.string().required("Brand Name is required"),
        //     screenshots: Yup.array().of(mediaSchema).required("screenshots are required"),
        // }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                const dataToSend = {
                    orgId: values.orgId,
                    botId: values.botId,
                    botVerificationDetails: {
                        actionsToTriggersMessagesToUsers: values.actionsToTriggersMessagesToUsers,
                        typeOfActionsBotWillHaveWithUsers: values.typeOfActionsBotWillHaveWithUsers,
                        respondp2aMessages: values.respondp2aMessages,
                        keywordsBotRecognizesAndResponds: values.keywordsBotRecognizesAndResponds,
                        messageBotSendWhenUserOptsOut: values.messageBotSendWhenUserOptsOut,

                        ensureConsent: values.ensureConsent,
                        optOutKeywordsForBot: values.optOutKeywordsForBot,
                        platformWhenUserRevokesOptout: values.platformWhenUserRevokesOptout,
                        messageBotSendWhenUserRevokesOptout: values.messageBotSendWhenUserRevokesOptout,

                        keywordsUserSendToRevokeOptOut: values.keywordsUserSendToRevokeOptOut,
                        botAccessInstructions: values.botAccessInstructions,
                        screenshots: values.screenshots,
                        chatbotWebhook: values.chatbotWebhook,
                        videoUrls: values.videoUrls,
                    },
                    brandVerificationDetails: {
                        brandName: values.brandName,
                        industryType: values.industryType,
                        officialBrandWebsite: values.officialBrandWebsite,
                        brandLogo: "",
                        contactPersonFirstName: values.contactPersonFirstName,
                        contactPersonLastName: values.contactPersonLastName,
                        contactPersonDesignation: values.contactPersonDesignation,
                        contactPersonEmailId: values.contactPersonEmailId,
                        contactPersonPhoneNumber: values.contactPersonPhoneNumber,
                        companyAddress: values.companyAddress,
                    },
                    businessVerificationDetails: {
                        verifyBusinessName: values.verifyBusinessName,
                        verifyBusinessAddress: values.verifyBusinessAddress,
                        termsAndConditions: values.termsAndConditions,
                        agreeToPayVerificationFee: values.agreeToPayVerificationFee,
                    },
                    paymentStatus: "pending",
                };

                // sending the imagesData + payload in formData with content-type as Multipart/Form-data
                let formDataToSend = new FormData();

                // adding both images in imageFormData to formDataToSend
                for (let fieldName in imageFormData) {
                    formDataToSend.append(fieldName, imageFormData[fieldName]);
                }

                for (let fieldName in screenshotFormData) {
                    formDataToSend.append(`screenshot[${fieldName}]`, screenshotFormData[fieldName]);
                }
                // also, adding botdata(payload) to formDataToSend
                formDataToSend.append("botVerificationData", JSON.stringify(dataToSend));

                // const respond = axios.post("",formDataToSend);

                dispatch(verifyBotThunk({ formDataToSend }));
            } catch (error) {
                const message = formik.errors.message || "Something went wrong";
                // setStatus({ success: false });
                // // setErrors({ submit: message });
                // setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        // verifyBotDetails();
        state.controls.showEditVerifyBotDetailsForm.isEditable && setBotDetailsEditable(true);
    }, []);


    return (
        <Card>
            <CardContent>
                <FormikStepper
                    initialValues={initialValues}
                    validateBotDetails={validateBotDetails}
                    validateBrandDetails={validateBrandDetails}
                    formik={formik}
                    validateBusinessVerification={validateBusinessVerification}
                    onSubmit={async values => {
                        formik.handleSubmit();
                    }}
                    botStatus={botVerificationDetails?.botStatus}
                // onSubmit={formik.handleSubmit}
                >
                    <FormikStep
                        label='Bot Details & Experience'
                    // validationSchema={object({
                    //   actionTriggers: Yup.string().required(
                    //     "Action triggers are required!"
                    //   ),
                    // })}
                    >
                        <BotDetailsAndExperienceComponent
                            formik={formik}
                            onDeleteScreenshot={onDeleteScreenshot}
                            fileRef={fileRef}
                            onChangeAddScreenshots={onChangeAddScreenshots}
                        />
                    </FormikStep>

                    {/* Step 2: Brand Details */}

                    <FormikStep label='Brand Details'>
                        <BrandDetails
                            formik={formik}
                            onDeleteImage={onDeleteImage}
                            fileRef={fileRef}
                            previewFile={previewFile}
                            onChangeSelectCountry={onChangeSelectCountry}
                            imageFormData={imageFormData}
                            brandAndBusinessVerificationDetails={brandAndBusinessVerificationDetails}
                        />
                    </FormikStep>

                    {/* Step 3: Business Verification */}
                    <FormikStep label='Business Verification'>
                        <BusinessVerification businessVerificationDetails={brandDetails.businessVerificationDetails} botDetailsEditable={false} />
                        {/* <BusinessVerification
                            addDocument={addDocument}
                            formik={formik}
                            handleChangeCheckBox={handleChangeCheckBox}
                            deleteDocument={deleteDocument}
                            onChangeBusinessAddressVerification={onChangeBusinessAddressVerification}
                            fileRef2={fileRef2}
                            fileRef1={fileRef1}
                            brandAndBusinessVerificationDetails={brandAndBusinessVerificationDetails}
                            onChangeAddDocumentForAddressVerification={onChangeAddDocumentForAddressVerification}
                            deleteDocumentForBusinessNameVerification={deleteDocumentForBusinessNameVerification}
                            addDocumentForBusinessNameVerification={addDocumentForBusinessNameVerification}
                            onChangeAddDocumentTypeForNameVerification={onChangeAddDocumentTypeForNameVerification}
                            onChangeSelectDocumentForBusinessNameVerification={onChangeSelectDocumentForBusinessNameVerification}
                        /> */}
                    </FormikStep>

                    {/* Step 4: Payment */}
                    <FormikStep label='Payment'>
                        <PaymentComponent isBrandNotVerified={brandDetails.brandStatus === BrandStatus.NotVerified} />
                    </FormikStep>
                </FormikStepper>
            </CardContent>
            {state.controls.verifyBotErrorMessage && (
                <Alert pt={5} pb={1} severity='warning'>
                    {state.controls.verifyBotErrorMessage}
                </Alert>
            )}
        </Card>
    );
}

export function FormikStep({ children }) {
    return <>{children}</>;
}

export function FormikStepper({
    children,
    validateBotDetails,
    validateBrandDetails,
    validateBusinessVerification,
    onSubmit,
    formik,
    botStatus,
    ...props
}) {
    const childrenArray = React.Children.toArray(children);
    const [step, setStep] = useState(0);
    const currentChild = childrenArray[step];
    const [completed, setCompleted] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");
    const state = useSelector(state => state.botManagementSlice);

    const dispatch = useDispatch();

    function isLastStep() {
        return step === childrenArray.length - 1;
    }

    const onClickCancelButton = () => {
        state.controls.showEditVerifyBotDetailsForm.isEditable
            ? dispatch(setEditViewVerifyBotDetailsForm({ status: false, botId: "" }))
            : dispatch(setShowVerifyBotForm({ status: false, botId: "" }));
    };

    const onClickNext = () => {
        switch (step) {
            case 0: {
                if (validateBotDetails()) {
                    setStep(1);
                }
                break;
            }
            case 1: {
                setStep(2);

                break;
            }

            case 2: {
                setStep(3);

                break;
            }

            case 3: {
                onSubmit();

                break;
            }

            default:
                break;
        }
    };

    const onClickUpdate = async () => {
        try {
            const dataToSend = {
                botId: formik.values.botId,
                botVerificationDetails: {
                    actionsToTriggersMessagesToUsers: formik.values.actionsToTriggersMessagesToUsers,
                    typeOfActionsBotWillHaveWithUsers: formik.values.typeOfActionsBotWillHaveWithUsers,
                    respondp2aMessages: formik.values.respondp2aMessages,
                    keywordsBotRecognizesAndResponds: formik.values.keywordsBotRecognizesAndResponds,
                    messageBotSendWhenUserOptsOut: formik.values.messageBotSendWhenUserOptsOut,

                    ensureConsent: formik.values.ensureConsent,
                    optOutKeywordsForBot: formik.values.optOutKeywordsForBot,
                    platformWhenUserRevokesOptout: formik.values.platformWhenUserRevokesOptout,
                    messageBotSendWhenUserRevokesOptout: formik.values.messageBotSendWhenUserRevokesOptout,

                    keywordsUserSendToRevokeOptOut: formik.values.keywordsUserSendToRevokeOptOut,
                    botAccessInstructions: formik.values.botAccessInstructions,
                    screenshots: formik.values.screenshots,
                    chatbotWebhook: formik.values.chatbotWebhook,
                    videoUrls: formik.values.videoUrls,
                },
                botStatus: botStatus,
            };
            const response = await apiGatewayAxiosInstance.post(`/bot_management/bot/update-verified-bot-data/`, { dataToSend });
            if (response.status === 200) {
                dispatch(setEditViewVerifyBotDetailsForm({ status: false, botId: "" }));
            }
        } catch (error) {
            let errorMessage = "Failed to update.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    return (
        <>
            <Formik
                {...props}
                validationSchema={currentChild.props.validationSchema}
                onSubmit={async (values, helpers) => {
                    if (isLastStep()) {
                        await props.onSubmit(values, helpers);
                        setCompleted(true);
                    } else {
                        setStep(s => s + 1);
                        // helpers.setTouched({});
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Stepper alternativeLabel activeStep={step}>
                            {childrenArray.map((child, index) => (
                                <Step key={child.props.label} completed={step > index || completed}>
                                    <StepLabel>{child.props.label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        {currentChild}

                        <Grid container justifyContent={"center"} spacing={5}>
                            {step > 0 ? (
                                <Grid item>
                                    <Button disabled={isSubmitting} variant='contained' color='primary' onClick={() => setStep(s => s - 1)}>
                                        {"<--"} Back
                                    </Button>
                                </Grid>
                            ) : null}
                            <Grid item display={"flex"} gap={3}>
                                {state.controls.showEditVerifyBotDetailsForm.isEditable && step == 3 && (
                                    <Button
                                        startIcon={isSubmitting ? <CircularProgress size='1rem' /> : null}
                                        disabled={isSubmitting}
                                        variant='contained'
                                        color={isLastStep ? "success" : `primary`}
                                        // onError={props.errors}
                                        // type="submit"
                                        onClick={() => onClickUpdate()}
                                    >
                                        Update
                                    </Button>
                                )}
                                {!(state.controls.showEditVerifyBotDetailsForm.isEditable && step == 3) && (
                                    <Button
                                        startIcon={isSubmitting ? <CircularProgress size='1rem' /> : null}
                                        disabled={isSubmitting}
                                        variant='contained'
                                        color={isLastStep ? "success" : `primary`}
                                        // onError={props.errors}
                                        // type="submit"
                                        onClick={() => onClickNext()}
                                    >
                                        {isSubmitting ? "Submitting" : isLastStep() ? "Submit" : "Next -->"}
                                    </Button>
                                )}
                                {/* <Grid item> */}
                                <Button variant='contained' color='error' onClick={onClickCancelButton}>
                                    Cancel
                                </Button>
                                {/* </Grid> */}
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>

        </>
    );
}

export function BotDetailsAndExperienceComponent({ formik, onDeleteScreenshot, fileRef, onChangeAddScreenshots }) {

    const { user } = useAuth();

    return (
        <>
            <Box paddingTop={5} display={"flex"} alignItems={"center"} className='text-primary'>
                <SmartToy />
                <Typography paddingLeft={2} variant='h5'>
                    Bot Details & Experience
                </Typography>
                <hr />
            </Box>
            {/* Bot Interactions */}
            <Box paddingY={5}>
                <Typography variant='h5' className='p-2'>
                    Bot Interactions
                </Typography>
                <hr />
                <Typography paddingBottom={3} className='text-muted'>
                    This section seeks details on how your bot interacts with users.
                </Typography>
                <FormControl fullWidth error={Boolean(formik.errors.actionsToTriggersMessagesToUsers)} className='py-2'>
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                            What actions trigger messages to users?
                            <span className='text-danger'>*</span>
                        </Typography>
                        <tooltip
                            title='When is the first message sent from the agent to the user? Are
                    messages sent at a consistent date/time, do user actions (such
                    as a purchase) trigger messages, or do external triggers (such
                    as package delivery) trigger messages?'
                            arrow
                            className='fw-bold rounded-circle text-light  bg-secondary px-2'
                        >
                            i
                        </tooltip>
                    </Box>
                    <TextField
                        className='mt-3'
                        name='actionsToTriggersMessagesToUsers'
                        fullWidth
                        label='Messages To Trigger Actions To Users'
                        varient='outlined'
                        error={Boolean(formik.errors.actionsToTriggersMessagesToUsers)}
                        helperText={formik.errors.actionsToTriggersMessagesToUsers}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        defaultValue={formik.values.actionsToTriggersMessagesToUsers}
                        multiline
                        rows={3}
                    />
                </FormControl>
                <FormControl fullWidth error={formik.errors.typeOfActionsBotWillHaveWithUsers ? true : false} className='py-2'>
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                            What types of interactions will the bot have with users?
                            <span className='text-danger'>*</span>
                        </Typography>
                        <tooltip
                            title="What are the bot's primary (those that occur most commonly)
                      and secondary (those that are possible but less common)
                      interactions? If the bot understands user messages, please
                      share examples of keywords it responds to."
                            arrow
                            className='fw-bold rounded-circle text-light  bg-secondary px-2'
                        >
                            i
                        </tooltip>
                    </Box>
                    <TextField
                        className='mt-3'
                        name='typeOfActionsBotWillHaveWithUsers'
                        fullWidth
                        label='Type of Actions Bot will have with Users'
                        varient='outlined'
                        error={Boolean(formik.errors.typeOfActionsBotWillHaveWithUsers)}
                        helperText={formik.errors.typeOfActionsBotWillHaveWithUsers}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        multiline
                        rows={3}
                        defaultValue={formik.values.typeOfActionsBotWillHaveWithUsers}
                    />
                </FormControl>
                <FormControl fullWidth error={formik.errors.respondp2aMessages ? true : false} className='py-2'>
                    <Typography id='respondp2aMessages' paddingY={1} variant={"h6"} fontWeight={"bold"}>
                        Does the bot support Conversational flow? Does it respond to P2A messages from users?
                        <span className='text-danger'>*</span>
                    </Typography>

                    <RadioGroup
                        aria-labelledby='respondp2aMessages'
                        name='respondp2aMessages'
                        id='respondp2aMessages'
                        error={Boolean(formik.errors.respondp2aMessages)}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        row
                        defaultValue={formik.values.respondp2aMessages}
                    >
                        <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                        <FormControlLabel value='No' control={<Radio />} label='No' />
                    </RadioGroup>
                </FormControl>
                {formik.values.respondp2aMessages === "Yes" && (
                    <FormControl fullWidth error={formik.errors.keywordsBotRecognizesAndResponds ? true : false} className='py-2'>
                        <Box display={"flex"} alignItems={"center"} gap={2}>
                            <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                                Please share a list of of some of the keywords that the bot recognizes and responds to.
                                <span className='text-danger'>*</span>
                            </Typography>
                            <tooltip
                                title='Some examples of keywords are: Menu, Help, Hi. (use comma
                        separated list, when adding multiple keywords).'
                                arrow
                                className='fw-bold rounded-circle text-light  bg-secondary px-2'
                            >
                                i
                            </tooltip>
                        </Box>
                        <TextField
                            className='mt-3'
                            name='keywordsBotRecognizesAndResponds'
                            fullWidth
                            label='Keywords Bot Recognizes and Responds'
                            varient='outlined'
                            error={Boolean(formik.errors.keywordsBotRecognizesAndResponds)}
                            helperText={formik.errors.keywordsBotRecognizesAndResponds}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            multiline
                            rows={3}
                            defaultValue={formik.values.keywordsBotRecognizesAndResponds}
                        />
                    </FormControl>
                )}
            </Box>

            {/* Consent Management */}

            <Box paddingY={5}>
                <Typography variant='h5' className='p-2'>
                    Consent Management
                </Typography>
                <hr />
                <FormControl fullWidth error={formik.errors.ensureConsent ? true : false} className='py-2'>
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                            How do you ensure consent, so that only the users who have opted-in will receive messages from your bot?
                            <span className='text-danger'>*</span>
                        </Typography>
                        <tooltip
                            title='Opt-in is critical for approving the bot launch. Provide details for how opt-in is acquired.
                    If opt-in is through a website or an app, please provide links.'
                            arrow
                            className='fw-bold rounded-circle text-light  bg-secondary px-2'
                        >
                            i
                        </tooltip>
                    </Box>
                    <TextField
                        className='mt-3'
                        name='ensureConsent'
                        fullWidth
                        label='Ensure Consent to Users Opted In will Receive Messages from Bot'
                        varient='outlined'
                        error={Boolean(formik.errors.ensureConsent)}
                        helperText={formik.errors.ensureConsent}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        multiline
                        rows={4}
                        defaultValue={formik.values.ensureConsent}
                    />
                    <Typography className='my-1 text-muted'>
                        All agents must comply with a user’s request to opt-out (e.g., “STOP” message or equivalent). This platform processes and
                        enforces opt-out requests. After a user opts-out, any A2P messages from the agent will be blocked by this platform.
                    </Typography>
                </FormControl>

                <FormControl fullWidth error={formik.errors.optOutKeywordsForBot ? true : false} className='py-2'>
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                            What are the opt-out keywords for the bot?
                            <span className='text-danger'>*</span>
                        </Typography>
                        <tooltip
                            title='Please note that your bot should also process the opt-out keywords and honor the user request.'
                            arrow
                            className='fw-bold rounded-circle text-light  bg-secondary px-2'
                        >
                            i
                        </tooltip>
                    </Box>
                    <Typography className='my-1 text-muted'>
                        By default, platform recognizes "STOP" as the opt-out keyword. If you would like to configure additional keywords for opt-out,
                        please specify below (use comma separated list, if configuring multiple additional keywords).
                    </Typography>
                    <TextField
                        className='mt-3'
                        name='optOutKeywordsForBot'
                        fullWidth
                        label='Opt Out Keywords'
                        varient='outlined'
                        error={Boolean(formik.errors.optOutKeywordsForBot)}
                        helperText={formik.errors.optOutKeywordsForBot}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        multiline
                        rows={3}
                        defaultValue={formik.values.optOutKeywordsForBot}
                    />
                </FormControl>
                <FormControl fullWidth error={formik.errors.messageBotSendWhenUserOptsOut ? true : false} className='py-2'>
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                            What message does the bot send when a user opts out?
                            <span className='text-danger'>*</span>
                        </Typography>
                        <tooltip
                            title='Please note that even if your bot send a message in response to the opt-out request, it will not be sent to users as the platform block any further A2P messages from the bot.'
                            arrow
                            className='fw-bold rounded-circle text-light  bg-secondary px-2'
                        >
                            i
                        </tooltip>
                    </Box>
                    <Typography className='mt-1 text-muted'>
                        Upon receiving an Opt-out request, the platform responds with a{" "}
                        <tooltip
                            className='text-primary'
                            title='Sorry to see you leave. You will no longer receive notifications from this agent on RCS. To start receiving notifications on RCS again, send ‘START’.'
                        >
                            'default message'
                        </tooltip>
                        . If you would like to change the response message for an opt-out request, please specify it below.
                    </Typography>
                    <TextField
                        className='mt-3'
                        name='messageBotSendWhenUserOptsOut'
                        fullWidth
                        label='Message User Opts Out'
                        varient='outlined'
                        error={Boolean(formik.errors.messageBotSendWhenUserOptsOut)}
                        helperText={formik.errors.messageBotSendWhenUserOptsOut}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        multiline
                        rows={3}
                        defaultValue={formik.values.messageBotSendWhenUserOptsOut}
                    />
                </FormControl>

                <FormControl fullWidth error={formik.errors.keywordsUserSendToRevokeOptOut ? true : false} className='py-2'>
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                            What keywords can the user send to revoke the opt-out and start receiving messages again?
                            <span className='text-danger'>*</span>
                        </Typography>
                        <tooltip
                            title='Please note that your bot should also process these keywords to cancel the opt-out from the user.'
                            arrow
                            className='fw-bold rounded-circle text-light  bg-secondary px-2'
                        >
                            i
                        </tooltip>
                    </Box>
                    <Typography className='mt-1 text-muted'>
                        A user who has opted out, may change their mind and cancel the opt-out request. The platform recognizes "START" as the keyword
                        to cancel an opt-out request. If you would like to configure additional keywords for cancelling an opt-out, please specify
                        below (use comma separated list, if configuring multiple additional keywords).
                    </Typography>
                    <TextField
                        className='mt-3'
                        name='keywordsUserSendToRevokeOptOut'
                        fullWidth
                        label='Keywords User Send'
                        varient='outlined'
                        error={Boolean(formik.errors.keywordsUserSendToRevokeOptOut)}
                        helperText={formik.errors.keywordsUserSendToRevokeOptOut}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        multiline
                        rows={3}
                        defaultValue={formik.values.keywordsUserSendToRevokeOptOut}
                    />
                </FormControl>

                <FormControl fullWidth error={formik.errors.platformWhenUserRevokesOptout ? true : false} className='py-2'>
                    <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                        Do you want the platform to handle when a user revokes opt-out?
                        <span className='text-danger'>*</span>
                    </Typography>

                    <Typography className='my-1 text-muted'>
                        When a user sends the keyword to cancel an earlier opt-out, they need to get a confirmation message. How do you want this
                        message to be sent?
                    </Typography>

                    <RadioGroup
                        aria-labelledby='platformWhenUserRevokesOptout'
                        name='platformWhenUserRevokesOptout'
                        id='platformWhenUserRevokesOptout'
                        error={Boolean(formik.errors.platformWhenUserRevokesOptout)}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        row
                        defaultValue={formik.values.platformWhenUserRevokesOptout}
                    >
                        <FormControlLabel value='Default' control={<Radio />} label='Default: Platform sends the Confirmation message' />
                        <FormControlLabel value='Custom' control={<Radio />} label='Custom: Bot (Aggregator) sends the Confirmation message' />
                    </RadioGroup>
                </FormControl>
                {formik.values?.platformWhenUserRevokesOptout === "Default" && (
                    <FormControl fullWidth error={formik.errors.messageBotSendWhenUserRevokesOptout ? true : false} className='py-2'>
                        <Box gap={2}>
                            <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                                What message does the bot send when a user revokes opt-out?
                                <span className='text-danger'>*</span>
                            </Typography>
                            <Typography className='my-1 text-muyted'>
                                By default, the platform will send the following confirmation message: "Thanks for your request. You will start
                                receiving RCS messages again from the Test Bot". If you would like to change this message, please specify it here:
                            </Typography>
                        </Box>
                        <TextField
                            className='mt-3'
                            name='messageBotSendWhenUserRevokesOptout'
                            fullWidth
                            label='Message Bot Send When User Revokes Opt out'
                            varient='outlined'
                            error={Boolean(formik.errors.messageBotSendWhenUserRevokesOptout)}
                            helperText={formik.errors.messageBotSendWhenUserRevokesOptout}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            multiline
                            rows={3}
                            defaultValue={formik.values.messageBotSendWhenUserRevokesOptout}
                        />
                    </FormControl>
                )}
            </Box>

            {/* Bot Interactions */}
            <Box paddingY={5}>
                <Typography variant='h5' className='p-2'>
                    Agent Preview
                </Typography>
                <hr />
                <Typography marginY={3} className='text-muted'>
                    The RCS Business Messaging team needs to review your agent before it launches. Reviewers can test your agent with their own
                    devices, or you can provide a video showing core functionality and functioning opt-out capabilities.
                </Typography>
                <FormControl fullWidth error={formik.errors.botAccessInstructions ? true : false} className='py-2'>
                    <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                        Bot Access Instructions
                        <span className='text-danger'>*</span>
                    </Typography>
                    <Typography className='my-1 text-muted'>
                        To test the bot, please specify a wakeup/trigger phrase (e.g. "START"or "Hi"). When received, the bot will start the
                        conversation with the user. This method would be used by reviewers to trigger the bot, such as a website where reviewers can
                        invite and add phone numbers. If you need a list of phone numbers to add as test devices, contact{" "}
                        <a className='ml-2' href='mailto:rbm-support@virbm.in'>
                            rbm-support@virbm.in
                        </a>
                    </Typography>
                    <TextField
                        className='mt-3'
                        name='botAccessInstructions'
                        fullWidth
                        label='Bot Access Instructions'
                        varient='outlined'
                        error={Boolean(formik.errors.botAccessInstructions)}
                        helperText={formik.errors.botAccessInstructions}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        multiline
                        rows={3}
                        defaultValue={formik.values.botAccessInstructions}
                    />
                </FormControl>
                {user?.roleName === ERoles.SUPER_ADMIN &&
                    <FormControl fullWidth error={formik.errors.chatbotWebhook ? true : false} className='py-2'>
                        <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                            Chatbot Webhook
                            <span className='text-danger'>*</span>
                        </Typography>
                        <Typography className='my-1 text-muted'>
                            Enter the webhook that your bot will receive messages from the RBM agent. NOTE: The webhook needs to be active and be able to
                            respond with a 200 OK to POST requests.
                        </Typography>
                        <TextField
                            disabled
                            className='mt-3'
                            name='chatbotWebhook'
                            fullWidth
                            label='Chatbot Webhook'
                            varient='outlined'
                            error={Boolean(formik.errors.chatbotWebhook)}
                            helperText={formik.errors.chatbotWebhook}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            multiline
                            defaultValue={"https://rcsgateway.pinnacle.in:444/"}
                        />
                    </FormControl>
                }
                <FormControl fullWidth error={formik.errors.screenshots ? true : false} className='py-2'>
                    <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                        Screenshots
                        <span className='text-danger'>*</span>
                    </Typography>
                    <Typography className='my-1 text-muted'>
                        Screenshots give your agent better visibility and make reviews easier. You can add multiple Images/Media here. (only jpg, jpeg
                        and png files allowed)
                    </Typography>
                    <Box sx={{ maxWidth: "100%", display: "flex", alignItems: "start", flexDirection: "column" }}>
                        {/* <Box sx={{ display: "flex", alignItems: "center", maxWidth: "800px", margin: "5px", justifyContent: "center" }}> */}
                        <Box className='p-2 border rounded' sx={{ display: "flex", margin: "10px", maxWidth: "1100px", overflowX: "scroll" }}>
                            {formik.values?.screenshots?.map((screenshot, index) => (
                                <Card style={{ padding: "0px", marginBottom: "10px", width: "220px", minWidth: "220px" }}>
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                            paddingLeft: "10px",
                                            paddingRight: "0px",
                                        }}
                                    >
                                        <Grid sx={{ alignItems: "center", textAlign: "end" }}>
                                            <IconButton size='large' style={{ margin: "0px" }} onClick={() => onDeleteScreenshot(index)}>
                                                <ClearIcon className='text-danger ' />
                                            </IconButton>
                                        </Grid>

                                        <Grid className='p-1 border'>
                                            <img height={"200px"} width={"200px"} src={screenshot?.url} style={{ objectFit: "contain" }} alt='' />
                                        </Grid>
                                    </CardContent>
                                    {/* <FilePreviewComponent
                      state={formik.values}
                      url={screenshot?.url}
                      type={"image"}
                      width={"100px"}
                      height={"100px"}
                      formikImage={screenshot}
                      deleteFile={() => {

                        onDeleteScreenshot(index);

                      }
                      }
                    /> */}
                                </Card>
                            ))}
                        </Box>
                        {/* </Box> */}

                        {formik.values?.screenshots?.length < 8 && (
                            <Button
                                variant='outlined'
                                sx={{ width: "250px" }}
                                onClick={() => fileRef.current.click()}
                                error={true}
                                helperText={formik.errors.screenshots}
                            >
                                Choose
                            </Button>
                        )}
                        <FormHelperText>{formik.errors.screenshots}</FormHelperText>

                        <Box className='btn-container'>
                            <input
                                name='screenshots'
                                ref={fileRef}
                                accept='image/*'
                                multiple
                                onChange={e =>
                                    onChangeAddScreenshots({
                                        length: e.target.files.length,
                                        name: "screenshots",
                                        value: e.target.files,
                                    })
                                }
                                onClick={e => {
                                    e.target.value = "";
                                }}
                                type='file'
                                hidden
                            />
                        </Box>
                    </Box>
                </FormControl>

                <FormControl fullWidth error={formik.errors.videoUrls ? true : false} className='py-2'>
                    <Typography paddingY={1} variant={"h6"} fontWeight={"bold"}>
                        Video URLs
                        <span className='text-danger'>*</span>
                    </Typography>
                    <Typography className='my-1 text-muted'>
                        If you prefer to review your agent via video, provide
                        <span className='fw-bold'> comma-separated URLs</span> for a video showing your agent's core functionality and opt-out
                        capabilities. URLs must be publicly accessible.
                    </Typography>
                    <TextField
                        className='mt-3'
                        name='videoUrls'
                        fullWidth
                        rows={3}
                        label='Bot Video Urls'
                        varient='outlined'
                        error={Boolean(formik.errors.videoUrls)}
                        helperText={formik.errors.videoUrls}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        multiline
                        defaultValue={formik.values.videoUrls}
                    />
                </FormControl>
            </Box>
        </>
    );
}

export function BrandDetails({
    formik,
    onDeleteImage,
    fileRef,
    previewFile,
    onChangeSelectCountry,
    imageFormData,
    brandAndBusinessVerificationDetails,
}) {
    return (
        <>
            <Box paddingTop={10}>
                <Typography variant='h2'>Brand Details</Typography>
                <Typography className='my-2 text-muted'>
                    The following information will be required as part of your business verification. Please ensure that the information you share
                    with us is complete and accurate.
                </Typography>
                <hr />
            </Box>
            <Box>
                <Typography variant='h5' className='p-2 mb-3'>
                    Brand Information
                </Typography>
                <Box paddingBottom={2} display={"flex"} gap={5}>
                    <FormControl error={Boolean(formik.errors.brandName)} className='w-50'>
                        <Typography variant='h6' fontWeight={"bold"}>
                            Brand Name <span className='text-danger'>*</span>
                        </Typography>
                        <Typography className='text-muted mb-3'>Select a brand name from the list</Typography>
                        <TextField
                            disabled
                            label='Brand Name'
                            className='fw-bold'
                            name='brandName'
                            fullWidth
                            varient='outlined'
                            error={Boolean(formik.errors.brandName)}
                            helperText={formik.errors.brandName}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            defaultValue={formik.values.brandName}
                        />
                    </FormControl>
                    <FormControl className='w-50' error={Boolean(formik.errors.industryType)}>
                        <Typography variant='h6' fontWeight={"bold"}>
                            Industry Type <span className='text-danger'>*</span>
                        </Typography>
                        <Typography className='text-muted mb-3'>Select a Industry Type from the list</Typography>
                        <Select
                            // MenuProps={{
                            //     PaperProps: {
                            //         sx: {
                            //             boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                            //         },
                            //     },
                            // }}
                            name='industryType'
                            label='Industry Type'
                            id='industryType'
                            error={Boolean(formik.errors.industryType)}
                            helperText={formik.errors.industryType}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            defaultValue={formik.values.industryType}
                            disabled={true}
                        >
                            {[
                                "Advertising/marketing",
                                "Arts & entertainment",
                                "Commercial and industrial",
                                "Community organisation",
                                "Education",
                                "Finance",
                                "Government",
                                "Hotel and B&B",
                                "Legal",
                                "Local service",
                                "Media/ news company",
                                "Non-governmental organisation (NGO)",
                                "Non-profit organisation",
                                "Not Available",
                                "Other",
                                "Property",
                                "Science, technology and engineering",
                                "Shopping & retail",
                                "Sport & recreation",
                                "Telecom",
                            ].map((industryType, index) => (
                                <MenuItem key={index} value={industryType}>
                                    {industryType}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box paddingBottom={2} display={"flex"} gap={5}>
                    <FormControl error={Boolean(formik.errors.officialBrandWebsite)} className='w-50'>
                        <Typography variant='h6' fontWeight={"bold"}>
                            Official Brand Website <span className='text-danger'>*</span>
                        </Typography>
                        <TextField
                            className='mt-3'
                            type='url'
                            name='officialBrandWebsite'
                            fullWidth
                            label='Official Brand Website'
                            varient='outlined'
                            error={Boolean(formik.errors.officialBrandWebsite)}
                            disabled={true}
                            // value={formik.values.officialBrandWebsite}
                            helperText={formik.errors.officialBrandWebsite}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            defaultValue={formik.values.officialBrandWebsite}
                        />
                    </FormControl>
                    <FormControl error={Boolean(formik.errors.brandLogo)} className='w-50'>
                        <Typography variant='h6' fontWeight={"bold"}>
                            Brand Logo
                        </Typography>
                        <Typography className='text-muted mb-3'>
                            Provide a logo for your bot that will be displayed in connection with the bot's name.
                        </Typography>
                        <Grid sx={{ maxWidth: "200px", display: "flex" }}>
                            <FilePreviewComponent
                                // url={formik.values.brandLogo.url}
                                formikImage={formik.values.brandLogo}
                                aspect={1 / 1}
                                imageHeightRatio={1}
                                imageWidthtRatio={1}
                                isDisabled={true}
                                deleteFile={() => onDeleteImage("brandLogo.url", "brandLogo.type")}
                                type={formik.values.brandLogo.type}
                                width={"240px"}
                                height={"240px"}
                            />
                        </Grid>

                        {formik.values.brandLogo.url === null && (
                            <Button variant='outlined' sx={{ width: "250px" }} onClick={() => fileRef.current.click()}>
                                Choose
                            </Button>
                        )}
                        <FormHelperText>{formik.errors?.brandLogo?.url}</FormHelperText>

                        <Box className='btn-container'>
                            <input
                                name='brandLogo'
                                ref={fileRef}
                                accept='image/*'
                                onChange={e => previewFile(e.target.files[0], "brandLogo.url", "brandLogo.type")}
                                type='file'
                                hidden
                            />
                        </Box>
                    </FormControl>
                </Box>

                <Box paddingBottom={2} display={"flex"} flexDirection={"column"} gap={3}>
                    <Typography variant='h5' className=' p-2 mb-1'>
                        Contact Person
                    </Typography>
                    <Box display={"flex"} gap={5}>
                        <FormControl error={Boolean(formik.errors.contactPersonFirstName)} fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Contact Person First Name <span className='text-danger'>*</span>
                            </Typography>
                            <TextField
                                disabled={true}
                                className='mt-2'
                                name='contactPersonFirstName'
                                fullWidth
                                label='Contact Person First Name'
                                varient='outlined'
                                error={Boolean(formik.errors.contactPersonFirstName)}
                                helperText={formik.errors.contactPersonFirstName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.contactPersonFirstName}
                            />
                        </FormControl>

                        <FormControl fullWidth error={Boolean(formik.errors.contactPersonLastName)}>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Contact Person Last Name <span className='text-danger'>*</span>
                            </Typography>
                            <TextField
                                className='mt-2'
                                name='contactPersonLastName'
                                fullWidth
                                label='Contact Person Last Name'
                                varient='outlined'
                                error={Boolean(formik.errors.contactPersonLastName)}
                                disabled={true}
                                helperText={formik.errors.contactPersonLastName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.contactPersonLastName}
                            />
                        </FormControl>
                    </Box>

                    <Box display={"flex"} gap={5}>
                        <FormControl error={Boolean(formik.errors.contactPersonDesignation)} fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Contact Person Designation <span className='text-danger'>*</span>
                            </Typography>
                            <TextField
                                className='mt-2'
                                name='contactPersonDesignation'
                                fullWidth
                                label='Company Person Designation'
                                varient='outlined'
                                disabled={true}
                                error={Boolean(formik.errors.contactPersonDesignation)}
                                helperText={formik.errors.contactPersonDesignation}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.contactPersonDesignation}
                            />
                        </FormControl>

                        <FormControl fullWidth error={Boolean(formik.errors.contactPersonEmailId)}>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Contact Person Email Id <span className='text-danger'>*</span>
                            </Typography>
                            <TextField
                                className='mt-2'
                                type='email'
                                name='contactPersonEmailId'
                                fullWidth
                                disabled={true}
                                label='Contact Person Email Id'
                                varient='outlined'
                                error={Boolean(formik.errors.contactPersonEmailId)}
                                helperText={formik.errors.contactPersonEmailId}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.contactPersonEmailId}
                            />
                        </FormControl>
                    </Box>
                    <FormControl
                        // error={
                        //     Boolean(
                        //         formik.errors.contactPersonPhoneNumber)
                        // }
                        fullWidth
                    >
                        <Typography variant='h6' mb={3} fontWeight={"bold"}>
                            Contact Person Phone Number <span className='text-danger'>*</span>
                        </Typography>

                        <Box display={"flex"} gap={5}>
                            <Grid container>
                                <Grid item md={2} paddingInline={2}>
                                    <Select
                                        fullWidth
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                },
                                            },
                                        }}
                                        disabled={true}
                                        name='contactPersonPhoneNumber.country'
                                        label='Country'
                                        id='country'
                                        onBlur={formik.handleBlur}
                                        onChange={e =>
                                            onChangeSelectCountry({
                                                value: e.target.value,
                                                name: e.target.name,
                                            })
                                        }
                                        defaultValue={formik.values.contactPersonPhoneNumber.country}
                                    >
                                        {countryCode?.map((country, index) => (
                                            <MenuItem key={index} value={country.name}>
                                                {country.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item md={1} paddingInline={2}>
                                    <TextField
                                        name='contactPersonPhoneNumber.code'
                                        label={"Code"}
                                        error={Boolean(formik.errors.contactPersonPhoneNumber && formik.errors.contactPersonPhoneNumber?.code)}
                                        disabled={true}
                                        helperText={formik.errors.contactPersonPhoneNumber && formik.errors.contactPersonPhoneNumber?.code}
                                        value={formik.values.contactPersonPhoneNumber?.code}
                                        onBlur={formik.handleBlur}
                                    />
                                </Grid>
                                <Grid item md={3} paddingInline={2}>
                                    <TextField
                                        name='contactPersonPhoneNumber.number'
                                        label={`Contact Person Phone Number`}
                                        error={Boolean(formik.errors?.contactPersonPhoneNumber && formik.errors?.contactPersonPhoneNumber?.number)}
                                        disabled={true}
                                        helperText={formik.errors?.contactPersonPhoneNumber && formik.errors?.contactPersonPhoneNumber?.number}
                                        defaultValue={formik.values.contactPersonPhoneNumber?.number}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </FormControl>
                </Box>

                <Box paddingBottom={7} display={"flex"} flexDirection={"column"} gap={3}>
                    <Typography variant='h3' className='p-2 my-1'>
                        Company Address
                    </Typography>
                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Address Line 1 <span className='text-danger'>*</span>
                            </Typography>
                            <TextField
                                disabled={true}
                                className='mt-2'
                                name='companyAddress.addressLine1'
                                fullWidth
                                label='Address Line 1'
                                varient='outlined'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.addressLine1}
                                error={Boolean(formik.errors?.companyAddress?.addressLine1)}
                                helperText={formik.errors?.companyAddress?.addressLine1}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Address Line 2 <span className='text-danger'>*</span>
                            </Typography>
                            <TextField
                                disabled={true}
                                className='mt-2'
                                name='companyAddress.addressLine2'
                                fullWidth
                                label='Address Line 2'
                                varient='outlined'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.addressLine2}
                                error={Boolean(formik.errors?.companyAddress?.addressLine2)}
                                helperText={formik.errors?.companyAddress?.addressLine2}
                            />
                        </FormControl>
                    </Box>

                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth error={Boolean(formik.errors?.companyAddress?.country)}>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Country <span className='text-danger'>*</span>
                            </Typography>
                            <Select
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                        },
                                    },
                                }}
                                disabled={true}
                                name='companyAddress.country'
                                label='Country'
                                id='companyAddress.country'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.country}
                                className='mt-2'
                                error={Boolean(formik.errors?.companyAddress?.country)}
                                helperText={formik.errors?.companyAddress?.country}
                            >
                                {countryCode?.map((country, index) => (
                                    <MenuItem key={index} value={country.name}>
                                        {country.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth error={Boolean(formik.errors?.companyAddress?.state)}>
                            <Typography variant='h6' fontWeight={"bold"}>
                                State <span className='text-danger'>*</span>
                            </Typography>
                            <TextField
                                className='mt-2'
                                name='companyAddress.state'
                                disabled={true}
                                fullWidth
                                label='State'
                                varient='outlined'
                                error={Boolean(formik.errors?.companyAddress?.state)}
                                helperText={formik.errors?.companyAddress?.state}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.state}
                            />
                        </FormControl>
                    </Box>

                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth error={Boolean(formik.errors?.companyAddress?.city)}>
                            <Typography variant='h6' fontWeight={"bold"}>
                                City <span className='text-danger'>*</span>
                            </Typography>
                            <TextField
                                disabled={true}
                                className='mt-2'
                                name='companyAddress.city'
                                fullWidth
                                label='City'
                                varient='outlined'
                                error={Boolean(formik.errors?.companyAddress?.city)}
                                helperText={formik.errors?.companyAddress?.city}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.city}
                            />
                        </FormControl>

                        <FormControl fullWidth error={Boolean(formik.errors?.companyAddress?.zipCode)}>
                            <Typography variant='h6' fontWeight={"bold"}>
                                Zip Code <span className='text-danger'>*</span>
                            </Typography>
                            <TextField
                                disabled={true}
                                className='mt-2'
                                name='companyAddress.zipCode'
                                fullWidth
                                label='Zip Code'
                                varient='outlined'
                                error={Boolean(formik.errors?.companyAddress?.zipCode)}
                                helperText={formik.errors?.companyAddress?.zipCode}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.zipCode}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

// export function BusinessVerification({
//     addDocument,
//     formik,
//     handleChangeCheckBox,
//     deleteDocument,
//     onChangeBusinessAddressVerification,
//     fileRef2,
//     brandAndBusinessVerificationDetails,
//     fileRef1,
//     onChangeAddDocumentForAddressVerification,
//     deleteDocumentForBusinessNameVerification,
//     addDocumentForBusinessNameVerification,
//     onChangeAddDocumentTypeForNameVerification,
//     onChangeSelectDocumentForBusinessNameVerification,
// }) {
//     const DocumentList = [
//         "PAN card of Company",
//         "GST document",
//         "CST / VAT /Service Tax Certificate or letter",
//         "Certificate of incorporation given by Ministry of Corporate Affairs",
//         "Memorandum of Association / Articles of Association",
//         "Bank account details",
//         "Address proof of principal place of business",
//         "Property tax receipt",
//         "Municipal Khata copy",
//         "Electricity bill copy",
//         "Ownership deed/document (owned property)",
//         "Lease / rent agreement ( leased / rented property)",
//         "Consent letter / NOC from the owner (in case of consent arrangement or shared property)",
//         "Company Registration Proof",
//         "Company Brand Registration Proof",
//         "Other",
//     ];

//     return (
//         <>
//             <Box paddingTop={10}>
//                 <Typography variant='h2'>Business Verification</Typography>
//                 <hr />
//             </Box>
//             <Box marginTop={2}>
//                 <Box>
//                     <Typography variant='h6' fontWeight={"bold"}>
//                         Verify the Legal Business Name
//                     </Typography>
//                     <Typography className='my-2 text-muted w-75'>
//                         Please provide official documentation of your business’s legal name. Upload an official document that matches the business’s
//                         legal name such as a business license,article of incorporation or business tax registration.
//                     </Typography>
//                     <Box className='d-flex item-center justify-center flex-column'>
//                         {formik.values.verifyBusinessName?.map((business, index) => (
//                             <Box key={`${business.documentType}-${index}`} display={"flex"} gap={2} className='mt-3'>
//                                 <FormControl className='w-50'>
//                                     <Typography className='fw-semibold mb-2'>Document Type</Typography>
//                                     <Select
//                                         disabled={brandAndBusinessVerificationDetails}
//                                         MenuProps={{
//                                             PaperProps: {
//                                                 sx: {
//                                                     boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
//                                                 },
//                                             },
//                                         }}
//                                         name='documentType'
//                                         label='Document Type'
//                                         error={Boolean(formik.errors.verifyBusinessName && formik.errors?.verifyBusinessName[index]?.documentType)}
//                                         helperText={formik.errors.verifyBusinessName && formik.errors?.verifyBusinessName[index]?.documentType}
//                                         onBlur={formik.handleBlur}
//                                         onChange={e =>
//                                             onChangeAddDocumentTypeForNameVerification({
//                                                 index,
//                                                 value: e.target.value,
//                                                 name: e.target.name,
//                                             })
//                                         }
//                                         defaultValue={business.documentType}
//                                     >
//                                         {DocumentList.map((documentType, index) => (
//                                             <MenuItem key={index} value={documentType}>
//                                                 {documentType}
//                                             </MenuItem>
//                                         ))}
//                                     </Select>
//                                     <FormHelperText style={{ color: "#E90E0EDE" }}>
//                                         {formik.errors.verifyBusinessName ? formik.errors?.verifyBusinessName[index]?.documentType : ""}
//                                     </FormHelperText>
//                                 </FormControl>

//                                 <FormControl className='w-50'>
//                                     <Typography className='fw-semibold mb-2'>Select File</Typography>
//                                     {formik.values.verifyBusinessName[index]?.selectedFile?.url === "" ? (
//                                         <>
//                                             <Button
//                                                 variant='outlined'
//                                                 sx={{ width: "250px" }}
//                                                 onClick={() => document.getElementById(`verifyBusinessName_${index}`).click()}
//                                             >
//                                                 Choose
//                                                 {/* <input

//                                                 type="file"
//                                                 hidden
//                                                 defaultValue={business.url}
//                                             /> */}
//                                                 <input
//                                                     name='selectedFile'
//                                                     id={`verifyBusinessName_${index}`}
//                                                     ref={fileRef1}
//                                                     accept='.docx, .pdf'
//                                                     multiple
//                                                     onChange={e =>
//                                                         onChangeSelectDocumentForBusinessNameVerification({
//                                                             index,
//                                                             // e
//                                                             name: e.target.name,
//                                                             value: e.target.files[0],
//                                                         })
//                                                     }
//                                                     type='file'
//                                                     hidden
//                                                 />
//                                             </Button>
//                                             <FormHelperText sx={{ color: "#E90E0EDE" }}>
//                                                 {formik.errors?.verifyBusinessName ? formik.errors?.verifyBusinessName[index]?.selectedFile?.url : ""}
//                                             </FormHelperText>
//                                         </>
//                                     ) : (
//                                         <Chip
//                                             label={formik.values.verifyBusinessName[index]?.selectedFile.url.name || ""}
//                                             onDelete={() => formik.setFieldValue(`verifyBusinessName[${index}].selectedFile.url`, "", true)}
//                                         />
//                                     )}
//                                     <Box className='btn-container'>
//                                         {formik.values.verifyBusinessName.length > 1 && (
//                                             <Button
//                                                 variant='outlined'
//                                                 type='button'
//                                                 onClick={() => deleteDocumentForBusinessNameVerification(index)}
//                                                 style={{ marginRight: 10, marginTop: 10 }}
//                                             >
//                                                 Delete Document
//                                             </Button>
//                                         )}
//                                     </Box>
//                                 </FormControl>
//                             </Box>
//                         ))}
//                         <Box className='d-flex gap-5'>
//                             {formik.values.verifyBusinessName.length < 5 && (
//                                 <Button
//                                     type='button'
//                                     variant='outlined'
//                                     onClick={addDocumentForBusinessNameVerification}
//                                     style={{ marginRight: 10, marginTop: 10 }}
//                                 >
//                                     Add Document
//                                 </Button>
//                             )}
//                             {/* {formik.values.verifyBusinessName.length > 1 && (
//                                 <Button
//                                     variant="outlined"
//                                     type="button"
//                                     onClick={() =>
//                                         deleteDocumentForBusinessNameVerification(
//                                             formik.values.verifyBusinessName.length - 1
//                                         )
//                                     }
//                                     style={{ marginRight: 10, marginTop: 10 }}
//                                 >
//                                     Delete Document
//                                 </Button>
//                             )} */}
//                         </Box>
//                     </Box>
//                 </Box>
//                 {/* Verify the Business Address */}
//                 <Box marginY={5}>
//                     <Typography variant='h6' fontWeight={"bold"}>
//                         Verify the Business Address
//                     </Typography>
//                     <Typography className='mt-2 text-muted w-75'>
//                         Upload a document that shows both the legal name of your business and the mailing address entered while filling up brand
//                         details.
//                     </Typography>
//                     <Box className='d-flex item-center justify-center flex-column'>
//                         {formik.values.verifyBusinessAddress.map((business, index) => (
//                             <Box key={index} display={"flex"} gap={2} className='mt-3'>
//                                 <FormControl className='w-50'>
//                                     <Typography className='fw-semibold mb-2'>Document Type</Typography>
//                                     <Select
//                                         disabled={brandAndBusinessVerificationDetails}
//                                         MenuProps={{
//                                             PaperProps: {
//                                                 sx: {
//                                                     boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
//                                                 },
//                                             },
//                                         }}
//                                         name='documentType'
//                                         label='Document Type'
//                                         error={Boolean(
//                                             formik.errors?.verifyBusinessAddress && formik.errors?.verifyBusinessAddress[index]?.documentType,
//                                         )}
//                                         helperText={formik.errors?.verifyBusinessAddress && formik.errors?.verifyBusinessAddress[index]?.documentType}
//                                         onBlur={formik.handleBlur}
//                                         onChange={e =>
//                                             onChangeAddDocumentForAddressVerification({
//                                                 index,
//                                                 value: e.target.value,
//                                                 name: e.target.name,
//                                             })
//                                         }
//                                         defaultValue={business.documentType}
//                                     >
//                                         {DocumentList.map((documentType, index) => (
//                                             <MenuItem key={index} value={documentType}>
//                                                 {documentType}
//                                             </MenuItem>
//                                         ))}
//                                     </Select>
//                                     <FormHelperText sx={{ color: "#E90E0EDE" }}>
//                                         {formik.errors?.verifyBusinessAddress ? formik.errors?.verifyBusinessAddress[index]?.documentType : ""}
//                                     </FormHelperText>
//                                 </FormControl>

//                                 <FormControl className='w-50'>
//                                     <Typography className='fw-semibold mb-2'>Select File</Typography>
//                                     {formik.values.verifyBusinessAddress[index]?.selectedFile?.url === "" ? (
//                                         <>
//                                             <Button
//                                                 variant='outlined'
//                                                 sx={{ width: "250px" }}
//                                                 // onClick={() => fileRef2.current.click()}

//                                                 onClick={() => document.getElementById(`verifyBusinessAddress${index}`).click()}
//                                             >
//                                                 Choose
//                                                 <input
//                                                     name='selectedFile'
//                                                     id={`verifyBusinessAddress${index}`}
//                                                     mul
//                                                     ref={fileRef2}
//                                                     accept='.docx, .pdf'
//                                                     onChange={e =>
//                                                         onChangeBusinessAddressVerification({
//                                                             index,
//                                                             name: e.target.name,
//                                                             value: e.target.files[0],
//                                                         })
//                                                     }
//                                                     type='file'
//                                                     hidden
//                                                 />
//                                             </Button>
//                                             <FormHelperText sx={{ color: "#E90E0EDE" }}>
//                                                 {formik.errors?.verifyBusinessAddress
//                                                     ? formik.errors?.verifyBusinessAddress[index]?.selectedFile?.url
//                                                     : ""}
//                                             </FormHelperText>
//                                         </>
//                                     ) : (
//                                         <Chip
//                                             label={formik.values.verifyBusinessAddress[index]?.selectedFile.url.name || ""}
//                                             onDelete={() => formik.setFieldValue(`verifyBusinessAddress[${index}].selectedFile.url`, null, true)}
//                                         />
//                                     )}
//                                     <Box className='btn-container'>
//                                         {formik.values.verifyBusinessAddress.length > 1 && (
//                                             <Button
//                                                 variant='outlined'
//                                                 type='button'
//                                                 onClick={() => deleteDocument(index)}
//                                                 style={{ marginRight: 10, marginTop: 10 }}
//                                             >
//                                                 Delete Document
//                                             </Button>
//                                         )}
//                                     </Box>
//                                 </FormControl>
//                             </Box>
//                         ))}
//                         <Box className='d-flex gap-5'>
//                             {formik.values.verifyBusinessAddress.length < 5 && (
//                                 <Button type='button' variant='outlined' onClick={addDocument} style={{ marginRight: 10, marginTop: 10 }}>
//                                     Add Document
//                                 </Button>
//                             )}
//                             {/* {formik.values.verifyBusinessAddress.length > 1 && (
//                                 <Button
//                                     variant="outlined"
//                                     type="button"
//                                     onClick={() =>
//                                         deleteDocument(
//                                             formik.values.verifyBusinessAddress.length - 1
//                                         )
//                                     }
//                                     style={{ marginRight: 10, marginTop: 10 }}
//                                 >
//                                     Delete Document
//                                 </Button>
//                             )} */}
//                         </Box>
//                     </Box>
//                 </Box>

//                 <Box marginY={5}>
//                     <Box marginY={2}>
//                         <FormControlLabel
//                             component='legend'
//                             // color={Boolean(formik.errors.termsAndConditions) ? "red":"yellow"}
//                             control={
//                                 <Checkbox
//                                     disabled={brandAndBusinessVerificationDetails}
//                                     checked={formik.values.termsAndConditions}
//                                     onChange={handleChangeCheckBox}
//                                     name='termsAndConditions'
//                                     size={"small"}
//                                     error={Boolean(formik.errors.termsAndConditions)}
//                                 />
//                             }
//                             label='I accept Terms and Conditions'
//                         />
//                         <FormHelperText sx={{ color: "#E90E0EDE" }}>{formik.errors.termsAndConditions}</FormHelperText>
//                     </Box>
//                     <Box>
//                         <FormControlLabel
//                             control={
//                                 <Checkbox
//                                     disabled={brandAndBusinessVerificationDetails}
//                                     checked={formik.values.agreeToPayVerificationFee}
//                                     onChange={handleChangeCheckBox}
//                                     name='agreeToPayVerificationFee'
//                                     size={"small"}
//                                 />
//                             }
//                             label='I agree to Pay an amount of 5000 to Pinnacle for Business Verification'
//                         />
//                         <FormHelperText sx={{ color: "#E90E0EDE" }}>{formik.errors.agreeToPayVerificationFee}</FormHelperText>
//                     </Box>
//                 </Box>
//             </Box>
//         </>
//     );
// }

export function PaymentComponent({ isBrandNotVerified }) {
    return (
        <>
            <Box paddingY={10}>
                <Typography variant='h2' className='p-2'>
                    Summary
                </Typography>
                <hr />
            </Box>
            <Box className='mx-auto d-flex justify-content-center'>
                <Box className='d-flex flex-column w-50 gap-2 mb-5'>
                    <Box className='d-flex justify-content-between'>
                        <Typography fontSize={18}>Verification Fee</Typography>
                        <Typography fontSize={18} fontWeight={"bold"}>
                            ₹ {!(isBrandNotVerified) ? "0" : "5000.00"}
                        </Typography>
                    </Box>
                    <Box className='d-flex justify-content-between'>
                        <Typography fontSize={18}>Launch Fee</Typography>
                        <Typography fontSize={18} fontWeight={"bold"}>
                            ₹ {"0.00"}
                        </Typography>
                    </Box>
                    <hr />
                    <Box className='d-flex justify-content-between'>
                        <Typography fontSize={20} fontWeight={"bold"}>
                            Total
                        </Typography>
                        <Typography fontSize={20} fontWeight={"bold"}>
                            ₹ {!(isBrandNotVerified) ? "0" : "5000.00"}
                        </Typography>
                    </Box>
                    <Box className='d-flex gap-2'>
                        <Typography className='fw-bold text-secondary'>Note:</Typography>
                        <Box className='mb-3'>
                            <Typography className='text-secondary'>
                                Additional taxes may be applicable.
                                <span className='text-danger'>* </span>
                            </Typography>
                            <Typography className='text-secondary'>
                                (This amount would be debited at the time of Bot approval and would be charged once per Brand.)
                            </Typography>
                        </Box>
                    </Box>
                    <Box className='d-flex justify-content-between'>
                        <Typography fontSize={18}>Maintenance Fee (to be paid after 12 Months)</Typography>
                        <Typography fontSize={18} fontWeight={"bold"}>
                            ₹ {"0.00"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
