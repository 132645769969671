import React from 'react';
import { channelsArray } from '../WorkFlowEditor';

const getChannelName = (channelId) => {
    return channelsArray.find((channel) => channel.channelId === channelId).channelName;
}

export const MasterCardLayout = ({ children, title, tailwindClass, startNode, hasError, nodeData }) => {
    return (
        <div>
            <div className={`master-card-layout ${startNode === true ? 'startCard' : ""}`}>
                <div className={`${tailwindClass} w-full flex justify-between`}><div className={`font-semibold text-white py-1 px-1.5 text-lg`}>{title}</div> <div className='font-semibold text-red-500 py-1 px-1.5 text-xl error' title={hasError?.flag === true ? hasError.message : ''}>{hasError?.flag === true ? '!' : ''}</div> </div>
                {children}
            </div>
            <div className='block'>
                {
                    nodeData.belongsToChannel && nodeData.belongsToChannel.map((channel) => {
                        return (
                            <div className='bg-dark-700'>
                                <p> ChannelName: {getChannelName(channel.channelId)} {'=>'}  RefCount: {channel.refCount}  </p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

