import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Grid, Divider as MuiDivider, Typography as MuiTypography } from "@mui/material";
import { red } from "@mui/material/colors";
import { spacing } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { getAllAnalyticsDataThunk, getAllVerifiedBotsToRunCampaignThunk } from "../../../redux/botManagement/botManagementThunk";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import DoughnutChart from "./DoughnutChart";
import StatsDouble from "./StatsDouble";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

export default function Default() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useSelector(state => state.botManagementSlice);
    const { user } = useAuth();
    const [errorResponse, setErrorResponse] = useState("");
    const [totalSubmitted, setTotalSubmitted] = useState(0);
    const [totalSubmittedLoader, setTotalSubmittedLoader] = useState(false);
    const [totalPending, setTotalPending] = useState(0);
    const [totalPendingLoader, setTotalPendingLoader] = useState(false);
    const [totalSentRCS, setTotalSentRCS] = useState(0);
    const [totalSentRCSLoader, setTotalSentRCSLoader] = useState(false);
    const [totalSentSMS, setTotalSentSMS] = useState(0);
    const [totalSentSMSLoader, setTotalSentSMSLoader] = useState(false);
    const [totalFailed, setTotalFailed] = useState(0);
    const [totalFailedLoader, setTotalFailedLoader] = useState(false);
    const [totalDelivered, setTotalDelivered] = useState(0);
    const [totalDeliveredLoader, setTotalDeliveredLoader] = useState(false);


    const [todaySubmitted, setTodaySubmitted] = useState(0);
    const [todaySubmittedLoader, setTodaySubmittedLoader] = useState(false);
    const [todayPending, setTodayPending] = useState(0);
    const [todayPendingLoader, setTodayPendingLoader] = useState(false);
    const [todaySentRCS, setTodaySentRCS] = useState(0);
    const [todaySentRCSLoader, setTodaySentRCSLoader] = useState(false);
    const [todaySentSMS, setTodaySentSMS] = useState(0);
    const [todaySentSMSLoader, setTodaySentSMSLoader] = useState(false);
    const [todayFailed, setTodayFailed] = useState(0);
    const [todayFailedLoader, setTodayFailedLoader] = useState(false);
    const [todayDelivered, setTodayDelivered] = useState(0);
    const [todayDeliveredLoader, setTodayDeliveredLoader] = useState(false);
    const [totalSMS, setTotalSMS] = useState(0);
    const [totalSMSLoader, setTotalSMSLoader] = useState(false);
    const [totalSMSDelivered, setTotalSMSDelivered] = useState(0);
    const [totalSMSDeliveredLoader, setTotalSMSDeliveredLoader] = useState(false);
    const [totalSMSFailed, setTotalSMSFailed] = useState(0);
    const [totalSMSFailedLoader, setTotalSMSFailedLoader] = useState(false);
    const [todaySMS, setTodaySMS] = useState(0);
    const [todaySMSLoader, setTodaySMSLoader] = useState(false);
    const [todaySMSDelivered, setTodaySMSDelivered] = useState(0);
    const [todaySMSDeliveredLoader, setTodaySMSDeliveredLoader] = useState(false);
    const [todaySMSFailed, setTodaySMSFailed] = useState(0);
    const [todaySMSFailedLoader, setTodaySMSFailedLoader] = useState(false);

    const botState = useSelector((state) => state.botManagementSlice);


    const fetchAnalyticsData = () => {
        dispatch(getAllAnalyticsDataThunk());
        // dispatch(getTotalSubmitedDataThunk());
        // dispatch(getAnalyticsDataThunkParallelly());
    };

    const totalSubmittedCall = async (botIds) => {
        try {
            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-month-submitted`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTotalSubmitted(response.data.totalSubmitted);
                setTotalSubmittedLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTotalSubmittedLoader(false);



            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const totalPendingCall = async (botIds) => {
        try {
            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-month-pending`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTotalPending(response.data.totalPending);
                setTotalPendingLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTotalPendingLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const totalSentRCSCall = async (botIds) => {
        try {


            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-month-sent-rcs`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTotalSentRCS(response.data.totalSentRCS);
                setTotalSentRCSLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTotalSentRCSLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const totalSentSMSCall = async (botIds) => {
        try {


            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-month-sent-sms`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTotalSentSMS(response.data.totalSentSMS);
                setTotalSentSMSLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTotalSentSMSLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const totalDeliveredCall = async (botIds) => {
        try {


            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-month-delivered`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTotalDelivered(response.data.totalDelivered);
                setTotalDeliveredLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTotalDeliveredLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const totalFailedCall = async (botIds) => {
        try {


            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-month-failed`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTotalFailed(response.data.totalFailed);
                setTotalFailedLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTotalFailedLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const totalSMSCall = async (botIds) => {
        try {
            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-sms-reports-parallelly`, { botIds, isToday: false },
                {
                    params: {
                        status: 'TOTAL_SMS'
                    }
                }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTotalSMS(response.data.TOTAL_SMS);
                setTotalSMSLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);
            setTotalSMSLoader(false);



            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const totalSMSDeliveredCall = async (botIds) => {
        try {
            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-sms-reports-parallelly`, { botIds, isToday: false },
                {
                    params: {
                        status: 'SMS-DELIVERED'
                    }
                }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTotalSMSDelivered(response.data['SMS-DELIVERED']);
                setTotalSMSDeliveredLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);
            setTotalSMSDeliveredLoader(false);



            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const totalSMSFailedCall = async (botIds) => {
        try {
            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-sms-reports-parallelly`, { botIds, isToday: false },
                {
                    params: {
                        status: 'TOTAL_SMS_FAILED'
                    }
                }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTotalSMSFailed(response.data.TOTAL_SMS_FAILED);
                setTotalSMSFailedLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            setTotalSMSFailedLoader(false);

            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    const todaySubmittedCall = async (botIds) => {
        try {

            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-today-submitted`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTodaySubmitted(response.data.todaySubmitted);
                setTodaySubmittedLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTodaySubmittedLoader(false);



            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const todayPendingCall = async (botIds) => {
        try {

            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-today-pending`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTodayPending(response.data.todayPending);
                setTodayPendingLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTodayPendingLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const todaySentRCSCall = async (botIds) => {
        try {


            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-today-sent-rcs`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTodaySentRCS(response.data.todaySentRCS);
                setTodaySentRCSLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTodaySentRCSLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const todaySentSMSCall = async (botIds) => {
        try {


            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-today-sent-sms`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTodaySentSMS(response.data.todaySentSMS);
                setTodaySentSMSLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTodaySentSMSLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const todayDeliveredCall = async (botIds) => {
        try {


            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-today-delivered`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTodayDelivered(response.data.todayDelivered);
                setTodayDeliveredLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTodayDeliveredLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const todayFailedCall = async (botIds) => {
        try {


            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-dashboard-today-failed`, { botIds }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTodayFailed(response.data.todayFailed);
                setTodayFailedLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);

            setTodayFailedLoader(false);


            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const todaySMSCall = async (botIds) => {
        try {
            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-sms-reports-parallelly`, { botIds, isToday: true },
                {
                    params: {
                        status: 'TOTAL_SMS'
                    }
                }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTodaySMS(response.data.TOTAL_SMS);
                setTodaySMSLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);
            setTodaySMSLoader(false);



            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const todaySMSDeliveredCall = async (botIds) => {
        try {
            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-sms-reports-parallelly`, { botIds, isToday: true },
                {
                    params: {
                        status: 'SMS-DELIVERED'
                    }
                }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTodaySMSDelivered(response.data['SMS-DELIVERED']);
                setTodaySMSDeliveredLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            // setShowViewCampaignReport(true);
            setTodaySMSDeliveredLoader(false);



            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }

        // setCampaign(campaigndetails);
    };

    const todaySMSFailedCall = async (botIds) => {
        try {
            const response = await apiGatewayAxiosInstance.post(`/conversation_logger/logger/get-sms-reports-parallelly`, { botIds, isToday: true },
                {
                    params: {
                        status: 'TOTAL_SMS_FAILED'
                    }
                }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {

                setTodaySMSFailed(response.data.TOTAL_SMS_FAILED);
                setTodaySMSFailedLoader(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            setTodaySMSFailedLoader(false);

            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };



    // useEffect(async () => {

    //     totalPendingCall();
    //     totalSubmittedCall();
    //     totalSentRCSCall();
    //     totalSentSMSCall();
    //     totalDeliveredCall();
    //     totalFailedCall();

    //     todayPendingCall();
    //     todaySubmittedCall();
    //     todaySentRCSCall();
    //     todaySentSMSCall();
    //     todayDeliveredCall();
    //     todayFailedCall();

    // }, []);


    useEffect(() => {
        dispatch(getAllVerifiedBotsToRunCampaignThunk());

    }, [])

    useEffect(() => {

        if (botState.verifiedBotListToRunCampaign && botState.verifiedBotListToRunCampaign.length > 0) {

            const botIds = botState.verifiedBotListToRunCampaign.map((bot) => {
                return bot.config.rbmClientId;
            });
            setTotalSubmittedLoader(true)
            setTotalPendingLoader(true)
            setTotalSentRCSLoader(true)
            setTotalSentSMSLoader(true)
            setTotalFailedLoader(true)
            setTotalDeliveredLoader(true)
            setTotalSMSLoader(true)
            setTotalSMSDeliveredLoader(true)
            setTotalSMSFailedLoader(true)
            setTodaySubmittedLoader(true)
            setTodayPendingLoader(true)
            setTodaySentRCSLoader(true)
            setTodaySentSMSLoader(true)
            setTodayFailedLoader(true)
            setTodayDeliveredLoader(true)
            setTodaySMSLoader(true)
            setTodayDeliveredLoader(true)
            setTodayFailedLoader(true)


            setTotalSubmittedLoader(true)
            setTotalPendingLoader(true)
            setTotalSentRCSLoader(true)
            setTotalSentSMSLoader(true)
            setTotalFailedLoader(true)
            setTotalDeliveredLoader(true)
            setTodaySubmittedLoader(true)
            setTodayPendingLoader(true)
            setTodaySentRCSLoader(true)
            setTodaySentSMSLoader(true)
            setTodayFailedLoader(true)
            setTodayDeliveredLoader(true)

            totalPendingCall(botIds);
            totalSubmittedCall(botIds);
            totalSentRCSCall(botIds);
            totalSentSMSCall(botIds);
            totalDeliveredCall(botIds);
            totalFailedCall(botIds);
            totalSMSCall(botIds);
            totalSMSDeliveredCall(botIds);
            totalSMSFailedCall(botIds);

            todayPendingCall(botIds);
            todaySubmittedCall(botIds);
            todaySentRCSCall(botIds);
            todaySentSMSCall(botIds);
            todayDeliveredCall(botIds);
            todayFailedCall(botIds);
            todaySMSCall(botIds);
            todaySMSDeliveredCall(botIds);
            todaySMSFailedCall(botIds);


        }
    }, [botState.verifiedBotListToRunCampaign])

    return (
        <React.Fragment>
            <Helmet title='Dashboard' />
            <Grid justifyContent='space-between' container spacing={6}>
                <Grid item>
                    <Typography variant='h3' gutterBottom>
                        Dashboard
                    </Typography>
                    {/* <Typography variant="subtitle1"> */}
                    {/* {t("Welcome back")}, <b>{user?.name ? user.name : 'Pinnacle'}</b> ! */}

                    {/* </Typography> */}
                </Grid>

                {/* <Grid item>
          <Actions />
        </Grid> */}
            </Grid>

            <Divider my={3} />
            <Typography variant='h4' gutterBottom marginBottom={4}>
                Today :
            </Typography>
            <Grid container spacing={6}>
                {/* <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Submitted'
                        amount={todaySubmitted ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={green[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader={todaySubmittedLoader}
                    />
                </Grid> */}

                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Submitted'
                        amount1={todaySubmitted ?? "0"}
                        amount2={todaySMS ?? "0"}
                        title1="RCS"
                        title2="SMS"

                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader1={todaySubmittedLoader}
                        loader2={todaySMSLoader}


                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Pending'
                        amount1={todayPending ?? "0"}
                        amount2={"-"}
                        title1="RCS"
                        title2="SMS"

                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader1={todayPendingLoader}
                    // loader2={todaySentSMSLoader}


                    />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Pending'
                        amount={todayPending ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={green[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader={todayPendingLoader}

                    />
                </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Sent'
                        amount1={todaySentRCS ?? "0"}
                        amount2={todaySentSMS ?? "0"}
                        title1="RCS"
                        title2="SMS"

                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader1={todaySentRCSLoader}
                        loader2={todaySentSMSLoader}


                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Delivered'
                        amount1={todayDelivered ?? "0"}
                        amount2={todaySMSDelivered ?? "0"}
                        title1="RCS"
                        title2="SMS"

                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader1={todayDeliveredLoader}
                        loader2={todaySMSDeliveredLoader}


                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Failed'
                        amount1={todayFailed ?? "0"}
                        amount2={todaySMSFailed ?? "0"}
                        title1="RCS"
                        title2="SMS"

                        // chip='Today'
                        // percentagetext="0%"
                        textColor='#d93636'
                        percentagecolor={red[500]}
                        backgroundColor='#f5cdcd'
                        loader1={todayFailedLoader}
                        loader2={todaySMSFailedLoader}


                    />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Sent SMS'
                        amount={todaySentSMS ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader={todaySentSMSLoader}

                    />
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Delivered'
                        amount={todayDelivered ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={green[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader={todayDeliveredLoader}

                    />
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Failed'
                        amount={todayFailed ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        textColor='#d93636'
                        percentagecolor={red[500]}
                        backgroundColor='#f5cdcd'
                        loader={todayFailedLoader}

                    // illustration="/static/img/illustrations/waiting.png"
                    />
                </Grid> */}
            </Grid>
            {/* <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Submitted'
                        amount={todaySubmitted ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={green[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader={todaySubmittedLoader}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Pending'
                        amount={todayPending ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={green[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader={todayPendingLoader}

                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Sent RCS'
                        amount={todaySentRCS ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader={todaySentRCSLoader}

                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Sent SMS'
                        amount={todaySentSMS ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader={todaySentSMSLoader}

                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Delivered'
                        amount={todayDelivered ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        percentagecolor={green[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader={todayDeliveredLoader}

                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Failed'
                        amount={todayFailed ?? "0"}
                        // chip='Today'
                        // percentagetext="0%"
                        textColor='#d93636'
                        percentagecolor={red[500]}
                        backgroundColor='#f5cdcd'
                        loader={todayFailedLoader}

                    // illustration="/static/img/illustrations/waiting.png"
                    />
                </Grid>
            </Grid> */}

            <Typography variant='h4' gutterBottom marginBottom={4}>
                This Month :
            </Typography>

            <Grid container spacing={6}>

                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Submitted'
                        amount1={totalSubmitted ?? "0"}
                        amount2={totalSMS ?? "0"}
                        title1="RCS"
                        title2="SMS"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader1={totalSubmittedLoader}
                        loader2={totalSMSLoader}


                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Pending'
                        amount1={totalPending ?? "0"}
                        amount2={"-"}
                        title1="RCS"
                        title2="SMS"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader1={totalSentSMSLoader}
                    // loader2={totalSe}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Sent'
                        amount1={totalSentRCS ?? "0"}
                        amount2={totalSentSMS ?? "0"}
                        title1="RCS"
                        title2="SMS"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader1={totalSentRCSLoader}
                        loader2={totalSentSMSLoader}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Delivered'
                        amount1={totalDelivered ?? "0"}
                        amount2={totalSMSDelivered ?? "0"}
                        title1="RCS"
                        title2="SMS"
                        percentagecolor={red[500]}
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        loader1={totalDeliveredLoader}
                        loader2={totalSMSDeliveredLoader}


                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <StatsDouble
                        header='Failed'
                        amount1={totalFailed ?? "0"}
                        amount2={totalSMSFailed ?? "0"}
                        title1="RCS"
                        title2="SMS"
                        textColor='#d93636'
                        percentagecolor={red[500]}
                        backgroundColor='#f5cdcd'
                        loader1={totalFailedLoader}
                        loader2={totalSMSFailedLoader}


                    />
                </Grid>

            </Grid>

            {/* <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Submitted'
                        amount={totalSubmitted ?? "0"}
                        // chip="Overall"

                        // percentagetext="0%"
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        percentagecolor={green[500]}
                        loader={totalSubmittedLoader}

                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Pending'
                        amount={totalPending ?? "0"}
                        // chip="Overall"

                        // percentagetext="0%"
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        percentagecolor={green[500]}
                        loader={totalPendingLoader}

                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Sent RCS'
                        amount={totalSentRCS ?? "0"}
                        // chip="Overall"
                        // percentagetext="0%"
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        percentagecolor={red[500]}
                        loader={totalSentRCSLoader}

                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Sent SMS'
                        amount={totalSentSMS ?? "0"}
                        // chip="Overall"
                        // percentagetext="0%"
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        percentagecolor={red[500]}
                        loader={totalSentSMSLoader}

                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Delivered'
                        amount={totalDelivered ?? "0"}
                        // chip="Overall"
                        // percentagetext="0%"
                        textColor='#4848d7'
                        backgroundColor='#d5e7f6'
                        percentagecolor={green[500]}
                        loader={totalDeliveredLoader}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title='Failed'
                        amount={totalFailed ?? "0"}
                        // chip="Overall"
                        // percentagetext="0%"
                        textColor='#d93636'
                        percentagecolor={red[500]}
                        backgroundColor='#f5cdcd'
                        loader={totalFailedLoader}
                    // illustration="/static/img/illustrations/waiting.png"
                    />
                </Grid>
            </Grid> */}

            <Grid container spacing={6}>
                {/* <Grid item xs={12} lg={8}>
                    <LineChart />
                </Grid> */}
                <Grid item xs={12} lg={4}>
                    <DoughnutChart todayLogData={{ todaySubmitted, todayDelivered, todayFailed, todayPending, todaySentRCS }} showFor={"RCS"} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DoughnutChart todayLogData={{ todaySMS, todaySMSDelivered, todaySMSFailed, todaySMSPending:0,  todaySentSMS }} showFor={"SMS"} />
                </Grid>
            </Grid>

            {/* <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Table />
        </Grid>
      </Grid>  */}
            <AlertMessageComponent
                message={errorResponse}
                fieldName={""}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />
        </React.Fragment>
    );
}

