import React from 'react'

export default function VideoMessageComponent({ videoData }) {

    return (
        <div >
            <video controls width="250" height="200" style={{ borderRadius: "10px", overflow: "hidden", backgroundColor: "#d5d1d1" }} >
                <source src={videoData.fileUrl} type="video/mp4" />

            </video></div>
    )
}
