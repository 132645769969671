import React from 'react';
import { cssClass } from '../../utils/workflow-utils/cssClasses';

export const Sidebar = ({ onSave }) => {

    // type of cards pin bot support
    // later fetch this list from master server
    const cardList = [
        {
            type: 'messageCard',
            nodeType: 'messageCard',
            title: 'Message',
            tailwindClass: 'bg-sky-500'
        },
        {
            type: 'listCard',
            title: 'List',
            nodeType: "listCard",
            tailwindClass: 'bg-indigo-500'
        },
        {
            type: 'button',
            title: 'Button',
            nodeType: "input",
            tailwindClass: 'bg-yellow-500'
        },
        {
            type: 'media',
            title: 'Media',
            nodeType: "input",
            tailwindClass: 'bg-pink-500'
        },
        ,
        {
            type: 'webhook',
            title: 'Webhook',
            nodeType: "input",
            tailwindClass: 'bg-blue-800'
        },
        {
            type: 'conditionCard',
            title: 'Simple Condition',
            nodeType: "input",
            tailwindClass: 'bg-teal-500'
        },
        {
            type: 'switchCondition',
            title: 'Complex Condition',
            nodeType: "input",
            tailwindClass: 'bg-green-800'
        },
        {
            type: 'channelSwitch',
            title: 'Channel Switch Condition',
            nodeType: "input",
            tailwindClass: 'bg-red-500'
        },
        {
            type: 'switchNode',
            title: 'Switch Node',
            nodeType: "input",
            tailwindClass: 'bg-purple-700'
        },
    ];

    //  triggers when we drag card from sidebar to worlflow editor
    const onDragStart = (event, type, title, tailwindClass) => {
        event.dataTransfer.setData('application/reactflow/card-type', type);
        event.dataTransfer.setData('application/reactflow/card-title', title);
        event.dataTransfer.setData('application/reactflow/tailwind-class', tailwindClass);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <aside className='sidebar'>
            <div className="sidebar-list">
                {
                    cardList && cardList.map((ele, idx) => {
                        return (
                            <div key={`sidebar-item-${idx}`} className={`${cssClass['pin-bot-sidebar-item']} ${ele.tailwindClass} text-white`} onDragStart={(event) => onDragStart(event, ele.type, ele.title, ele.tailwindClass)} draggable={true}>
                                <p className='sidebar-card-item-title'>  {ele.title} </p>
                            </div>
                        )
                    })
                }
            </div>
        </aside >
    );
}

