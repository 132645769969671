import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { ERoles } from "../../../config";
import { clearErrorMessagesForAllUsers, setShowAddUserForm } from "../../../redux/userManagement/userManagementSlice";
import {
  addUpdateUserThunk,
  getAllClientsThunk,
  // getAllParentClientsThunk,
  getAllRolesThunk,
} from "../../../redux/userManagement/userManagementThunk";
import userAxiosInstance from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import StatusRadioButtons from "../../components/StatusRadioButtons";
function AddUsers() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.userManagementSlice);
  const [changePassword, setChangePassword] = useState(false);
  const [isPasswordExist, setIsPasswordExist] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(getAllRolesThunk());
    dispatch(getAllClientsThunk());
    // dispatch(getAllParentClientsThunk());
  }, []);

  const userSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be valid email")
      .required("Email is required"),
    name: Yup.string().required("Name is required"),
    mobileNumber: Yup.number()
      .min(10, "Must be atleast 10 Numbers")
      .required("Mobile is required"),
    status: Yup.string().required("Branch Status is required"),
  });

  const branchSchema = Yup.object().shape({
    _id: Yup.string().required("Branch Id is required"),
    branchCode: Yup.string().required("Branch Code is required"),
    branchName: Yup.string().required("Branch Name is required"),
    status: Yup.string().required("Branch Status is required"),
  });

  // const executiveSchema = Yup.object().shape({
  //   _id: Yup.string().required("Executive Id is required"),
  //   name: Yup.string().required("Name is required"),

  //   email: Yup.string()
  //     .email("Must be valid email")
  //     .required("Email is required"),
  //   mobileNumber: Yup.string()
  //     .matches(/^\d{10}$/, "Valid number required")
  //     .required("Mobile is required"),
  //   branchId: Yup.string().required("Branch Id is required"),
  //   status: Yup.string().required("Executive Status is required"),
  // });

  // const branchManagerSchema = Yup.object().shape({
  //   _id: Yup.string().required("Branch Manager Id is required"),
  //   name: Yup.string().required("Name is required"),
  //   email: Yup.string()
  //     .email("Must be valid email")
  //     .required("Email is required"),
  //   mobileNumber: Yup.string()
  //     .matches(/^\d{10}$/, "Valid number required")
  //     .required("Mobile is required"),
  //   branchManager: Yup.object().shape({
  //     branchId: Yup.string().required("Branch Id is required"),
  //   }),
  //   status: Yup.string().required("Executive Status is required"),
  // });

  // const selectedParentClient = Yup.object().shape({
  //   _id: Yup.string().required("Parent Client Id is required"),
  //   clientName: Yup.string().required("Parent Client Name is required"),
  //   roleName: Yup.string().required("Parent client role name required"),
  // });

  const clientSchema = Yup.object().shape({
    // assignStatus: Yup.string().required("assign Status required"),
    branchId: Yup.string().required("Branch Id is required"),
    // selectedExecutiveId: Yup.mixed().when("assignStatus", {
    //   is: (val) => val === "executive",
    //   then: Yup.string().required("Please select executive to assign"),
    //   otherwise: Yup.string().notRequired(),
    // }),
    // selectedBranchManagerId: Yup.mixed().when("assignStatus", {
    //   is: (val) => val === "branchManager",
    //   then: Yup.string().required("Please select branch manager to assign"),
    //   otherwise: Yup.string().notRequired(),
    // }),
    // selectedBranchManager: Yup.mixed().when("selectedBranchManagerId", {
    //   is: (val) => val !== "",
    //   then: branchManagerSchema.nullable(),
    // }),
    // executivesListByBranchId: Yup.array()
    //   .of(Yup.object())
    //   .required("Executive is required"),
    // selectedExecutive: Yup.mixed().when("selectedExecutiveId", {
    //   is: (val) => val !== "",
    //   then: executiveSchema.nullable(),
    // }),
    // selectedParentClientId: Yup.string().required("Parent Client  is required"),
    // selectedParentClient: Yup.mixed().when("selectedParentClientId", {
    //   is: (val) => val !== "",
    //   then: selectedParentClient.nullable(),
    // }),
  });

  const initialValues = {
    role: "",
    email: "",
    name: "",
    mobileNumber: "",
    userName: "",
    password: "",
    confirmPassword: "",
    status: "Active",
    userListByRole: [],
    selectedUser: null,
    selectedUserId: "",
    client: {
      // assignStatus: "executive",
      // branchId: "",
      // selectedExecutiveId: "",
      // selectedExecutive: null,
      // executivesListByBranchId: [],
      // selectedBranchManagerId: "",
      // branchManagersListByBranchId: [],
      // selectedBranchManager: null,
      // selectedParentClientId: "",
      // selectedParentClient: null,
    },
    selectedBranch: null,
    selectedBranchId: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      role: Yup.string().required("Role is required"),
      selectedUserId: Yup.string().required("User is required"),
      selectedUser: Yup.mixed().when("selectedUserId", {
        is: (val) => val !== "",
        then: userSchema.nullable(),
      }),
      email: Yup.string().max(255).required("Email is required"),
      name: Yup.string().min(2).max(255).required("Name is required"),
      mobileNumber: Yup.number()
        .min(10, "Must be more than 10 Numbers")
        .required("Mobile is required"),
      userName: Yup.string().min(2).max(255).required("Username is required"),
      password: Yup.string().min(8).max(255).required("Password is required"),
      confirmPassword: Yup.string()
        .min(8)
        .max(255)
        .required("Confirm Password is required")
        .test("password-match", "Passwords do not match", function (value) {
          return this.parent.password === value;
        }),

      selectedBranchId: Yup.mixed().when("role", {
        is: (val) => val?.toLowerCase() !== ERoles.SUPER_ADMIN,
        then: Yup.string().required("please select branch to select executive"),
        otherwise: Yup.string().notRequired(),
      }),

      selectedBranch: Yup.mixed().when("selectedBranchId", {
        is: (val) => val !== "",
        then: branchSchema.nullable(),
      }),

      client: Yup.mixed().when("role", {
        is: (val) => val === ERoles.ORG_ADMIN,
        then: clientSchema.nullable(),
      }),
    }),
    onSubmit: async (
      values,
      { setErrors, setStatus, setSubmitting, resetForm }
    ) => {
      try {
        const selectedManagerToSend = {
          branchManagerId: values.client?.selectedBranchManager?._id,
          name: values.client?.selectedBranchManager?.name,
          email: values.client?.selectedBranchManager?.email,
          branchId: values.selectedBranchId,
        };

        const clientObjectToAdd = {
          assignStatus: values.client?.assignStatus,
          branchId: values.selectedBranchId,
          assignedExecutive: values.client?.selectedExecutive,
          assignedBranchManager: selectedManagerToSend
            ? selectedManagerToSend
            : null,
          // parentClientId: values.client.selectedParentClientId,
        };

        const dataToSend = {
          roleName: values.role.toLowerCase(),
          email: values.email,
          name: values.name,
          mobileNumber: values.mobileNumber,
          userName: values.userName,
          password: values.password,
          status: values.status,
          client:
            values.role.toLowerCase() === ERoles.ORG_ADMIN ? clientObjectToAdd : null,
        };

        dispatch(
          addUpdateUserThunk({
            data: dataToSend,
            userId: values.selectedUserId,
          })
        );
      } catch (error) {
        const message = error.message || "Something went wrong";
        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
    },
  });

  const onChangeStatus = (e) => {
    formik.setFieldValue("status", e.target.value);
  };

  const onChangeAssignStatus = async (e) => {
    await getBranchManagersListByBranchId(formik.values.selectedBranchId);
    await getExecutiveListByBranchId(formik.values.selectedBranchId);
    if (formik.values.client.assignStatus === "branchManager") {
      // formik.setFieldValue("selectedBranchId", "");
      formik.setFieldValue("client.assignedBranchManager", null);
      formik.setFieldValue("client.selectedBranchManager", null);
    }
    if (formik.values.client.assignStatus === "executive") {
      // formik.setFieldValue("selectedBranchId", "");
      formik.setFieldValue("client.assignedExecutive", null);
      formik.setFieldValue("client.selectedExecutive", null);
    }
    formik.setFieldValue("client.assignStatus", e.target.value);
  };

  const onClickCancelButton = () => {
    dispatch(setShowAddUserForm(false));
  };


  const onSelectChangePassword = () => {
    if (changePassword) {
      setChangePassword(false);
    } else {
      setIsPasswordExist(false);
      formik.setFieldValue("password", "");
      formik.setFieldValue("confirmPassword", "");
      setChangePassword(true);
    }
  };

  const onChangeSelectRole = async (e) => {
    const roleName = e.target.value;
    formik.values.role = e.target.value;

    await getUsersListByRole(roleName);
    formik.setFieldValue("selectedUserId", "");
    formik.setFieldValue("selectedUser", null);
    formik.setFieldValue("email", "");
    formik.setFieldValue("name", "");
    formik.setFieldValue("mobileNumber", "");
  };

  const onChangeSelectBranch = async (e) => {
    const selectedBranch = state.branchList.filter(
      (branch) => branch.branchCode === e.target.value
    )[0];

    const branchId = selectedBranch._id;
    formik.setFieldValue("selectedBranchId", branchId, true);
    formik.setFieldValue("selectedBranch", selectedBranch, true);
    // formik.setFieldValue("client.selectedExecutive", null);
    // formik.setFieldValue("client.selectedExecutiveId", "");
    // formik.setFieldValue("client.selectedBranchManager", null);
    // formik.setFieldValue("client.selectedBranchManagerId", "");

    // await getExecutiveListByBranchId(branchId);
    // await getBranchManagersListByBranchId(branchId);
  };

  const onChangeSelectExecutive = (e) => {
    // formik.handleChange(e);
    const findSelectedExecutive = formik.values.executivesListByBranchId.filter(
      (executive) => executive.name === e.target.value
    )[0];

    const { _id, name, mobileNumber, status, email, executive } =
      findSelectedExecutive;
    formik.setFieldValue("client.selectedExecutiveId", _id, true);
    const executiveToSave = {
      _id,
      name,
      mobileNumber,
      email,
      branchId: executive.branchId,
      status,
    };
    formik.setFieldValue("client.selectedExecutive", executiveToSave, true);
  };

  const onChangeSelectBranchManager = (e) => {
    // formik.handleChange(e);
    const findSelectedBranchManager =
      formik.values.branchManagersListByBranchId.filter(
        (manager) => manager.name === e.target.value
      )[0];

    const { _id, name, mobileNumber, status, email, branchManager } = findSelectedBranchManager;
    formik.setFieldValue("client.selectedBranchManagerId", _id, true);
    const branchManagerToSave = {
      _id,
      name,
      mobileNumber,
      email,
      branchId: branchManager?.branchId,
      status,
    };
    formik.setFieldValue(
      "client.selectedBranchManager",
      branchManagerToSave,
      true
    );
  };

  const onChangeSelectEmail = async (e) => {
    //get user details to fill up the form.
    const selectedUserDetails = await formik.values.userListByRole.find(
      (user) => user._id === e.target.value._id
    );
    const { _id, email, name, mobileNumber, status, password } = selectedUserDetails;

    // ------------------------------add Parent Client---------------------------------------
    // check for parent client exist or not otherwise set default parent client of role "root_client"
    // let parentClientToAdd = null;

    // if (selectedUserDetails.parentClient) {
    //   parentClientToAdd = {
    //     _id: selectedUserDetails.parentClient[0]?._id,
    //     clientName: selectedUserDetails.parentClient[0]?.clientName,
    //     roleName: selectedUserDetails.parentClient[0]?.roleName,
    //   };
    // } else {
    //   if (state.parentClientsList.length > 0) {
    //     const rootParentClient = state.parentClientsList.find(
    //       (parentClient) =>
    //         parentClient.roleName.toLowerCase() === "root_client"
    //     );

    //     parentClientToAdd = {
    //       _id: rootParentClient._id,
    //       clientName: rootParentClient.clientName,
    //       roleName: rootParentClient.roleName,
    //     };
    //   }
    // }

    // formik.setFieldValue("client.selectedParentClient", parentClientToAdd);
    // formik.setFieldValue(
    //   "client.selectedParentClientId",
    //   parentClientToAdd?._id
    // );

    // ------------------------------add Parent Client---------------------------------------


    const selectedUserDataToSave = {
      email,
      name,
      mobileNumber,
      status,
      password,
    };
    // formik.setTouched("email", true);
    formik.setFieldValue("selectedUserId", _id);
    formik.setFieldValue("selectedUser", selectedUserDataToSave);
    formik.setFieldValue("email", email);
    formik.setFieldValue("name", name);
    formik.setFieldValue("mobileNumber", mobileNumber);
    formik.setFieldValue("status", status);


    if (e.target.value?.userName) {
      formik.setFieldValue("userName", e.target.value.userName);
    }
    if (e.target.value?.password) {
      formik.setFieldValue("password", e.target.value.password);
      setIsPasswordExist(true);
    }
    if (e.target.value?.password) {
      formik.setFieldValue("confirmPassword", e.target.value.password);
    }

    if (e.target.value.client) {
      // const { assignStatus, assignedExecutive, assignedBranchManager } = e.target.value.client;
      // let branchManagerObject = {};
      // let assignedExecutiveObject = {};

      // if (assignedBranchManager) {
      //   branchManagerObject = {
      //     _id: assignedBranchManager.branchManagerId,
      //     name: assignedBranchManager.name,
      //     email: assignedBranchManager.email,
      //     branchManager: {
      //       branchId: assignedBranchManager.branchId,
      //     },
      //   };
      // }

      // if (assignedExecutive) {
      //   assignedExecutiveObject = {
      //     _id: assignedExecutive.executiveId,
      //     name: assignedExecutive.name,
      //     email: assignedExecutive.email,
      //     branchId: assignedExecutive.branchId,
      //   };
      // }

      const clientObjectToSet = {
        // assignStatus,
        branchId: formik.values.selectedBranchId,
        // selectedExecutiveId: assignedExecutive
        //   ? assignedExecutive.executiveId
        //   : "",
        // selectedExecutive: assignedExecutiveObject,
        // selectedBranchManagerId: assignedBranchManager
        //   ? assignedBranchManager.branchManagerId
        //   : "",
        // selectedBranchManager: branchManagerObject,
        // selectedParentClient: parentClientToAdd,
        // selectedParentClientId: parentClientToAdd?._id,
      };

      formik.setFieldValue("selectedBranchId", e.target.value.client.branchId);
      const selectedBranch = state.branchList.filter(
        (branch) => branch._id == e.target.value.client.branchId
      )[0];
      formik.setFieldValue("selectedBranch", selectedBranch);

      // if (assignStatus === "executive") {
      //   await getExecutiveListByBranchId(assignedExecutive.branchId);
      //   formik.setFieldValue("selectedBranchId", assignedExecutive.branchId);
      //   const selectedBranch = state.branchList.filter(
      //     (branch) => branch._id == assignedExecutive.branchId
      //   )[0];
      //   formik.setFieldValue("selectedBranch", selectedBranch);
      // } else {
      //   await getBranchManagersListByBranchId(assignedBranchManager.branchId);
      //   formik.setFieldValue(
      //     "selectedBranchId",
      //     assignedBranchManager.branchId
      //   );
      //   const selectedBranch = state.branchList.filter(
      //     (branch) => branch._id == assignedBranchManager.branchId
      //   )[0];
      //   formik.setFieldValue("selectedBranch", selectedBranch);
      // }
      formik.setFieldValue(ERoles.ORG_ADMIN, clientObjectToSet);
    }
  };



  const getExecutiveListByBranchId = async (branchId) => {
    try {
      const response = await userAxiosInstance.get(
        `executives/get-all-by-branch-id/${branchId}`
      );
      if (response.status === 200) {
        formik.setFieldValue(
          "executivesListByBranchId",
          response.data.data,
          true
        );
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBranchManagersListByBranchId = async (branchId) => {
    try {
      const response = await userAxiosInstance.get(
        `branch-managers/get-all-by-branch-id/${branchId}`
      );
      if (response.status === 200) {
        formik.setFieldValue(
          "branchManagersListByBranchId",
          response.data.data,
          true
        );
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsersListByRole = async (roleName) => {
    try {
      const response = await userAxiosInstance.get(
        `users/get-all-by-role/${roleName}`
      );
      if (response.status === 200) {
        await formik.setFieldValue("userListByRole", response.data.data, true);
        return;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const onChangeSelectParentClient = async (e) => {
  //   const selectedParentClientName = e.target.value;

  //   const selectedParentClient = await state.parentClientsList.find(
  //     (user) => user.clientName === selectedParentClientName
  //   );
  //   const parentClientDetail = {
  //     _id: selectedParentClient._id,
  //     clientName: e.target.value,
  //     roleName: selectedParentClient.roleName,
  //   };
  //   formik.setFieldValue(
  //     "client.selectedParentClientId",
  //     selectedParentClient._id,
  //     true
  //   );
  //   formik.setFieldValue(
  //     "client.selectedParentClient",
  //     parentClientDetail,
  //     true
  //   );
  //   formik.handleBlur(e);
  // };

  const onCloseErrorMessage = ({ fieldName }) => {
    dispatch(clearErrorMessagesForAllUsers({ fieldName }));
  }


  return (
    <form onSubmit={formik.handleSubmit}>
      <Card sx={{ padding: '0.8rem' }}>
        <CardContent sx={{ padding: "10px" }}>
          <Typography variant="h4">
            <b className="text-primary">Manage Users</b>
          </Typography>
          <hr />
          <Grid sx={{ display: "flex" }} gap={5}>
            <FormControl
              fullWidth
              className="field-spacing"
              error={formik.errors.role ? true : false}
            >
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                disabled={state.controls.isUpdateUsersLoading}
                name="role"
                labelId="role-label"
                id="role"
                value={formik.values.role}
                error={Boolean(formik.touched.role && formik.errors.role)}
                onChange={onChangeSelectRole}
                onBlur={formik.handleBlur}
                label="Role"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      boxShadow:
                        " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                    },
                  },
                }}
              >
                {state.rolesList?.map((role, index) => (
                  <MenuItem key={index} value={role.roleName}>
                    {role.roleName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.role && formik.errors.role}
              </FormHelperText>
            </FormControl>

            <FormControl
              fullWidth
              className="field-spacing"
              error={Boolean(
                formik.touched.selectedUser && formik.errors.email
              )}
            >
              <InputLabel id="user-label">Email</InputLabel>
              <Select
                disabled={state.controls.isUpdateUsersLoading}
                name="selectedUser"
                labelId="user-label"
                id="selectedUser"
                value={formik.values.selectedUser?.email}
                error={Boolean(
                  formik.touched.selectedUser && formik.errors.email
                )}
                onChange={onChangeSelectEmail}
                // onBlur={formik.handleBlur}
                label="Email"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      boxShadow:
                        " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                    },
                  },
                }}
              >
                {formik.values.userListByRole?.map((user, index) => (
                  <MenuItem key={index} value={user}>
                    {user.email}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.selectedUser && formik.errors.email}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid sx={{ display: "flex" }} gap={5}>

            <TextField
              className="field-spacing"
              disabled={state.controls.isUpdateUsersLoading}
              name="name"
              fullWidth
              label="Name"
              varient="outlined"
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
            />

            <TextField
              className="field-spacing"
              disabled={state.controls.isUpdateUsersLoading}
              name="mobileNumber"
              fullWidth
              label="Mobile Number"
              variant="outlined"
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.touched.mobileNumber && formik.errors.mobileNumber
              )}
              helperText={
                formik.touched.mobileNumber && formik.errors.mobileNumber
              }
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid container spacing={5} sx={{ alignItems: "center" }}>

            <Grid item xs={6}>
              <TextField
                className="field-spacing"
                disabled={state.controls.isUpdateUsersLoading}
                name="userName"
                fullWidth
                label="Username"
                varient="outlined"
                error={Boolean(
                  formik.touched.userName && formik.errors.userName
                )}
                helperText={formik.touched.userName && formik.errors.userName}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
                onChange={formik.handleChange}
              />
            </Grid>

            {!isPasswordExist ? (
              <>
                <Grid item xs={3}>

                  <TextField
                    className="field-spacing"
                    disabled={state.controls.isUpdateUsersLoading}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    fullWidth
                    label="Password"
                    varient="outlined"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={3}>

                  <TextField
                    className="field-spacing"
                    disabled={state.controls.isUpdateUsersLoading}
                    type={showPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    fullWidth
                    label="Confirm Password"
                    varient="outlined"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={Boolean(
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    )}
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={2} >
                <Button className="field-spacing" variant="contained" disabled={state.controls.isUpdateUsersLoading}
                  onClick={onSelectChangePassword}>
                  Change Password
                </Button>
              </Grid>
            )}
          </Grid>
          {formik.values.role.toLowerCase() === ERoles.ORG_ADMIN && (
            <>
              <Grid sx={{ display: "flex" }} gap={5}>

                {/* <FormControl
                  fullWidth
                  className="field-spacing"
                  error={Boolean(
                    formik.touched.client?.selectedParentClient &&
                    formik.errors.client?.selectedParentClientId
                  )}
                >
                  <InputLabel id="parentClient-label">
                    Parent Client
                  </InputLabel>
                  <Select
                    disabled={state.controls.isUpdateUsersLoading}
                    name="client.selectedParentClient"
                    labelId="parentClient-label"
                    id="parentClient"
                    value={
                      formik.values.client?.selectedParentClient
                        ? formik.values.client?.selectedParentClient
                          .clientName
                        : ""
                    }
                    error={Boolean(
                      formik.touched.client?.selectedParentClient &&
                      formik.errors.client?.selectedParentClientId
                    )}
                    onChange={onChangeSelectParentClient}
                    onBlur={formik.handleBlur}
                    label="Parent Client"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          boxShadow:
                            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                        },
                      },
                    }}
                  >
                    {state.parentClientsList?.map((parentClient, index) => (
                      <MenuItem key={index} value={parentClient.clientName}>
                        {parentClient.clientName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.client?.selectedParentClient &&
                      formik.errors.client?.selectedParentClientId}
                  </FormHelperText>
                </FormControl> */}
              </Grid>
              {/* <FormControl className='field-spacing' sx={{ display: "flex" }}>
                <RadioGroup
                  sx={{ display: "flex !important", WebkitFlexDirection: "row" }}
                  defaultValue="executive"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="executive"
                    disabled={state.controls.isUpdateUsersLoading}
                    control={
                      <Radio
                        checked={formik.values.client?.assignStatus == "executive" && formik.values.client ? true : false}
                        size='small'
                      />
                    }
                    label={<Typography variant="subtitle2">Assign Executive</Typography>}
                    sx={{ width: "20%" }}
                    onChange={onChangeAssignStatus}
                  />

                  <FormControlLabel
                    value="branchManager"
                    disabled={state.controls.isUpdateUsersLoading}
                    control={
                      <Radio
                        checked={formik.values.client?.assignStatus == "branchManager" && formik.values.client ? true : false}
                        size='small'
                      />
                    }
                    label={<Typography variant="subtitle2">Assign Branch Manager</Typography>}
                    sx={{ width: "20%" }}
                    onChange={onChangeAssignStatus}
                  />
                </RadioGroup>
              </FormControl> */}
            </>
          )}

          {formik.values.role.toLowerCase() === ERoles.ORG_ADMIN && (
            <Grid sx={{ display: "flex" }} gap={5}>
              <FormControl
                fullWidth
                className="field-spacing"
                error={Boolean(
                  formik.touched.selectedBranch &&
                  formik.errors.selectedBranchId
                )}
              >
                <InputLabel id="branch-label">Branch</InputLabel>
                <Select
                  disabled={state.controls.isUpdateUsersLoading}
                  name="selectedBranch"
                  labelId="branch-label"
                  id="branch"
                  value={
                    formik.values.selectedBranch
                      ? formik.values.selectedBranch.branchCode
                      : ""
                  }
                  error={Boolean(
                    formik.touched.selectedBranch &&
                    formik.errors.selectedBranchId
                  )}
                  onChange={onChangeSelectBranch}
                  onBlur={formik.handleBlur}
                  label="Branch"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        boxShadow:
                          " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                      },
                    },
                  }}
                >
                  {state.branchList?.map((branchDetails, index) => (
                    <MenuItem
                      key={index}
                      value={branchDetails.branchCode}
                    >
                      {branchDetails.branchCode}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {" "}
                  {formik.touched.selectedBranch &&
                    formik.errors.selectedBranchId}
                </FormHelperText>
              </FormControl>

              {/* {formik.values.client?.assignStatus == "executive" && (

                <FormControl
                  fullWidth
                  className="field-spacing"
                  error={Boolean(
                    formik.touched.client?.selectedExecutive &&
                    formik.errors.client?.selectedExecutiveId
                  )}
                >
                  <InputLabel id="executive-label">Executive</InputLabel>
                  <Select
                    disabled={state.controls.isUpdateUsersLoading}
                    name="client.selectedExecutive"
                    labelId="executive-label"
                    id="client.selectedExecutive"
                    value={
                      formik.values.client?.selectedExecutive
                        ? formik.values.client?.selectedExecutive.name
                        : ""
                    }
                    error={Boolean(
                      formik.touched.client?.selectedExecutive &&
                      formik.errors.client?.selectedExecutiveId
                    )}
                    onChange={onChangeSelectExecutive}
                    onBlur={formik.handleBlur}
                    label="Executive"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          boxShadow:
                            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                        },
                      },
                    }}
                  >
                    {formik.values.executivesListByBranchId?.map(
                      (executive, index) => (
                        <MenuItem
                          key={`executive_${index}`}
                          value={executive.name}
                        >
                          {executive.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText>
                    {formik.touched.client?.selectedExecutive &&
                      formik.errors.client?.selectedExecutiveId}
                  </FormHelperText>
                </FormControl>
              )}
              {formik.values.client?.assignStatus == "branchManager" && (
                <FormControl
                  fullWidth
                  className="field-spacing"
                  error={Boolean(
                    formik.touched.client?.selectedBranchManager &&
                    formik.errors.client?.selectedBranchManagerId
                  )}
                >
                  <InputLabel id="manager-label">
                    Branch Manager
                  </InputLabel>
                  <Select
                    disabled={state.controls.isUpdateUsersLoading}
                    name="client.selectedBranchManager"
                    labelId="manager-label"
                    id="client.selectedBranchManager"
                    value={
                      formik.values.client?.selectedBranchManager
                        ? formik.values.client?.selectedBranchManager.name
                        : ""
                    }
                    error={Boolean(
                      formik.touched.client?.selectedBranchManager &&
                      formik.errors.client?.selectedBranchManagerId
                    )}
                    onChange={onChangeSelectBranchManager}
                    onBlur={formik.handleBlur}
                    label="BranchManager"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          boxShadow:
                            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                        },
                      },
                    }}
                  >
                    {formik.values.branchManagersListByBranchId?.map((manager, index) => (
                      <MenuItem key={`branchManager${index}`} value={manager.name}>
                        {manager.name}
                      </MenuItem>
                    )
                    )}
                  </Select>
                  <FormHelperText>
                    {formik.touched.client?.selectedBranchManager &&
                      formik.errors.client?.selectedBranchManagerId}
                  </FormHelperText>
                </FormControl>
              )} */}
            </Grid>
          )}

          <StatusRadioButtons
            onChangeRadio={onChangeStatus}
            isDisabled={state.controls.isUpdateUsersLoading}
            status={formik.values.status}
          />
        </CardContent>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item>
            {state.controls.isUpdateUsersLoading ?
              <Button variant="outlined">
                <CircularProgress size={15} className="spinner" />
                Updating..
              </Button>
              :
              <Button variant="contained" className="m-2" type="submit">
                Update User
              </Button>
            }
          </Grid>
          <Grid item>
            <Button
              disabled={state.controls.isUpdateUsersLoading}
              variant="contained"
              color="error"
              onClick={onClickCancelButton}
              className="m-2"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        {
          state.controls.addNewUserErrorMessage && (
            <AlertMessageComponent
              message={state.controls.addNewUserErrorMessage}
              fieldName={"addNewUserErrorMessage"}
              handleClose={onCloseErrorMessage}
              show={Boolean(state.controls.addNewUserErrorMessage)}
            />
          )
        }
      </Card >
    </form >
  );
}

export default AddUsers;
