import { Grid } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import PublicIcon from '@mui/icons-material/Public';

export default function SuggestionSimulatorComponent({ suggestion }) {
    return (

        <Grid sx={{ color: "#337ab7", display: "flex" }}>
            <Grid>
                {suggestion.typeOfAction === "url_action" && <PublicIcon />}
                {suggestion.typeOfAction === "dialer_action" && <PhoneIcon />}
            </Grid>
            <Grid paddingLeft={1}>
                {suggestion.suggestionText}
            </Grid>
        </Grid>

    )
}