import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { number } from "yup";
import CharCounter from "../../../components/CharCounter";
import { GetCity, GetCountries, GetState } from "react-country-state-city";


import DeleteIcon from '@mui/icons-material/Delete';

export default function SuggestionsComponent({
  suggestion,
  indexOfSuggstion,
  handleChange,
  handleRemoveSuggestion,
  SuggestionErrors,
  SuggestionTouched,
  SuggestionBlur,
  isDisabled,
  formik
}) {
  const reply = "reply";
  const urlAction = "url_action";
  const dialerAction = "dialer_action";
  const viewLocation = "view_location_query";
  const [countriesList, setCountriesList] = useState([]);

  const onFieldChange = (e) => {
    const { name, value } = e.target;
    // if(name==="suggestionText"){
    //   if(value.length<=25){
    //     handleChange({ field: name, value, indexOfSuggstion });

    //   }else{
    //     formik.setFieldError(name,"")
    //   }

    // }else{

    handleChange({ field: name, value, indexOfSuggstion });
    // }
  };



  const handleCountryName = value => {
    // const { name, value } = e.target;


    const country = countriesList.filter(country => {
      return country.name === value;
    })[0];


    onFieldChange({ target: { name: "phoneNumberToDial.country", value: value } })
    onFieldChange({ target: { name: "phoneNumberToDial.countryCode", value: country.phone_code } })
  };
  const onClickRemoveSuggestion = () => {
    handleRemoveSuggestion({ indexOfSuggstion });
  };



  const getCountriesName = async () => {
    await GetCountries().then(result => {
      setCountriesList(result);
    });


  };

  useEffect(() => { getCountriesName() }, [])

  console.log(countriesList, "countriesList")
  return (
    <Box
      key={indexOfSuggstion}
      sx={{
        borderRadius: 1,
        border: "2px solid rgb(209 213 219)",
        padding: 5,
      }}
      className="col"
    >
      <Grid display={"flex"} justifyContent={"end"}>
        {
          !isDisabled && (<Box >

            <IconButton
              // variant="contained"
              disabled={isDisabled}

              size="small"
              color="error"
              onClick={() => onClickRemoveSuggestion()}
            >
              <DeleteIcon />
            </IconButton>
            {/* <Button
              disabled={isDisabled}
              variant="contained"
              type="button"
              onClick={() => onClickRemoveSuggestion()}
              sx={{ borderRadius: 50 }}
              color={"error"}
            >
              X
            </Button> */}
          </Box>)
        }</Grid>
      <Box className="row ">
        <Grid className="col-3 " sx={{ mb: "21px" }}>
          <FormControl
            fullWidth
            error={
              SuggestionTouched?.typeOfAction &&
              SuggestionErrors?.typeOfAction
            }
          >
            {/* <InputLabel id="action-type">Action Type</InputLabel> */}

            <Typography fontWeight={"bold"} mb={2}>
              Action Type
            </Typography>
            <Select
              name="typeOfAction"
              onChange={onFieldChange}
              value={suggestion?.typeOfAction}
              label="Type of Action"
              labelId="action-type"
              id="demo-simple-select"
              error={Boolean(
                SuggestionTouched?.typeOfAction &&
                SuggestionErrors?.typeOfAction
              )}
              disabled={isDisabled}
              helperText={
                SuggestionTouched?.typeOfAction &&
                SuggestionErrors?.typeOfAction
              }
              onBlur={SuggestionBlur}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    GridShadow:
                      " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                  },
                },
              }}
            >
              <MenuItem value={reply}>Reply </MenuItem>
              <MenuItem value={urlAction}>URL Action</MenuItem>
              <MenuItem value={dialerAction}>Dialer Action</MenuItem>
              {/* <MenuItem value={viewLocation}>View Location</MenuItem> */}
            </Select>
            <FormHelperText>
              {SuggestionTouched?.typeOfAction &&
                SuggestionErrors?.typeOfActions}
            </FormHelperText>

          </FormControl>
        </Grid>
        {suggestion?.typeOfAction === reply && (
          <Box className="col-8 row">
            <Box className="col-8">
              <Typography >
                <b className="text-dark-50 m-2">
                  Button Text
                </b>
              </Typography>
              <TextField
                className="m-2"
                fullWidth
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                value={suggestion?.suggestionText}
                multiline
                rows={1}
                maxRows={1}
                name="suggestionText"
                onChange={onFieldChange}
                error={Boolean(
                  SuggestionTouched?.suggestionText &&
                  SuggestionErrors?.suggestionText
                )}
                helperText={
                  SuggestionTouched?.suggestionText &&
                  SuggestionErrors?.suggestionText
                }
                disabled={isDisabled}
                onBlur={SuggestionBlur}
              />

              {/* <Grid sx={{display: "flex", justifyContent: "space-between"}}> */}
              {/* <FormHelperText sx={{color:"#e90e0ede"}}  >
              {SuggestionTouched?.suggestionText &&
                  SuggestionErrors?.suggestionText}
            </FormHelperText> */}
              <Box
                sx={{
                  px: 1,
                  mx: 1,
                  // fontSize: "0.875rem",
                  // fontWeight: "700",
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                {CharCounter(suggestion?.suggestionText)}
                /25
              </Box>
              {/* </Grid>  */}
            </Box>
            {/* <Box className="col-6">
              <Typography >
                <b className="text-dark-50 m-2">
                  Button Postback
                </b>
              </Typography>
              <TextField
                className="m-2"
                fullWidth
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                multiline
                rows={1}
                maxRows={1}
                name="suggestionPostback"
                onChange={onFieldChange}
                value={suggestion?.suggestionPostback}
                error={Boolean(
                  SuggestionTouched?.suggestionPostback &&
                  SuggestionErrors?.suggestionPostback
                )}
                helperText={
                  SuggestionTouched?.suggestionPostback &&
                  SuggestionErrors?.suggestionPostback
                }
                disabled={true}
                onBlur={SuggestionBlur}
              />
              <React.Fragment>
               
                <Box
                  sx={{
                    px: 1,
                    mx: 1,
                    // fontSize: "0.875rem",
                    // fontWeight: "700",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {CharCounter(suggestion?.suggestionPostback)}
                  /120
                </Box>
              </React.Fragment>
            </Box> */}
          </Box>
        )}
        {suggestion?.typeOfAction === urlAction && <>
          <Box className="col-9 row align-items-center">
            <Box className="col-6">
              <Typography >
                <b className="text-dark-50 m-2">
                  Button Text
                </b>
              </Typography>
              <TextField
                className="m-2"
                fullWidth
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                multiline
                rows={1}
                maxRows={1}
                name="suggestionText"
                onChange={onFieldChange}
                value={suggestion?.suggestionText}
                error={Boolean(
                  SuggestionTouched?.suggestionText &&
                  SuggestionErrors?.suggestionText
                )}
                helperText={
                  SuggestionTouched?.suggestionText &&
                  SuggestionErrors?.suggestionText
                }
                onBlur={SuggestionBlur}
                disabled={isDisabled}
              />
              <React.Fragment>
                <Box
                  sx={{
                    px: 1,
                    mx: 1,
                    // fontSize: "0.875rem",
                    // fontWeight: "700",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {CharCounter(suggestion?.suggestionText)}
                  /25
                </Box>
              </React.Fragment>
            </Box>
            {/* <Box className="col-6">
              <Typography >
                <b className="text-dark-50 m-2">
                  Button Postback
                </b>
              </Typography>
              <TextField
                className="m-2"
                fullWidth
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                multiline
                rows={1}
                maxRows={1}
                name="suggestionPostback"
                onChange={onFieldChange}
                value={suggestion?.suggestionPostback}
                error={Boolean(
                  SuggestionTouched?.suggestionPostback &&
                  SuggestionErrors?.suggestionPostback
                )}
                helperText={
                  SuggestionTouched?.suggestionPostback &&
                  SuggestionErrors?.suggestionPostback
                }
                onBlur={SuggestionBlur}
                disabled={true}
              />
              <React.Fragment>
                <Box
                  sx={{
                    px: 1,
                    mx: 1,
                    // fontSize: "0.875rem",
                    // fontWeight: "700",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {CharCounter(suggestion?.suggestionPostback)}
                  /120
                </Box>
              </React.Fragment>
            </Box> */}
          </Box>
          <Box className="col-9">
            <Typography >
              <b className="text-dark-50 m-2">URL to Open</b>
            </Typography>
            <TextField
              className="m-2"
              fullWidth
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              multiline
              rows={1}
              maxRows={1}
              name="urlAction"
              onChange={onFieldChange}
              value={suggestion?.urlAction}
              error={Boolean(
                SuggestionTouched?.urlAction &&
                SuggestionErrors?.urlAction
              )}
              helperText={
                SuggestionTouched?.urlAction &&
                SuggestionErrors?.urlAction
              }
              onBlur={SuggestionBlur}
              disabled={isDisabled}
            />
          </Box>
        </>}
        {suggestion.typeOfAction === dialerAction && <>
          <Box className="col-9 row">
            <Box className="col-6">
              <Typography >
                <b className="text-dark-50 m-2">
                  Button Text
                </b>
              </Typography>
              <TextField
                className="m-2"
                fullWidth
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                multiline
                rows={1}
                maxRows={1}
                name="suggestionText"
                onChange={onFieldChange}
                value={suggestion?.suggestionText}
                error={Boolean(
                  SuggestionTouched?.suggestionText &&
                  SuggestionErrors?.suggestionText
                )}
                helperText={
                  SuggestionTouched?.suggestionText &&
                  SuggestionErrors?.suggestionText
                }
                onBlur={SuggestionBlur}
                disabled={isDisabled}
              />
              <React.Fragment>
                <Box
                  sx={{
                    px: 1,
                    mx: 1,
                    // fontSize: "0.875rem",
                    // fontWeight: "700",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {CharCounter(suggestion?.suggestionText)}
                  /25
                </Box>
              </React.Fragment>
            </Box>
            {/* <Box className="col-6">
              <Typography >
                <b className="text-dark-50 m-2">
                  Button Postback
                </b>
              </Typography>
              <TextField
                className="m-2"
                fullWidth
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                multiline
                rows={1}
                maxRows={1}
                name="suggestionPostback"
                onChange={onFieldChange}
                value={suggestion?.suggestionPostback}
                error={Boolean(
                  SuggestionTouched?.suggestionPostback &&
                  SuggestionErrors?.suggestionPostback
                )}
                helperText={
                  SuggestionTouched?.suggestionPostback &&
                  SuggestionErrors?.suggestionPostback
                }
                onBlur={SuggestionBlur}
                disabled={true}
              />
              <React.Fragment>
                <Box
                  sx={{
                    px: 1,
                    mx: 1,
                    // fontSize: "0.875rem",
                    // fontWeight: "700",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {CharCounter(suggestion?.suggestionPostback)}
                  /120
                </Box>
              </React.Fragment>
            </Box> */}
          </Box>

          <Box className="col-3">
            <Typography >
              <b className="text-dark-50 m-2">
                Country Name
              </b>
            </Typography>
            <Select
              className="m-2"

              id='countryId'
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              name='phoneNumberToDial.country'
              disabled={isDisabled}

              value={suggestion.phoneNumberToDial?.country}
              onBlur={SuggestionBlur}
              onChange={e => {
                handleCountryName(e.target.value);
              }}




              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "250px", // Adjust the maxHeight as per your requirement
                    width: "230px",
                  },
                },
              }}
            >
              {/* <Grid height={'250px'} width={'225px'}> */}
              {countriesList.map(item => (

                <MenuItem value={item.name} sx={{ display: "grid", placeItems: "center", fontSize: "0.8rem" }}>
                  {item.name}
                </MenuItem>
              ))}
              {/* </Grid> */}
            </Select>
          </Box>
          <Box className="col-3">
            <Typography >
              <b className="text-dark-50 m-2">
                Country Code
              </b>
            </Typography>
            <TextField
              className="m-2"

              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              name='phoneNumberToDial.countryCode'
              disabled={isDisabled}

              value={suggestion.phoneNumberToDial?.countryCode}
              onBlur={SuggestionBlur}
            />
          </Box>


          <Box className="col-5">
            <Typography >
              <b className="text-dark-50 m-2">
                Phone Number To Dial
              </b>
            </Typography>
            <TextField
              className="m-2"

              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              multiline
              type={number}
              value={suggestion.phoneNumberToDial?.number}
              rows={1}
              maxRows={1}
              name="phoneNumberToDial.number"
              onChange={onFieldChange}
              disabled={isDisabled}
              error={Boolean(
                SuggestionTouched?.phoneNumberToDial
                  ?.number &&
                SuggestionErrors?.phoneNumberToDial
                  ?.number
              )}
              helperText={
                SuggestionTouched?.phoneNumberToDial
                  ?.number &&
                SuggestionErrors?.phoneNumberToDial?.number
              }
              onBlur={SuggestionBlur}
            />
          </Box>
        </>}
        {suggestion.typeOfAction === viewLocation && <>
          <Box className="col-9 row">
            <Box className="col-6">
              <Typography >
                <b className="text-dark-50 m-2">
                  Button Text
                </b>
              </Typography>
              <TextField
                className="m-2"
                fullWidth
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                multiline
                rows={1}
                maxRows={1}
                name="suggestionText"
                onChange={onFieldChange}
                value={suggestion?.suggestionText}
                error={Boolean(
                  SuggestionTouched?.suggestionText &&
                  SuggestionErrors?.suggestionText
                )}
                helperText={
                  SuggestionTouched?.suggestionText &&
                  SuggestionErrors?.suggestionText
                }
                onBlur={SuggestionBlur}
                disabled={isDisabled}
              />
              <React.Fragment>
                <Box
                  sx={{
                    px: 1,
                    mx: 1,
                    // fontSize: "0.875rem",
                    // fontWeight: "700",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {CharCounter(suggestion?.suggestionText)}
                  /25
                </Box>
              </React.Fragment>
            </Box>
            <Box className="col-6">
              <Typography >
                <b className="text-dark-50 m-2">
                  Button Postback
                </b>
              </Typography>
              <TextField
                className="m-2"
                fullWidth
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                multiline
                rows={1}
                maxRows={1}
                name="suggestionPostback"
                onChange={onFieldChange}
                value={suggestion?.suggestionPostback}
                error={Boolean(
                  SuggestionTouched?.suggestionPostback &&
                  SuggestionErrors?.suggestionPostback
                )}
                helperText={
                  SuggestionTouched?.suggestionPostback &&
                  SuggestionErrors?.suggestionPostback
                }
                onBlur={SuggestionBlur}
                disabled={true}
              />
              <React.Fragment>
                <Box
                  sx={{
                    px: 1,
                    mx: 1,
                    // fontSize: "0.875rem",
                    // fontWeight: "700",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {CharCounter(suggestion?.suggestionPostback)}
                  /120
                </Box>
              </React.Fragment>
            </Box>
          </Box>
          <Box className="col-9">
            <Typography >
              <b className="text-dark-50 m-2">
                Location Query
              </b>
            </Typography>
            <TextField
              className="m-2"
              fullWidth
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              multiline
              type={number}
              value={suggestion?.viewLocation}
              rows={1}
              maxRows={1}
              name="viewLocation"
              onChange={onFieldChange}
              disabled={isDisabled}
              error={Boolean(
                SuggestionTouched?.viewLocation &&
                SuggestionErrors?.viewLocation
              )}
              helperText={
                SuggestionTouched?.viewLocation &&
                SuggestionErrors?.viewLocation
              }
              onBlur={SuggestionBlur}
            />
          </Box>
        </>}
      </Box>
      {/* <hr /> */}

    </Box>
  );
}
