

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GppGoodIcon from '@mui/icons-material/GppGood';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Divider, Grid, IconButton } from "@mui/material";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import Battery90Icon from '@mui/icons-material/Battery90';
import CircleIcon from '@mui/icons-material/Circle';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ImageIcon from '@mui/icons-material/Image';
import NetworkCellIcon from '@mui/icons-material/NetworkCell';
import NetworkWifi3BarIcon from '@mui/icons-material/NetworkWifi3Bar';
import { getStatusToDisplay, MessageStatus } from '../../../config';
import FileMessageSimulator from './FileMessageSimulator';
import VideoMessageComponent from './VideoMessageComponent';
import ImageMessageComponent from './imageMessageComponent';
import { RichCardCarouselCardCampaignSimulator } from './richCardCarouselCardCampaignSimulator';
import { RichCardStandAloneCampaignSimulator } from './richCardStandAloneCampaignSimulator';
import { TextMessageCampaignSimulator } from './textMessageCampaignSimulator';
import UserMediaSimulator from './userMediaSimulator';


export function UserSuggestonResponseSimulator({ textMessage }) {
    return (



        <Grid fullWidth sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant='inherit'
                textColor="var(--joy-palette-success-plainColor)"
                fontFamily="monospace"
                sx={{ opacity: '50%' }}
                padding={1}
                paddingBottom={4}

            >User Clicked on :</Typography>
            <Typography variant='inherit'
                textColor="var(--joy-palette-success-plainColor)"
                fontFamily="monospace"

                padding={1}
                paddingBottom={4}
                color={"#0854e7"}

            >

                {textMessage}
            </Typography>
            <Typography variant='inherit'
                textColor="var(--joy-palette-success-plainColor)"
                fontFamily="monospace"
                sx={{ opacity: '50%' }}
                padding={1}
                paddingBottom={4}

            >

                Button
            </Typography>
        </Grid>

    )
}


export function BotConversationLogSimulator({ campaignDetails, contentMessage, botLogoImage, status ,campaignSimulator}) {

    const formatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    });


    function convertUTCDateToLocalDate(date, index) {
        var newDate = new Date(date);
        return formatter.format(newDate);
    }

    const calculateTime = (value) => {
        const date = new Date(value);

        let newTime = date.setHours(date.getHours());
        newTime = date.setMinutes(date.getMinutes());
        const time = new Date(newTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        return time;
    };

    return (
        <Grid>



            <Grid fullWidth sx={{ display: "flex", justifyContent: "center" }}>
                <Typography level="body-xs"
                    textColor="var(--joy-palette-success-plainColor)"
                    fontFamily="monospace"
                    sx={{ opacity: '50%' }}
                    padding={1}
                    paddingBottom={4}

                >
                    {campaignDetails?.sendTime && convertUTCDateToLocalDate(campaignDetails?.sendTime).split(',')[0]}&nbsp;
                    {campaignDetails?.sendTime && calculateTime(campaignDetails?.sendTime)}
                </Typography>
            </Grid>



            {campaignDetails.messagePayload.type === "image" && <ImageMessageComponent imageData={campaignDetails.messagePayload.payload.contentMessage.contentInfo} contentMessage={campaignDetails.messagePayload.payload.contentMessage} />}
            {campaignDetails.messagePayload.type === "video" && <VideoMessageComponent videoData={campaignDetails.messagePayload.payload.contentMessage.contentInfo} />}
            {campaignDetails.messagePayload.type === "document" && <FileMessageSimulator documentData={campaignDetails.messagePayload.payload.contentMessage.contentInfo} />}



            {campaignDetails.messagePayload.type === "media" && <UserMediaSimulator sentBy={campaignDetails.sentBy} thumbnail={campaignDetails.messagePayload.payload.contentMessage.thumbnail} media={campaignDetails.messagePayload.payload.contentMessage.mediaPayload} />}



            {(campaignDetails.messagePayload.type === "button_with_text" || campaignDetails.messagePayload.type === "text_message" || campaignDetails.messagePayload.type === "text") && <TextMessageCampaignSimulator botLogoImage={botLogoImage} contentMessage={campaignDetails.messagePayload.payload.contentMessage} sentBy={campaignDetails.sentBy} />}


            {campaignDetails.messagePayload.type === "suggestionResponse" && <UserSuggestonResponseSimulator textMessage={campaignDetails.messagePayload.payload.contentMessage.text} />}
            {campaignDetails.messagePayload.type === "suggestionResponse" && campaignDetails.messagePayload.payload.contentMessage.type === "REPLY" && <TextMessageCampaignSimulator botLogoImage={botLogoImage} contentMessage={campaignDetails.messagePayload.payload.contentMessage} sentBy={campaignDetails.sentBy} />}

            {campaignDetails.messagePayload.type === "rich_card" && <RichCardStandAloneCampaignSimulator contentMessage={contentMessage} campaignSimulator={campaignSimulator} />}



            {campaignDetails.messagePayload.type === "carousel" && <RichCardCarouselCardCampaignSimulator richCardCarouselCard={contentMessage.richCard.carouselCard} />}



            {!(campaignDetails.messagePayload.type === "suggestionResponse" && campaignDetails.messagePayload.payload.contentMessage.type !== "REPLY") &&
                < Grid item sx={{ display: "flex", justifyContent: campaignDetails.sentBy === 'user' ? 'end' : 'start' }} >
                    <Typography level="body-xs"
                        sx={{ opacity: '50%' }}
                        padding={1}
                        paddingLeft={2}
                        fontFamily="monospace"

                    >
                        {calculateTime(campaignDetails?.sendTime)},
                    </Typography>
                    <Typography level="body-xs"
                        sx={{ opacity: '80%' }}
                        padding={1}

                        color={
                            status === MessageStatus.Failed || status === MessageStatus.TTLExpirationRevoked ? "error" :
                                status === MessageStatus.Delivered ? "green" :
                                    status === MessageStatus.Pending ? "orange" :
                                        status === MessageStatus.Sent ? "primary" :
                                            status === MessageStatus.Read ? "darkblue" : "blue"
                        }
                    > {getStatusToDisplay(status)}
                    </Typography>
                    {campaignDetails?.provider && <Typography level="body-xs"
                        sx={{ opacity: '80%' }}
                        padding={1}>Sent By : {campaignDetails?.provider.toUpperCase()}
                    </Typography>
                    }
                </Grid>
            }

        </Grid >
    )
}




export default function CampaignSimulator({ open, setShowCampaignSimulator, campaignDetails, campaignData, contentMessage }) {
    const [index, setIndex] = React.useState(0);

    const handleClose = () => {
        setShowCampaignSimulator(false);
    }

    const formatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    });


    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date);
        return formatter.format(newDate);
    }

    const calculateTime = (value) => {
        const date = new Date(value);

        let newTime = date.setHours(date.getHours());
        newTime = date.setMinutes(date.getMinutes());
        const time = new Date(newTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        return time;
    };




    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xs'}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}


            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 60,
                        top: 0,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers padding={2}  >
                    <Grid

                        padding={2}

                        md={3.5}
                        item

                        maxWidth={"22vw"}

                    >
                        <Grid
                            elevation={5}
                            sx={{
                                minHeight: "83vh",

                                minWidth: "20vw",
                                borderRadius: "30px",
                                height: "80vh",
                                maxHeight: "80vh",
                                minHeight: "80vh",
                                border: "2px solid black",
                                backgroundColor: "white",
                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"
                                // boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"

                            }}
                        >
                            <Grid container sx={{
                                display: "flex", flexDirection: "column", height: "80vh",
                                maxHeight: "80vh",
                                minHeight: "80vh",
                                width: "100%",
                                padding: 1
                            }}>
                                <Grid item sx={{ width: "100%" }} >
                                    <Grid container paddingTop={3} paddingX={4} display={"flex"} justifyContent={"space-between"} sx={{ width: "100%" }}>
                                        <Grid item md={5} sx={{ display: "flex", alignItems: "center" }}>

                                            <Typography variant='caption'>
                                                {calculateTime(new Date().toString())}
                                            </Typography>
                                        </Grid>

                                        <Grid item md={1}>

                                            <CircleIcon />
                                        </Grid>
                                        <Grid item md={5} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                                            <NetworkWifi3BarIcon fontSize='small' />
                                            <NetworkCellIcon fontSize='small' />
                                            <Battery90Icon fontSize='small' />
                                            <Typography variant='caption'>
                                                90%
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item >
                                    <Grid
                                        item
                                        md={11}
                                        height={"66vh"}
                                        minHeight={"66vh"}
                                        maxHeight={"66vh"}
                                        width={"100%"}
                                        minWidth={"100%"}




                                    >
                                        <Box sx={{ margin: 1, minHeight: "67vh", maxHeight: "67vh", backgroundColor: "white", overflow: "hidden" }}>
                                            <Grid container sx={{ minHeight: "4vh", padding: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Grid itme md={1} ><ArrowBackIcon /></Grid>
                                                <Grid itme md={9} sx={{ display: "flex", textAlign: "left" }}>
                                                    <Box sx={{ borderRadius: "50%", overflow: "hidden", marginRight: 2, boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;", }}>

                                                        <img src={campaignData.otherDetails.botDetails.botLogoImage.url} height={"25px"} width={"25px"} />
                                                    </Box>
                                                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>

                                                        {campaignData.botName}
                                                    </Typography>
                                                </Grid>
                                                <Grid itme md={2}><GppGoodIcon /><MoreVertIcon /></Grid>

                                            </Grid>
                                            <Divider />

                                            <Grid container fullWidth padding={2} sx={{ overflow: "scroll", maxHeight: "61vh", }} >
                                                <Grid fullWidth sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>

                                                    {/* <img src={campaignData.otherDetails.botDetails.botLogoImage.url} height={"50px"} width={"50px"} /> */}
                                                    <div className="play-button-container" style={{ position: "relative" }}>
                                                        <Box sx={{
                                                            borderRadius: "50%", overflow: "hidden", marginRight: 2, boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
                                                            height: "50px", width: "50px"
                                                        }}>
                                                            <img src={campaignData.otherDetails.botDetails.botLogoImage.url} height={"50px"} width={"50px"} display={'block'} />
                                                        </Box>
                                                        <div className="play-button-overlay" style={{ position: "absolute", top: "80%", left: "80%", transform: "translate(-50%, -50%)" }}>
                                                            {/* You can replace the content inside the overlay div with your own play button logo */}
                                                            <GppGoodIcon color="primary" />
                                                        </div>
                                                    </div>


                                                    <Typography level="body-xs"
                                                        textColor="var(--joy-palette-success-plainColor)"

                                                        sx={{ opacity: '50%' }}
                                                        align="center"
                                                        padding={1}
                                                    >
                                                        {campaignData.otherDetails.botDetails.shortDescription}
                                                    </Typography>

                                                </Grid>

                                                <BotConversationLogSimulator campaignDetails={campaignDetails} contentMessage={contentMessage} botLogoImage={campaignData.otherDetails.botDetails.botLogoImage.url} status={campaignDetails.messageStatus} campaignSimulator={true} />





                                            </Grid>
                                            <Divider />


                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item >
                                    <Grid container padding={2} display={'flex'} alignItems={"center"} justifyContent={"space-between"}>
                                        <Grid item md={1}>

                                            <AttachFileIcon />
                                        </Grid>
                                        <Grid item md={1}>

                                            <ImageIcon />
                                        </Grid>
                                        <Grid item md={9}>
                                            <Box display={'flex'} alignItems={"center"} sx={{ border: "1px solid grey", width: "100%", height: "35px", borderRadius: "20px", padding: 3, justifyContent: "space-between" }}>
                                                <Typography sx={{ opacity: "50%" }}>
                                                    RCS message
                                                </Typography>
                                                <EmojiEmotionsIcon />

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid >
                    </Grid >
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions> */}
            </Dialog>
        </React.Fragment>
    );
}







