import { generate } from 'short-uuid';


export function generateUniqueIdForCard() {
    return generate();
}

export function generateUniqueId() {
    return generate();
}


export function readFromLocalStorge({ keyname }) {
    if (localStorage.getItem(keyname) !== null) {
        let result = JSON.parse(localStorage.getItem(keyname));
        // return value = JSON.parse(localStorage.getItem(keyname));
        return { nodes: result.nodes, edges: result.edges };
    }
    else {
        return { nodes: [], edges: [] };
    }
}

export function setItemInLocalStorage({ keyname, value }) {
    localStorage.setItem(keyname, JSON.stringify(value));
}

export const getConditions = () => {
    const conditions = [
        { key: "LESSTHAN", value: "Less Than" },
        { key: "LESSTHANEQUALTO", value: "Less Than Equal To" },
        { key: "GREATERTHAN", value: "Greater Than" },
        { key: "GREATERTHANEQUALTO", value: "Greater Than Equal To" },
        { key: "EQUALTO", value: "Equal To" },
        { key: "NOTEQUALTO", value: "Not Equal To" },
    ]

    return conditions;
}