import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import { useDebounce } from "../../components/useDebounce";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.grey[100], 0.5),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),

        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "30ch",
        },
    },
}));

export default function UsersList({
    selectedUserNumber,
    usersList,
    selectedBot,
    onSelectUser,
    loading,
    numberListOfUser,
    setSearchValue,
    searchValue,
    botId


}) {
    const [userNumber, setUserNumber] = useState([...numberListOfUser]);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [recipientNumbers, setRecipientNumbers] = useState([]);
    const [fetchingRecipientNumber, setFetchingRecipientNumber] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [isThereMoreNumber, setIsThereMoreNumber] = useState(true);


    const searchRecipientNumber = useDebounce(searchText, 1000);


    // useEffect(() => {
    //     if (selectedBot && selectedBot !== "") {

    //         setUserNumber([...numberListOfUser]);
    //     }
    // }, [selectedBot]);

    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    // ------------------------------- code change -------------------

    const handleNumberListScroll = async (e) => {

        const userListScrollPosition = e.currentTarget;
        const position = userListScrollPosition.scrollTop + userListScrollPosition.clientHeight;

        if (userListScrollPosition.scrollHeight - position <= 1 && !fetchingRecipientNumber && isThereMoreNumber) {
            setPageNumber(pageNumber + 1)
            fetchRecipientListByBotId(pageNumber + 1)
        }
    }

    const fetchRecipientListByBotId = async (pageNumber) => {
        try {
            setFetchingRecipientNumber(true);

            const recipientListResponse = await apiGatewayAxiosInstance.get(`conversation_logger/logger/get-numbers-by-botId`, {
                params: { botId: selectedBot.config.rbmClientId, pageSize, pageNumber, searchText },
            });

            if (recipientListResponse.status === 200) {
                if (recipientListResponse?.data?.NumberList && recipientListResponse?.data?.NumberList.length > 0) {

                    let recipientData = []
                    if (pageNumber === 1) {
                        recipientData = [...recipientListResponse.data.NumberList]
                    } else {
                        recipientData = [...recipientNumbers, ...recipientListResponse.data.NumberList]
                    }
                    setRecipientNumbers(recipientData);
                } else {
                    setIsThereMoreNumber(false);

                }

            }

            setFetchingRecipientNumber(false);

        } catch (error) {
            setFetchingRecipientNumber(false);
        }
    }


    useEffect(() => {

        if (!fetchingRecipientNumber && selectedBot) {
            setPageNumber(1)
            setIsThereMoreNumber(true);
            setRecipientNumbers([])
            fetchRecipientListByBotId(1);
        }


    }, [botId, searchRecipientNumber])


    return (
        <Grid>
            <Grid padding={1.4} display={"flex"} justifyContent={"center"}>
                <Typography variant="h4">
                    {/* Users */}
                    <Search sx={{ border: "1px solid gray" }}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder=" Search Number"
                            inputProps={{ "aria-label": "search" }}
                            value={searchText}
                            onChange={handleChange}
                        />
                    </Search>
                </Typography>
            </Grid>
            <hr style={{ margin: "0px", padding: "0px" }} />
            <Grid
                style={{
                    height: "55vh",
                    overflowY: "scroll",
                    paddingX: "10px",
                }}
                onScroll={(e) => handleNumberListScroll(e)} >



                {
                    <>
                        {

                            (recipientNumbers.length > 0 || fetchingRecipientNumber) ? (
                                <>
                                    {recipientNumbers.map((user, index) => (
                                        <Grid
                                            onClick={() => onSelectUser(user)}
                                            className={
                                                user === selectedUserNumber
                                                    ? "selected-bot-item"
                                                    : "select-bot-item"
                                            }
                                            display={"flex"}
                                            padding={5}
                                            alignItems={"center"}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            <PersonOutlineOutlinedIcon />
                                            <Typography paddingLeft={2} variant="subtitle1">
                                                {user}
                                            </Typography>
                                        </Grid>
                                    ))}
                                    {fetchingRecipientNumber && (
                                        <Stack alignItems="center" justifyContent="center">
                                            <CircularProgress />
                                        </Stack>
                                    )}
                                </>

                            ) : selectedBot === "" ? (
                                <Grid
                                    alignItems={"center"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    height={"100%"}
                                >
                                    <Typography variant="h5">Select Bot</Typography>
                                </Grid>
                            ) : (
                                <Grid
                                    alignItems={"center"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    height={"100%"}
                                >
                                    <Typography variant="h5">No Number Found</Typography>
                                </Grid>
                            )
                        }
                    </>
                }
            </Grid >
        </Grid >
    );
}
