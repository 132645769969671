import { CampaignOutlined } from "@mui/icons-material";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";

import CachedIcon from "@mui/icons-material/Cached";
import { CampaignStatus, ERoles } from "../../config";
import {
    clearErrorMessagesForCampaigns,
    setErroMessageForField,
    setShowAddCampaignForm,
    setShowRunCampaingLoading,
} from "../../redux/campaignManagment/campaignManagmentSlice";
import { getAllCampaignByFilterThunk, getAllCampaignsByPageSize, runCampaign } from "../../redux/campaignManagment/campaignManagmentThunk";
import { fetchCurrentBalanceByClientId } from "../../redux/userManagement/userManagementThunk";
import { apiGatewayAxiosInstance } from "../../utils/axios";
import AlertMessageComponent from "../components/AlertMessageComponent";
import FetchingSpinner from "../components/FetchingSpinner";
import NoRecords from "../components/NoRecords";
import StyledMenu from "../tables/StyledMenu";
import FilterAllCampaignsModel from "./FilterCampaignModel";
import RunCampaignModel from "./runCampaignModel";
// import { CampaignOutlined } from '@material-ui/icons';
// import "react-datepicker/dist/react-datepicker.css";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import TablePaginationShowPage from "../../utils/tablePagination";
import CancelScheduleCampaignModel from "./CancelScheduleCampaignModel";
import ScheduleCampaignModel from "./ScheduleCampaignModel";

const calculateTime = value => {
    const time = new Date(value).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    });
    const day = new Date(value).toLocaleDateString([], { day: "2-digit" });
    const month = new Date(value).toLocaleDateString([], { month: "2-digit" });
    const year = new Date(value).toLocaleDateString([], { year: "numeric" });

    return (
        <Typography className='tableContentFontForDateAndTime'>
            {day}-{month}-{year} <br />
            {time}
        </Typography>
    );
    // return (day + "-" + month + "-" + year + " , " + time);
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 16,
        backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const ListCampaigns = ({
    searchParams,
    setSearchParams,
    handleShowReport,
    handleViewCampaignDetails,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
}) => {
    const { user } = useAuth();
    const state = useSelector(state => state.campaignManagmentSlice);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = anchorEl?.id.split("@")[1];
    const [openModal, setOpenModal] = React.useState(false);
    const [opencalendarModel, setOpenCalendarModel] = useState(false);
    const [openCancelScheduleCampaignModel, setOpenCancelScheduleCampaignModel] = useState(false);

    const [isGetAllocatedAmountLoading, setIsGetAllocatesAmountLoading] = useState(false);
    const [campaignToRun, setCampaignToRun] = useState({});
    const [openFilter, setOpenFilter] = React.useState(false);

    const [reload, setReload] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleClickOpenFilterModel = () => {
        setOpenFilter(true);
    };

    const handleCloseFilterModel = () => {
        setOpenFilter(false);
    };

    const handleClickRunCampaignModal = campaign => {
        setOpenModal(true);
        getAllocatedEstimateAmountForCampaign(campaign._id);
    };

    const handleCloseRunCampaignModal = () => {
        setOpenModal(false);
        setAnchorEl(null);
    };

    const handleCloseCalender = () => {
        setOpenCalendarModel(false);
    };

    const handleCloseCancelScheduleCampaignModel = () => {
        setOpenCancelScheduleCampaignModel(false);
    };

    const scheduleCampaign = async ({ campaign, timeDifferenceInSeconds, scheduleCampaignTime }) => {
        const data = {
            botId: campaign.botId,
            campaignId: campaign._id,
            scheduleTimeInSecond: timeDifferenceInSeconds,
            scheduleCampaignTime,
            org: user.orgDetails,
        };
        try {
            const response = await apiGatewayAxiosInstance.post("/bot_management/campaign/schedule-campaign", data);
            if (response.status === 200) {
                dispatch(
                    getAllCampaignsByPageSize({
                        currentPage: currentPage + 1,
                        pageSize,
                    }),
                );
                setAnchorEl(null);

                dispatch(setShowRunCampaingLoading());
                handleCloseCalender();
                dispatch(fetchCurrentBalanceByClientId());

                return true;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Failed to schedule campaign.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

            return false;
        }
    };
    const cancelScheduleCampaign = async campaignId => {
        try {
            const response = await apiGatewayAxiosInstance.get("/bot_management/campaign/cancel-schedule-campaign", { params: { campaignId } });
            if (response.status === 200) {
                dispatch(
                    getAllCampaignsByPageSize({
                        currentPage: currentPage + 1,
                        pageSize,
                    }),
                );
                setAnchorEl(null);

                dispatch(setShowRunCampaingLoading());
                setOpenCancelScheduleCampaignModel(false);
                dispatch(fetchCurrentBalanceByClientId());

                return true;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Failed to schedule campaign.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

            return false;
        }
    };
    const getAllocatedEstimateAmountForCampaign = async campaignId => {
        try {
            setIsGetAllocatesAmountLoading(true);
            const allocatedAmountCampaignDetailsResponse = await apiGatewayAxiosInstance.post(
                "/bot_management/campaign/allocate-amount-to-campaign",
                {
                    campaignId,
                    org: user.orgDetails,
                },
            );
            if (allocatedAmountCampaignDetailsResponse.status === 200) {
                setCampaignToRun({
                    ...allocatedAmountCampaignDetailsResponse.data.data.updatedCampaignDetails,
                    totalRcsEnabledNumbers: allocatedAmountCampaignDetailsResponse.data.data.totalRcsEnabledNumbers,
                    remainingCurrentBalanceAfterDeduction: allocatedAmountCampaignDetailsResponse.data.data.remainingCurrentBalanceAfterDeduction,
                    messageCountAbleToSend: allocatedAmountCampaignDetailsResponse.data.data.messageCountAbleToSend,
                });
                setIsGetAllocatesAmountLoading(false);
            } else {
                throw new Error("Failed to allocate amount to camapign");
            }
        } catch (error) {
            setOpenModal(false);
            let errorMessage = "Server not reachable";
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            }
            dispatch(
                setErroMessageForField({
                    fieldName: "getAllocatedAmountForCampaignErrorMessage",
                    errorMessage,
                }),
            );
            setAnchorEl(null);
            setIsGetAllocatesAmountLoading(false);
        }
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //   const  handleScheduleCampaign=(campaignData,select)

    const handleRunCampaign = campaignData => {
        const data = {
            botId: campaignData.botId,
            campaignId: campaignData._id,
            org: user.orgDetails,
        };
        dispatch(runCampaign({ ...data }));
        // window.location.reload();
        dispatch(
            getAllCampaignsByPageSize({
                currentPage: currentPage + 1,
                pageSize,
            }),
        );
        setAnchorEl(null);
        setTimeout(() => {
            dispatch(setShowRunCampaingLoading());
            setOpenModal(false);
            dispatch(fetchCurrentBalanceByClientId());
        }, 2000);
    };
    // const scheduleCampaign = async ({ campaign, timeDifferenceInSeconds, scheduleCampaignTime }) => {

    //     const data = {
    //         botId: campaign.botId,
    //         campaignId: campaign._id,
    //         scheduleTimeInSecond: timeDifferenceInSeconds,
    //         scheduleCampaignTime
    //     };
    //     try {
    // const response = await apiGatewayAxiosInstance.post(
    // "/bot_management/campaign/schedule-campaign",
    //             data
    //         );
    //         if (response.status === 200) {
    //             dispatch(
    //                 getAllCampaignsByPageSize({
    //                     currentPage: currentPage + 1,
    //                     pageSize,
    //                 })
    //             );
    //             setAnchorEl(null);

    //             dispatch(setShowRunCampaingLoading());
    //             handleCloseCalender();
    //             dispatch(fetchCurrentBalanceByClientId());

    //             return true
    //         } else {
    //             throw new Error(response);
    //         }
    //     } catch (error) {
    //         if (error.response) {
    //             setErrorResopnse(error.response.data.message);
    //         } else {
    //             setErrorResopnse("Server not reachable");
    //         }
    //         return false
    //     }

    // };
    // // const handleScheduleCampaign = (selectedDate) => {

    // };

    const onClickAddNewCampaign = () => {
        dispatch(setShowAddCampaignForm(true));
    };

    const handlePageChange = page => {
        setCurrentPage(page);
        dispatch(getAllCampaignsByPageSize({ currentPage: page + 1, pageSize }));
    };

    useEffect(() => {
        // dispatch(
        //     getAllCampaignsByPageSize({
        //         currentPage: currentPage + 1,
        //         pageSize,
        //     })
        // );

        dispatch(
            getAllCampaignByFilterThunk({
                currentPage: currentPage + 1,
                pageSize,
                searchParams,
            }),
        );
    }, [currentPage, pageSize, reload]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForCampaigns({ fieldName }));
    };

    const handleCalenderPopUp = campaign => {
        getAllocatedEstimateAmountForCampaign(campaign._id);

        setOpenCalendarModel(true);
    };

    const handleCancelScheduleCampaign = campaign => {
        setCampaignToRun(campaign);

        setOpenCancelScheduleCampaignModel(true);
    };

    const handleClickSearch = searchParams => {
        setCurrentPage(0);
        setPageSize(10);
        setSearchParams(searchParams);
        dispatch(
            getAllCampaignByFilterThunk({
                currentPage: 1,
                pageSize: 10,
                searchParams,
            }),
        );
        handleCloseFilterModel();
    };
    const reloadList = () => {
        setReload(!reload);
    };

    return (
        <Grid>
            <Grid container mb={4} className='listHeader'>
                <Grid item md={6} className='align-items-center'>
                    <Typography variant='h3'>
                        <b>Campaigns</b>
                    </Typography>
                </Grid>

                <Grid item md={6} className='listHeaderFilter'>
                    <Grid display={"flex"} flexDirection={"row"} gap={2}>
                        <IconButton
                            title='Refresh'
                            onClick={reloadList}
                            sx={{
                                animation: state.controls.isGetAllCampaignsLoading ? "spin 0.5s linear infinite" : "",
                                "@keyframes spin": {
                                    "0%": {
                                        transform: "rotate(360deg)",
                                    },
                                    "100%": {
                                        transform: "rotate(0deg)",
                                    },
                                },
                                // marginInline: "20px",
                            }}
                        >
                            <CachedIcon />
                        </IconButton>
                        <Button variant='contained' onClick={handleClickOpenFilterModel}>
                            Filter
                        </Button>
                        {user && user.roleName.toLowerCase() !== ERoles.SUPER_ADMIN && (
                            <>
                                <Button
                                    variant='contained'
                                    onClick={onClickAddNewCampaign}
                                // disabled={
                                //     state.totalCampaignsCount &&
                                //     state.totalCampaignsCount >=
                                //     50
                                // }
                                >
                                    Add New Campaign
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Card className='border border-secondary-50'>
                <CardContent sx={{
                    padding: 0,
                }}>
                    {/* <Grid container display={"flex"} justifyContent={"space-between"} className='text-primary align-items-center'>
                        <Grid item display={"flex"} alignItems={"center"} className='ms-2 me-2'>
                            <Grid padding={1}>
                                <CampaignOutlined />
                            </Grid>
                            <Typography padding={1} variant='h4'>
                                <b>Campaigns List</b>
                            </Typography>

                        </Grid>
                        <Grid item width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                            <Grid display={"flex"} flexDirection={"row"} gap={2}>
                                <IconButton
                                    title='Refresh'
                                    onClick={reloadList}
                                    sx={{
                                        animation: state.controls.isGetAllCampaignsLoading ? "spin 0.5s linear infinite" : "",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(360deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(0deg)",
                                            },
                                        },
                                        // marginInline: "20px",
                                    }}
                                >
                                    <CachedIcon />
                                </IconButton>
                                <Button variant='contained' onClick={handleClickOpenFilterModel}>
                                    Filter
                                </Button>
                                {user && user.roleName.toLowerCase() !== ERoles.SUPER_ADMIN && (
                                    <>
                                        <Button
                                            variant='contained'
                                            onClick={onClickAddNewCampaign}
                                            // disabled={
                                            //     state.totalCampaignsCount &&
                                            //     state.totalCampaignsCount >=
                                            //     50
                                            // }
                                        >
                                            Add New Campaign
                                        </Button>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid> */}
                    {/* <hr /> */}

                    {state.controls.isGetAllCampaignsLoading ? (
                        <FetchingSpinner />
                    ) : state.campaignListByPageSize && state.campaignListByPageSize.length > 0 ? (
                        <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "70vh" }}>
                            <Table stickyHeader aria-label='simple table'>
                                <TableHead className='p-3 mb-2 row'>
                                    <TableRow>
                                        <StyledTableCell className='tableHeaderFont' align='center'>
                                            Sr No
                                        </StyledTableCell>
                                        <StyledTableCell className='tableHeaderFont' align='left'>
                                            Campaign Name
                                        </StyledTableCell>
                                        <StyledTableCell className='tableHeaderFont' align='left'>
                                            Bot Name
                                        </StyledTableCell>
                                        {user?.roleName.toLowerCase() == ERoles.SUPER_ADMIN && (
                                            <StyledTableCell className='tableHeaderFont' align='left'>
                                                Client Name
                                            </StyledTableCell>
                                        )}
                                        <StyledTableCell className='tableHeaderFont' align='left'>
                                            Template Name
                                        </StyledTableCell>
                                        <StyledTableCell className='tableHeaderFont' align='center'>
                                            Created At
                                        </StyledTableCell>
                                        <StyledTableCell className='tableHeaderFont' align='center'>
                                            Executed At
                                        </StyledTableCell>
                                        <StyledTableCell className='tableHeaderFont' align='center'>
                                            Status
                                        </StyledTableCell>
                                        <StyledTableCell className='tableHeaderFont' align='left'>
                                            Actions
                                        </StyledTableCell>
                                        {/* )} */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.campaignListByPageSize.length > 0 &&
                                        state.campaignListByPageSize?.map((campaign, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <b>{currentPage * pageSize + index + 1}</b>
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {campaign.campaignName}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {campaign.botName}
                                                </StyledTableCell>
                                                {user?.roleName.toLowerCase() == ERoles.SUPER_ADMIN && (
                                                    <StyledTableCell className='tableContentFont' align='left'>
                                                        {campaign?.orgName ? campaign?.orgName : "-"}
                                                    </StyledTableCell>
                                                )}

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {campaign.templateName}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFontForDateAndTime' align='center'>
                                                    {/* 24-04-2024 , <br/>
                                                    03:34 PM	 */}
                                                    {calculateTime(campaign.createdAt)}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFontForDateAndTime' align='center'>
                                                    {campaign.executedTime ? calculateTime(campaign.executedTime) : "-"}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {campaign.status === CampaignStatus.Created ? (
                                                        <Typography
                                                            variant='subtitle2'
                                                            // className='button_width chip'
                                                            color='green'
                                                        >
                                                            {campaign?.status?.replaceAll("_", " ").toUpperCase()}
                                                        </Typography>
                                                    ) : campaign.status === CampaignStatus.Pending ? (
                                                        <Typography
                                                            variant='subtitle2'
                                                            // className='button_width chip'
                                                            color='orange'
                                                        >
                                                            {campaign?.status?.replaceAll("_", " ").toUpperCase()}
                                                        </Typography>
                                                    ) : campaign.status === CampaignStatus.Running ? (
                                                        <Typography
                                                            variant='subtitle2'
                                                            // className='button_width chip'
                                                            color='primary'
                                                        >
                                                            {campaign?.status?.replaceAll("_", " ").toUpperCase()}
                                                        </Typography>
                                                    ) : campaign.status === CampaignStatus.Scheduled ? (
                                                        <Typography
                                                            variant='subtitle2'
                                                            // className='button_width chip'
                                                            color='purple'
                                                        >
                                                            {campaign?.status?.toUpperCase()}
                                                        </Typography>
                                                    ) : campaign.status === CampaignStatus.Executed ? (
                                                        <Typography
                                                            variant='subtitle2'
                                                            // className='button_width chip'
                                                            color='#6c757d'

                                                        >
                                                            {campaign?.status?.replaceAll("_", " ").toUpperCase()}
                                                        </Typography>

                                                    ) : campaign.status === CampaignStatus.SMS_Executed ? (
                                                        <Typography
                                                            variant='subtitle2'
                                                            // className='button_width chip'
                                                            color='secondary'
                                                        >
                                                            {campaign?.status?.replaceAll("_", " ").toUpperCase()}
                                                        </Typography>
                                                    ) : campaign.status === CampaignStatus.Scheduled ? (
                                                        <Typography
                                                            variant='subtitle2'
                                                            // className='button_width chip'
                                                            color='info'
                                                        >
                                                            {campaign.status.toUpperCase()}
                                                        </Typography>
                                                    ) : CampaignStatus.Execution_Failed || campaign.status === CampaignStatus.Validation_Failed ? (
                                                        <Typography
                                                            variant='subtitle2'
                                                            // className='button_width chip'
                                                            color='error'
                                                        >
                                                            {campaign?.status?.replaceAll("_", " ").toUpperCase()}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            variant='subtitle2'
                                                            // className='button_width chip'
                                                            color='warning'
                                                        >
                                                            {campaign?.status?.replaceAll("_", " ").toUpperCase()}
                                                        </Typography>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    <IconButton
                                                        id={`menu-button@${campaign._id}`}
                                                        aria-controls={open === campaign._id ? `customized-menu@${campaign._id}` : undefined}
                                                        aria-haspopup='true'
                                                        aria-expanded={open === campaign._id ? "true" : undefined}
                                                        variant='contained'
                                                        disableElevation
                                                        onClick={handleClick}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>

                                                    <StyledMenu
                                                        id={`customized-menu@${campaign._id}`}
                                                        MenuListProps={{
                                                            "aria-labelledby": `menu-button@${campaign._id}`,
                                                        }}
                                                        anchorEl={anchorEl}
                                                        open={open === campaign._id}
                                                        onClose={handleClose}
                                                    >
                                                        <MenuItem onClick={() => handleViewCampaignDetails(campaign)}>
                                                            <IconButton title='View Details'>
                                                                <VisibilityIcon className='text-primary' />
                                                            </IconButton>
                                                            View Details
                                                        </MenuItem>

                                                        {campaign.status === CampaignStatus.Created &&
                                                            user?.roleName.toLowerCase() === ERoles.ORG_ADMIN && (
                                                                <>
                                                                    <MenuItem onClick={() => handleClickRunCampaignModal(campaign)}>
                                                                        <IconButton title='Run Campaign'>
                                                                            <CampaignOutlined className='text-primary' />
                                                                        </IconButton>
                                                                        Run Campaign
                                                                    </MenuItem>

                                                                    <MenuItem onClick={() => handleCalenderPopUp(campaign)}>
                                                                        <IconButton title='Schedule Campaign'>
                                                                            <ScheduleSendIcon className='text-primary' />
                                                                        </IconButton>
                                                                        Schedule Campaign
                                                                    </MenuItem>
                                                                </>
                                                            )}

                                                        {campaign.status === CampaignStatus.Scheduled &&
                                                            user?.roleName.toLowerCase() === ERoles.ORG_ADMIN && (
                                                                <MenuItem onClick={() => handleCancelScheduleCampaign(campaign)}>
                                                                    <IconButton title='Schedule Campaign'>
                                                                        <CancelScheduleSendIcon className='text-danger' />
                                                                    </IconButton>
                                                                    Cancel Schedule Campaign
                                                                </MenuItem>
                                                            )}
                                                    </StyledMenu>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoRecords />
                    )}

                    {state.totalCampaignsCount > 0 && (
                        <Grid
                            className='my-2'
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 10
                            }}
                        >
                            <Grid
                                style={{
                                    marginTop: "15px",
                                }}
                            >
                                Total Campaigns: {state.totalCampaignsCount}
                            </Grid>

                            {/* <TablePagination
                                count={
                                    state.totalCampaignsCount
                                    /// rowsPerPage
                                }
                                component='div'
                                variant='outlined'
                                color='primary'
                                page={currentPage}
                                // onChange={handlePageChange}
                                onPageChange={handleChangePage}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}

                            <TablePaginationShowPage
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                colSpan={3}
                                count={state.totalCampaignsCount}
                                rowsPerPage={pageSize}
                                page={currentPage}
                                slotProps={{
                                    select: {
                                        "aria-label": "Rows per page",
                                    },
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                        slots: {
                                            firstPageIcon: FirstPageRoundedIcon,
                                            lastPageIcon: LastPageRoundedIcon,
                                            nextPageIcon: ChevronRightRoundedIcon,
                                            backPageIcon: ChevronLeftRoundedIcon,
                                        },
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            <AlertMessageComponent
                                message={errorResponse}
                                fieldName={"ErrorMessage"}
                                handleClose={() => setErrorResponse("")}
                                show={Boolean(errorResponse)}
                            />
                        </Grid>
                    )}
                    {state.controls.runCampaignServerErrorMessage && (
                        <AlertMessageComponent
                            message={state.controls.runCampaignServerErrorMessage}
                            fieldName={"runCampaignServerErrorMessage"}
                            handleClose={onCloseErrorMessage}
                            show={Boolean(state.controls.runCampaignServerErrorMessage)}
                        />
                    )}
                    {state.controls.getAllocatedAmountForCampaignErrorMessage && (
                        <AlertMessageComponent
                            message={state.controls.getAllocatedAmountForCampaignErrorMessage}
                            fieldName={"getAllocatedAmountForCampaignErrorMessage"}
                            handleClose={onCloseErrorMessage}
                            show={Boolean(state.controls.getAllocatedAmountForCampaignErrorMessage)}
                        />
                    )}
                    {state.controls.getAllCampaignsByPageSizeErrorMessage && (
                        <AlertMessageComponent
                            message={state.controls.getAllCampaignsByPageSizeErrorMessage}
                            fieldName={"getAllCampaignsByPageSizeErrorMessage"}
                            handleClose={onCloseErrorMessage}
                            show={Boolean(state.controls.getAllCampaignsByPageSizeErrorMessage)}
                        />
                    )}
                </CardContent>

                {
                    openFilter && ( 
                        <FilterAllCampaignsModel
                            open={openFilter}
                            searchParams={searchParams}
                            handleClickOpenFilterModel={handleClickOpenFilterModel}
                            handleCloseFilterModel={handleCloseFilterModel}
                            setSearchParams={setSearchParams}
                            handleClickSearch={handleClickSearch}
                        />
                    )
                }
            </Card >
            {openModal && (
                <RunCampaignModel
                    open={openModal}
                    setOpen={setOpenModal}
                    campaign={campaignToRun}
                    handleClose={handleCloseRunCampaignModal}
                    handleRunCampaign={handleRunCampaign}
                    isRunCampaignLoading={state.controls.isRunCampaignLoading}
                    isGetAllocatedAmountLoading={isGetAllocatedAmountLoading}
                />
            )}
            {
                opencalendarModel && (
                    <ScheduleCampaignModel
                        open={opencalendarModel}
                        setOpen={setOpenCalendarModel}
                        handleClose={handleCloseCalender}
                        campaign={campaignToRun}
                        scheduleCampaign={scheduleCampaign}
                        isGetAllocatedAmountLoading={isGetAllocatedAmountLoading}
                    />
                )
            }

            {
                openCancelScheduleCampaignModel && (
                    <CancelScheduleCampaignModel
                        open={openCancelScheduleCampaignModel}
                        setOpen={setOpenCancelScheduleCampaignModel}
                        handleClose={handleCloseCancelScheduleCampaignModel}
                        campaign={campaignToRun}
                        cancelScheduleCampaign={cancelScheduleCampaign}
                    />
                )
            }
        </Grid >
    );
};

export default ListCampaigns;