import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Button,
    Card,
    CardContent,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BotMessageType, BotStatus, ERoles, TemplateStatus } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { getAllVerifiedBotsThunk } from "../../../redux/botManagement/botManagementThunk";
import {
    clearErrorMessagesForTemplates,
    setSelectedBotDetails,
    setShowAddTemplateForm,
} from "../../../redux/templateManagement/templateManagementSlice";
import { getAllTemplatesByFilterThunk, getAllTemplatesByPageSize } from "../../../redux/templateManagement/templateManagementThunk";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import { calculateTime } from "../../../utils/calculateTime";
import { formatDataToShowInUI } from "../../../utils/formatDataToShowInUI";
import TablePaginationShowPage from "../../../utils/tablePagination";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import FetchingSpinner from "../../components/FetchingSpinner";
import NoRecords from "../../components/NoRecords";
import StyledMenu from "../../tables/StyledMenu";
import DeleteTemplateConformationModel from "./DeleteTemplateConformationModel";
import FilterAllTemplateModel from "./FilterTemplateModel";

// import CustomTablePagination from "../../../utils/tablePagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 24,
        backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 22,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const ListTemplates = ({ searchParams, setSearchParams, handleViewDetailsChange, selectedBotname, setSelectedBotName }) => {
    const state = useSelector(state => state.templateManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);
    const dispatch = useDispatch();
    const [deleteTemplateModel, setDeleteTemplateModel] = useState(false);
    const [templateData, setTemplateData] = useState({});
    const { user } = useAuth();
    const [selectedBotId, setSelectedBotId] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = anchorEl?.id.split("@")[1];
    const [errorResponse, setErrorResopnse] = useState("");
    const [openFilter, setOpenFilter] = React.useState(false);

    const handleClickOpenFilterModel = () => {
        setOpenFilter(true);
    };

    const handleCloseFilterModel = () => {
        setOpenFilter(false);
    };
    const handleChangePage = (event, newPage) => {

        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        dispatch(getAllVerifiedBotsThunk());
        if (state.selectedBotDetails && state.selectedBotDetails?._id) {
            // dispatch(
            //     getAllTemplatesByPageSize({
            //         currentPage: currentPage + 1,
            //         pageSize: 10,
            //         botId: state.selectedBotDetails._id,
            //     })
            // );
            dispatch(
                getAllTemplatesByFilterThunk({
                    currentPage: currentPage + 1,
                    pageSize,
                    botId: state.selectedBotDetails._id,
                    searchParams,
                }),
            );
        }
    }, [currentPage, pageSize]);

    const onClickAddNewTemplate = () => {
        dispatch(setShowAddTemplateForm(true));
    };

    const getTemplateDetailsFromVI = async ({ templateData, isEditable }) => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/bot_management/template/get-template-detail-by-VI`, {
                params: { botId: state.selectedBotDetails._id, templateName: templateData.templateName },
            });

            if (response.status === 200) {
                handleViewDetailsChange({
                    templateData: response.data,
                    val: isEditable,
                });
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Failed to get template details.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResopnse(errorMessage);
        }
    };

    const onClickDeleteTemplate = async templateData => {
        setTemplateData(templateData);
        setDeleteTemplateModel(true);
        // try {
        //   const response = await apiGatewayAxiosInstance.get(`template/delete-template/${state.selectedBotDetails._id}/${templateData.templateName}`);

        //   if (response.status === 200) {
        //     dispatch(getAllVerifiedBotsThunk());
        //     if (state.selectedBotDetails) {

        //       dispatch(getAllTemplatesByPageSize({ currentPage: 1, pageSize: 10, botId: state.selectedBotDetails.config.rbmClientId }));
        //     }
        //   } else {
        //     throw new Error(response);
        //   }
        // } catch (error) {
        //   if (error.response) {
        //     setErrorResopnse(error.response.data.message);
        //   } else {
        //     setErrorResopnse("Server not reachable");

        //   }
        // }
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForTemplates({ fieldName }));
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        dispatch(getAllTemplatesByPageSize({ currentPage: page + 1, pageSize }));

        if (state.selectedBotDetails && state.selectedBotDetails?._id) {
            // dispatch(
            //     getAllTemplatesByPageSize({
            //         currentPage: page + 1,
            //         pageSize,
            //         botId: state.selectedBotDetails._id,
            //     })
            // );
            dispatch(
                getAllTemplatesByFilterThunk({
                    currentPage: currentPage + 1,
                    pageSize,
                    botId: state.selectedBotDetails._id,
                    searchParams,
                }),
            );
        }
    };
    const handleClickSearch = (searchParams) => {
        setCurrentPage(0);
        setPageSize(10);
        setSearchParams(searchParams)
        dispatch(
            getAllTemplatesByFilterThunk({
                currentPage: 1,
                pageSize: 10,
                searchParams,
                botId: state.selectedBotDetails._id,
            }),
        );
        handleCloseFilterModel();
    };
    const onChangeSelectBot = e => {
        let selecBotname = e.target.value;

        setSelectedBotName(selecBotname);
        const bot = botState.verifiedBotList.filter(botData => botData.botName === selecBotname)[0];
        dispatch(setSelectedBotDetails(bot));

        const botClientId = bot._id;
        setSelectedBotId(botClientId);
        setCurrentPage(0);
        setPageSize(10);
        if (bot) {

            dispatch(
                getAllTemplatesByFilterThunk({
                    currentPage: 1,
                    pageSize: 10,
                    searchParams,
                    botId: bot._id,
                }),
            );
        }
    };

    return (
        <>
            <Grid mb={4}>
                <Grid display={"flex"} sx={{ color: "black" }}>
                    {/* <Grid>
                        <ChatOutlinedIcon /> &nbsp;
                    </Grid> */}
                    <Typography variant='h3'>
                        <b>Templates</b>
                    </Typography>
                </Grid>
                {/* <Grid>
          <Box>
            <Card className="border border-secondary-50 m-2 p-1">
              <CardContent>

              </CardContent>
              <CardActions>
                <Button variant="contained" disabled>Search</Button>

              </CardActions>
            </Card>
          </Box>
        </Grid> */}
                {/* <Card>
                    <CardContent style={{ padding: "15px" }}>
                        <Grid display={"flex"} className="text-primary">
                            <Grid>
                                <ChatOutlinedIcon /> &nbsp;
                            </Grid>
                            <Typography variant="h4">
                                <b>Templates List</b>
                            </Typography>
                        </Grid>
                    </CardContent>
                </Card> */}

                <Grid className='mt-2'>
                    <Card className='border border-secondary-50'>
                        <CardContent sx={{
                            padding: 0,
                        }}>
                            <Grid container display={"flex"} justifyContent={"space-between"} className='text-primary align-items-center ' padding={5}>
                                <Grid item md={9}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id='botType-label'>Select Bot</InputLabel>
                                        <Select
                                            disabled={state.controls.isCreateTemplateLoading}
                                            name='BotId'
                                            labelId='botType-label'
                                            id='botType-label'
                                            value={state.selectedBotDetails.botName}
                                            onChange={e => onChangeSelectBot(e)}
                                            label='Select Bot'
                                            size='small'

                                            Props={{
                                                PaperProps: {
                                                    sx: {
                                                        boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                    },
                                                },
                                            }}
                                        >
                                            {botState.verifiedBotList &&
                                                botState.verifiedBotList.length > 0 &&
                                                botState.verifiedBotList?.map((bot, index) => (
                                                    <MenuItem key={index} value={bot.botName}>
                                                        <Grid
                                                            container
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Grid item md={6} display={"flex"} flexDirection={"column"}>
                                                                <Typography sx={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                                    {bot.botName}
                                                                </Typography>
                                                                {user && user.roleName.toLowerCase() === ERoles.SUPER_ADMIN && (
                                                                    <Typography variant='caption'>Client Name: {bot.clientName}</Typography>
                                                                )}
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                md={3}
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                }}
                                                            >
                                                                <Typography variant="caption">
                                                                    Status : &nbsp;
                                                                    {bot.status === BotStatus.New ? (
                                                                        <Chip
                                                                            variant='outlined'
                                                                            className='dropdownChip'
                                                                            label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                            color='primary'
                                                                            size='small'
                                                                        />
                                                                    ) : bot.status === BotStatus.Development ? (
                                                                        <Chip
                                                                            variant='outlined'
                                                                            className='dropdownChip'
                                                                            label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                            color='info'
                                                                            size='small'
                                                                        />
                                                                    ) : bot.status === BotStatus.VerificationInProcess ? (
                                                                        <Chip
                                                                            variant='outlined'
                                                                            className='dropdownChip'
                                                                            label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                            color='warning'
                                                                            size='small'
                                                                        />
                                                                    ) : bot.status === BotStatus.Verified ? (
                                                                        <Chip
                                                                            variant='outlined'
                                                                            className='dropdownChip'
                                                                            label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                            color='success'
                                                                            size='small'
                                                                        />
                                                                    ) : bot.status === BotStatus.Launched ? (
                                                                        <Chip
                                                                            variant='outlined'
                                                                            className='dropdownChip'
                                                                            label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                            color='success'
                                                                            size='small'
                                                                        />
                                                                    ) : (
                                                                        <Chip
                                                                            variant='outlined'
                                                                            className='dropdownChip'
                                                                            label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                            color='warning'
                                                                            size='small'
                                                                        />
                                                                    )}
                                                                </Typography>
                                                                <Typography variant="caption">
                                                                    Type :&nbsp; &nbsp;
                                                                    {bot.botMessageType == BotMessageType.OTP ? (
                                                                        <b>{BotMessageType.OTP}</b>
                                                                    ) : bot.botMessageType == BotMessageType.Promotional ? (
                                                                        <b>{BotMessageType.Promotional}</b>
                                                                    ) : bot.botMessageType == BotMessageType.Transactional ? (
                                                                        <b>{BotMessageType.Transactional}</b>
                                                                    ) : (
                                                                        "NA"
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} justifyContent={"end"} display={"flex"}>
                                    {user && user.roleName.toLowerCase() !== ERoles.SUPER_ADMIN && (
                                        <Button variant='contained' onClick={onClickAddNewTemplate} sx={{ marginBottom: "10px" }}>
                                            Add New Template
                                        </Button>
                                    )}

                                    <Grid paddingLeft={1}>
                                        {(state.selectedBotDetails.botName && state.selectedBotDetails.botName !== "") &&
                                            <Button
                                                sx={{ marginRight: "10px" }}
                                                variant="contained"
                                                onClick={handleClickOpenFilterModel}
                                            >
                                                Filter
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <hr />
                            {state.controls.isGetAllTemplatesLoading ? (
                                <FetchingSpinner />
                            ) : state.templateListByPageSize.length > 0 ? (
                                <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "60vh" }}>
                                    <Table stickyHeader aria-label='simple table'>
                                        <TableHead className='p-3 mb-2 row'>
                                            <TableRow>
                                                <StyledTableCell className='tableHeaderFont' align='center'>
                                                    Sr No
                                                </StyledTableCell>
                                                <StyledTableCell className='tableHeaderFont' align='left'>
                                                    Template Name
                                                </StyledTableCell>
                                                <StyledTableCell className='tableHeaderFont' align='left'>
                                                    Template Type
                                                </StyledTableCell>
                                                <StyledTableCell className='tableHeaderFont' align='center'>
                                                    Created At
                                                </StyledTableCell>
                                                <StyledTableCell className='tableHeaderFont' align='center'>
                                                    Status
                                                </StyledTableCell>

                                                <StyledTableCell className='tableHeaderFont' align='left'>
                                                    Actions
                                                </StyledTableCell>
                                                {/* )} */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.templateListByPageSize.length > 0 &&
                                                state.templateListByPageSize?.map((template, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            <b>{currentPage * pageSize + index + 1}</b>
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            {template.templateName}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            {formatDataToShowInUI(template.templateType)}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            {template.createdAt ? calculateTime(template.createdAt) : "-"}
                                                        </StyledTableCell>

                                                        <StyledTableCell className='tableContentFont' align='center'>

                                                            {template?.status === TemplateStatus.Pending ? (
                                                                <Typography
                                                                    color='orange'
                                                                >
                                                                    {template?.status?.replaceAll("_", " ").toUpperCase()}
                                                                </Typography>
                                                            ) : template?.status === TemplateStatus.Approved ? (
                                                                <Typography
                                                                    color='green'
                                                                >
                                                                    {template?.status?.replaceAll("_", " ").toUpperCase()}
                                                                </Typography>
                                                            ) : template?.status === TemplateStatus?.Rejected ? (
                                                                <Typography
                                                                    color='error'
                                                                >
                                                                    {template?.status?.replaceAll("_", " ").toUpperCase()}
                                                                </Typography>
                                                            ) : (
                                                                <Typography
                                                                    color='secondary'
                                                                >
                                                                    {template?.status?.replaceAll("_", " ").toUpperCase()}
                                                                </Typography>
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            <IconButton
                                                                id={`menu-button@${template.templateName}`}
                                                                aria-controls={
                                                                    open === template.templateName
                                                                        ? `customized-menu@${template.templateName}`
                                                                        : undefined
                                                                }
                                                                aria-haspopup='true'
                                                                aria-expanded={open == template.templateName ? "true" : undefined}
                                                                variant='contained'
                                                                disableElevation
                                                                onClick={handleClick}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <StyledMenu
                                                                id={`customized-menu@${template.templateName}`}
                                                                MenuListProps={{
                                                                    "aria-labelledby": `menu-button@${template.templateName}`,
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open === template.templateName}
                                                                onClose={handleClose}
                                                            >
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        getTemplateDetailsFromVI({
                                                                            templateData: template,
                                                                            isEditable: false,
                                                                        })
                                                                    }
                                                                >
                                                                    <IconButton title='view Details'>
                                                                        <VisibilityIcon className='text-primary' />
                                                                    </IconButton>
                                                                    View Template
                                                                </MenuItem>

                                                                {template.status !== TemplateStatus.Approved && user?.roleName && user.roleName !== ERoles.SUPER_ADMIN && (
                                                                    <MenuItem
                                                                        onClick={() =>
                                                                            getTemplateDetailsFromVI({
                                                                                templateData: template,
                                                                                isEditable: true,
                                                                            })
                                                                        }
                                                                    >
                                                                        <IconButton title='Update Details'>
                                                                            <FormatAlignLeftIcon className='text-primary' />
                                                                        </IconButton>
                                                                        Update Template
                                                                    </MenuItem>
                                                                )}

                                                                {user && user.roleName.toLowerCase() !== ERoles.SUPER_ADMIN && (
                                                                    <MenuItem onClick={() => onClickDeleteTemplate(template)}>
                                                                        <IconButton title='Delete Details'>
                                                                            <DeleteIcon className='text-danger' />
                                                                        </IconButton>
                                                                        Delete Template
                                                                    </MenuItem>
                                                                )}
                                                            </StyledMenu>
                                                            {template.status === TemplateStatus.Rejected && template.reason &&
                                                                <Tooltip
                                                                    title={
                                                                        <p
                                                                            style={{
                                                                                fontSize: ".7rem",
                                                                            }}
                                                                        >
                                                                            {template.reason}
                                                                        </p>
                                                                    }
                                                                    className='cursor-pointer'
                                                                >
                                                                    <InfoOutlinedIcon sx={{ opacity: "50%" }} />
                                                                </Tooltip>}
                                                        </StyledTableCell>
                                                        {/* // )} */}
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : !selectedBotId ? (
                                <Typography variant='h5' className='text-muted' alignItems={"center"} display={"flex"} justifyContent={"center"}>
                                    Please select bot
                                </Typography>
                            ) : (
                                <NoRecords />
                            )}

                            {state.totalTemplatesCount > 0 && (
                                <Grid
                                    className='my-2'
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingLeft: 10
                                    }}
                                >
                                    <Grid
                                        style={{
                                            marginTop: "15px",
                                        }}
                                    >
                                        Total Templates: {state.totalTemplatesCount}
                                    </Grid>
                                    {/* <TablePagination
                                        count={
                                            state.totalTemplatesCount
                                            /// rowsPerPage
                                        }
                                        component='div'
                                        variant='outlined'
                                        color='primary'
                                        page={currentPage}
                                        // onChange={handlePageChange}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={pageSize}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    /> */}


                                    <TablePaginationShowPage
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        colSpan={3}
                                        count={state.totalTemplatesCount}
                                        rowsPerPage={pageSize}
                                        page={currentPage}
                                        slotProps={{
                                            select: {
                                                "aria-label": "Rows per page",
                                            },
                                            actions: {
                                                showFirstButton: true,
                                                showLastButton: true,
                                                slots: {
                                                    firstPageIcon: FirstPageRoundedIcon,
                                                    lastPageIcon: LastPageRoundedIcon,
                                                    nextPageIcon: ChevronRightRoundedIcon,
                                                    backPageIcon: ChevronLeftRoundedIcon,
                                                },
                                            },
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            )}
                        </CardContent>
                        {openFilter && (
                            <FilterAllTemplateModel
                                open={openFilter}
                                searchParams={searchParams}
                                handleClickOpenFilterModel={handleClickOpenFilterModel}
                                handleCloseFilterModel={handleCloseFilterModel}
                                setSearchParams={setSearchParams}
                                handleClickSearch={handleClickSearch}
                            />
                        )}
                        <AlertMessageComponent
                            message={state.controls.getAllTemplatesByPageSizeErrorMessage}
                            fieldName={"getAllTemplatesByPageSizeErrorMessage"}
                            handleClose={onCloseErrorMessage}
                            show={Boolean(state.controls.getAllTemplatesByPageSizeErrorMessage)}
                        />

                        <AlertMessageComponent
                            message={errorResponse}
                            fieldName={"getAllTemplatesByPageSizeErrorMessage"}
                            handleClose={() => setErrorResopnse("")}
                            show={Boolean(errorResponse)}
                        />
                    </Card>
                </Grid>
            </Grid>
            {deleteTemplateModel && (
                <DeleteTemplateConformationModel
                    open={deleteTemplateModel}
                    setOpen={setDeleteTemplateModel}
                    templateData={templateData}
                    selectedBotDetails={state.selectedBotDetails}
                    setErrorResopnse={setErrorResopnse}
                    searchParams={searchParams}
                />
            )}
        </>
    );
};

export default ListTemplates;
