import csvParser from 'csv-parser'

export function fetchCsvAndConvertToJson(csvData) {
    const jsonData = []

    // Use the csv-parser to convert CSV to JSON
    return new Promise((resolve, reject) => {
        const parser = csvParser()
        parser.on('data', row => {
            jsonData.push(row)
        })
        parser.on('end', () => {
            resolve(jsonData)
        })
        parser.on('error', error => {
            reject(error)
        })
        parser.write(csvData)
        parser.end()
    })
}
// }
