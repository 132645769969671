import React from "react";
import styled from "@emotion/styled";
import { CircularProgress, LinearProgress } from "@mui/material";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader({ size = 40, color = 'secondary' }) {
    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);

    const progressRef = React.useRef(() => { });
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    React.useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <Root>
            <CircularProgress color={color} size={size} />

            {/* <LinearProgress sx={{width: '100%'}} variant="buffer" value={progress} color="inherit" valueBuffer={buffer} /> */}
        </Root>
    );
}

export default Loader;
