import { Grid } from "@mui/material";

import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uuid } from "short-uuid";
import * as Yup from "yup";
import CharCounter from "../../../../components/CharCounter";
import { getAllVerifiedBotsThunk } from "../../../../redux/botManagement/botManagementThunk";
import {
    clearErrorMessagesForTemplates,
    setErrorMessagesForTemplates,
    setShowAddTemplateForm,
    setUpdateTemplateErrorMessage,
} from "../../../../redux/templateManagement/templateManagementSlice";
import { addNewTemplateThunk, getAllTemplatesByPageSize } from "../../../../redux/templateManagement/templateManagementThunk";
import { apiGatewayAxiosInstance } from "../../../../utils/axios";
import SimulatorComponent from "../simulator/SimulatorComponent";
import CreateTemplate from "./CreateTemplate";
import useAuth from "../../../../hooks/useAuth";
import { BotMessageType, config } from "../../../../config";

export default function AddTemplate({ templateData, setTemplateDetails, isTempalteEditable, setEditable, selectedBotName }) {
    const dispatch = useDispatch();
    const state = useSelector(state => state.templateManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);
    const [selectedTab, setSelectedTab] = useState(0);
    const richCardStandAlone = "rich_card";
    const richCardCarousel = "carousel";
    const textMessage = "text_message";
    const vertical = ["SHORT_HEIGHT", "MEDIUM_HEIGHT"];
    const horizontal = ["LEFT", "RIGHT"];
    const user = useAuth();
    const [isHorizontal, setIsHorizontal] = useState(false);
    const [imageFormDataRichCardStandAlone, setImageFormDataRichCardStandAlone] = useState({});
    const [imageFormDataRichCardCarousel, setImageFormDataRichCardCarousel] = useState({});
    const filePicekerRef = useRef(null);
    const thumnailInputRef = useRef(null);
    const [previewImage, setPreviewImage] = useState({ url: null, type: null, file: null, fieldName: null });
    const [thumbnailpreviewImage, setThumbnailPreviewImage] = useState({ url: null, type: null, file: null, fieldName: null });
    const [showSelectThumbnail, setShowSelectThumbnail] = useState(false);
    const [cardsPreviewImageList, setCardsPreviewImageList] = useState([
        { url: null, type: null, file: null, fieldName: null },
        { url: null, type: null, file: null, fieldName: null },
        { url: null, type: null, file: null, fieldName: null },
        { url: null, type: null, file: null, fieldName: null },
        { url: null, type: null, file: null, fieldName: null },
        { url: null, type: null, file: null, fieldName: null },
        { url: null, type: null, file: null, fieldName: null },
        { url: null, type: null, file: null, fieldName: null },
        { url: null, type: null, file: null, fieldName: null },
        { url: null, type: null, file: null, fieldName: null },
    ]);
    const [cardsPreviewThumbnailImageList, setCardsPreviewThumbnailImageList] = useState({});

    useEffect(() => {
        dispatch(getAllVerifiedBotsThunk());
    }, []);

    const mediaSchema = Yup.object().shape({
        url: Yup.string("select Image").url("Url is Required"),
    });

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const phoneNumberSchema = Yup.object().shape({
        country: Yup.string().required("Country required"),
        countryCode: Yup.string().required("Country Code is Required"),
        number: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid!")
            .min(10, "Phone Number must contain at least 10 digits!")
            .max(14, "Phone Number should not exceed its limit!")
            .required("Phone Number is required"),
    });

    const regMatch =
        /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

    const suggestionSchema = Yup.object().shape({
        typeOfAction: Yup.string().required("Please select the Action Type"),
        suggestionText: Yup.string()
            .test("validCharacters", "Variables should contain only alphabets, numbers, and underscores", function (value) {
                if (!value) {
                    return true;
                }

                let withinBracket = false;
                let bracketCount = 0;

                for (const char of value) {
                    if (char === "[") {
                        if (withinBracket) {
                            return false;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            return false;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        return false;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    return false;
                }
                if (value.includes("[]")) {
                    return false;
                }

                return true;
            })

            .test("charCount", "Max 25 characters can be used", function (value) {
                const countResult = CharCounter(value);

                if (countResult <= 25) {
                    return true;
                } else {
                    return false;
                }
            })
            .required("Enter the button Text"),
        suggestionPostback: Yup.string()
            .test("validCharacters", "Variables should contain only alphabets, numbers, and underscores", function (value) {
                if (!value) {
                    return true;
                }

                let withinBracket = false;
                let bracketCount = 0;

                for (const char of value) {
                    if (char === "[") {
                        if (withinBracket) {
                            return false;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            return false;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        return false;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    return false;
                }
                if (value.includes("[]")) {
                    return false;
                }

                return true;
            })

            .test("charCount", "Max 120 characters can be used", function (value) {
                const countResult = CharCounter(value);

                if (countResult <= 120) {
                    return true;
                } else {
                    return false;
                }
            })
            .required("Enter the button Postback"),
        suggestionId: Yup.string().required("button id is required"),
        urlAction: Yup.string().when("typeOfAction", {
            is: "url_action",
            then: Yup.string()
                .matches(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, "Invalid URL")
                .required("URL is required"),
        }),
        phoneNumberToDial: Yup.object().when("typeOfAction", {
            is: "dialer_action",
            then: phoneNumberSchema.required(),
        }),
        viewLocation: Yup.object().when("typeOfAction", {
            is: "view_location_query",
            then: Yup.string()
                .required("Location Query is required!"),
        }),
    });
    const cardMediaSchema = Yup.object().shape({
        url: Yup.string().nullable().required("Select Image"),
    });
    const richCardStandAloneSchema = Yup.object().shape({
        cardOrientation: Yup.string().required("please select Card Orientation"),
        mediaHeight: Yup.string().when("cardOrientation", {
            is: "VERTICAL",
            then: Yup.string().required("please select Media Height"),
        }),
        cardAlignment: Yup.string().when("cardOrientation", {
            is: "HORIZONTAL",
            then: Yup.string().required("please select Card Alignemt"),
        }),
        media: cardMediaSchema,

        // cardMediaSchema
        // media: Yup.object().of(
        //     cardMediaSchema
        // ),
        thumbnail: Yup.object().when("media.type", {
            is: "video/mp4",
            then: Yup.object().required("Video thumbnail is required."),
        }),
        cardTitle: Yup.string()
            .test("validCharacters", "Variables should contain only alphabets, numbers, and underscores", function (value) {
                if (!value) {
                    return true;
                }

                let withinBracket = false;
                let bracketCount = 0;

                for (const char of value) {
                    if (char === "[") {
                        if (withinBracket) {
                            return false;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            return false;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        return false;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    return false;
                }
                if (value.includes("[]")) {
                    return false;
                }

                return true;
            })
            .test("charCount", "Max 200 characters can be used", function (value) {
                const countResult = CharCounter(value);
                // You can adjust the condition as needed
                if (countResult <= 200) {
                    return true; // Validation passed
                } else {
                    return false; // Validation failed
                }
            })
            .required("CardTitle is required"),
        cardDescription: Yup.string()
            .test("validCharacters", "Variables should contain only alphabets, numbers, and underscores", function (value) {
                if (!value) {
                    return true;
                }

                let withinBracket = false;
                let bracketCount = 0;

                for (const char of value) {
                    if (char === "[") {
                        if (withinBracket) {
                            return false;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            return false;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        return false;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    return false;
                }
                if (value.includes("[]")) {
                    return false;
                }

                return true;
            })

            .test("charCount", "Max 2000 characters can be used", function (value) {
                const countResult = CharCounter(value);
                // You can adjust the condition as needed
                if (countResult <= 2000) {
                    return true; // Validation passed
                } else {
                    return false; // Validation failed
                }
            })
            .required("Card Discription is required"),
        suggestionsList: Yup.array().of(suggestionSchema),
    });

    const textMessageSchema = Yup.object().shape({
        messageContent: Yup.string()
            .test("validCharacters", "Variables should contain only alphabets, numbers, and underscores", function (value) {
                if (!value) {
                    return true;
                }

                let withinBracket = false;
                let bracketCount = 0;

                for (const char of value) {
                    if (char === "[") {
                        if (withinBracket) {
                            return false;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            return false;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        return false;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    return false;
                }
                if (value.includes("[]")) {
                    return false;
                }

                return true;
            })

            .test("charCount", "Max 2500 characters can be used", function (value) {
                const countResult = CharCounter(value);
                // You can adjust the condition as needed
                if (countResult <= 2500) {
                    return true; // Validation passed
                } else {
                    return false; // Validation failed
                }
            })
            .required("Message Content Required"),
        suggestionsList: Yup.array().of(suggestionSchema),
    });

    const carouselCardSchema = Yup.object().shape({
        cardMedia: Yup.object().required("cardMEdia is required"),
        cardTitle: Yup.string()
            .test("validCharacters", "Variables should contain only alphabets, numbers, and underscores", function (value) {
                if (!value) {
                    return true;
                }

                let withinBracket = false;
                let bracketCount = 0;

                for (const char of value) {
                    if (char === "[") {
                        if (withinBracket) {
                            return false;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                        }
                        return false;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    return false;
                }
                if (value.includes("[]")) {
                    return false;
                }

                return true;
            })

            .test("charCount", "Max 200 characters can be used", function (value) {
                const countResult = CharCounter(value);
                // You can adjust the condition as needed
                if (countResult <= 200) {
                    return true; // Validation passed
                } else {
                    return false; // Validation failed
                }
            })
            .required("CardTitle is required"),
        cardDescription: Yup.string()
            .test("validCharacters", "Variables should contain only alphabets, numbers, and underscores", function (value) {
                if (!value) {
                    return true;
                }

                let withinBracket = false;
                let bracketCount = 0;

                for (const char of value) {
                    if (char === "[") {
                        if (withinBracket) {
                            return false;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            return false;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        return false;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    return false;
                }
                if (value.includes("[]")) {
                    return false;
                }

                return true;
            })

            .test("charCount", "Max 2000 characters can be used", function (value) {
                const countResult = CharCounter(value);
                // You can adjust the condition as needed
                if (countResult <= 2000) {
                    return true; // Validation passed
                } else {
                    return false; // Validation failed
                }
            })
            .required("Card Description is required"),
        suggestionsList: Yup.array().of(suggestionSchema).notRequired(),
    });
    const richCardCarouselSchema = Yup.object().shape({
        cardsList: Yup.array().of(carouselCardSchema),
        cardWidth: Yup.string().required("Please select the Card Width"),
        mediaHeight: Yup.string().required("Please select the Media Height"),
    });

    const smsFallbackTemplateDetailsSchema = Yup.object().shape({
        senderId: Yup.string().uppercase().required("Sender Id is required"),
        message: Yup.string().required("Message is required"),
        dltEntityId: Yup.string().required("DLT Entity Id is required"),
        dltTempId: Yup.string().required("DLT Template Id is required"),
        // messageType: "TXT",
        apiKey: Yup.string().required("API Key is required"),
    });

    const initialValues = {
        botId: templateData?.botId ? templateData?.botId : state.selectedBotDetails?._id ? state.selectedBotDetails._id : "",
        orgId: templateData?.orgId ? templateData?.orgId : user.orgId,
        botMessageType: templateData?.botMessageType
            ? templateData.botMessageType
            : state.selectedBotDetails?.botMessageType
                ? state.selectedBotDetails.botMessageType
                : BotMessageType.Promotional,
        templateName: templateData?.templateName ? templateData.templateName : "",
        templateType: templateData?.templateType ? templateData.templateType : textMessage,
        textMessageDetails: templateData?.textMessageDetails
            ? templateData?.textMessageDetails
            : {
                messageContent: "",
                suggestionsList: [],
            },

        richCardStandAloneDetails: templateData?.richCardStandAloneDetails ? templateData?.richCardStandAloneDetails : {},

        richCardCarouselDetails: templateData?.richCardCarouselDetails
            ? templateData?.richCardCarouselDetails
            : {
                cardWidth: "SMALL_WIDTH",
                mediaHeight: "SHORT_HEIGHT",
                cardsList: [],
            },
        canEdit: templateData ? false : true,
        isSMSFallbackRequired: templateData?.isSMSFallbackRequired ?? false,
        smsFallbackTemplateDetails: templateData?.smsFallbackTemplateDetails ?? {
            senderId: "",
            message: "",
            dltEntityId: "",
            dltTempId: "",
            messageType: "TXT",
            apiKey: "",
        },
    };

    const validateIfValueIsEmpty = fieldName => {
        const fieldValue = formik.values[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(fieldName, `Required`);
            return true;
        } else {
            return false;
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            // botId: Yup.string().required("BotId is required"),
            // orgId: Yup.string().required("orgId is required"),
            // botMessageType: Yup.string().required(
            //     "Bot Message Type is Required"
            // ),
            // templateName: Yup.string()
            //     .trim()
            //     .matches(/^\S*$/, "template name cannot contain spaces")
            //     // .matches(/^[A-Z]+$/, 'template code must be in capital letters')
            //     .max(20, "Max 20 characters can be used")
            //     .required("Template Name  is required"),
            // templateType: Yup.string().required(
            //     "template type must be selected"
            // ),
            //     textMessageDetails: Yup.mixed().when("templateType", {
            //         is: (val) => val === textMessage,
            //         then: textMessageSchema,
            //     }),
            //     richCardStandAloneDetails: Yup.mixed().when("templateType", {
            //         is: (val) => val === richCardStandAlone,
            //         then: richCardStandAloneSchema,
            //     }),
            //     richCardCarouselDetails: Yup.mixed().when("templateType", {
            //         is: (val) => val === richCardCarousel,
            //         then: richCardCarouselSchema,
            //     }),
            //     smsFallbackTemplateDetails: Yup.mixed().when("isSMSFallbackRequired", {
            //         is: (val) => val === true,
            //         then: smsFallbackTemplateDetailsSchema
            //     })
        }),

        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            // validateYupSchema(values, formik.validationSchema);
            try {
                const isTemplateDataValidated = await validateDatingTemplate();

                let smsValidation = true
                if (formik.values.isSMSFallbackRequired) {
                    smsValidation = await validateSMSFileds();
                }

                if (isTemplateDataValidated && smsValidation) {
                    dispatch(clearErrorMessagesForTemplates({ fieldName: "addNewTemplateErrorMessage" }));
                    const formData = new FormData();
                    let templateDataToSend = {
                        botId: values.botId,
                        orgId: values.orgId,
                        botMessageType: values.botMessageType,
                        templateName: values.templateName.trim(),
                        templateType: values.templateType,
                        isSMSFallbackRequired: values.isSMSFallbackRequired,
                        smsFallbackTemplateDetails: values.smsFallbackTemplateDetails,
                    };

                    if (values.templateType === richCardStandAlone) {
                        let richCardStandAloneDetails = {
                            cardOrientation: values.richCardStandAloneDetails?.cardOrientation,
                            cardTitle: values.richCardStandAloneDetails?.cardTitle,
                            cardDescription: values.richCardStandAloneDetails?.cardDescription,
                            suggestionsList: values.richCardStandAloneDetails?.suggestionsList,
                        };

                        if (richCardStandAloneDetails.cardOrientation === "VERTICAL") {
                            richCardStandAloneDetails = {
                                ...richCardStandAloneDetails,
                                mediaHeight: values.richCardStandAloneDetails?.mediaHeight,
                            };
                        } else if (richCardStandAloneDetails.cardOrientation === "HORIZONTAL") {
                            richCardStandAloneDetails = {
                                ...richCardStandAloneDetails,
                                cardAlignment: values.richCardStandAloneDetails?.cardAlignment,
                            };
                        }
                        templateDataToSend = {
                            ...templateDataToSend,
                            richCardStandAloneDetails,
                        };
                        // ---------------------------------------------------------------
                        // for (let fieldName in imageFormDataRichCardStandAlone) {
                        //     formData.append(
                        //         fieldName,
                        //         imageFormDataRichCardStandAlone[fieldName]
                        //     );
                        // }
                        // ---------------------------------------------------------------

                        // const arrbuf = await previewImage.file.arrayBuffer();
                        // const buffer = Buffer.from(arrbuf);

                        formData.append(previewImage.fieldName, previewImage.file);
                        formData.append(thumbnailpreviewImage.fieldName, thumbnailpreviewImage.file);
                        formData.append("templateDataToSend", JSON.stringify(templateDataToSend));
                    } else if (values.templateType === richCardCarousel) {
                        templateDataToSend = {
                            ...templateDataToSend,
                            richCardCarouselDetails: values.richCardCarouselDetails,
                        };
                        // for (
                        //     let index = 0;
                        //     index < values.richCardCarouselDetails.cardsList.length;
                        //     index++
                        // ) {
                        //     formData.append(
                        //         `carouselCardMedia_${index}`,
                        //         values.richCardCarouselDetails.cardsList[index]
                        //             ?.mediaData
                        //     );
                        // }
                        // ---------------------------------------------------------------
                        // for (let fieldName in imageFormDataRichCardCarousel) {
                        //   formData.append(
                        //     fieldName,
                        //     imageFormDataRichCardCarousel[fieldName]
                        //   );
                        // }
                        // ---------------------------------------------------------------

                        for (let index = 0; index < cardsPreviewImageList.length; index++) {
                            const cardPreviewMedia = cardsPreviewImageList[index];
                            formData.append(`carouselCardMedia_${index}`, cardPreviewMedia.file);
                        }

                        Object.keys(cardsPreviewThumbnailImageList).map(name => {
                            formData.append(name, cardsPreviewThumbnailImageList[name].file);
                        });

                        formData.append("templateDataToSend", JSON.stringify(templateDataToSend));
                    } else if (values.templateType === textMessage) {
                        templateDataToSend = {
                            ...templateDataToSend,
                            textMessageDetails: values.textMessageDetails,
                        };

                        formData.append("templateDataToSend", JSON.stringify(templateDataToSend));
                    }
                    dispatch(
                        addNewTemplateThunk({
                            data: formData,
                            botId: values.botId,
                            templateType: values.templateType,
                            templateId: templateData?._id ? templateData?._id : "",
                        })
                    );
                } else {
                    throw new Error("Please fill all fields.");
                }
            } catch (error) {
                const errorMessage = error.message ?? "Server not reachable";
                dispatch(setErrorMessagesForTemplates({ fieldName: "addNewTemplateErrorMessage", errorMessage }));
            }
        },
    });

    const previewFile = (targetFile, urlFieldName, typeFieldName) => {
        setShowSelectThumbnail(false);
        formik.setFieldError("richCardStandAloneDetails.media", "");
        formik.setFieldError("richCardStandAloneDetails.thumbnail", "");
        // Reading New File (open file Picker Box)
        const reader = new FileReader();
        // new Blob();
        // Gettting Selected File (user can select multiple but we are choosing only one)
        const selectedFile = targetFile;
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (selectedFile.type.includes("image")) {
                let fileSizeInMB = selectedFile.size / 1024 / 1024;
                let fileSizeInKB = selectedFile.size / 1024;
                let mimeType = selectedFile.type;
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
                if (fileSizeInMB > 2 && urlFieldName === "richCardStandAloneDetails.media.url") {
                    formik.setFieldError("richCardStandAloneDetails.media.url", "Image should have max file size of 2MB.");
                    return;
                }
                if (fileSizeInMB > 2 && urlFieldName === "richCardStandAloneDetails.thumbnail.url") {
                    formik.setFieldError("richCardStandAloneDetails.thumbnail", "Image should have max file size of 2MB.");
                    return;
                }
                if (fileSizeInKB > 100 && urlFieldName === "richCardStandAloneDetails.thumbnail.url") {
                    formik.setFieldError("richCardStandAloneDetails.thumbnail", "Image should have max file size of 100kb.");
                    return;
                }
                if (!supportedMimeTypeArray.includes(mimeType)) {
                    formik.setFieldError("richCardStandAloneDetails.media.url", "Image type should be JPEG , JPG , PNG , or GIF.");
                    return;
                }

                if (mimeType == "image/gif") {
                    formik.setFieldValue(urlFieldName, readerEvent.target.result);
                }

                {
                    formik.values.richCardStandAloneDetails?.media && formik.values.richCardStandAloneDetails.media.type === "video/mp4"
                        ? setThumbnailPreviewImage({ url: readerEvent.target.result, type: mimeType, file: targetFile, fieldName: urlFieldName })
                        : setPreviewImage({ url: readerEvent.target.result, type: mimeType, file: targetFile, fieldName: urlFieldName });
                }

                // formik.setFieldValue(urlFieldName, readerEvent.target.result);
                // formik.setFieldValue(typeFieldName, targetFile.type);
                // setImageFormDataRichCardStandAlone({
                //     ...imageFormDataRichCardStandAlone,
                //     [urlFieldName]: targetFile,
                // });
            } else if (selectedFile.type.includes("video/mp4")) {
                setShowSelectThumbnail(true);
                setThumbnailPreviewImage({ url: null, type: null, file: null, fieldName: null });
                let fileSizeInMB = selectedFile.size / 1024 / 1024;
                let fileSizeInKB = selectedFile.size / 1024;
                // formik.setFieldValue(urlFieldName, readerEvent.target.result);
                // formik.setFieldValue(typeFieldName, targetFile.type);

                if (fileSizeInMB > 10 && urlFieldName === `richCardStandAloneDetails.media.url`) {
                    formik.setFieldError(`richCardStandAloneDetails.media.url`, `Video should have max file size of 10MB.`);
                    return;
                }

                let mimeType = selectedFile.type;
                const supportedMimeTypeArray = ["video/mp4"];
                if (supportedMimeTypeArray.includes(mimeType)) {
                    setPreviewImage({ url: readerEvent.target.result, type: mimeType, file: targetFile, fieldName: urlFieldName });
                    formik.setFieldValue(urlFieldName, readerEvent.target.result);
                    formik.setFieldValue(typeFieldName, mimeType);
                    // setImageFormDataRichCardStandAlone({
                    //     ...imageFormDataRichCardStandAlone,
                    //     [urlFieldName]: targetFile,
                    // });
                }
            } else {
                formik.setFieldError("richCardStandAloneDetails.media", "Invalid Type");
                return;
            }
        };
    };

    const carouselCardObjectToAdd = {
        cardId: uuid(),
        cardMedia: { url: null, type: "" },
        cardTitle: "",
        cardDescription: "",
        suggestionsList: [],
    };

    const suggestionObjectToAdd = {
        typeOfAction: "reply",
        suggestionText: "",
        suggestionId: uuid(),
        suggestionPostback: `[${config.REACT_APP_POSTBACK_VARIABLE}]`,
    };

    const onChangeSelectTemplate = e => {
        // formik.setErrors({});
        if (e.target.value === richCardCarousel) {
            const defaultCarouselCardsToAdd = [
                {
                    cardId: uuid(),
                    cardMedia: {
                        url: null,
                        type: "",
                    },
                    cardTitle: "",
                    cardDescription: "",
                    suggestionsList: [],
                },
                {
                    cardId: uuid(),
                    cardMedia: {
                        url: null,
                        type: "",
                    },
                    cardTitle: "",
                    cardDescription: "",
                    suggestionsList: [],
                },
            ];
            formik.setFieldValue("richCardCarouselDetails.cardsList", defaultCarouselCardsToAdd);
        }
        if (e.target.value === richCardStandAlone) {
            const defaultCarouselCardsToAdd = {
                cardOrientation: "VERTICAL",
                mediaHeight: "SHORT_HEIGHT",
                cardAlignment: "LEFT",
                media: {
                    url: null,
                    type: "",
                },
                thumbnail: {
                    url: null,
                    type: "",
                },
                cardTitle: "",
                cardDescription: "",
                suggestionsList: [],
            };
            formik.setFieldValue("richCardStandAloneDetails", defaultCarouselCardsToAdd);
        }

        formik.handleChange(e);
    };

    const onChangeCardTitle = e => {
        if (e.target.value.length <= 200) {
            formik.setFieldValue("richCardStandAloneDetails.cardTitle", e.target.value);
        } else {
            formik.setFieldError("richCardStandAloneDetails.cardTitle", "Max 200 characters can be used");
        }
    };


    const onChangeTemplateName = e => {
        if (e.target.value.length <= 20) {
            formik.setFieldValue("templateName", e.target.value);
        } else {
            formik.setFieldError("templateName", "Max 20 characters can be used");
        }
    };

    const onChangeMessageContent = e => {
        if (e.target.value.length <= 2500) {
            formik.setFieldValue("textMessageDetails.messageContent", e.target.value);
        } else {
            formik.setFieldError("textMessageDetails.messageContent", "Max 2500 characters can be used");
        }
    };

    const onSelectOrientationType = e => {
        formik.setFieldValue("richCardStandAloneDetails.cardOrientation", e.target.value, true);
        if (e.target.value === "VERTICAL") {
            setIsHorizontal(false);
        } else {
            setIsHorizontal(true);
        }
    };

    //rich card Stand Alone----------------------------------------------------------------
    function onDeleteImageRichCardStandAlone(fieldName) {
        if (formik.values.richCardStandAloneDetails?.thumbnail?.url || thumbnailpreviewImage?.url) {
            onDeleteRichCardStandAloneThumbnail("richCardStandAloneDetails.thumbnail.url");
        }

        formik.setFieldValue(fieldName, { url: null, type: null });
        setPreviewImage({ url: null, type: null, fieldName: null, file: null });
    }

    const onDeleteRichCardStandAloneThumbnail = fieldName => {
        formik.setFieldValue(fieldName, null);
        setThumbnailPreviewImage({ url: null, type: null, fieldName: null, file: null });
    };

    const onClickAddSuggestionRichCardStandAlone = () => {
        if (formik.values.richCardStandAloneDetails.suggestionsList.length < 4) {
            formik.setFieldValue("richCardStandAloneDetails.suggestionsList", [
                ...formik.values.richCardStandAloneDetails.suggestionsList,
                suggestionObjectToAdd,
            ]);
        }
    };

    const handleChangeRemoveSuggestionRichCardStandAlone = ({ indexOfSuggstion }) => {
        formik.values.richCardStandAloneDetails.suggestionsList.splice(indexOfSuggstion, 1);
        formik.setFieldValue(`richCardStandAloneDetails.suggestionsList`, formik.values.richCardStandAloneDetails.suggestionsList, true);

        formik.touched("richCardStandAloneDetails.suggestionsList");
    };

    const onChangeRichCardStandAloneCardDescripton = e => {
        if (e.target.value.length <= 2000) {
            formik.setFieldValue("richCardStandAloneDetails.cardDescription", e.target.value);
        } else {
            formik.setFieldError("richCardStandAloneDetails.cardDescription", "Max 2000 characters can be used");
        }
    };

    const onSelectRichCardStandAloneMediaHeight = e => {
        formik.setFieldValue("richCardStandAloneDetails.mediaHeight", e.target.value);
    };

    const onSelectRichCardStandAloneCardAlignement = e => {
        formik.setFieldValue("richCardStandAloneDetails.cardAlignment", e.target.value);
    };

    const handleChangeSuggestionRichCardStandAlone = ({ field, value, indexOfSuggstion }) => {
        formik.setFieldValue(`richCardStandAloneDetails.suggestionsList[${indexOfSuggstion}].${field}`, value);
        if (value === "dialer_action") {
            const data = {
                suggestionId: uuid(),
                typeOfAction: value,
                suggestionText: "",
                suggestionPostback: `[${config.REACT_APP_POSTBACK_VARIABLE}]`,
                phoneNumberToDial: {
                    country: "India",
                    countryCode: "+91",
                    number: "",
                },
            };

            formik.setFieldValue(`richCardStandAloneDetails.suggestionsList[${indexOfSuggstion}]`, data);
        } else if (value === "view_location_query") {
            const data = {
                suggestionId: uuid(),
                typeOfAction: value,
                suggestionText: "",
                suggestionPostback: `[${config.REACT_APP_POSTBACK_VARIABLE}]`,
                query: "",
            };

            formik.setFieldValue(`richCardStandAloneDetails.suggestionsList[${indexOfSuggstion}]`, data);

        }
    };



    //text message ------------------------------------------------------------------------


    const validateSMSFileds = async () => {
        let result = true;

        let array = ["dltEntityId", "dltTempId", "message"];
        for (let index = 0; index < array.length; index++) {
            const element = array[index];

            if (formik.values.smsFallbackTemplateDetails[element] === "") {
                result = false;
                formik.setFieldError(`smsFallbackTemplateDetails.${element}`, `Required`);
            }
        }

        return result;
    }


    const validateDatingTemplate = async () => {
        // validateIfValueIsEmpty()
        let result = true;
        // debugger;

        if (formik.values.templateName === "") {
            result = false
            formik.setFieldError("templateName", `Template Name  is required`);

        } else {
            if (formik.values.templateName.length > 20) {
                result = false
                formik.setFieldError("templateName", `Max 20 characters can be used`);
            }

            if (formik.values.templateName.includes(" ")) {
                result = false
                formik.setFieldError("templateName", `Template name cannot contain spaces`);
            }
        }


        if (formik.values.botId === "") {
            result = false
            formik.setFieldError("botId", `Select bot`);

        }

        if (formik.values.templateType == textMessage) {
            const fieldValue = formik.values.textMessageDetails.messageContent;
            if (fieldValue === "" || fieldValue === null) {
                result = false
                formik.setFieldError("textMessageDetails.messageContent", `Text Message Required`);

                // Additional validation for charCount
                const countResult = CharCounter(fieldValue);

                result = false;
            }
            if (fieldValue !== "" || fieldValue !== null) {
                let withinBracket = false;
                let bracketCount = 0;

                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            result = false
                            formik.setFieldError(
                                "textMessageDetails.messageContent",
                                "Variables should contain only alphabets, numbers, and underscores",
                            );

                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            result = false
                            formik.setFieldError(
                                "textMessageDetails.messageContent",
                                "Variables should contain only alphabets, numbers, and underscores",
                            );

                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        result = false
                        formik.setFieldError(
                            "textMessageDetails.messageContent",
                            "Variables should contain only alphabets, numbers, and underscores",
                        );

                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    result = false
                    formik.setFieldError("textMessageDetails.messageContent", "Variables should contain only alphabets, numbers, and underscores");

                }

                if (fieldValue.includes("[]")) {
                    result = false
                    formik.setFieldError("textMessageDetails.messageContent", "Variables should contain only alphabets, numbers, and underscores");

                }
            }
            if (fieldValue !== "" || fieldValue !== null) {
                const countResult = CharCounter(fieldValue);
                if (countResult > 2500) {
                    result = false
                    formik.setFieldError("textMessageDetails.messageContent", "Max 2500 characters can be used");

                }
            }

            if (formik.values.botId === "" || formik.values.botId === null) {
                formik.setFieldError("botId", "Bot Required");
                result = false;
            }
            if (formik.values.templateName === "" || formik.values.templateName === null) {
                formik.setFieldError("templateName", "Template Name Required");
                result = false;
            }

            const nullButtonPostBackIndicesss = formik.values.textMessageDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionPostback === null || card.suggestionPostback === "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonPostBackIndicesss.forEach(nullIndex => {
                formik.setFieldError(`textMessageDetails.suggestionsList[${nullIndex}].suggestionPostback`, "  Required");
                result = false
            });

            const nullButtonPostBackIndicess = formik.values.textMessageDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionPostback !== null || card.suggestionPostback !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonPostBackIndicess.forEach(nullIndex => {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.textMessageDetails.suggestionsList[nullIndex].suggestionPostback;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            formik.setFieldError(
                                `textMessageDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {

                            formik.setFieldError(
                                `textMessageDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {

                        formik.setFieldError(
                            `textMessageDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        result = false;
                        return;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    result = false
                    formik.setFieldError(
                        `textMessageDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    return;
                }

                if (fieldValue.includes("[]")) {
                    result = false
                    formik.setFieldError(
                        `textMessageDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    return;
                }
            });

            const nullButtonPostBackIndicesssss = formik.values.textMessageDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionPostback !== null || card.suggestionPostback !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonPostBackIndicesssss.forEach(nullIndex => {
                const countResult = CharCounter(formik.values.textMessageDetails.suggestionsList[nullIndex].suggestionPostback);
                if (countResult > 120) {
                    result = false
                    formik.setFieldError(`textMessageDetails.suggestionsList[${nullIndex}].suggestionPostback`, " Maximun limit 120");
                    return;
                }
            });

            const nullButtonTextIndicesss = formik.values.textMessageDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionText === null || card.suggestionText === "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonTextIndicesss.forEach(nullIndex => {
                result = false
                formik.setFieldError(`textMessageDetails.suggestionsList[${nullIndex}].suggestionText`, "  Required");
            });

            const nullButtonTextIndicess = formik.values.textMessageDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionText !== null || card.suggestionText !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonTextIndicess.forEach(nullIndex => {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.textMessageDetails.suggestionsList[nullIndex].suggestionText;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            result = false
                            formik.setFieldError(
                                `textMessageDetails.suggestionsList[${nullIndex}].suggestionText`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            return;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            result = false
                            formik.setFieldError(
                                `textMessageDetails.suggestionsList[${nullIndex}].suggestionText`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            return;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        result = false
                        formik.setFieldError(
                            `textMessageDetails.suggestionsList[${nullIndex}].suggestionText`,
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        return;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    result = false
                    formik.setFieldError(
                        `textMessageDetails.suggestionsList[${nullIndex}].suggestionText`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    return;
                }

                if (fieldValue.includes("[]")) {
                    result = false
                    formik.setFieldError(
                        `textMessageDetails.suggestionsList[${nullIndex}].suggestionText`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    return;
                }
            });

            const nullButtonTextIndicessss = formik.values.textMessageDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionText !== null || card.suggestionText !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonTextIndicessss.forEach(nullIndex => {
                const countResult = CharCounter(formik.values.textMessageDetails.suggestionsList[nullIndex].suggestionText);
                if (countResult > 25) {
                    result = false
                    formik.setFieldError(`textMessageDetails.suggestionsList[${nullIndex}].suggestionText`, " Maximun limit 25");
                    return;
                }
            });

            return result;
        }

        if (formik.values.templateType == richCardStandAlone) {
            if (formik.values.botId === "" || formik.values.botId === null) {
                formik.setFieldError("botId", "BotId Required");
                result = false;
            }
            if (formik.values.templateName === "" || formik.values.templateName === null) {
                formik.setFieldError("templateName", "Template Name Required");
                result = false;
            }
            if (formik.values.richCardStandAloneDetails.cardOrientation === "" || formik.values.richCardStandAloneDetails.cardOrientation === null) {
                formik.setFieldError("richCardStandAloneDetails.cardOrientation", "Card Orientation  Required");
                result = false;
            }

            if (formik.values.richCardStandAloneDetails.media.url === "" || formik.values.richCardStandAloneDetails.media.url === null) {
                formik.setFieldError("richCardStandAloneDetails.media.url", "Media  Required");
                result = false;
            } else {

                if ((formik.values.richCardStandAloneDetails.media.type === "video/mp4") && (!(formik.values.richCardStandAloneDetails.thumbnail.url) || formik.values.richCardStandAloneDetails.thumbnail.url === "" || formik.values.richCardStandAloneDetails.thumbnail.url === null)) {
                    formik.setFieldError("richCardStandAloneDetails.thumbnail", "Media  Required");
                    result = false;

                }
            }


            if (formik.values.richCardStandAloneDetails.mediaHeight === "" || formik.values.richCardStandAloneDetails.mediaHeight === null) {
                formik.setFieldError("richCardStandAloneDetails.mediaHeight", "Media Height  Required");
                result = false;
            }

            if (formik.values.richCardStandAloneDetails.cardDescription === "" || formik.values.richCardStandAloneDetails.cardDescription === null) {
                formik.setFieldError("richCardStandAloneDetails.cardDescription", "Card Description  Required");
                result = false;
            }

            if (formik.values.richCardStandAloneDetails.cardDescription !== "" || formik.values.richCardStandAloneDetails.cardDescription !== null) {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.richCardStandAloneDetails.cardDescription;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            formik.setFieldError(
                                "richCardStandAloneDetails.cardDescription",
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            formik.setFieldError(
                                "richCardStandAloneDetails.cardDescription",
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        formik.setFieldError(
                            "richCardStandAloneDetails.cardDescription",
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        result = false;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    formik.setFieldError(
                        "richCardStandAloneDetails.cardDescription",
                        "Variables should contain only alphabets, numbers, and underscores",
                    ); result = false;
                }

                if (fieldValue.includes("[]")) {
                    formik.setFieldError(
                        "richCardStandAloneDetails.cardDescription",
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                }
            }

            if (formik.values.richCardStandAloneDetails.cardDescription !== "" || formik.values.richCardStandAloneDetails.cardDescription !== null) {
                const countResult = CharCounter(formik.values.richCardStandAloneDetails.cardDescription);
                if (countResult > 2000) {
                    formik.setFieldError("richCardStandAloneDetails.cardDescription", "Max 2500 characters can be used");
                    result = false;
                }
            }

            if (formik.values.richCardStandAloneDetails.cardTitle === "" || formik.values.richCardStandAloneDetails.cardTitle === null) {
                formik.setFieldError("richCardStandAloneDetails.cardTitle", "Card Title  Required");
                result = false;
                return result;

            }

            if (formik.values.richCardStandAloneDetails.cardTitle !== "" || formik.values.richCardStandAloneDetails.cardTitle !== null) {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.richCardStandAloneDetails.cardTitle;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            formik.setFieldError(
                                "richCardStandAloneDetails.cardTitle",
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            formik.setFieldError(
                                "richCardStandAloneDetails.cardTitle",
                                "Variables should contain only alphabets, numbers, and underscores",
                            ); result = false;
                            return;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        formik.setFieldError(
                            "richCardStandAloneDetails.cardTitle",
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        result = false;
                        return;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    formik.setFieldError("richCardStandAloneDetails.cardTitle", "Variables should contain only alphabets, numbers, and underscores");
                    result = false;
                    return;
                }

                if (fieldValue.includes("[]")) {
                    formik.setFieldError("richCardStandAloneDetails.cardTitle", "Variables should contain only alphabets, numbers, and underscores");
                    result = false;
                    return;
                }
            }

            if (formik.values.richCardStandAloneDetails.cardTitle !== "" || formik.values.richCardStandAloneDetails.cardTitle !== null) {
                const countResult = CharCounter(formik.values.richCardStandAloneDetails.cardTitle);
                if (countResult > 200) {
                    formik.setFieldError("richCardStandAloneDetails.cardTitle", "Max 2500 characters can be used");
                    result = false;
                    return;
                }
            }

            const nullButtonPostBackIndicesss = formik.values.richCardStandAloneDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionPostback === null || card.suggestionPostback === "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonPostBackIndicesss.forEach(nullIndex => {
                formik.setFieldError(`richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionPostback`, "  Required");
                result = false;
            });

            const nullButtonPostBackIndicess = formik.values.richCardStandAloneDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionPostback !== null || card.suggestionPostback !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonPostBackIndicess.forEach(nullIndex => {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.richCardStandAloneDetails.suggestionsList[nullIndex].suggestionPostback;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            formik.setFieldError(
                                `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            formik.setFieldError(
                                `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        formik.setFieldError(
                            `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        result = false;
                        return;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    formik.setFieldError(
                        `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }

                if (fieldValue.includes("[]")) {
                    formik.setFieldError(
                        `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionPostback`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }
            });

            const nullButtonPostBackIndicessss = formik.values.richCardStandAloneDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionPostback !== null || card.suggestionPostback !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonPostBackIndicessss.forEach(nullIndex => {
                const countResult = CharCounter(formik.values.richCardStandAloneDetails.suggestionsList[nullIndex].suggestionPostback);
                if (countResult > 120) {
                    formik.setFieldError(`richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionPostback`, "  Maximun limit 120");
                    result = false;
                    return;
                }
            });

            const nullButtonTextIndicesss = formik.values.richCardStandAloneDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionText === null || card.suggestionText === "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonTextIndicesss.forEach(nullIndex => {
                formik.setFieldError(`richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionText`, "  Required");
                result = false;
            });

            const nullButtonTextIndicess = formik.values.richCardStandAloneDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionText !== null || card.suggestionText !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonTextIndicess.forEach(nullIndex => {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.richCardStandAloneDetails.suggestionsList[nullIndex].suggestionText;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            formik.setFieldError(
                                `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionText`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            formik.setFieldError(
                                `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionText`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        formik.setFieldError(
                            `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionText`,
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        result = false;
                        return;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    formik.setFieldError(
                        `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionText`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }

                if (fieldValue.includes("[]")) {
                    formik.setFieldError(
                        `richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionText`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }
            });

            const nullButtonTextIndicessss = formik.values.richCardStandAloneDetails.suggestionsList.reduce((indices, card, index) => {
                if (card.suggestionText !== null || card.suggestionText !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullButtonTextIndicessss.forEach(nullIndex => {
                const countResult = CharCounter(formik.values.richCardStandAloneDetails.suggestionsList[nullIndex].suggestionText);
                if (countResult > 25) {
                    formik.setFieldError(`richCardStandAloneDetails.suggestionsList[${nullIndex}].suggestionText`, " Maximun limit 25");
                    result = false;
                    return;
                }
            });

            return result;
        }

        if (formik.values.templateType == richCardCarousel) {
            if (formik.values.botId === "" || formik.values.botId === null) {
                formik.setFieldError("botId", "Bot Required");
                result = false;
            }
            if (formik.values.templateName === "" || formik.values.templateName === null) {
                formik.setFieldError("templateName", "Template Name Required");
                result = false;
            }
            if (formik.values.richCardCarouselDetails.cardWidth === "" || formik.values.richCardCarouselDetails.cardWidth === null) {
                formik.setFieldError("richCardCarouselDetails.cardWidth", "Card Width  Required");
                result = false;
            }
            if (formik.values.richCardCarouselDetails.mediaHeight === "" || formik.values.richCardCarouselDetails.mediaHeight === null) {
                formik.setFieldError("richCardCarouselDetails.mediaHeight", "Height  Required");
                result = false;
            }

            const nullmediaIndices = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, index) => {
                if (card.cardMedia.url === null || card.cardMedia.url === "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            nullmediaIndices.forEach(nullIndex => {
                formik.setFieldError(`richCardCarouselDetails.cardsList[${nullIndex}].cardMedia`, " Media Required");
                result = false;
            });

            const nullDescriptionIndices = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, index) => {
                if (card.cardDescription === null || card.cardDescription === "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullDescriptionIndices.forEach(nullIndex => {
                formik.setFieldError(`richCardCarouselDetails.cardsList[${nullIndex}].cardDescription`, "Card Description Required");
                result = false;
            });

            const nullDescriptionIndicess = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, index) => {
                if (card.cardDescription !== null || card.cardDescription !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullDescriptionIndicess.forEach(nullIndex => {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.richCardCarouselDetails.cardsList[nullIndex].cardDescription;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${nullIndex}].cardDescription`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${nullIndex}].cardDescription`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        formik.setFieldError(
                            `richCardCarouselDetails.cardsList[${nullIndex}].cardDescription`,
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        result = false;
                        return;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${nullIndex}].cardDescription`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }

                if (fieldValue.includes("[]")) {
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${nullIndex}].cardDescription`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }
            });

            const nullDescriptionIndicesss = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, index) => {
                if (card.cardDescription !== null || card.cardDescription !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullDescriptionIndicesss.forEach(nullIndex => {
                const countResult = CharCounter(formik.values.richCardCarouselDetails.cardsList[nullIndex].cardDescription);
                if (countResult > 2000) {
                    formik.setFieldError(`richCardCarouselDetails.cardsList[${nullIndex}].cardDescription`, "Card Description Required");
                    result = false;
                    return;
                }
            });

            const nullTitleIndices = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, index) => {
                if (card.cardTitle === null || card.cardTitle === "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullTitleIndices.forEach(nullIndex => {
                formik.setFieldError(`richCardCarouselDetails.cardsList[${nullIndex}].cardTitle`, " Card Title Required");
                result = false;
            });

            const nullTitleIndicess = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, index) => {
                if (card.cardTitle !== null || card.cardTitle !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullTitleIndicess.forEach(nullIndex => {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.richCardCarouselDetails.cardsList[nullIndex].cardTitle;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${nullIndex}].cardTitle`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${nullIndex}].cardTitle`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        formik.setFieldError(
                            `richCardCarouselDetails.cardsList[${nullIndex}].cardTitle`,
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        result = false;
                        return;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${nullIndex}].cardTitle`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }

                if (fieldValue.includes("[]")) {
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${nullIndex}].cardTitle`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }
            });

            const nullTitleIndicesss = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, index) => {
                if (card.cardTitle !== null || card.cardTitle !== "") {
                    indices.push(index);
                }
                return indices;
            }, []);

            // Iterate over the nullDescriptionIndices and set field errors
            nullTitleIndicesss.forEach(nullIndex => {
                const countResult = CharCounter(formik.values.richCardCarouselDetails.cardsList[nullIndex].cardTitle);
                if (countResult > 200) {
                    formik.setFieldError(`richCardCarouselDetails.cardsList[${nullIndex}].cardTitle`, " Card Title Required");
                    result = false;
                    return;
                }
            });

            const nullSuggestionPostbackIndices = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, cardIndex) => {
                // Check if suggestionsList exists and is an array
                if (Array.isArray(card.suggestionsList)) {
                    // Iterate over each suggestion in the suggestionsList array
                    card.suggestionsList.forEach((suggestion, suggestionIndex) => {
                        // Check if suggestionPostback is null or empty string
                        if (suggestion.suggestionPostback === null || suggestion.suggestionPostback === "") {
                            indices.push({ cardIndex, suggestionIndex });
                        }
                    });
                }
                return indices;
            }, []);

            // Iterate over the nullSuggestionPostbackIndices and set field errors
            nullSuggestionPostbackIndices.forEach(({ cardIndex, suggestionIndex }) => {
                // Set field error for the specific card and suggestion with null suggestionPostback
                formik.setFieldError(
                    `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionPostback`,
                    " Required",
                );
                result = false;
            });

            const nullSuggestionPostbackIndicess = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, cardIndex) => {
                // Check if suggestionsList exists and is an array
                if (Array.isArray(card.suggestionsList)) {
                    // Iterate over each suggestion in the suggestionsList array
                    card.suggestionsList.forEach((suggestion, suggestionIndex) => {
                        // Check if suggestionPostback is null or empty string
                        if (suggestion.suggestionPostback !== null || suggestion.suggestionPostback !== "") {
                            indices.push({ cardIndex, suggestionIndex });
                        }
                    });
                }
                return indices;
            }, []);

            // Iterate over the nullSuggestionPostbackIndices and set field errors
            nullSuggestionPostbackIndicess.forEach(({ cardIndex, suggestionIndex }) => {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.richCardCarouselDetails.cardsList[cardIndex].suggestionsList[suggestionIndex].suggestionPostback;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionPostback`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionPostback`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            result = false;
                            return;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        formik.setFieldError(
                            `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionPostback`,
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        result = false;
                        return;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionPostback`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }

                if (fieldValue.includes("[]")) {
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionPostback`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    result = false;
                    return;
                }
                // Set field error for the specific card and suggestion with null suggestionpostback
            });

            const nullSuggestionPostbackIndicesss = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, cardIndex) => {
                // Check if suggestionsList exists and is an array
                if (Array.isArray(card.suggestionsList)) {
                    // Iterate over each suggestion in the suggestionsList array
                    card.suggestionsList.forEach((suggestion, suggestionIndex) => {
                        // Check if suggestionPostback is null or empty string
                        if (suggestion.suggestionPostback !== null || suggestion.suggestionPostback !== "") {
                            indices.push({ cardIndex, suggestionIndex });
                        }
                    });
                }
                return indices;
            }, []);

            // Iterate over the nullSuggestionPostbackIndices and set field errors
            nullSuggestionPostbackIndicesss.forEach(({ cardIndex, suggestionIndex }) => {
                // Set field error for the specific card and suggestion with null suggestionPostback
                const countResult = CharCounter(
                    formik.values.richCardCarouselDetails.cardsList[cardIndex].suggestionsList[suggestionIndex].suggestionPostback,
                );
                if (countResult > 120) {

                    result = false;
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionPostback`,
                        " Maximum Limit 25",
                    );
                }
                return;
            });

            const nullSuggestionButtonTextIndices = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, cardIndex) => {
                // Check if suggestionsList exists and is an array
                if (Array.isArray(card.suggestionsList)) {
                    // Iterate over each suggestion in the suggestionsList array
                    card.suggestionsList.forEach((suggestion, suggestionIndex) => {
                        // Check if suggestionPostback is null or empty string
                        if (suggestion.suggestionText === null || suggestion.suggestionText === "") {
                            indices.push({ cardIndex, suggestionIndex });
                        }
                    });
                }
                return indices;
            }, []);

            // Iterate over the nullSuggestionPostbackIndices and set field errors
            nullSuggestionButtonTextIndices.forEach(({ cardIndex, suggestionIndex }) => {
                // Set field error for the specific card and suggestion with null suggestionPostback
                result = false;
                formik.setFieldError(
                    `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionText`,
                    "  Required",
                );
            });

            const nullSuggestionButtonTextIndicess = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, cardIndex) => {
                // Check if suggestionsList exists and is an array
                if (Array.isArray(card.suggestionsList)) {
                    // Iterate over each suggestion in the suggestionsList array
                    card.suggestionsList.forEach((suggestion, suggestionIndex) => {
                        // Check if suggestionPostback is null or empty string
                        if (suggestion.suggestionText !== null || suggestion.suggestionText !== "") {
                            indices.push({ cardIndex, suggestionIndex });
                        }
                    });
                }
                return indices;
            }, []);

            // Iterate over the nullSuggestionPostbackIndices and set field errors
            nullSuggestionButtonTextIndicess.forEach(({ cardIndex, suggestionIndex }) => {
                let withinBracket = false;
                let bracketCount = 0;
                const fieldValue = formik.values.richCardCarouselDetails.cardsList[cardIndex].suggestionsList[suggestionIndex].suggestionText;
                for (const char of fieldValue) {
                    if (char === "[") {
                        if (withinBracket) {

                            result = false;
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionText`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            return;
                        }
                        withinBracket = true;
                        bracketCount++;
                    } else if (char === "]") {
                        if (!withinBracket) {

                            result = false;
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionText`,
                                "Variables should contain only alphabets, numbers, and underscores",
                            );
                            return;
                        }
                        withinBracket = false;
                        bracketCount--;
                    } else if (withinBracket && !/^[a-zA-Z0-9_]+$/.test(char)) {
                        result = false;
                        formik.setFieldError(
                            `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionText`,
                            "Variables should contain only alphabets, numbers, and underscores",
                        );
                        return;
                    }
                }

                if (withinBracket || bracketCount !== 0) {
                    result = false;
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionText`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    return;
                }

                if (fieldValue.includes("[]")) {
                    result = false;
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionText`,
                        "Variables should contain only alphabets, numbers, and underscores",
                    );
                    return;
                }
                // Set field error for the specific card and suggestion with null suggestionpostback
            });

            const nullSuggestionButtonTextIndicesss = formik.values.richCardCarouselDetails.cardsList.reduce((indices, card, cardIndex) => {
                // Check if suggestionsList exists and is an array
                if (Array.isArray(card.suggestionsList)) {
                    // Iterate over each suggestion in the suggestionsList array
                    card.suggestionsList.forEach((suggestion, suggestionIndex) => {
                        // Check if suggestionPostback is null or empty string
                        if (suggestion.suggestionText !== null || suggestion.suggestionText !== "") {
                            indices.push({ cardIndex, suggestionIndex });
                        }
                    });
                }
                return indices;
            }, []);

            // Iterate over the nullSuggestionPostbackIndices and set field errors
            nullSuggestionButtonTextIndicesss.forEach(({ cardIndex, suggestionIndex }) => {
                // Set field error for the specific card and suggestion with null suggestionPostback
                const countResult = CharCounter(
                    formik.values.richCardCarouselDetails.cardsList[cardIndex].suggestionsList[suggestionIndex].suggestionText,
                );
                if (countResult > 25) {

                    result = false;
                    formik.setFieldError(
                        `richCardCarouselDetails.cardsList[${cardIndex}].suggestionsList[${suggestionIndex}].suggestionText`,
                        " Maximum Limit 25",
                    );
                }
                return;
            });

            return result
        }


    };

    const onClickAddSuggestionTextMessage = () => {
        if (formik.values.textMessageDetails.suggestionsList.length < 11) {
            formik.setFieldValue("textMessageDetails.suggestionsList", [...formik.values.textMessageDetails.suggestionsList, suggestionObjectToAdd]);
        }
    };

    const handleChangeRemoveSuggestionTextMessage = ({ indexOfSuggstion }) => {
        formik.values.textMessageDetails.suggestionsList.splice(indexOfSuggstion, 1);

        formik.setFieldValue(`textMessageDetails.suggestionsList`, formik.values.textMessageDetails.suggestionsList, true);
        formik.touched("textMessageDetails.suggestionsList");
    };

    const handleChangeSuggestionTextMessage = ({ field, value, indexOfSuggstion }) => {
        formik.setFieldValue(`textMessageDetails.suggestionsList[${indexOfSuggstion}].${field}`, value);
        if (value === "dialer_action") {
            const data = {
                suggestionId: uuid(),
                typeOfAction: value,
                suggestionText: "",
                suggestionPostback: `[${config.REACT_APP_POSTBACK_VARIABLE}]`,
                phoneNumberToDial: {
                    country: "India",
                    countryCode: "+91",
                    number: "",
                },
            };

            formik.setFieldValue(`textMessageDetails.suggestionsList[${indexOfSuggstion}]`, data);
        }
    };

    // carousel card----------------------------------------------------------------------

    const addCardRichCardCarousal = () => {
        let allCardImageList = cardsPreviewImageList;

        allCardImageList.push({ url: null, type: null, file: null, fieldName: null });

        setCardsPreviewImageList(allCardImageList);

        if (formik.values.richCardCarouselDetails.cardsList.length <= 9) {
            formik.setFieldValue(
                "richCardCarouselDetails.cardsList",
                [...formik.values.richCardCarouselDetails.cardsList, carouselCardObjectToAdd],
                true,
            );
        }
    };

    const handleChangeRemoveCarouselCard = ({ e, cardIndex }) => {
        let allCardImageList = cardsPreviewImageList;
        allCardImageList.splice(cardIndex, 1);

        // allCardImageList = allCardImageList

        setCardsPreviewImageList(allCardImageList);
        formik.values.richCardCarouselDetails.cardsList.splice(cardIndex, 1);

        formik.setFieldValue(`richCardCarouselDetails.cardsList`, formik.values.richCardCarouselDetails.cardsList, true);

        formik.handleChange(e);
        formik.touched("richCardCarouselDetails.cardsList");
    };

    const handleChangeCarouselCardDetails = ({ field, value, index }) => {
        if (field === "cardWidth" || field === "mediaHeight") {
            formik.setFieldValue(`richCardCarouselDetails.${field}`, value);
        } else {
            switch (field) {
                case "cardTitle":
                    if (value.length > 201) {

                        formik.setFieldError(`richCardCarouselDetails.cardsList[${index}].${field}`, "Max 200 characters can be used");
                        return
                    }
                    break;
                case "cardDescription":
                    if (value.length > 2001) {
                        formik.setFieldError(`richCardCarouselDetails.cardsList[${index}].${field}`, "Max 2000 characters can be used");
                        return
                    }

                    break;
            }



            formik.setFieldValue(`richCardCarouselDetails.cardsList[${index}].${field}`, value);
        }







    };

    const setCharLimitErrors = ({ fieldName, limit }) => {
        formik.setFieldError(fieldName, `max ${limit} charactors can be used`);
    };

    //-----------------------------------------------------------------------------------------------

    const onClickCancelButton = () => {
        setTemplateDetails(null);
        setEditable(true);
        dispatch(setShowAddTemplateForm(false));
    };

    const onChangeSelectBot = (e) => {
        const selectedBot = botState.verifiedBotList.filter((value) => value._id == e.target.value)[0];
        formik.setFieldValue("botId", selectedBot._id);
        formik.setFieldValue("botMessageType", selectedBot.botMessageType);
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForTemplates({ fieldName }));
    };

    const onSelectImage = ({ file, url, fieldName }) => {
        formik.setFieldValue(fieldName, url);
        if (fieldName === "richCardStandAloneDetails.thumbnail.url") {
            setThumbnailPreviewImage({ url, type: file.type, file, fieldName });
        } else {
            setPreviewImage({ url, type: file.type, file, fieldName });
        }
    };



    const onUpdatingTemplate = async () => {
        try {
            const values = formik.values;
            let smsValidation = true
            if (formik.values.isSMSFallbackRequired) {
                smsValidation = await validateSMSFileds();
            }
            const isTemplateDataValidated = await validateDatingTemplate();
            if (!(isTemplateDataValidated && smsValidation)) {
                return;
            }

            const formData = new FormData();
            let templateDataToSend = {
                botId: values.botId,
                orgId: values.orgId,
                botMessageType: values.botMessageType,
                templateName: values.templateName.trim(),
                templateType: values.templateType,
                isSMSFallbackRequired: values.isSMSFallbackRequired,
                smsFallbackTemplateDetails: values.smsFallbackTemplateDetails,
            };

            if (values.templateType === richCardStandAlone) {
                let richCardStandAloneDetails = {
                    cardOrientation: values.richCardStandAloneDetails?.cardOrientation,
                    cardTitle: values.richCardStandAloneDetails?.cardTitle,
                    cardDescription: values.richCardStandAloneDetails?.cardDescription,
                    suggestionsList: values.richCardStandAloneDetails?.suggestionsList,
                };

                if (richCardStandAloneDetails.cardOrientation === "VERTICAL") {
                    richCardStandAloneDetails = {
                        ...richCardStandAloneDetails,
                        mediaHeight: values.richCardStandAloneDetails?.mediaHeight,
                    };
                } else if (richCardStandAloneDetails.cardOrientation === "HORIZONTAL") {
                    richCardStandAloneDetails = {
                        ...richCardStandAloneDetails,
                        cardAlignment: values.richCardStandAloneDetails?.cardAlignment,
                    };
                }
                templateDataToSend = {
                    ...templateDataToSend,
                    richCardStandAloneDetails,
                };
                // ---------------------------------------------------------------
                // for (let fieldName in imageFormDataRichCardStandAlone) {
                //     formData.append(
                //         fieldName,
                //         imageFormDataRichCardStandAlone[fieldName]
                //     );
                // }
                // ---------------------------------------------------------------

                // const arrbuf = await previewImage.file.arrayBuffer();
                // const buffer = Buffer.from(arrbuf);

                formData.append(previewImage.fieldName, previewImage.file);
                formData.append(thumbnailpreviewImage.fieldName, thumbnailpreviewImage.file);
                formData.append("templateDataToSend", JSON.stringify(templateDataToSend));
            } else if (values.templateType === richCardCarousel) {
                templateDataToSend = {
                    ...templateDataToSend,
                    richCardCarouselDetails: values.richCardCarouselDetails,
                };
                // for (
                //     let index = 0;
                //     index < values.richCardCarouselDetails.cardsList.length;
                //     index++
                // ) {
                //     formData.append(
                //         `carouselCardMedia_${index}`,
                //         values.richCardCarouselDetails.cardsList[index]
                //             ?.mediaData
                //     );
                // }
                // ---------------------------------------------------------------
                // for (let fieldName in imageFormDataRichCardCarousel) {
                //   formData.append(
                //     fieldName,
                //     imageFormDataRichCardCarousel[fieldName]
                //   );
                // }
                // ---------------------------------------------------------------

                for (let index = 0; index < cardsPreviewImageList.length; index++) {
                    const cardPreviewMedia = cardsPreviewImageList[index];
                    formData.append(`carouselCardMedia_${index}`, cardPreviewMedia.file);
                }

                Object.keys(cardsPreviewThumbnailImageList).map(name => {
                    formData.append(name, cardsPreviewThumbnailImageList[name].file);
                });

                formData.append("templateDataToSend", JSON.stringify(templateDataToSend));
            } else if (values.templateType === textMessage) {
                templateDataToSend = {
                    ...templateDataToSend,
                    textMessageDetails: values.textMessageDetails,
                };

                formData.append("templateDataToSend", JSON.stringify(templateDataToSend));
            }

            // dispatch(
            //     addNewTemplateThunk({
            //         data: formData,
            //         botId: values.botId,
            //         templateType: values.templateType,
            //         templateId: templateData?._id ? templateData?._id : "",
            //     })
            // );

            const data = formData;

            dispatch(setUpdateTemplateErrorMessage(""));

            const response = await apiGatewayAxiosInstance.post(`/bot_management/template/update-template`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    botId: values.botId,
                    templateType: values.templateType,
                    templateId: templateData?._id ? templateData?._id : "",
                },
            });
            if (response.status === 200) {
                if (state.selectedBotDetails) {
                    dispatch(getAllTemplatesByPageSize({ currentPage: 1, pageSize: 10, botId: state.selectedBotDetails._id }));
                }

                onClickCancelButton();
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Failed to update template.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(setUpdateTemplateErrorMessage(errorMessage));
        }
    };



    console.log(formik.values, "values", botState)
    const propsToSendToCreateTemplateComponent = {
        formik,
        templateData,
        state,
        selectedTab,
        botState,
        isTempalteEditable,
        filePicekerRef,
        thumnailInputRef,
        textMessage,
        richCardCarousel,
        richCardStandAlone,
        vertical,
        isHorizontal,
        horizontal,
        previewImage,
        thumbnailpreviewImage,
        onSelectOrientationType,
        onSelectRichCardStandAloneCardAlignement,
        onSelectRichCardStandAloneMediaHeight,
        onDeleteImageRichCardStandAlone,
        onDeleteRichCardStandAloneThumbnail,
        previewFile,
        showSelectThumbnail,
        cardsPreviewImageList,
        setCardsPreviewImageList,
        onChangeSelectTemplate,
        onChangeSelectBot,
        onChangeCardTitle,
        onChangeRichCardStandAloneCardDescripton,
        handleChangeRemoveSuggestionRichCardStandAlone,
        handleChangeSuggestionRichCardStandAlone,
        CharCounter,
        setSelectedTab,
        onClickAddSuggestionRichCardStandAlone,
        handleChangeRemoveCarouselCard,
        handleChangeCarouselCardDetails,
        onClickAddSuggestionTextMessage,
        onChangeMessageContent,
        addCardRichCardCarousal,
        handleChangeRemoveSuggestionTextMessage,
        handleChangeSuggestionTextMessage,
        onCloseErrorMessage,
        onClickCancelButton,
        onSelectImage,
        setCardsPreviewThumbnailImageList,
        cardsPreviewThumbnailImageList,
        onUpdatingTemplate,
        selectedBotName,
        onChangeTemplateName,
        user
    };
    return (
        <Grid container sx={{ minHeight: "80vh !important", backgroundColor: "transparent !important" }}>
            <CreateTemplate inputs={propsToSendToCreateTemplateComponent} />
            <SimulatorComponent formik={formik} />
        </Grid>
    );
}
