import React, { useState } from 'react';
import { ContextMenu } from '../ContextMenu';
import { Handle, Position } from 'react-flow-renderer';
import deleteImage from '../../../assets/icons/delete-icon.svg'
import { generateUniqueIdForCard } from '../../../utils/workflow-utils/helpers';
import { useEffect } from 'react';

export default function ConditionNodePropertyMenu({ node, variableNamespace, updateConditionNode }) {
    const { data, id } = node;

    const [isConditionEditable, setIsConditionEditable] = useState(true);

    const [contents, setContents] = useState({});


    const [isSecondCondition, setIsSecondCondition] = useState(true);

    const [errors, setErrors] = useState({});


    const updateContents = () => {
        if (node.data.contents.length === 0) {
            const content = {
                checkType: null,
                conditions: [
                    {
                        id: generateUniqueIdForCard(),
                        isCompareToText: true,
                        compareToValue: '',

                        conditionType: 'less than',

                        isCompareWithText: true,
                        compareWithValue: ''
                    },
                    {
                        id: generateUniqueIdForCard(),
                        isCompareToText: true,
                        compareToValue: '',

                        conditionType: 'less than',

                        isCompareWithText: true,
                        compareWithValue: ''
                    }
                ]
            }
            setContents(content);
        } else {
            setContents(node.data.contents);
            if (node.data.contents.checkType !== null) {
                setIsSecondCondition(false);
            }
        }
    }



    const setCompareToValue = (e, con) => {
        const value = e.target.value;

        let newConditionsArray = contents.conditions.map((condition) => {
            if (condition.id === con.id) {
                if (value === 'compareTo') {
                    return {
                        ...condition,
                        isCompareToText: true,
                        compareToType: 'userInput',
                        compareToValue: ''
                    }
                } else {
                    return {
                        ...condition,
                        isCompareToText: false,
                        compareToType: 'variable',
                        compareToValue: value
                    }
                }
            }
            return condition;
        });
        setContents({ ...contents, conditions: newConditionsArray });
    }

    const setCompareWithValue = (e, con) => {
        const value = e.target.value;

        let newConditionArray = contents.conditions.map((condition) => {
            if (condition.id === con.id) {
                if (value === 'compareWith') {
                    return {
                        ...condition,
                        isCompareWithText: true,
                        compareWithType: 'userInput',
                        compareWithValue: ''
                    }
                } else {
                    return {
                        ...condition,
                        isCompareWithText: false,
                        compareWithType: 'variable',
                        compareWithValue: value
                    }
                }
            }
            return condition;
        });
        setContents({ ...contents, conditions: newConditionArray });
    }

    const updateCompareInput = (e, con, type) => {
        const value = e.target.value;

        let newConditionArray = contents.conditions.map((condition) => {
            if (condition.id === con.id) {
                if (type === 'compareTo') {
                    return {
                        ...condition,
                        compareToValue: value
                    }
                }
                else if (type === 'compareWith') {
                    return {
                        ...condition,
                        compareWithValue: value
                    }
                }
            }
        });
        setContents({ ...contents, conditions: newConditionArray });
    }

    const setConditionType = (e, con) => {
        const value = e.target.value;

        let newConditionArray = contents.conditions.map((condition) => {
            if (condition.id === con.id) {
                return {
                    ...condition,
                    conditionType: value
                }
            }
        });
        setContents({ ...contents, conditions: newConditionArray });
    }


    const onChangeEventHandler = (e, conditionIndex, field) => {
        setContents({
            ...contents,
            conditions: contents.conditions.map((cod, idx) => {
                if (idx === conditionIndex) {
                    return {
                        ...cod,
                        [field]: e.target.value
                    }
                } else {
                    return cod;
                }
            })
        });
    }

    //
    const checkTypeChanged = (e) => {
        setContents({
            ...contents,
            checkType: e.target.value
        });
    }

    // 
    const addCondition = () => {
        setContents({
            ...contents,
            checkType: 'AND'
        });
        setIsSecondCondition(false);
    };

    // 
    const deleteCondition = (index) => {
        setContents({
            ...contents,
            checkType: null
        });
        setIsSecondCondition(true);
    }

    // changes feild to be editable and non editable 
    const saveCondition = () => {
        if ((contents.conditions[0].compareToValue === '') || (contents.conditions[0].compareWithValue === '')) {
            setErrors({ message: 'Please enter the values' });
        } else if (contents.checkType !== null && ((contents.conditions[1].compareToValue === '') || (contents.conditions[1].compareWithValue === ''))) {
            setErrors({ message: 'Please enter the values' });
        } else {
            setErrors({});
            setIsConditionEditable(false);
            updateConditionNode(node.id, contents);
        }
    }

    // changes feild to be editable and non editable 
    const editCondition = () => {
        setIsConditionEditable(true);
    }

    useEffect(() => {

        updateContents();

    }, []);


    return (
        <>

            <div className="text-updater-node" id={`${id}`}>
                <div style={{ padding: '10px' }}>
                    <div className='mb-2'>Condition:-  </div>
                    {
                        isConditionEditable && <div>
                            <div>

                                {
                                    contents.conditions && contents.conditions.length > 0 && <div className='border-2  border-gray-300 p-2 rounded'>
                                        <div className='m-1'>
                                            <input
                                                value={contents.conditions[0].compareToValue}
                                                onChange={(e) => onChangeEventHandler(e, 0, 'compareToValue')}
                                                className='p-1 w-72 border border-black rounded'
                                                type="text"
                                                name="compareTo"
                                                list="compareToValue"
                                                autoComplete="off"
                                                title='You are either choose from Variable list or can use your own value'
                                            />
                                            <datalist id="compareToValue" >
                                                {
                                                    variableNamespace && variableNamespace.map((item, idx) => {
                                                        return (
                                                            <option key={`var-name-1-${item.variant}-${idx}`} value={item.variant} >{item.variant}</option>
                                                        )
                                                    })
                                                }
                                            </datalist>
                                        </div>
                                        <div className="flex  justify-center">
                                            <select
                                                value={contents.conditions[0].conditionType}
                                                onChange={(e) => onChangeEventHandler(e, 0, 'conditionType')}
                                                className='w-32 border '>

                                                <option>less than</option>
                                                <option>less than equal to</option>
                                                <option>greater than</option>
                                                <option>greater than equal to</option>
                                                <option>equal to</option>
                                                <option>not equal to</option>
                                            </select>
                                        </div>

                                        <div className='m-1'>
                                            <input
                                                value={contents.conditions[0].compareWithValue}
                                                onChange={(e) => onChangeEventHandler(e, 0, 'compareWithValue')}
                                                className='p-1 w-72 border border-black rounded'
                                                type="text"
                                                name="compareWith"
                                                list="compareWithValue"
                                                autoComplete="off"
                                                title='You are either choose from Variable list or can use your own value'
                                            />
                                            <datalist id="compareWithValue">
                                                {
                                                    variableNamespace && variableNamespace.map((item, idx) => {
                                                        return (
                                                            <option key={`var-name-2-${item.variant}-${idx}`} value={item.variant} >{item.variant}</option>
                                                        )
                                                    })
                                                }
                                            </datalist>
                                        </div>
                                    </div>
                                }


                                {
                                    isSecondCondition === true &&
                                    <input className='button' onClick={addCondition} type="button" value="Add Condition" />
                                }

                                {
                                    isSecondCondition === false &&
                                    <>
                                        <label className="and-or-condition" style={{ border: 'none' }}>
                                            <select
                                                value={contents.checkType ?? 'AND'}
                                                onChange={checkTypeChanged}
                                                className='select-option-box'
                                            >
                                                <option>AND</option>
                                                <option>OR</option>
                                            </select>
                                            <button className='card-icon-button' onClick={deleteCondition}>
                                                <img className='card-icon' src={deleteImage} alt="delete" style={{ width: '20px', height: '20px' }} />
                                            </button>
                                        </label>
                                    </>
                                }
                                {
                                    !isSecondCondition &&
                                    <>
                                        <div className='border-2  border-gray-300 p-2 rounded'>
                                            <div className='m-1'>
                                                <input
                                                    value={contents.conditions[1].compareToValue}
                                                    onChange={(e) => onChangeEventHandler(e, 1, 'compareToValue')}
                                                    className='p-1 w-72 border border-black rounded'
                                                    type="text"
                                                    name="compareTo2"
                                                    list="compareToValue2"
                                                    autoComplete="off"
                                                    title='You are either choose from Variable list or can use your own value'
                                                />
                                                <datalist id="compareToValue2">
                                                    {
                                                        variableNamespace && variableNamespace.map((item, idx) => {
                                                            return (
                                                                <option key={`var-name-3-${item.variant}-${idx}`} value={item.variant} >{item.variant}</option>
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                            </div>
                                            <div className="flex  justify-center">
                                                <select
                                                    value={contents.conditions[1].conditionType}
                                                    onChange={(e) => onChangeEventHandler(e, 1, 'conditionType')}
                                                    className='w-32 border '
                                                >
                                                    <option>less than</option>
                                                    <option>less than equal to</option>
                                                    <option>greater than</option>
                                                    <option>greater than equal to</option>
                                                    <option>equal to</option>
                                                    <option>not equal to</option>
                                                </select>
                                            </div>

                                            <div className='m-1'>
                                                <input
                                                    value={contents.conditions[1].compareWithValue}
                                                    onChange={(e) => onChangeEventHandler(e, 1, 'compareWithValue')}
                                                    className='p-1 w-72 border border-black rounded'
                                                    type="text"
                                                    name="compareWith2"
                                                    list="compareWithValue2"
                                                    autoComplete="off"
                                                    title='You are either choose from Variable list or can use your own value'
                                                />
                                                <datalist id="compareWithValue2">
                                                    {
                                                        variableNamespace && variableNamespace.map((item, idx) => {
                                                            return (
                                                                <option key={`var-name-4-${item.variant}-${idx}`} value={item.variant} >{item.variant}</option>
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                            </div>
                                        </div>
                                    </>
                                }


                            </div>

                            <div className='border-t-2 flex justify-end'>
                                <button className='py-1 px-4 mt-2 bg-teal-300 rounded border-2' onClick={saveCondition}>Save</button>
                            </div>
                            {
                                errors && errors.message &&
                                <p className='text-xs px-2' style={{ color: '#F96363' }}>  {errors.message} </p>
                            }
                        </div>
                    }
                    {
                        !isConditionEditable && <div>
                            <div>

                                <div className='border-2  border-gray-300 p-2 rounded'>
                                    <div className='m-1'>
                                        <input disabled value={contents.conditions[0].compareToValue} className='p-1 w-72 border border-black rounded' type="text" name="compareTo" list="compareToValue" />
                                    </div>
                                    <div className="flex  justify-center">
                                        {contents.conditions[0].conditionType}
                                    </div>

                                    <div className='m-1'>
                                        <input disabled value={contents.conditions[0].compareWithValue} className='p-1 w-72 border border-black rounded' type="text" name="compareWith" list="compareWithValue" />
                                    </div>
                                </div>

                                {
                                    !isSecondCondition &&
                                    <>
                                        <label className="and-or-condition" style={{ border: 'none' }}>
                                            {contents.checkType}
                                        </label>
                                    </>
                                }
                                {
                                    !isSecondCondition &&
                                    <div className='border-2  border-gray-300 p-2 rounded'>
                                        <div className='m-1'>
                                            <input disabled value={contents.conditions[1].compareToValue} className='p-1 w-72 border border-black rounded' type="text" name="compareTo" list="compareToValue" />
                                        </div>
                                        <div className="flex  justify-center">
                                            {contents.conditions[1].conditionType}
                                        </div>

                                        <div className='m-1'>
                                            <input disabled value={contents.conditions[1].compareWithValue} className='p-1 w-72 border border-black rounded' type="text" name="compareWith" list="compareWithValue" />
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className='border-t-2 flex justify-end'>
                                <button className='py-1 px-4 mt-2 bg-red-300 rounded border' onClick={editCondition}>Edit</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
