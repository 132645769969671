import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import FilePreviewComponent from '../../botBuilder/FilePreviewComponent'
import CountryCode from "../../../assets/CountryCode.json";

export default function AddBrandDetails({ formik, onDeleteImage, fileRef, previewFile, onChangeSelectCountry, isBrandNotVerified, brandLogoPreviewImage, brandVerified, onSelectImage }) {
    const countryCode = CountryCode;

    return (
        <Grid>
            <Typography className="my-2 text-muted">
                The following information will be required as part of your
                business verification. Please ensure that the information you
                share with us is complete and accurate.
            </Typography>
            <Box>

                <Box paddingBottom={2} display={"flex"} gap={5}>
                    <FormControl
                        error={
                            Boolean(formik.errors.brandName)
                        }
                        className="w-50"
                    >
                        <TextField
                            label="Brand Name"
                            className="fw-bold"
                            name="brandName"
                            fullWidth
                            varient="outlined"
                            error={Boolean(formik.errors.brandName
                            )}
                            disabled={!isBrandNotVerified}

                            helperText={formik.errors.brandName}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            defaultValue={formik.values.brandName}

                        />
                    </FormControl>
                    <FormControl
                        className="w-50"
                        error={
                            Boolean(formik.errors.industryType)
                        }

                    >
                        <InputLabel id="industyTypeLabel">
                            Industry Type
                        </InputLabel>
                        <Select
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        boxShadow:
                                            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                    },
                                },
                            }}
                            name="industryType"
                            label="Industry Type"
                            labelId="industyTypeLabel"
                            id="industryType"
                            error={Boolean(
                                formik.errors.industryType
                            )}
                            helperText={
                                formik.errors.industryType
                            }

                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            defaultValue={formik.values.industryType}
                            disabled={!isBrandNotVerified}

                        >
                            {["Advertising/marketing", "Arts & entertainment", "Commercial and industrial", "Community organisation", "Education", "Finance", "Government", "Hotel and B&B", "Legal", "Local service", "Media/ news company", "Non-governmental organisation (NGO)", "Non-profit organisation", "Not Available", "Other", "Property", "Science, technology and engineering", "Shopping & retail", "Sport & recreation", "Telecom"].map(
                                (industryType, index) => (
                                    <MenuItem key={index} value={industryType}>
                                        {industryType}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Box>

                <Box paddingBottom={2} display={"flex"} gap={5}>
                    <FormControl
                        error={
                            Boolean(
                                formik.errors.officialBrandWebsite
                            )
                        }
                        className="w-50"
                    >
                        <TextField
                            className="mt-3"
                            type="url"
                            name="officialBrandWebsite"
                            fullWidth
                            label="Official Brand Website *"
                            varient="outlined"
                            error={Boolean(
                                formik.errors.officialBrandWebsite
                            )}
                            disabled={!isBrandNotVerified}


                            // value={formik.values.officialBrandWebsite}
                            helperText={
                                formik.errors.officialBrandWebsite
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            defaultValue={formik.values.officialBrandWebsite}
                        />
                    </FormControl>
                    <FormControl
                        error={
                            Boolean(formik.errors.brandLogo)
                        }
                        className="w-50"
                    >
                        <Typography variant="h6" fontWeight={"bold"}>
                            Brand Logo
                        </Typography>
                        <Typography className="text-muted mb-3">
                            Provide a logo for your bot that will be displayed in
                            connection with the bot's name.
                        </Typography>
                        {(formik.values.brandLogo.url || brandLogoPreviewImage?.url) &&
                            <Grid sx={{ display: "flex" }}>
                                <FilePreviewComponent
                                    type={brandLogoPreviewImage.type}
                                    url={brandLogoPreviewImage.url}
                                    previewImage={brandLogoPreviewImage}
                                    formikImage={formik.values.brandLogo}
                                    aspect={1 / 1}
                                    onSelectImage={onSelectImage}
                                    imageHeightRatio={1}
                                    imageWidthtRatio={1}
                                    fieldName={'brandLogo'}
                                    isDisabled={!isBrandNotVerified}
                                    deleteFile={() =>
                                        onDeleteImage("brandLogo.url", "brandLogo.type")
                                    }
                                    width={"240px"}
                                    height={"100px"}
                                />

                            </Grid>
                        }

                        {!(formik.values.brandLogo.url || brandLogoPreviewImage?.url) && (
                            <Button
                                variant="outlined"
                                sx={{ width: "250px" }}
                                onClick={() => fileRef.current.click()}

                            >
                                Choose
                            </Button>
                        )}
                        <FormHelperText>
                            {formik.errors?.brandLogo?.url}
                        </FormHelperText>


                        <Box className="btn-container">
                            <input
                                name="brandLogo"
                                ref={fileRef}
                                accept="image/*"
                                onChange={(e) =>
                                    previewFile(
                                        e.target.files[0],
                                        "brandLogo",
                                        "brandLogo.type"
                                    )
                                }
                                onClick={(e) => e.target.value = ""}
                                type="file"
                                hidden
                            />
                        </Box>
                    </FormControl>
                </Box>

                <Box
                    paddingBottom={2}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                >

                    {/* <Box display={"flex"} gap={5}>
                        <FormControl
                            error={
                                Boolean(
                                    formik.errors.contactPersonFirstName)
                            }
                            fullWidth
                        >
                            <TextField
                                                            disabled={!isBrandNotVerified}


                                className="mt-2"
                                name="contactPersonFirstName"
                                fullWidth
                                label="Contact Person First Name"
                                varient="outlined"
                                error={Boolean(
                                    formik.errors.contactPersonFirstName
                                )}
                                helperText={
                                    formik.errors.contactPersonFirstName
                                }
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.contactPersonFirstName}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={
                                Boolean(
                                    formik.errors.contactPersonLastName)
                            }
                        >
                            <TextField
                                className="mt-2"
                                name="contactPersonLastName"
                                fullWidth
                                label="Contact Person Last Name"
                                varient="outlined"
                                error={Boolean(
                                    formik.errors.contactPersonLastName
                                )}
                                                            disabled={!isBrandNotVerified}


                                helperText={
                                    formik.errors.contactPersonLastName
                                }
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.contactPersonLastName}
                            />
                        </FormControl>
                    </Box> */}

                    {/* <Box display={"flex"} gap={5}>
                        <FormControl
                            error={
                                Boolean(formik.errors.contactPersonDesignation)
                            }
                            fullWidth
                        >
                            <TextField
                                className="mt-2"
                                name="contactPersonDesignation"
                                fullWidth
                                label="Company Person Designation"
                                varient="outlined"
                                                            disabled={!isBrandNotVerified}


                                error={Boolean(
                                    formik.errors.contactPersonDesignation
                                )}
                                helperText={
                                    formik.errors.contactPersonDesignation
                                }
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.contactPersonDesignation}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={
                                Boolean(
                                    formik.errors.contactPersonEmailId)
                            }
                        >
                            <TextField
                                className="mt-2"
                                type="email"
                                name="contactPersonEmailId"
                                fullWidth
                                                            disabled={!isBrandNotVerified}


                                label="Contact Person Email Id"
                                varient="outlined"
                                error={Boolean(
                                    formik.errors.contactPersonEmailId
                                )}
                                helperText={
                                    formik.errors.contactPersonEmailId
                                }
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.contactPersonEmailId}
                                value={formik.values.contactPersonEmailId}
                            />
                        </FormControl>
                    </Box> */}
                    {/* <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <TextField
                                                            disabled={!isBrandNotVerified}


                                className="mt-2"
                                name="companyAddress.addressLine1"
                                fullWidth
                                label="Company Address Line 1"
                                varient="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.addressLine1}
                                error={Boolean(
                                    formik.errors?.companyAddress?.addressLine1
                                )}
                                helperText={
                                    formik.errors?.companyAddress?.addressLine1
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                                            disabled={!isBrandNotVerified}


                                className="mt-2"
                                name="companyAddress.addressLine2"
                                fullWidth
                                label="Company Address Line 2"
                                varient="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.addressLine2}
                                error={Boolean(
                                    formik.errors?.companyAddress?.addressLine2
                                )}
                                helperText={
                                    formik.errors?.companyAddress?.addressLine2
                                }

                            />
                        </FormControl>
                    </Box> */}
                </Box>

            </Box>
        </Grid>
    )
}
