import styled from '@emotion/styled'
import React, { useState } from 'react'

import { Alert as MuiAlert, TextField as MuiTextField } from '@mui/material'
import { spacing } from '@mui/system'

import { useEffect } from 'react'
import FetchingSpinner from '../../pages/components/FetchingSpinner'
import { apiGatewayAxiosInstance } from '../../utils/axios'
import InvalidEmailToken from './InvalidEmailToken'
import ValidEmailToken from './ValidEmailToken'
// import { cli } from 'webpack'

const Alert = styled(MuiAlert)(spacing)

const TextField = styled(MuiTextField)(spacing)

function SetPassword({ param }) {
    let token = ''
    let id = ''
    // const { setClientPassword } = useAuth()
    const [validToken, setValidToken] = useState(false)

    const [orgUserId, setOrgUserId] = useState('')
    const [errorResponse, setErrorResponse] = useState('')
    const [clientName, setClientName] = useState('')
    const [resetDisable, setResetDisable] = useState(false)
    const [isTokenValid, setIsTokenValid] = useState(false)
    const [clientData, setClientData] = useState({})
    const [pageLoading, setPageLoading] = useState(false)
    // const param = useParams();

    useEffect(() => {
        if (param && param.token) {
            sessionStorage.setItem("accessToken", param.token);
        }
    }, [param]);


    const checkEmailTokenValidity = async () => {
        try {
            setPageLoading(true)
            const response = await apiGatewayAxiosInstance.get(
                `user_management/clients/validate-email-link-token`,
                { params: { token, id } }
            )


            if (response.status === 200) {

                setPageLoading(false)
                setIsTokenValid(response.data?.data?.isTokenValid)
                setClientData(response.data?.data?.clientData)
                if (
                    response.data.data.clientData &&
                    response.data.data.isTokenValid
                )
                    setValidToken(true)
                setOrgUserId(response.data.data?.clientData?._id)
                setClientName(response.data.data.clientName)
            }
        } catch (error) {
            setPageLoading(false)
            let errorMessage = "Failed to schedule campaign.";

            if (error.response) {
                if (error.response?.data?.message === 'Invalid id') {
                    setResetDisable(true)
                }
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);

        }
    }

    useEffect(() => {
        token = param.token
        id = param.id
        checkEmailTokenValidity()
    }, [])
    return (
        <>
            {pageLoading ? (
                <FetchingSpinner />
            ) : (
                <div>


                    {
                        validToken ? (
                            <ValidEmailToken
                                orgUserId={orgUserId}
                                clientName={clientName}
                                clientData={clientData}

                            />
                        ) : (
                            <InvalidEmailToken
                                id={param.id}
                                resetDisable={resetDisable}
                                isTokenValid={isTokenValid}
                                clientData={clientData}
                            />
                        )}
                    {/* <AlertMessageComponent
                message={errorResponse}
                fieldName={'getAllTemplatesByPageSizeErrorMessage'}
                handleClose={() => setErrorResponse('')}
                show={Boolean(errorResponse)}
            /> */}
                </div>
            )}
        </>
    )
}

export default SetPassword
