import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

const VariablesModal = ({ open, handleClose, variables = [] , handleVariablesSubmit}) => {
  const [variableValues, setVariableValues] = useState({});

  useEffect(() => {
    if (variables.length > 0) {
      const initialValues = {};
      variables.forEach((variable) => {
        initialValues[variable] = '';
      });
      setVariableValues(initialValues);
    }
  }, [variables]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVariableValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setVariableValues({...variableValues , [name]:value})
  };

  const handleSubmit = () => {
    handleVariablesSubmit(variableValues);
  };

  if (!open) return null;


  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="variables-modal-title"
      aria-describedby="variables-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}
      >
        <Typography id="variables-modal-title" variant="h6" component="h2" gutterBottom>
          Enter Variable Values
        </Typography>
        {variables && variables.length > 0 ? (
          variables.map((variable, index) => (
            <TextField
              key={index}
              fullWidth
              name={variable}
              label={variable}
              value={variableValues[variable] || ''}
              onChange={handleChange}
              margin="normal"
            />
          ))
        ) : (
          <Typography>No variables to display</Typography>
        )}
        <Box mt={2}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default VariablesModal;
