import { Button, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

import { DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DialogContentText from "@mui/material/DialogContentText";
import { useState } from "react";
import FetchingSpinner from "../components/FetchingSpinner";
import { useSelector } from "react-redux";

//import { DatePicker, DateTimePicker } from "@mui/lab";

export default function ScheduleCampaignModel({
    open,
    setOpen,
    handleClose,
    handleScheduleCampaign,
    campaign,
    scheduleCampaign,
    isGetAllocatedAmountLoading,
}) {
    const currentDateTime = new Date();
    const [selectedDate, setSelectedDate] = useState(currentDateTime);
    const [timeDifferenceInSeconds, setTimeDifferenceInSeconds] = useState(0);
    const [loading, setLoading] = useState(false);
    const [scheduleCampaignTime, setScheduleCampaignTime] = useState(0);
    const [isValidDateTime, setIsValidDateTime] = useState(true);

    const handleDateChange = date => {
        setSelectedDate(date);
        calculateTimeDifferenceInSeconds(date);
    };

    const calculateTimeDifferenceInSeconds = date => {
        const currentDate = new Date();
        if (date instanceof Date && !isNaN(date.getTime())) {
            const selectedDateTime = new Date(date);
            setScheduleCampaignTime(selectedDateTime);
            const timeDifference = selectedDateTime.getTime() - currentDate.getTime();
            const timeDifferenceInSeconds = Math.floor(timeDifference / 1000);
            setTimeDifferenceInSeconds(timeDifferenceInSeconds);
            setIsValidDateTime(true);
        } else {
            setIsValidDateTime(false);
            // if (!date) { // Handle the case when date is empty
            //    // setScheduleCampaignTime(null);
            //     setSelectedDate(null);
            // }
        }
    };

    const state = useSelector(slice => slice.userManagementSlice);

    const runScheduleCampaign = async () => {
        setLoading(true);
        const response = await scheduleCampaign({ campaign, timeDifferenceInSeconds, scheduleCampaignTime });

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                // sx={{ minWidth: "100px !important" }}
                sx={{ position: "absolute", top: "150px" }}
            >
                <DialogTitle id='alert-dialog-title'>Schedule Campaign</DialogTitle>
                <DialogContent>
                    {isGetAllocatedAmountLoading ? (
                        <Grid padding={15}>
                            <FetchingSpinner />
                        </Grid>
                    ) : (
                        <>
                            <>
                                <Grid>
                                    <Typography className='margin'>
                                        Total RCS enabled numbers to process : &nbsp; {campaign.totalRcsEnabledNumbers}
                                    </Typography>
                                    <Typography className='margin'>
                                        Total RCS messages that can be sent in available balance : &nbsp; {campaign.messageCountAbleToSend}
                                    </Typography>
                                    <Typography className='margin' variant='body1'>
                                        Estimated balance to run campaign : &nbsp; Rs.{campaign.allocatedAmount?.toFixed(2)}/-
                                    </Typography>

                                    {state.userBillingType === "Postpaid" ? (
                                        <Typography className='margin' variant='body1'>
                                            Total usage will be : &nbsp; Rs.{campaign?.remainingCurrentBalanceAfterDeduction.toFixed(2)}/-
                                        </Typography>
                                    ) : (
                                        <Typography className='margin' variant='body1'>
                                            Remaining Balance : &nbsp; Rs.{campaign?.remainingCurrentBalanceAfterDeduction.toFixed(2)}/-
                                        </Typography>
                                    )}
                                </Grid>
                                <hr />

                                {campaign?.messageCountAbleToSend < campaign?.totalRcsEnabledNumbers && state.userBillingType === "Prepaid" && (
                                    <Grid>
                                        <Typography className='margin' variant='h6' color={"red"}>
                                            Your current balance is low, all messages will not be processed.
                                        </Typography>
                                    </Grid>
                                )}

                                {Number(campaign?.totalRcsEnabledNumbers) === 0 &&
                                    <Grid>
                                        <Typography variant='h6' color={"red"} m={1}>
                                            In this campaign, there are no RCS-enabled numbers.
                                        </Typography>
                                    </Grid>
                                }
                                {campaign?.messageCountAbleToSend < campaign?.totalRcsEnabledNumbers &&
                                    state.postpaidUserCapcheck &&
                                    state.userBillingType === "Postpaid" && (
                                        <Grid>
                                            <Typography className='margin' variant='h6' color={"red"}>
                                                You're exceeding max balance limit, all messages will not be processed.
                                            </Typography>
                                        </Grid>
                                    )}

                                {campaign.messageCountAbleToSend != 0 && (
                                    <DialogContentText id='alert-dialog-description'>Sure do you want to run the campaign ?</DialogContentText>
                                )}
                            </>

                            {/* <DialogContentText id='alert-dialog-description'>Please select the date and time</DialogContentText> */}
                            <br></br>
                        </>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label='Select Date and Time'
                            value={selectedDate}
                            onChange={handleDateChange}
                            minDate={new Date()}
                            maxDate={new Date(new Date().setMonth(new Date().getMonth() + 6))}
                            minTime={
                                selectedDate && selectedDate.getDate() === currentDateTime.getDate()
                                    ? new Date(
                                        currentDateTime.getFullYear(),
                                        currentDateTime.getMonth(),
                                        currentDateTime.getDate(),
                                        currentDateTime.getHours(),
                                        currentDateTime.getMinutes(),
                                    )
                                    : null
                            }
                            renderInput={params => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    {/* <LocalizationProvider>
                        <DatePicker
                            label="Select Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            slotProps={{ textField: { variant: "standard", size: "small", fullWidth: true } }} />
                    </LocalizationProvider> */}
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => runScheduleCampaign()}
                        aria-label='sent'
                        color='primary'
                        variant='contained'
                        title='Schedule Campaign'
                        disabled={!isValidDateTime || loading || campaign?.messageCountAbleToSend === 0}
                    >
                        &nbsp;Schedule
                    </Button>

                    <Button onClick={handleClose} variant='outlined' color='error'>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
