import { configureStore } from "@reduxjs/toolkit";
import { userManagementSlice } from "./userManagement/userManagementSlice";
import botManagementSlice from "./botManagement/botManagementSlice";
import templateManagementSlice from "./templateManagement/templateManagementSlice";
import brandManagementSlice from "./brandManagement/brandManagementSlice";
import { campaignManagmentSlice } from "./campaignManagment/campaignManagmentSlice";
import { campaignReportsManagementSlice } from "./campaignReportsManagment/campaignReportsManagmentSlice";
import { apiReportsManagementSlice } from "./apiReportManagement/apiReportManagementSlice";

export const store = configureStore({
  reducer: {
    userManagementSlice: userManagementSlice.reducer,
    botManagementSlice: botManagementSlice.reducer,
    templateManagementSlice: templateManagementSlice.reducer,
    brandManagementSlice: brandManagementSlice.reducer,
    campaignManagmentSlice: campaignManagmentSlice.reducer,
    campaignReportsManagementSlice: campaignReportsManagementSlice.reducer,
    apiReportsManagementSlice: apiReportsManagementSlice.reducer
  },
});
