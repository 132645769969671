import React, { useEffect, useState } from 'react'
import { useDebounce } from '../../../hooks/useDebounce';
import { generateUniqueIdForCard, getConditions } from '../../../utils/workflow-utils/helpers';
import closeIcon from '../../../assets/icons/close-icon.svg';
import { TextEditor } from '../TextEditor/TextEditor';
import { validateButtonNode } from '../../../utils/workflow-utils/channelValidations';
import { cssClass } from '../../../utils/workflow-utils/cssClasses';



function SingleConditionNode({ data, index, saveCondition, deleteCondition, variableNamespace }) {

    const updateObject = () => {
        saveCondition(index, conditionState);
    }

    const handleUpdate = useDebounce(updateObject, 80);

    const listOfOperations = getConditions();

    const [conditionState, setConditionState] = useState(data);
    const [errors, setError] = useState({});

    const changeHandler = (event) => {
        const { value, name, id } = event.target;
        setConditionState({
            ...conditionState,
            [name]: value,
        });
    }

    // const submitAddCondition = () => {
    //     //TODO Validation of selected

    //     let errors = {};

    //     if (conditionState && !conditionState.compareValue) {
    //         if (typeof conditionState.compareValue !== 'string' || conditionState.compareValue.trim().length === 0) {
    //             errors['compareValue'] = "Invalid Value"
    //         }
    //     }

    //     if (conditionState && !conditionState.conditionType) {
    //         if (typeof conditionState.conditionType !== 'string' || conditionState.conditionType.trim().length === 0) {
    //             errors['.conditionType'] = "Invalid Value"
    //         }
    //     }

    //     if (Object.keys(errors).length > 0) {
    //         return setError(errors);
    //     }

    //     addCondition(conditionState);
    //     return;
    // }

    const removeCondition = () => {
        deleteCondition(index);
    }

    useEffect(() => {
        handleUpdate();
    }, [conditionState]);

    return (
        <React.Fragment>
            <div className="w-full mt-2">
                <div className="block p-6 rounded-lg shadow-lg bg-white ">
                    {/* <h5 className={`${cssClass['card-title']}`}>Card title</h5> */}

                    <div className={`flex flex-col justify-between ${cssClass['card-body']} `} >
                        <div className="mb-2">
                            <label htmlFor="compareWith">If <small> variable to check </small> </label>
                            <input
                                className={`${cssClass['pin-bot-form-input']} mt-2`}
                                type="text"
                                name="compareWith"
                                id="compareWith"
                                readOnly={true}
                            />
                        </div>

                        <div className="mb-2">
                            <label htmlFor="conditionType">Operation </label>
                            <select
                                value={conditionState.conditionType}
                                onChange={changeHandler}
                                name="conditionType"
                                id="conditionType"
                                className={`${cssClass['pin-bot-form-select']}`}
                            >
                                <option value="select">Select Condition Type</option>
                                {
                                    listOfOperations && listOfOperations.map((item, idx) => {
                                        return (
                                            <option key={`operations-${idx}`} value={item.key}>{item.value}</option>
                                        )
                                    })
                                }

                            </select>
                            {
                                errors && errors.conditionType &&
                                <p> {errors.conditionType} </p>
                            }
                        </div>
                        <div className="mb-2">
                            <label htmlFor="compareValue">Compare To</label>
                            <input
                                className={`${cssClass['pin-bot-form-input']} mt-2`}
                                type="text"
                                name="compareValue"
                                id="compareValue"
                                list='switchNodeCompareToValue'
                                placeholder='Compare to'
                                onChange={changeHandler}
                            />
                            {
                                errors && errors.compareValue &&
                                <p> {errors.compareValue} </p>
                            }
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        <button type="button" className={`${cssClass['btn-primary']}`} onClick={removeCondition}>Remove</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default function SwitchNodePropertyMenu({ node, variableNamespace, updateSwitchNode, deleteButtonFromSwitchNode }) {


    const [errors, setErrors] = useState({});
    const [showConditionCard, setShowConditionCard] = useState(false);

    const [switchNodeState, setSwitchNodeState] = useState(node.data.contents);

    const questionChangeHandler = (value) => {
        setSwitchNodeState({
            ...switchNodeState,
            'question': value,
        });
    }

    const toggleConditionCard = () => {
        setShowConditionCard(true);
    }

    const removeConditionCard = () => {
        setShowConditionCard(false);
    }

    // delete condition card from the menu
    const deleteConditionCard = (id) => {
        let newConditions = switchNodeState.conditions.filter((con, idx) => idx !== id);
        deleteButtonFromSwitchNode(node.id, id);
        updateSwitchNode(node.id, newConditions);
        setSwitchNodeState({
            ...switchNodeState,
            'conditions': newConditions
        });
    }

    const addCondition = (temp) => {
        const newConditions = [...switchNodeState.conditions, { conditionType: '', compareValue: '' }];
        setSwitchNodeState({
            ...switchNodeState,
            'conditions': newConditions
        });
        removeConditionCard();
    }

    const saveCondition = (index, newConditonObject) => {
        // update node object here  
        setSwitchNodeState({
            ...switchNodeState,
            ['conditions']: switchNodeState.conditions.map((con, idx) => {
                if (idx === index) {
                    return newConditonObject;
                } else {
                    return con;
                }
            })
        });
    }

    const deleteButton = (id) => {
        // let newConditions = switchNodeState.conditions.filter((con, idx) => idx !== id);
        // //  delete from property menu
        // deleteButtonFromSwitchNode(node.id, id);

        // // delete from update switch menu
        // updateSwitchNode(node.id, newConditions);
        // setSwitchNodeState({
        //     ...switchNodeState,
        //     'conditions': newConditions
        // });
    }

    useEffect(() => {
        updateSwitchNode(node.id, switchNodeState);
    }, [switchNodeState]);


    useEffect(() => {
        setSwitchNodeState(node.data.contents);
    }, []);


    return (
        <div key={`button_node_${node.id}`}>

            <div className='flex flex-col'>
                <label className='mb-2'> Question To Ask </label>
                {
                    switchNodeState && <TextEditor
                        data={switchNodeState.question ? switchNodeState.question : []}
                        key={`text-editor-node-for-switch${node.id}`}
                        getEditorDataOnChange={questionChangeHandler}
                    />
                }


            </div>

            <div className='flex justify-between mt-2'>
                <div>
                    <h3 className='mb-2'> Conditions </h3>
                </div>
                <div>
                    <button className='bg-yellow-300 rounded w-35 p-1 px-2 text-base border-2 border-gray-400' onClick={addCondition}> Add Condition </button>
                </div>
            </div>

            {
                switchNodeState &&
                Array.isArray(switchNodeState.conditions) && switchNodeState.conditions.map((condition, idx) => {
                    return <SingleConditionNode
                        data={condition}
                        key={`singleNodeCondition-${idx}`}
                        index={idx}
                        variableNamespace={variableNamespace}
                        saveCondition={saveCondition}
                        deleteCondition={deleteConditionCard}
                    />
                })
            }


        </div>
    )
}
