import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBotsThunk } from "../../../redux/botManagement/botManagementThunk";
import { Divider, Grid, Typography } from "@mui/material";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import "../../../App.css";

export default function BotsList({ botList, onSelectBot, selectedBot }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllBotsThunk());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Grid
            sx={{
                overflowX: "hidden",
                overflowY: "scroll",
                height: "100%"
            }}
        >
            <Grid padding={2} display={"flex"} justifyContent={"center"}>
                <Typography variant="h4">Bots</Typography>
            </Grid>
            <hr style={{ margin: "0px", padding: "0px" }} />
            <Grid style={{ height: "55vh", overflowY: "scroll", paddingX: "10px" }}>
                {botList &&
                    botList.map((bot, key) => (
                        <Grid
                            onClick={() => onSelectBot(bot)}
                            className={
                                bot?.config?.rbmClientId === selectedBot?.config?.rbmClientId
                                    ? "selected-bot-item"
                                    : "select-bot-item"
                            }
                            display={"flex"}
                            padding={5}
                            alignItems={"center"}
                            sx={{ cursor: "pointer" }}
                            key={`bot-list-${bot._id}-${key}`}
                        >
                            {bot.botLogoImage.url ?

                                <img src={bot.botLogoImage.url} height={"25px"} width={"25px"} display={'block'} />
                                :
                                <SmartToyOutlinedIcon />

                            }


                            <Typography paddingLeft={2} variant="subtitle1" key={`bot-list-typo-${bot._id}-${key}`}>
                                {bot.botName}
                            </Typography>
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    );
}
