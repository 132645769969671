import { Box, Grid, Typography } from "@mui/material";
import { SuggestionSimulatorComponentForCampaign } from "./suggestionSimulatorComponentForCampaign";

export function TextMessageCampaignSimulator({ botLogoImage, contentMessage, sentBy }) {

    return (
        <>
            <Grid container sx={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}>

                {sentBy === 'user' ?
                    <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: 'end' }}>
                        <Box sx={{ border: "1px solid grey", borderRadius: "20px", padding: 2, paddingInline: 4, overflow: "hidden" }}>
                            <Typography level="body-xs">

                                {contentMessage.text}
                            </Typography>
                        </Box>
                    </Grid>
                    :
                    <Grid item sx={{ display: "flex", alignItems: "center", maxWidth: "100%" }}>

                        <Box sx={{ borderRadius: "50%", overflow: "hidden", marginRight: 2, boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;" }}>

                            <img src={botLogoImage} height={"25px"} width={"25px"} />
                        </Box>
                        <Grid sx={{ maxWidth: "100%" }}>

                            <Box sx={{ border: "1px solid grey", borderRadius: "15px", maxWidth: "300px", padding: 2, paddingInline: 4 }}>
                                <Typography level="body-xs" sx={{ wordBreak: "break-word" }}>

                                    {contentMessage.text}
                                </Typography>
                            </Box>
                            <Grid container sx={{ display: "flex", alignItems: "center", maxWidth: "100px", margin: "5px" }}>

                                {contentMessage.suggestions && contentMessage.suggestions.length > 0 && contentMessage.suggestions.map((suggestion, index) => (
                                    <Grid item md={5} key={index} sx={{minWidth:"100%", border: "1px solid grey", borderRadius: "35px", padding: 1, paddingInline: 2, margin: 1 }}>
                                        <SuggestionSimulatorComponentForCampaign suggestion={suggestion} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                }


            </Grid>

        </>
    )
}