import {
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { ERoles } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import {
    clearErrorMessagesForReports,
    onRejectGetCampaignDetails,
    setCampaignReportDetails,
    setIsCampaignDetailsLoading,
    setShowCampaignReport,
} from "../../../redux/campaignReportsManagment/campaignReportsManagmentSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import FetchingSpinner from "../../components/FetchingSpinner";
import NoRecords from "../../components/NoRecords";
import FilterAllCampaignsReportsModel from "./FilterCampaignReportsModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 24,
        backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 22,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const ListCampaignReports = () => {
    const campaignReportState = useSelector(state => state.campaignReportsManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);
    const userState = useSelector(state => state.userManagementSlice);
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [selectedBot, setSelectedBot] = useState();
    const dispatch = useDispatch();
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { user } = useAuth();
    const [pageSize, setPageSize] = React.useState(10);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [searchParams, setSearchParams] = React.useState({
        botName: "",
        campaignName: "",
        templateName: "",
        startTime: "",
        executedTime: "",
    });

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage + 1);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };
    const handleClickOpenFilterModel = () => {
        setOpenFilter(true);
    };

    const handleCloseFilterModel = () => {
        // setSearchParams({});
        setOpenFilter(false);
    };
    useEffect(async () => {
        try {
            dispatch(setIsCampaignDetailsLoading(true));
            // dispatch(setShowCampaignReport(true));
            const response = await apiGatewayAxiosInstance.get(`/bot_management/logger/get-campaign-reports`, {
                params: {
                    pageSize,
                    currentPage,
                },
            });

            if (response.status === 200) {
                dispatch(setCampaignReportDetails(response.data.campaignListWithReportDetails));
                setTotalCount(response.data.totalCount);

                dispatch(setIsCampaignDetailsLoading(false));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            dispatch(setIsCampaignDetailsLoading(false));
            // dispatch(setShowCampaignReport(false));
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(onRejectGetCampaignDetails(errorMessage));
        }
    }, [currentPage, pageSize]);

    const handleViewCampaignReports = async campaignData => {
        dispatch(setShowCampaignReport({ status: true, campaignData }));
    };

    const handleClickSearch = async () => {
        setCurrentPage(0);
        setPageSize(10);
        try {
            dispatch(setIsCampaignDetailsLoading(true));
            // dispatch(setShowCampaignReport(true));
            const response = await apiGatewayAxiosInstance.get(`/bot_management/logger/get-by-Filter`);
            if (response.status === 200) {
                dispatch(setCampaignReportDetails(response.data.campaignListWithReportDetails));
                dispatch(setIsCampaignDetailsLoading(false));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            dispatch(setIsCampaignDetailsLoading(false));
            // dispatch(setShowCampaignReport(false));
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(onRejectGetCampaignDetails(errorMessage));
        }

        handleCloseFilterModel();
    };
    // const handlePageChange = (event, page) => {
    //     setCurrentPage(page);
    // };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForReports({ fieldName }));
    };

    const onChangeSelectOrganization = e => {
        if (e.target.value !== "") {
            const selectedClientName = e.target.value;
            const organization = userState.clientsList.filter(org => org.clientName === selectedClientName)[0];
            setSelectedOrganization(organization);
            // dispatch(getAllBotsThunk({ orgId: organization._id }));
        }
    };

    const formatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    });

    function convertUTCDateToLocalDate(date, index) {
        var newDate = new Date(date);
        return formatter.format(newDate);
    }

    const calculateTime = value => {
        const date = new Date(value);

        let newTime = date.setHours(date.getHours());
        newTime = date.setMinutes(date.getMinutes());
        const time = new Date(newTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
        return time;
    };
    return (
        <Grid>
            <Grid>
                <Card className='border border-secondary-50 '>
                    <CardContent>
                        <Grid container display={"flex"} justifyContent={"space-between"} className='text-primary align-items-center'>
                            <Typography variant='h4' className='text-primary'>
                                <b>Campaigns Report</b>
                            </Typography>
                            <Grid item>
                                <Button sx={{ marginRight: "10px" }} variant='contained' onClick={handleClickOpenFilterModel}>
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                        <hr />
                        {campaignReportState.controls.isCampaignDetailsLoading ? (
                            <FetchingSpinner />
                        ) : campaignReportState.campaignListWithReportDetails && campaignReportState.campaignListWithReportDetails.length > 0 ? (
                            <TableContainer component={Paper} className='table-container'>
                                <Table aria-label='customized table'>
                                    <TableHead className='p-3 mb-2 bg-secondary text-white row'>
                                        <TableRow>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Sr No
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                Campaign Name
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                Bot Name
                                            </StyledTableCell>
                                            {user.roleName && user.roleName == ERoles.SUPER_ADMIN && (
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    Organization Name
                                                </StyledTableCell>
                                            )}
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                Template Name
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Total Message
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Started At
                                            </StyledTableCell>{" "}
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Executed At
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                Actions
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {campaignReportState.campaignListWithReportDetails?.map((campaign, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <b>{(currentPage - 1) * 10 + index + 1}</b>
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {campaign.campaignName}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {campaign.botName}
                                                </StyledTableCell>

                                                {user?.roleName && user?.roleName == ERoles.SUPER_ADMIN && (
                                                    <StyledTableCell className='tableContentFont' align='left'>
                                                        {campaign.otherDetails.orgDetails.clientName}
                                                    </StyledTableCell>
                                                )}
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {campaign.templateName}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <Typography variant='subtitle2'>{campaign.otherDetails?.reportDetails.length}</Typography>{" "}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {campaign?.startTime ? (
                                                        <>
                                                            {convertUTCDateToLocalDate(campaign.startTime).split(",")[0]} <br />
                                                            {convertUTCDateToLocalDate(campaign.startTime).split(",")[1]}
                                                        </>
                                                    ) : (
                                                        "-"
                                                    )}

                                                    {/* &nbsp; */}
                                                    {/* {calculateTime(
                                                            campaign?.startTime
                                                        )} */}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {campaign?.executedTime ? (
                                                        <>
                                                            {convertUTCDateToLocalDate(campaign.executedTime).split(",")[0]} <br />
                                                            {convertUTCDateToLocalDate(campaign.executedTime).split(",")[1]}
                                                        </>
                                                    ) : (
                                                        "-"
                                                    )}

                                                    {/* &nbsp;
                                                        {calculateTime(
                                                            campaign?.executedTime
                                                        )} */}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <Button
                                                        title='View Campaign Details'
                                                        onClick={() => handleViewCampaignReports(campaign)}
                                                    // variant="contained"
                                                    >
                                                        <VisibilityIcon />
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <NoRecords />
                        )}
                        {campaignReportState.campaignListWithReportDetails && (
                            <div
                                className='my-2'
                                style={{
                                    zIndex: "2",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Grid
                                    style={{
                                        marginTop: "15px",
                                    }}
                                >
                                    Total Campaign Reports : {totalCount}
                                </Grid>
                                <Grid>
                                    <TablePagination
                                        count={totalCount}
                                        component='div'
                                        variant='outlined'
                                        color='primary'
                                        page={currentPage - 1}
                                        // onChange={handlePageChange}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={pageSize}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </div>
                        )}
                    </CardContent>
                    <AlertMessageComponent
                        message={campaignReportState.controls.getCampaignReportDetailsErrorMessage}
                        fieldName={"getCampaignReportDetailsErrorMessage"}
                        handleClose={onCloseErrorMessage}
                        show={Boolean(campaignReportState.controls.getCampaignReportDetailsErrorMessage)}
                    />
                    {openFilter && (
                        <FilterAllCampaignsReportsModel
                            open={openFilter}
                            searchParams={searchParams}
                            handleClickOpenFilterModel={handleClickOpenFilterModel}
                            handleCloseFilterModel={handleCloseFilterModel}
                            setSearchParams={setSearchParams}
                            handleClickSearch={handleClickSearch}
                        />
                    )}
                </Card>
            </Grid>
        </Grid>
    );
};

export default ListCampaignReports;
