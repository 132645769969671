import React, { useEffect } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getAllRolesThunk } from "../../../redux/userManagement/userManagementThunk";

function FilterAllUserModel({
    open,
    searchParams,
    setSearchParams,
    handleClickOpen,
    handleClose,
    handleClickSearch,
}) {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.userManagementSlice);
    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setSearchParams({ ...searchParams, [name]: value });
    };

    const onClickClear = () => {
        setSearchParams({
            name: "",
            email: "",
            mobileNumber: "",
            status: "",
        });
    };
    // useEffect(() => {
    //     dispatch(getAllRolesThunk());
    // }, [])

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5">User Filter</Typography>
                    <IconButton onClick={() => handleClose()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent
                    sx={{
                        overflowY: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                        width: "500px",
                    }}
                >
                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            <TextField
                                value={searchParams.name}
                                name="name"
                                onChange={handleChange}
                                label="User"
                                fullWidth
                                placeholder="Enter User Name"
                            />
                        </Grid>
                        <Grid item md={5.5}>
                            <TextField
                                name="email"
                                value={searchParams.email}
                                onChange={handleChange}
                                label="Email"
                                fullWidth
                                placeholder="Enter Email Id"
                            />
                        </Grid>
                    </Grid>

                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            {" "}
                            <TextField
                                name="mobileNumber"
                                value={searchParams.mobileNumber}
                                onChange={handleChange}
                                label="Mobile"
                                fullWidth
                                placeholder="Enter Mobile Number"
                            />
                        </Grid>
                        <Grid item md={5.5}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-controlled-open-select-label">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    name="status"
                                    value={searchParams.status}
                                    label="Status"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"Active"}>Active</MenuItem>
                                    <MenuItem value={"InActive"}>
                                        InActive
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item md={5.5}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-controlled-open-select-label">
                                    Role
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    name="role"
                                    value={searchParams.role}
                                    label="Role"
                                    onChange={handleChange}
                                >
                                    {state.rolesList.length > 0 &&
                                        <>
                                            <MenuItem value={""} disabled>Select Role</MenuItem>
                                            {state.rolesList.map((role, key) => (
                                                <MenuItem value={role.roleName} key={key}>{role.roleName}</MenuItem>
                                            ))
                                            }
                                        </>
                                    }
                                </Select>
                            </FormControl>
                        </Grid> */}
                    </Grid>
                </DialogContent>

                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => onClickClear()}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClickSearch(searchParams)}
                        autoFocus
                    >
                        Search{" "}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default FilterAllUserModel;
