import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PdfIcon from '../../../vendor/pdf.png';
import { convertFileSizeToKB } from '../../../utils/calculateSize';

export default function UserMediaSimulator({ sentBy, thumbnail, media }) {
    return (
        <>
            <Grid container sx={{ display: "flex", flexDirection: "column" }}>
                {media.mimeType === "application/pdf" &&
                    <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <Box sx={{ border: "1px solid grey", borderRadius: "10px", paddingInline: 2 }}>
                            <Grid container>
                                <Grid item md={1} padding={2} alignItems={'center'} justifyContent={'center'} display={'flex'} >
                                    <img width={"25px"} height={"25px"} style={{ alignItems: "center" }} src={PdfIcon} />
                                </Grid>
                                <Grid item md={11} padding={2}>
                                    <Typography level="body-xs">
                                        {media.fileName}
                                    </Typography>
                                    <Typography variant='caption'>
                                        {convertFileSizeToKB(media.fileSizeBytes)} KB
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                }
                <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: 'end' }}>

                    <div className="play-button-container" style={{ position: "relative" }}>
                        <img src={thumbnail?.fileUri} style={{

                            width: "100px",
                            display: "block",
                            borderRadius: "10px"
                        }
                        } className="image" />

                        <div className="play-button-overlay" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                            {/* You can replace the content inside the overlay div with your own play button logo */}
                            {media.mimeType === "video/mp4" &&
                                <PlayCircleIcon sx={{ fontSize: 40, color: "white" }} />
                            }
                        </div>
                    </div>

                </Grid>



            </Grid >

        </>
    )
}
