import React, { useState } from 'react'
import BotListApiReport from './BotListApiReport'
import ViewBotApiReport from './ViewBotApiReport'

export default function ManageApiReport() {
    const [showBotReport, setShowBotReport] = useState(false);
    const [selectedBot, setSelectedBot] = useState({});






    return (
        <div>

            {showBotReport ?
                <ViewBotApiReport selectedBot={selectedBot} setShowBotReport={setShowBotReport} /> :
                <BotListApiReport setShowBotReport={setShowBotReport} setSelectedBot={setSelectedBot} selectedBot={selectedBot} />
            }
        </div>
    )
}
