const CharCounter = (inputString = "") => {
    let characterCount = 0;
    const variableRegex = /\[([^\]]+)\]/g; // this regex will match [name], [var] etc...
    const regexForSquareBracketsWithoutVariableName = /\[\]/g; // this regex will match only []

    // precautionary check so that even if inputString might come as undefined | null ...
    if (inputString && typeof inputString === "string") {
        // Checking if there are square brackets without variable name i.e '[]' ...
        // if exists then replacing it with blank string ...
        // we are doing it, since it is implemented on VI panel ...
        const squareBracketsWithoutVariableName = String(inputString).match(
            regexForSquareBracketsWithoutVariableName
        );

        if (squareBracketsWithoutVariableName !== null) {
            inputString = inputString.replaceAll(
                regexForSquareBracketsWithoutVariableName,
                ""
            );
        }

        // returns array of variables if pattern matches
        // otherwise returns null...
        const variablesInString = String(inputString).match(variableRegex);

        // If variables are found increasing character count by 10 for each variable...
        if (variablesInString !== null) {
            characterCount = characterCount + variablesInString.length * 10;
        }

        // Replacing variables with blank string to calculate characters length without variables....
        inputString = inputString.replaceAll(variableRegex, "");

        const characterCountWithoutVariables = inputString.length;

        characterCount = characterCount + characterCountWithoutVariables;
    }

    return characterCount;
};

export default CharCounter;
