import React from 'react';

export function TextEditorReadOnly({ contents }) {
    return (
        <>
            {
                contents && Array.isArray(contents) && contents.map((item, idx) => {
                    return (
                        <div key={`text-editor-${idx}_${idx}`}>
                            {
                                item.type === 'paragraph' && item.children && item.children.map((child, idn) => {
                                    return (
                                        <p className='text-sm' key={`text-${idx}_${idn}`}> {child.text} </p>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </>
    )
}
