import { useTheme } from "@emotion/react";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CountryCode from "../../../assets/CountryCode.json";

import { clearErrorMessageForBaseRate, setErrorMessageForUser } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
// import { showSnackbar } from '../store/snackbarSlice';
// import { CountryByCurrencyCodeJson } from '../utils/CountryByCurrencyCodeJson';

// import UploadExcelFileModal from './UploadExcelFileModal';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { fetchCsvAndConvertToJson } from "../../../utils/createJsonfromCsvUrl";
import NoRecords from "../../components/NoRecords";

export default function AddMasterRateModal({ setRateForm, selectProviderName, otherFields, providerDetailsByProviderName, getAllMasterRates }) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            fontWeight: 600,
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
            padding: "10px !important",
            width: "20vw",
        },
        [`&.${tableCellClasses.body}`]: {
            padding: "10px !important",
            fontSize: 14,
        },
        [`&.${tableCellClasses.head.tr}`]: {
            backgroundColor: "#79E0EE",
            width: "45vw",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(even)": {
            // backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const theme = useTheme();
    const dispatch = useDispatch();
    const state = useSelector(state => state.userManagementSlice);
    const [isAddMasterRateLoading, setIsAddMasterRateLoading] = useState(false);
    const [providerDetailsForDownloadCSV, setProviderDetailsForDownloadCSV] = useState();
    // const [providerDetailsByProviderName, setProviderDetailsByProviderName] =
    //     useState()
    const uploadFileRef = useRef(null);
    const [csvFileFormData, setCSVFileFormData] = useState({});
    const [uploadedCSVFields, setUploadedCSVFields] = useState({
        url: "",
        type: "",
        name: "",
    });

    const [csvDataToShow, setCsvDataToShow] = useState([]);
    const [csvVariables, setCsvVariables] = useState([]);
    const [IsUploadCsvLoading, setIsUploadCsvLoading] = useState(false);

    let initialValues = {
        countryName: "",
        countryCode: "",
        shortCode: "",
        textMessageRate: 0,
        multiMediaMessageRate: 0,
        A2pSessionConversationRate: 0,
        feedbackRate: 0,
        otherFields: otherFields ?? {},
    };

    // const validateIfValueNumber = (fieldName) => {
    //     const fieldValue = formik.values[fieldName];
    //     if (fieldValue === "" || fieldValue === null) {
    //         formik.setFieldError(fieldName, `Required`)
    //         return false;
    //     } else {
    //         const numberRegex = /^-?[0-9]\d*(\.\d+)?$/;

    //         if (!fieldValue.match(numberRegex)) {
    //             formik.setFieldError(fieldName, `Only Numbers`)
    //             return false;
    //         } else {
    //             return true;
    //         }
    //     }
    // }
    const validateIfValueIsEmpty = fieldName => {

        const falsyValuesToValidate = [null, undefined, NaN];

        const fieldValue = formik.values[fieldName];
        if (falsyValuesToValidate.includes(fieldValue) || fieldValue === "") {
            formik.setFieldError(fieldName, `Required`);
            return false;
        }
        return true;
    };

    const validateIfValueIsEmptyForOtherFields = fieldName => {
        const fieldValue = formik.values.otherFields[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(`otherFields.${fieldName}`, `Required`);
            return false;
        } else {
            return true;
        }
    };


    const validation = () => {

        let result = true;
        if (!validateIfValueIsEmpty("countryName")) {
            result = false;
        }

        if (!validateIfValueIsEmpty("countryCode")) {
            result = false;
        }

        if (!validateIfValueIsEmpty("shortCode")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("textMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("multiMediaMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("A2pSessionConversationRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("feedbackRate")) {
            result = false;
        }

        for (let index = 0; index < Object.keys(initialValues.otherFields).length; index++) {
            const otherRate = Object.keys(initialValues.otherFields)[index];
            if (!validateIfValueIsEmptyForOtherFields(otherRate)) {
                result = false;
            }

        }
        return result;
    };

    const onChangeSelectCountry = ({ name, value }) => {
        const foundCountry = CountryCode.find(country => country.name === value);
        formik.setFieldValue(name, value);

        formik.setFieldValue(`countryCode`, foundCountry.dial_code);

        formik.setFieldValue(`shortCode`, foundCountry.code);
    };

    const addMasterRateForCountry = async ({ data }) => {
        try {
            setIsAddMasterRateLoading(true);
            const response = await apiGatewayAxiosInstance.post(`/user_management/rate-card/create`, data);

            if (response.status === 200) {
                setIsAddMasterRateLoading(false);
                getAllMasterRates({ providerName: selectProviderName.name });
                onClickCancelButton();
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Failed to create master rate.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setIsAddMasterRateLoading(false);
            dispatch(
                setErrorMessageForUser({
                    fieldName: "addMasterRateForCountryErrorMessage",
                    errorMessage,
                }),
            );
        }
    };

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                if (validation()) {
                    const dataToSend = {
                        countryName: values.countryName,
                        countryCode: values.countryCode,
                        shortCode: values.shortCode,
                        providerName: selectProviderName.name,
                        currencyType: "INR",
                        baseRate: {
                            A2pMessageRate: {
                                textMessageRate: values.textMessageRate,
                                multiMediaMessageRate: values.multiMediaMessageRate,
                                A2pSessionConversationRate: values.A2pSessionConversationRate,
                                feedbackRate: values.feedbackRate,
                            },
                            otherFields: values.otherFields,
                        },
                    };

                    addMasterRateForCountry({ data: dataToSend });
                }
            } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
            }
        },
    });

    const onClickCancelButton = () => {
        setRateForm(false);
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessageForBaseRate({ fieldName }));
    };

    const onClickDownloadSampleCSV = async () => {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/config/get-provider-fields-details-download-csv`, {
            params: { providerName: selectProviderName.name },
        });

        if (response.status === 200) {
            setProviderDetailsForDownloadCSV(response.data.data);
        }

        const csvBlob = new Blob([response.data.data], {
            type: "text/csv",
        });
        const csvUrl = URL.createObjectURL(csvBlob);

        const link = document.createElement("a");
        link.href = csvUrl;
        link.setAttribute("download", `SampleMasterRateCSV${selectProviderName.name}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const previewFile = async (targetFile, url, name) => {
        const reader = new FileReader();
        const isFirefox = typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().includes('firefox');

        if (targetFile) {
            reader.readAsDataURL(targetFile);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = async readerEvent => {

            if (isFirefox && targetFile.type.includes('application/vnd.ms-excel')) {

                targetFile = new File(
                    [targetFile],
                    targetFile.name,
                    {
                        type: 'text/csv',
                    }
                );
            }

            if (targetFile.type.includes("csv")) {
                setUploadedCSVFields({
                    url: readerEvent.target.result,
                    type: targetFile.type,
                    name: targetFile.name,
                });

                let mimeType = targetFile.type;
                const supportedMimeTypeArray = ["text/csv"];
                if (supportedMimeTypeArray.includes(mimeType)) {
                    //   setting both the images in imageFormData state on fn call

                    const fileUrl = URL.createObjectURL(targetFile);
                    const response = await fetch(fileUrl);
                    const text = await response.text();
                    const lines = text.split("\n");
                    let _data = lines.map(line => line.replace("\r", "").split(","))[0];
                    setCsvVariables(_data);
                    let dataInUploadedCsv = await fetchCsvAndConvertToJson(text);

                    setCsvDataToShow(dataInUploadedCsv);
                    setCSVFileFormData({
                        ...csvFileFormData,
                        ["file"]: targetFile,
                    });
                }
            }

        };
    };

    const onClickDeleteCSVFile = () => {
        setUploadedCSVFields({});
    };

    const handleUploadOnClick = async () => {
        try {
            setIsUploadCsvLoading(true);
            const dataToSend = {
                providerName: selectProviderName.name,
                providerOtherFields: selectProviderName.otherFields,
            };
            const formData = new FormData();
            formData.append("MasterRateCSV", csvFileFormData.file);
            formData.append("data", JSON.stringify(dataToSend));

            const response = await apiGatewayAxiosInstance.post(`/user_management/rate-card/upload-master-rate-csv`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                // setProviderDetailsForDownloadCSV(response.data.data);
                setIsUploadCsvLoading(false);
                getAllMasterRates({ providerName: selectProviderName.name });
                setRateForm(false);
            } else {
                throw new Error("Failed to update");
            }
        } catch (error) {
            setIsUploadCsvLoading(false);
            let errorMessage = "Failed to upload csv.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "updateMasterRateErrorMessage",
                    errorMessage,
                }),
            );
        }
    };

    useEffect(() => { }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box width={"100%"}>
                <div>
                    <Card className='border border-secondary-50' sx={{ maxWidth: "100%" }}>
                        <CardContent width={"100%"}>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Grid item md={4} display={"flex"}>
                                    <Typography variant='h4' className='text-primary'>
                                        <b>Add Country Rate</b>
                                    </Typography>
                                </Grid>
                                <Grid item md={8} display={"flex"} alignItems={"center"} justifyContent={"end"} gap={3}>
                                    <Grid
                                        display={"flex"}
                                    // textAlign={'end'}
                                    // alignItems={'center'}
                                    >
                                        <b>Selected Provider :</b>
                                        <Typography variant='h5'>
                                            &nbsp;
                                            {selectProviderName?.name}
                                        </Typography>
                                    </Grid>

                                    <Grid>
                                        {!uploadedCSVFields?.url ? (
                                            <>
                                                <Grid display={"flex"} gap={3}>
                                                    <Grid>
                                                        <Button variant='outlined' onClick={onClickDownloadSampleCSV}>
                                                            Download Sample CSV
                                                        </Button>
                                                    </Grid>
                                                    <Grid>
                                                        <Button
                                                            variant='outlined'
                                                            // disabled={
                                                            //     campaignState.controls
                                                            //         .isCreatingCampaignLoading
                                                            // }
                                                            onClick={() => uploadFileRef.current.click()}
                                                        >
                                                            Upload CSV
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid display={"flex"} justifyContent={"end"} justifyItems={"flex-end"}>
                                                <Grid
                                                    container
                                                    sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            backgroundColor: "#e4eff7",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <InsertDriveFileOutlinedIcon
                                                            sx={{
                                                                margin: "5px",
                                                            }}
                                                        />
                                                        <Typography
                                                            variant='button'
                                                            sx={{
                                                                margin: "5px",
                                                            }}
                                                        >
                                                            {uploadedCSVFields.name}
                                                        </Typography>
                                                        <a href={uploadedCSVFields.url}>
                                                            <FileDownloadOutlinedIcon
                                                                color='primary'
                                                                sx={{
                                                                    margin: "5px",
                                                                }}
                                                            />
                                                        </a>
                                                        <DeleteOutlinedIcon
                                                            onClick={onClickDeleteCSVFile}
                                                            color='error'
                                                            sx={{
                                                                margin: "5px",
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Box className='btn-container'>
                                            <input
                                                name='uploadedCSV'
                                                ref={uploadFileRef}
                                                accept='.csv'
                                                onChange={e =>
                                                    previewFile(
                                                        e.target.files[0],
                                                        uploadedCSVFields.url,
                                                        // uploadedCSVFields.name
                                                    )
                                                }
                                                onClick={e => (e.target.value = "")}
                                                type='file'
                                                hidden
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {uploadedCSVFields?.url ? (
                                <>
                                    {/* <Grid
                                        display={'flex'}
                                        justifyContent={'end'}
                                        justifyItems={'flex-end'}
                                    >
                                        <Grid
                                            container
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'end',
                                                marginTop: '15px'
                                            }}
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    backgroundColor: '#e4eff7',
                                                    padding: '10px',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <InsertDriveFileOutlinedIcon
                                                    sx={{ margin: '5px' }}
                                                />
                                                <Typography
                                                    variant='button'
                                                    sx={{ margin: '5px' }}
                                                >
                                                    {uploadedCSVFields.name}
                                                </Typography>
                                                <a href={uploadedCSVFields.url}>
                                                    <FileDownloadOutlinedIcon
                                                        color='primary'
                                                        sx={{ margin: '5px' }}
                                                    />
                                                </a>
                                                <DeleteOutlinedIcon
                                                    onClick={
                                                        onClickDeleteCSVFile
                                                    }
                                                    color='error'
                                                    sx={{ margin: '5px' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                    <Grid
                                        mt={2}
                                        sx={{
                                            display: "flex ",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            maxWidth: "100%",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {csvDataToShow.length > 0 ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    margin: "10px",
                                                    minWidth: "70vw",
                                                    maxWidth: "70rem",
                                                    overflowX: "scroll",
                                                }}
                                            >
                                                <TableContainer
                                                    component={Paper}
                                                    className='table-container'
                                                    sx={{
                                                        height: "420px",
                                                        overflowY: "scroll",
                                                        overflowX: "scroll !important",
                                                        maxWidth: "100% !important",
                                                    }}
                                                >
                                                    <Table
                                                        className='center'
                                                        aria-label='table with sticky header'
                                                        stickyHeader
                                                        sx={{
                                                            objectFit: "contain",
                                                        }}
                                                    >
                                                        <TableHead
                                                            className='p-3 mb-2 row'
                                                            sx={{
                                                                maxWidth: "100% !important",
                                                            }}
                                                        >
                                                            <TableRow>
                                                                {csvVariables.map((head, index) => (
                                                                    <StyledTableCell className='col-1 tableHeaderFont'>
                                                                        <Typography
                                                                            style={{
                                                                                wordWrap: "break-word",
                                                                            }}
                                                                        >
                                                                            {head.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })} {head.includes("Rate") && "(INR)"}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            {csvDataToShow.map((individualCSVData, index) => (
                                                                <StyledTableRow key={index}>
                                                                    {csvVariables.map((head, index) => (
                                                                        <StyledTableCell align='center'>{individualCSVData[head]}</StyledTableCell>
                                                                    ))}
                                                                </StyledTableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        ) : (
                                            <NoRecords />
                                        )}
                                    </Grid>
                                    <Grid ml={3} mt={1}>
                                        {IsUploadCsvLoading ? (
                                            <Button variant='outlined'>
                                                <CircularProgress size={15} className='spinner' />
                                                Uploading..
                                            </Button>
                                        ) : (
                                            <Button variant='contained' onClick={handleUploadOnClick}>
                                                Upload
                                            </Button>
                                        )}
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid container mt={5}>
                                        <Grid item md={6}>
                                            <FormControl
                                                fullWidth
                                                size='small'
                                                error={Boolean(formik.touched.countryName && formik.errors.countryName)}
                                            >
                                                <InputLabel id='country'>Country</InputLabel>
                                                <Select
                                                    name='countryName'
                                                    label='Country'
                                                    labelId='country'
                                                    id='country'
                                                    size='small'
                                                    error={Boolean(formik.touched.countryName && formik.errors.countryName)}
                                                    helperText={formik.touched.countryName && formik.errors.countryName}
                                                    onBlur={formik.handleBlur}
                                                    onChange={e =>
                                                        onChangeSelectCountry({
                                                            value: e.target.value,
                                                            name: e.target.name,
                                                        })
                                                    }
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                            },
                                                        },
                                                    }}
                                                // disabled={
                                                //   !isBotEditable
                                                // }
                                                >
                                                    {CountryCode?.map((country, index) => (
                                                        <MenuItem key={index} value={country.name}>
                                                            {country.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                            <b>Country Code :</b>
                                            &nbsp; &nbsp;
                                            {formik.values.countryCode}
                                        </Grid>
                                        <Grid item md={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                            <b>Short Code:</b>
                                            &nbsp; &nbsp;
                                            {formik.values.shortCode}
                                        </Grid>
                                    </Grid>

                                    {/*
                            <Grid mt={3}>
                                <TextField
                                    fullWidth
                                    className='mb-2'
                                    size='small'

                                    name="countryCode"
                                    label={"Code"}
                                    error={Boolean(
                                        formik.touched.countryCode && formik.errors.countryCode
                                    )}
                                    helperText={
                                        formik.touched.countryCode && formik.errors.countryCode
                                    }
                                    value={formik.values.countryCode}
                                    onBlur={formik.handleBlur}
                                    disabled={true}
                                />
                            </Grid>


                            <Grid mt={3}>
                                <TextField
                                    className='mb-2'
                                    fullWidth
                                    size='small'
                                    label='Enter Short Code'
                                    name={'shortCode'}
                                    // onChange={handleChangeRateCardDetails}
                                    value={formik.values.shortCode}
                                    error={Boolean(
                                        formik.touched.shortCode && formik.errors.shortCode
                                    )}
                                    helperText={
                                        formik.touched.shortCode && formik.errors.shortCode
                                    }
                                    onBlur={formik.handleBlur}
                                    disabled={true}
                                />
                            </Grid> */}

                                    <Grid mt={3}>
                                        <TextField
                                            className='mb-2'
                                            fullWidth
                                            size='small'
                                            label='Enter Text Message Rate(INR)'
                                            name='textMessageRate'
                                            error={Boolean(formik.touched.textMessageRate && formik.errors.textMessageRate)}
                                            helperText={formik.touched.textMessageRate && formik.errors.textMessageRate}
                                            value={formik.values.textMessageRate}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            type='number'
                                            inputProps={{
                                                step: "any",
                                            }}
                                            onWheel={e => e.target.blur()}
                                        // onChange={handleChangeRateCardDetails}
                                        />
                                    </Grid>

                                    <Grid mt={3}>
                                        <TextField
                                            className='mb-2'
                                            fullWidth
                                            size='small'
                                            label='Enter Multi Media Message Rate(INR)'
                                            name='multiMediaMessageRate'
                                            error={Boolean(formik.touched.multiMediaMessageRate && formik.errors.multiMediaMessageRate)}
                                            helperText={formik.touched.multiMediaMessageRate && formik.errors.multiMediaMessageRate}
                                            value={formik.values.multiMediaMessageRate}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            type='number'
                                            inputProps={{
                                                step: "any",
                                            }}
                                            onWheel={e => e.target.blur()}
                                        // onChange={handleChangeRateCardDetails}
                                        />
                                    </Grid>
                                    <Grid mt={3}>
                                        <TextField
                                            className='mb-2'
                                            fullWidth
                                            size='small'
                                            label='A2P Session Conversation Rate(INR)'
                                            name='A2pSessionConversationRate'
                                            error={Boolean(formik.touched.A2pSessionConversationRate && formik.errors.A2pSessionConversationRate)}
                                            helperText={formik.touched.A2pSessionConversationRate && formik.errors.A2pSessionConversationRate}
                                            value={formik.values.A2pSessionConversationRate}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            type='number'
                                            inputProps={{
                                                step: "any",
                                            }}
                                            onWheel={e => e.target.blur()}
                                        // onChange={handleChangeRateCardDetails}
                                        />
                                    </Grid>
                                    <Grid mt={3}>
                                        <TextField
                                            className='mb-2'
                                            fullWidth
                                            size='small'
                                            type='number'
                                            label='Feedback Rate(INR)'
                                            name='feedbackRate'
                                            error={Boolean(formik.touched.feedbackRate && formik.errors.feedbackRate)}
                                            helperText={formik.touched.feedbackRate && formik.errors.feedbackRate}
                                            value={formik.values.feedbackRate}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        // onChange={handleChangeRateCardDetails}
                                        />
                                    </Grid>

                                    {Object.keys(providerDetailsByProviderName?.otherFields).length > 0 &&
                                        Object.keys(providerDetailsByProviderName?.otherFields).map((fieldElement, index) => (
                                            <Grid mt={3} key={index}>
                                                <TextField
                                                    className='mb-2'
                                                    fullWidth
                                                    size='small'
                                                    type='number'
                                                    label={fieldElement}
                                                    name={`otherFields.${fieldElement}`}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.otherFields[fieldElement]}
                                                    error={Boolean(formik.touched?.otherFields && formik.touched?.otherFields[fieldElement] && formik.errors?.otherFields && formik.errors?.otherFields[fieldElement])}
                                                    helperText={formik.touched?.otherFields && formik.touched?.otherFields[fieldElement] && formik.errors?.otherFields && formik.errors?.otherFields[fieldElement]}

                                                // onChange={handleChangeRateCardDetails}
                                                />
                                            </Grid>
                                        ))}

                                    <Grid container mt={2}>
                                        {/* {isBotEditable && ( */}
                                        <Grid padding={1}>
                                            {isAddMasterRateLoading ? (
                                                <Button variant='outlined'>
                                                    <CircularProgress size={15} className='spinner' />
                                                    Adding..
                                                </Button>
                                            ) : (
                                                <Button type='submit' variant='contained'>
                                                    Add
                                                </Button>
                                            )}
                                        </Grid>
                                        {/* )} */}

                                        <Grid padding={1}>
                                            <Button variant='contained' color='error' onClick={() => onClickCancelButton()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                </div>
            </Box>

            {state.controls.addMasterRateForCountryErrorMessage && (
                <AlertMessageComponent
                    message={state.controls.addMasterRateForCountryErrorMessage}
                    fieldName={"addMasterRateForCountryErrorMessage"}
                    handleClose={onCloseErrorMessage}
                    show={Boolean(state.controls.addMasterRateForCountryErrorMessage)}
                />
            )}

            {state.controls.updateMasterRateErrorMessage && (
                <AlertMessageComponent
                    message={state.controls.updateMasterRateErrorMessage}
                    fieldName={"updateMasterRateErrorMessage"}
                    handleClose={onCloseErrorMessage}
                    show={Boolean(state.controls.updateMasterRateErrorMessage)}
                />
            )}
        </form>
    );
}
