import { createSlice } from "@reduxjs/toolkit";
import { setShowAddBotForm } from "../botManagement/botManagementSlice";
import {
    addNewBrandThunk,
    getAllBrandByFilterThunk,
    getAllBrands,
    getAllBrandsByPageSize,
} from "./brandManagementThunk";

export const brandManagementSlice = createSlice({
    name: "brandMangementSlice",
    initialState: {
        brandList: [],
        brandListByPageSize: [],
        listOfBrandBySuperAdmin: [],

        totalBrandsCount: 0,

        controls: {
            showAddBrandForm: false,
            isCreateBrandLoading: false,
            isGetAllBrandsLoading: false,

            addBrandErrorMessage: "",
            listAllBrandsErrorMessage: "",
            listAllBrandsByPageSizeErrorMessage: "",
        },
    },
    reducers: {
        setShowAddBrandForm: (state, action) => {
            state.controls.showAddBrandForm = action.payload;
            state.controls.addBrandErrorMessage = "";
            state.controls.listAllBrandsByPageSizeErrorMessage = "";
        },
        clearErrorMessagesOfBrands: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = "";
        },
    },
    extraReducers: (builder) => {
        //---------------------------------create brand-----------------------------------------------------------
        builder.addCase(addNewBrandThunk.pending, (state, action) => {
            state.controls.isCreateBrandLoading = true;
            state.controls.addBrandErrorMessage = "";
        });
        builder.addCase(addNewBrandThunk.fulfilled, (state, action) => {
            state.controls.addBrandErrorMessage = "";
            state.controls.isCreateBrandLoading = false;
            state.controls.showAddBrandForm = false;
        });
        builder.addCase(addNewBrandThunk.rejected, (state, action) => {
            state.controls.isCreateBrandLoading = false;
            state.controls.addBrandErrorMessage = action.error.message;
        });

        //---------------------------------get all brands---------------------------------------------------------
        builder.addCase(getAllBrands.pending, (state, action) => {});
        builder.addCase(getAllBrands.fulfilled, (state, action) => {
            state.brandList = action.payload.data;
        });
        builder.addCase(getAllBrands.rejected, (state, action) => {
            state.controls.listAllBrandsErrorMessage = action.error.message;
        });
        //---------------------------------get all brands by pagesize---------------------------------------------
        builder.addCase(getAllBrandsByPageSize.pending, (state, action) => {
            state.controls.listAllBrandsByPageSizeErrorMessage = "";
            state.controls.isGetAllBrandsLoading = true;
        });
        builder.addCase(getAllBrandsByPageSize.fulfilled, (state, action) => {
            state.controls.listAllBrandsByPageSizeErrorMessage = "";
            state.controls.isGetAllBrandsLoading = false;
            state.brandListByPageSize = action.payload.allBrandsData;
            state.listOfBrandBySuperAdmin =
                action.payload.listOfBrandBySuperAdmin;
            state.totalBrandsCount = action.payload.totalCount;
        });
        builder.addCase(getAllBrandsByPageSize.rejected, (state, action) => {
            state.controls.isGetAllBrandsLoading = false;
            state.controls.listAllBrandsByPageSizeErrorMessage =
                action.error.message;
            state.brandListByPageSize = [];
            state.totalBrandsCount = 0;
        });
        //---------------------------------get all brands by Filter---------------------------------------------

        builder.addCase(getAllBrandByFilterThunk.pending, (state, action) => {
            state.controls.listAllBrandsByPageSizeErrorMessage = "";
            state.controls.isGetAllBrandsLoading = true;
        });
        builder.addCase(getAllBrandByFilterThunk.fulfilled, (state, action) => {
            state.controls.listAllBrandsByPageSizeErrorMessage = "";
            state.controls.isGetAllBrandsLoading = false;
            state.brandListByPageSize = action.payload.allBrandsData;
            state.listOfBrandBySuperAdmin =
                action.payload.listOfBrandBySuperAdmin;
            state.totalBrandsCount = action.payload.totalCount;
        });
        builder.addCase(getAllBrandByFilterThunk.rejected, (state, action) => {
            state.controls.isGetAllBrandsLoading = false;
            state.controls.listAllBrandsByPageSizeErrorMessage =
                action.error.message;
            state.brandListByPageSize = [];
            state.totalBrandsCount = 0;
        });
    },
});

export const { setShowAddBrandForm, clearErrorMessagesOfBrands } =
    brandManagementSlice.actions;

export default brandManagementSlice;
