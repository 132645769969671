import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { green } from "@mui/material/colors";
import { spacing } from "@mui/system";

import { Chip, Grid, ListItemButton, Box as MuiBox, Drawer as MuiDrawer, Typography } from "@mui/material";

import { ReactComponent as PinnacleLogo } from "../../vendor/pinnacleIcon.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { REACT_APP_WHITELABEL_LOGO_URL, REACT_APP_WHITELABEL_NAME } from "../../utils/axios";
import useAuth from "../../hooks/useAuth";

let logo = REACT_APP_WHITELABEL_LOGO_URL ? REACT_APP_WHITELABEL_LOGO_URL : PinnacleLogo;

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
    border-right: 0;

    > div {
        border-right: 0;
    }
`;

const Brand = styled(ListItemButton)`
    font-size: ${props => props.theme.typography.h5.fontSize};
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    color: ${props => props.theme.sidebar.header.color};
    background-color: ${props => props.theme.sidebar.header.background};
    font-family: ${props => props.theme.typography.fontFamily};
    min-height: 56px;
    padding-right: ${props => props.theme.spacing(6)};
    justify-content: center;
    cursor: pointer;
    flex-grow: 0;

    ${props => props.theme.breakpoints.up("sm")} {
        min-height: 64px;
    }

    &:hover {
        background-color: ${props => props.theme.sidebar.header.background};
    }
`;

const BrandIcon = styled(logo)`
    margin-right: ${props => props.theme.spacing(2)};
    color: ${props => props.theme.sidebar.header.brand.color};
    fill: ${props => props.theme.sidebar.header.brand.color};
    width: 32px;
    height: 32px;
`;

const BrandChip = styled(Chip)`
    background-color: ${green[700]};
    border-radius: 5px;
    color: ${props => props.theme.palette.common.white};
    font-size: 55%;
    height: 18px;
    margin-left: 2px;
    margin-top: -16px;
    padding: 3px 0;

    span {
        padding-left: ${props => props.theme.spacing(1.375)};
        padding-right: ${props => props.theme.spacing(1.375)};
    }
`;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
    const [favicon, setFavicon] = useState('');
    const { user } = useAuth();
    useEffect(() => {
        if (REACT_APP_WHITELABEL_LOGO_URL) {
            setFavicon(REACT_APP_WHITELABEL_LOGO_URL);
        } else {
            setFavicon(user?.whiteLabelLogo);
        }
    }, [REACT_APP_WHITELABEL_LOGO_URL, user?.whiteLabelLogo]);
    return (
        <Drawer variant='permanent' {...rest}>
            <Brand component={NavLink} to='/'>
                {/* <BrandIcon />{" "} */}
                <Grid mt={5} mb={2}>
                    <img src={favicon} className='sidebar-logo-img' />
                </Grid>
                <Box ml={5} mt={5}>
                    RCS Panel
                    <br />
                    {/* <Typography sx={{ fontSize: "0.7rem" }}>For</Typography> */}
                    {/* <br /> */}
                    <Typography sx={{ fontSize: "0.7rem" }}>For {REACT_APP_WHITELABEL_NAME}</Typography>
                </Box>
            </Brand>
            <SidebarNav items={items} />
            {!!showFooter && <Footer />}
        </Drawer>
    );
};

export default Sidebar;
