import { CloseOutlined } from '@mui/icons-material';
import { Divider, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getAllVerifiedBotsThunk } from '../../../redux/botManagement/botManagementThunk';
import { getAllTemplatesByPageSize } from '../../../redux/templateManagement/templateManagementThunk';
import { apiGatewayAxiosInstance } from '../../../utils/axios';





export default function DeleteTemplateConformationModel({ open, setOpen, templateData, selectedBotDetails, setErrorResopnse }) {
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const DeleteTemplateConfirm = async () => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/bot_management/template/delete-template`, { params: { botId: selectedBotDetails._id, templateName: templateData.templateName } });

            if (response.status === 200) {
                dispatch(getAllVerifiedBotsThunk());
                if (selectedBotDetails) {

                    dispatch(getAllTemplatesByPageSize({ currentPage: 1, pageSize: 10, botId: selectedBotDetails._id }));
                }
                handleClose()
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Failed to delete template.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResopnse(errorMessage);
        }
    }

    return (

        // <Dialog
        //     open={open}
        //     onClose={handleClose}
        //     aria-labelledby="alert-dialog-title"
        //     aria-describedby="alert-dialog-description"
        //     maxWidth={'xl'}

        // >
        //     <DialogTitle id="alert-dialog-title">{"Delete Template"}</DialogTitle>
        //     <DialogContent>
        //         <DialogContentText id="alert-dialog-slide-description">
        //             <Typography>

        //                 Delete Template {templateData.templateName}
        //             </Typography>
        //         </DialogContentText>
        //     </DialogContent>
        //     <DialogActions>
        //         <Button
        //             variant="contained"
        //             onClick={handleClose}>Cancle</Button>
        //         <Button
        //             variant="contained"
        //             color="error" onClick={DeleteTemplateConfirm}>Confirm</Button>
        //     </DialogActions>
        // </Dialog>
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{ padding: "1rem" }}
            fullWidth
            maxWidth={"xs"}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h5">Delete Template</Typography>
                <IconButton onClick={() => handleClose()}>
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Typography variant="h6">
                    Sure do you want to Delete "{templateData.templateName}" Template ?
                </Typography>
            </DialogContent>
            <DialogActions
                style={{
                    padding: "1rem 1.5rem",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: ".25rem",
                }}
            >
                <Button
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button variant="contained" color="error" onClick={DeleteTemplateConfirm}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>

    );
}
