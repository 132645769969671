
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { Box, Card, Divider, Grid } from "@mui/material";

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { SuggestionSimulatorComponentForCampaign } from './suggestionSimulatorComponentForCampaign';

export function RichCardStandAloneCampaignSimulator({ contentMessage, campaignSimulator }) {
    // console.log(contentMessage, "dfghjkjhgfdfghjkl")
    return (
        <>
            <Grid container sx={{ display: "flex", flexDirection: "column", maxWidth:  campaignSimulator ? "90%": "50%"  }}>

                <>


                    {contentMessage.richCard.standaloneCard.cardOrientation === "VERTICAL" &&
                        <Grid sx={{ display: "flex" }}>
                            {/* <Box sx={{ borderRadius: "50%", overflow: "hidden", marginRight: 2, boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px", height: "25px", width: "25px" }}>

                                                                        <img src={campaignData.otherDetails.botDetails.botLogoImage.url} height={"25px"} />
                                                                    </Box> */}
                            <Card sx={{ border: "1px solid Grey", minHeight: "6vh", maxWidth: "100%", display: "flex", flexDirection: "column" }}>

                                <Grid fullWidth >
                                    {!contentMessage.richCard.standaloneCard.cardContent.media.contentInfo.thumbnailUrl ?
                                        <>
                                            {
                                                contentMessage.richCard.standaloneCard.cardContent.media.height === "SHORT" &&
                                                <img src={contentMessage.richCard.standaloneCard.cardContent.media.contentInfo.fileUrl} style={{ height: "100px", width: "100%" }} />
                                            }
                                            {contentMessage.richCard.standaloneCard.cardContent.media.height === "MEDIUM" &&
                                                <img src={contentMessage.richCard.standaloneCard.cardContent.media.contentInfo.fileUrl} style={{ height: "150px", width: "100%" }} />
                                            }
                                        </> :
                                        <div className="play-button-container" style={{ position: "relative" }}>
                                            <img src={contentMessage.richCard.standaloneCard.cardContent.media.contentInfo.thumbnailUrl} style={{
                                                height: contentMessage.richCard.standaloneCard.cardContent.media.height === "SHORT" ? "100px" : "150px",
                                                width: "100%",
                                                display: "block"
                                            }
                                            } className="image" />
                                            <div className="play-button-overlay" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                                {/* You can replace the content inside the overlay div with your own play button logo */}
                                                <PlayCircleIcon sx={{ fontSize: 40, color: "white" }} />
                                            </div>
                                        </div>}

                                </Grid>
                                <Grid padding={2}>
                                    <Typography variant="h6" fontWeight={"bold"} sx={{ lineBreak: "auto", wordBreak: "break-all" }} >
                                        {contentMessage.richCard.standaloneCard.cardContent.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ lineBreak: "auto", wordBreak: "break-all" }} >
                                        {contentMessage.richCard.standaloneCard.cardContent.description}
                                    </Typography>

                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>


                                        {contentMessage.richCard.standaloneCard.cardContent.suggestions.map((suggestion, index) => (
                                            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <SuggestionSimulatorComponentForCampaign suggestion={suggestion} />
                                                {index + 1 < contentMessage.richCard.standaloneCard.cardContent.suggestions.length &&

                                                    <hr style={{ width: "60%", border: "1px solid black", margin: 3 }} />
                                                }
                                            </Box>
                                        ))}
                                    </Box>


                                </Grid>
                            </Card>
                        </Grid>}

                    {contentMessage.richCard.standaloneCard.cardOrientation === "HORIZONTAL" &&
                        <Card sx={{ border: "1px solid Grey", minHeight: "12vh", width: "100%", display: "flex", margin: 3 }}>
                            <Grid container>
                                {contentMessage.richCard.standaloneCard.cardAlignment !== "RIGHT" &&
                                    <Grid item md={4} >
                                        {!contentMessage.richCard.standaloneCard.cardContent.media.contentInfo.thumbnailUrl ?
                                            <>

                                                <img src={contentMessage.richCard.standaloneCard.cardContent.media.contentInfo.fileUrl} style={{ height: "100%", width: "100%" }} />

                                            </> :
                                            <div className="play-button-container" style={{ position: "relative" }}>
                                                <img src={contentMessage.richCard.standaloneCard.cardContent.media.contentInfo.thumbnailUrl} style={{
                                                    height: contentMessage.richCard.standaloneCard.cardContent.media.height === "SHORT" ? "100px" : "150px",
                                                    width: "100%",
                                                    display: "block"
                                                }
                                                } className="image" />
                                                <div className="play-button-overlay" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                                    {/* You can replace the content inside the overlay div with your own play button logo */}
                                                    <PlayCircleIcon sx={{ fontSize: 40, color: "white" }} />
                                                </div>
                                            </div>}


                                    </Grid>
                                }


                                <Grid item md={8} padding={2} >
                                    <Typography variant="h6" fontWeight={"bold"} sx={{ lineBreak: "auto", wordBreak: "break-all" }}>

                                        {contentMessage.richCard.standaloneCard.cardContent.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ lineBreak: "auto", wordBreak: "break-all" }} >
                                        {contentMessage.richCard.standaloneCard.cardContent.description}
                                    </Typography>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>


                                        {contentMessage.richCard.standaloneCard.cardContent.suggestions.map((suggestion) => (
                                            <Box marginTop={1}>
                                                <SuggestionSimulatorComponentForCampaign suggestion={suggestion} />
                                                <Divider />
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>


                                {contentMessage.richCard.standaloneCard.cardContent.cardAlignment === "RIGHT" &&
                                    <Grid item md={4}  >
                                        {contentMessage.richCard.standaloneCard.cardContent.media.type !== "video/mp4" ?
                                            <img src={contentMessage.richCard.standaloneCard.cardContent.media.url} style={{ height: "100%", width: "100%" }} />
                                            :
                                            <>
                                                {contentMessage.richCard.standaloneCard.cardContent.thumbnail.url && <>
                                                    {/* <PlayCircleOutlinedIcon style={{ position: "absolute", left: "73%", top: "25%", fontSize: "50px", zIndex: 1 }} /> */}
                                                    <div style={{ position: "relative", height: "100%", width: "100%", alignItems: "center" }}>
                                                        <img src={contentMessage.richCard.standaloneCard.cardContent.thumbnail.url} style={{ height: "100%", width: "100%", zIndex: 0 }} />
                                                    </div>
                                                </>
                                                }
                                            </>
                                        }

                                    </Grid>
                                }
                            </Grid>
                        </Card>}

                </>


            </Grid>

        </>

    )
}
