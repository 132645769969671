export const getNodeContent = (nodeType) => {
  let content = null;

  switch (nodeType) {
    case "messageCard":
      content = [];
      break;

    case "listCard":
      content = [];
      break;

    case "button":
      content = [];
      break;

    case "media":
      content = [];
      break;

    case "webhook":
      content = [];
      break;

    case "conditionCard":
      content = [];
      break;

    case "switchCondition":
      content = [];
      break;

    case "channelSwitch":
      content = [];
      break;

    case "switchNode":
      content = {
        question: "",
        conditions: [],
      };

      break;
    default:
      break;
  }

  return content;
};
