import {
    createSlice
} from "@reduxjs/toolkit";


export const apiReportsManagementSlice = createSlice({
    name: "apiReportsManagementSlice",
    initialState: {
        apiBotStatusReport: {},
        apiBotStatusReportLoading:{}


    },
    reducers: {

        setApiBotStatusReport: (state, action) => {
            state.apiBotStatusReport = { ...state.apiBotStatusReport, ...action.payload };

        },
        setApiBotStatusReportLoading: (state, action) => {
            state.apiBotStatusReportLoading = { ...state.apiBotStatusReportLoading,...action.payload };

        },


    },

    extraReducers: (builder) => {



    }
})

export const {
    setApiBotStatusReportLoading,
    setApiBotStatusReport
} = apiReportsManagementSlice.actions;