import React, { useEffect, useState } from 'react'

const validateVariantName = (variantName) => {
    try {
        const regexToCheckStartingOfString = /^(\$\$)/g;
        const regexToCheckForNewLine = /(\r\n|\r|\n)/g;
        const regexToCheckForSpaces = /\s/g;

        if (variantName === undefined || variantName === null || typeof variantName !== 'string') {
            return { passed: false, message: "Variant Name is not provided" };
        }

        if (regexToCheckForSpaces.test(variantName)) {
            return { passed: false, message: "Spaces are not allowed." };
        }

        if (variantName.trim().length < 4) {
            return { passed: false, message: "Variant Name must be greater than 4 character." };
        }


        if (!regexToCheckStartingOfString.test(variantName)) {
            return { passed: false, message: "Variant Name must start with $$" };
        }

        if (regexToCheckForNewLine.test(variantName)) {
            return { passed: false, message: "New line characters are not allowed." };
        }

        return { passed: true, message: "" };

    } catch (error) {
        console.log(error);
    }
}

export default function VariableNamespace({ node, addVariantToNameSpace, updateVariantInfoInNode }) {

    const formData = {
        nodeHasInput: false,
        variantName: "",
    }

    const [variantData, setVariantData] = useState(formData);
    const [errors, setErrors] = useState({});

    const changeHandler = (event) => {
        const { value, checked, name, id, type } = event.target;
        setVariantData({
            ...variantData,
            [name]: type === 'checkbox' ? checked : value
        });
    }

    const validate = () => {
        if (variantData.nodeHasInput === true) {
            const result = validateVariantName(variantData.variantName);
            setErrors({ ...errors, variantName: result.message });
        }
    }

    const addVariant = () => {
        if (variantData.nodeHasInput === true) {
            const result = validateVariantName(variantData.variantName);
            setErrors({ ...errors, variantName: result.message });
            if (result.passed === true) {
                addVariantToNameSpace(node.id, variantData.variantName);
                updateVariantInfoInNode(node.id, variantData.variantName);
            }
        }
    }

    useEffect(() => {
        setVariantData({
            nodeHasInput : node.data.requiredUserInput,
            variantName : node.data.variantName
        });
    }, [node]);

    return (
        <>
            <div><h3> Variant </h3></div>
            <div className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>

                <div className="mb-4">
                    <input
                        type="checkbox"
                        name="nodeHasInput"
                        id="nodeHasInput"
                        onChange={changeHandler}
                        checked={variantData.nodeHasInput}
                        className="mr-2 leading-tight"
                    />
                    <label htmlFor="nodeHasInput" className="block text-gray-700 text-sm font-bold mb-2"> Requires User input </label>
                </div>

                {
                    variantData.nodeHasInput === true &&
                    <div >
                        <div className="mb-4">
                            <label htmlFor="variantName" className="block text-gray-700 text-sm font-bold mb-2"> Variant Name </label>
                            <input
                                type="text"
                                name="variantName"
                                id="variantName"
                                onChange={changeHandler}
                                value={variantData.variantName}
                                placeholder={"Variant Name"}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={addVariant}> Create </button>

                        <p style={{ color: 'red' }}>
                            {errors?.variantName}
                        </p>
                    </div>
                }
                <p> <small> Note: Every Variable should start with <b>$$  </b> </small></p>
            </div>

        </>
    )
}
