import { CircularProgress, Grid, Typography } from '@mui/material'
import React from 'react'

export default function FetchingSpinner() {
    return (
        <Grid container className="centered fetching-spinner" >
            <Grid item>
                <CircularProgress size={25} />
            </Grid>
            <Grid item>
                <Typography variant="h4">
                    Please Wait..
                </Typography>
            </Grid>
        </Grid>
    )
}
