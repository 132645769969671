import { Grid } from '@mui/material';
import React from 'react';
import { SuggestionSimulatorComponentForCampaign } from './suggestionSimulatorComponentForCampaign';

export default function ImageMessageComponent({ imageData, contentMessage }) {
    return (
        <Grid>
            {imageData &&

                <img src={imageData.fileUrl} width={200}
                    style={{
                        ddisplay: "block",
                        borderRadius: "10px"
                    }} className="image" />

            }

            <Grid container sx={{ display: "flex", alignItems: "center", maxWidth: "100px", margin: "5px" }}>

                {contentMessage?.suggestions && contentMessage.suggestions.length > 0 && contentMessage.suggestions.map((suggestion, index) => (
                    <Grid item md={5} key={index} sx={{ minWidth: "100%", border: "1px solid grey", borderRadius: "35px", padding: 1, paddingInline: 2, margin: 1 }}>
                        <SuggestionSimulatorComponentForCampaign suggestion={suggestion} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}
