import React from 'react';
import duplicateIcon from '../../assets/icons/duplicate-icon.svg';
import deleteIcon from '../../assets/icons/delete-icon.svg';
import startIcon from '../../assets/icons/flag-start-icon.svg';

export function ContextMenu({ nodeId, onDuplicate, onDelete, markAsStartCard, methods, isStartCard }) {
    return (
        <div className='context-menu'>
            <button title="Mark As Initial Card" className='card-icon-button' onClick={() => methods.markAsStartCard(nodeId)}><img className='card-icon' src={startIcon} /></button>
            <button title="Duplicate" className='card-icon-button' onClick={onDuplicate}><img className='card-icon' src={duplicateIcon} /></button>
            {
                (isStartCard === undefined || isStartCard === false) &&
                <button title="Delete" className='card-icon-button' onClick={() => onDelete(nodeId)}><img className='card-icon' src={deleteIcon} /></button>
            }
        </div>
    );
}

