import React, { useState } from 'react';
import { MasterCardLayout } from './MasterCardLayout';
import { ContextMenu } from '../ContextMenu';
import { Handle, Position } from 'react-flow-renderer';
import deleteImage from '../../../assets/icons/delete-icon.svg'
import { generateUniqueIdForCard } from '../../../utils/workflow-utils/helpers';


export function WebhookNode(props) {
    const { data, selected, id } = props;
    const methods = data.methods;

    return (
        <MasterCardLayout
            title={data.title}
            tailwindClass={data.cardTailwindStyle}
            key={`webhook-master-card-${id}`}
            startNode={data?.isStartCard}
            nodeData={data}
        >
            {
                selected === true &&
                // Show context menu
                <ContextMenu
                    onDuplicate={(event) => { methods.onDuplicate(event, id) }}
                    onDelete={methods.onDelete}
                    nodeId={id}
                    methods={methods}
                    isStartCard={data.isStartCard}
                />
            }
            <div className="text-updater-node" id={`${id}`}>
                <Handle className='target-handle' type="target" position={Position.Left} />
                <div style={{ padding: '10px' }}>

                </div>
            </div>
        </MasterCardLayout>
    );
}

