import { createSlice } from "@reduxjs/toolkit";
import {
    addNewTemplateThunk,
    getAllTemplatesByFilterThunk,
    getAllTemplatesByPageSize,
} from "./templateManagementThunk";

export const templateManagementSlice = createSlice({
    name: "templateManagementSlice",
    initialState: {
        templateList: [],
        templateListByPageSize: [],
        selectedBotDetails: {},
        totalTemplatesCount: 0,
        totalBotsCount: 0,
        controls: {
            showAddTemplateForm: false,
            showViewDetailsModel: false,
            isCreateTemplateLoading: false,
            isGetAllTemplatesLoading: false,

            addNewTemplateErrorMessage: "",
            updateTemplateErrorMessage: "",
            getAllTemplatesByPageSizeErrorMessage: "",
        },
    },

    reducers: {
        setShowAddTemplateForm: (state, action) => {
            state.controls.showAddTemplateForm = action.payload;
            state.controls.addNewTemplateErrorMessage = "";
        },
        setShowViewDetailsModel: (state, action) => {
            state.controls.showViewDetailsModel = action.payload;
        },
        clearErrorMessagesForTemplates: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = "";
        },
        setErrorMessagesForTemplates: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = action.payload.errorMessage;
        },

        setSelectedBotDetails: (state, action) => {
            state.selectedBotDetails = action.payload;
        },

        setUpdateTemplateErrorMessage: (state, action) => {
            state.controls.updateTemplateErrorMessage = action.payload;
        },
    },

    extraReducers: (builder) => {
        //--------------------------Add New Template-------------------------------------

        builder.addCase(addNewTemplateThunk.pending, (state, action) => {
            state.controls.isCreateTemplateLoading = true;
        });
        builder.addCase(addNewTemplateThunk.fulfilled, (state, action) => {
            state.controls.isCreateTemplateLoading = false;
            state.controls.showAddTemplateForm = false;
            state.templateListByPageSize = [];
        });

        builder.addCase(addNewTemplateThunk.rejected, (state, action) => {
            state.controls.isCreateTemplateLoading = false;
            state.controls.addNewTemplateErrorMessage = action.error.message;
        });

        //-------------------------------get All templates by Page size---------------------------------------------
        builder.addCase(getAllTemplatesByPageSize.pending, (state, action) => {
            state.controls.getAllTemplatesByPageSizeErrorMessage = "";
            state.controls.isGetAllTemplatesLoading = true;
        });
        builder.addCase(
            getAllTemplatesByPageSize.fulfilled,
            (state, action) => {
                state.controls.isGetAllTemplatesLoading = false;
                state.templateListByPageSize = action.payload?.allTemplates;
                state.totalTemplatesCount = action.payload?.totalCount;
                state.controls.getAllTemplatesByPageSizeErrorMessage = "";
            }
        );
        builder.addCase(getAllTemplatesByPageSize.rejected, (state, action) => {
            state.controls.isGetAllTemplatesLoading = false;
            state.controls.getAllTemplatesByPageSizeErrorMessage = action.error.message;
            state.templateListByPageSize = [];
            state.totalTemplatesCount = 0;
        });
        //-------------------------------get All templates by Filter---------------------------------------------

        builder.addCase(
            getAllTemplatesByFilterThunk.pending,
            (state, action) => {
                state.controls.getAllTemplatesByFilterErrorMessage = "";
                state.controls.isGetAllTemplatesLoading = true;
            }
        );
        builder.addCase(
            getAllTemplatesByFilterThunk.fulfilled,
            (state, action) => {
                state.controls.isGetAllTemplatesLoading = false;
                state.templateListByPageSize = action.payload?.allTemplates;
                state.totalTemplatesCount = action.payload?.totalCount;
                state.controls.getAllTemplatesByFilterErrorMessage = "";
            }
        );
        builder.addCase(
            getAllTemplatesByFilterThunk.rejected,
            (state, action) => {
                state.controls.isGetAllTemplatesLoading = false;
                state.controls.getAllTemplatesByFilterErrorMessage =
                    action.error.message;
                state.templateListByPageSize = [];
                state.totalTemplatesCount = 0;
            }
        );
    },
});

export const {
    setShowAddTemplateForm,
    setShowViewDetailsModel,
    clearErrorMessagesForTemplates,
    setSelectedBotDetails,
    setUpdateTemplateErrorMessage,
    setErrorMessagesForTemplates
} = templateManagementSlice.actions;

export default templateManagementSlice;
