import React, { useState } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TemplateStatus, TemplateTypes } from "../../../config";

function FilterAllTemplateModel({
    open,
    searchParams,
    setSearchParams,
    handleClickOpenModel,
    handleCloseFilterModel,
    handleClickSearch,
}) {
    const [search, setSearch] = useState(searchParams);
    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setSearch({ ...search, [name]: value });
    };
    const templateStatus = Object.keys(TemplateStatus);
    const templateType = Object.values(TemplateTypes);




    const onClickClear = () => {
        setSearchParams({
            templateType: "",
            templateName: "",
            startDate: "",

            status: "",
        });

        handleClickSearch({
            templateType: "",
            templateName: "",
            startDate: "",

            status: "",
        })

    };
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleCloseFilterModel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5"> Templates Filter</Typography>
                    <IconButton onClick={() => handleCloseFilterModel()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent
                    sx={{
                        overflowY: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                        width: "500px",
                    }}
                >
                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            <TextField
                                value={search.templateName}
                                name="templateName"
                                onChange={handleChange}
                                label="Template Name"
                                fullWidth
                                placeholder=" Template Name"
                            />
                        </Grid>
                        <Grid item md={5.5}>
                            {/* <TextField
                                value={search.templateType}
                                name="templateType"
                                onChange={handleChange}
                                label="Template Type"
                                fullWidth
                                placeholder=" Enter Template Type"
                            /> */}



                            <FormControl fullWidth>
                                <InputLabel id="demo-controlled-open-select-label">
                                    Template Type
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    name="templateType"
                                    value={search.templateType}
                                    label="Template Type"
                                    onChange={handleChange}
                                >
                                    {templateType.map((type) => (
                                        <MenuItem value={type}>{type.replaceAll("_", " ").toUpperCase()}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            <TextField
                                fullWidth
                                type="date"
                                label=" Created Date"
                                name="startDate"
                                value={search.startDate}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>
                        {/* <Grid item md={5.5}>
                            <TextField
                                fullWidth
                                type="date"
                                label="Created End Date"
                                name="endDate"
                                value={search.endDate}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid> */}
                        {/* </Grid>
                    <Grid container fullWidth justifyContent={"space-between"}> */}
                        <Grid item md={5.5}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-controlled-open-select-label">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    name="status"
                                    value={search.status}
                                    label="Status"
                                    onChange={handleChange}
                                >
                                    {templateStatus.map((status) => (
                                        <MenuItem value={TemplateStatus[status]}>{status}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => onClickClear()}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClickSearch(search)}
                        autoFocus
                    >
                        Search{" "}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default FilterAllTemplateModel;
