import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowAddUserForm } from "../../../redux/userManagement/userManagementSlice";
import AddUsers from "./AddUsers";
import ListUsers from "./ListUsers";


function ManageUsers() {
    const state = useSelector((state) => state.userManagementSlice);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setShowAddUserForm(false));
    }, []);
    return (
        <div>
            {state.controls.showAddUserForm ?
                <AddUsers />
                :
                <ListUsers />
            }
        </div>
    );
}

export default ManageUsers;