import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { green, red } from '@mui/material/colors'
import React from 'react'
import { ClientStatus } from '../../config'

export default function StatusRadioButtons({ onChangeRadio, isDisabled, status }) {
    return (
        <FormControl className='field-spacing' sx={{ display: "flex" }} fullWidth >
            <FormLabel id="demo-controlled-radio-buttons-group" >
                <Typography variant="subtitle2">
                    Status:
                </Typography>
            </FormLabel>
            <RadioGroup

                aria-labelledby="demo-controlled-radio-buttons-group"
                sx={{ display: "flex !important", flexDirection: "row", minWidth: "100%", gap: "50px" }}
                defaultValue="female"
                name="radio-buttons-group"
            >
                <FormControlLabel
                    value={ClientStatus.Active}
                    disabled={isDisabled}
                    control={
                        <Radio
                            checked={status === ClientStatus.Active ? true : false}
                            color="success"
                            sx={{
                                color: green[800],
                                '&.Mui-checked': {
                                    color: green[600],
                                },
                            }}
                            size='small'
                        />
                    }
                    label={<Typography variant="subtitle2">Active</Typography>}
                    sx={{ color: "green" }}
                    onChange={(e) => onChangeRadio("status", e)}
                />

                <FormControlLabel
                    value={ClientStatus.InActive}
                    disabled={isDisabled}
                    control={
                        <Radio

                            checked={status === ClientStatus.InActive ? true : false}
                            sx={{
                                color: red[800],
                                '&.Mui-checked': {
                                    color: red[600],
                                },

                            }}
                            size='small'
                        />
                    }
                    label={<Typography variant="subtitle2">In-Active</Typography>}
                    sx={{ color: "red" }}
                    onChange={(e) => onChangeRadio("status", e)}

                />
            </RadioGroup>
        </FormControl>
    )
}
