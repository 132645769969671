import React from 'react'
import { Grid } from 'react-feather'

export default function RcsDeliveredIcon() {
    return (
        <div >
            <svg viewBox="0 0 16 10" width="25" height="20" style={{ display: "grid",marginBottom:"3",marginRight:"3"}}>
                <g clip-path="url(#clip0_510_18924)"
                >
                    <path
                        fill='green'
                        fill-rule="evenodd"
                         d="M8.078 2.01A4.503 4.503 0 0 0 6 1.5 4.504 4.504 0 0 0 1.5 6c0 2.482 2.018 4.5 4.5 4.5.758 0 1.463-.188 2.092-.518a4.895 4.895 0 0 1-.697-.667c-.427.18-.9.285-1.395.285a3.6 3.6 0 0 1 0-7.2c.487 0 .96.098 1.38.278a5.27 5.27 0 0 1 .69-.668h.008Zm-1.92 3.923L5.1 6.99l-.855-.855a.448.448 0 0 0-.63 0 .448.448 0 0 0 0 .63l1.162 1.163a.457.457 0 0 0 .638 0l.855-.856a5.175 5.175 0 0 1-.112-1.08v-.06Z"
                          clip-rule="evenodd">
                    </path>
                    <path
                        fill='green'
                        d="M11.25 1.5A4.504 4.504 0 0 0 6.75 6c0 2.482 2.018 4.5 4.5 4.5s4.5-2.018 4.5-4.5-2.018-4.5-4.5-4.5Zm0 8.1a3.6 3.6 0 0 1 0-7.2 3.6 3.6 0 0 1 0 7.2Z">
                    </path>
                    <path d="M13.005 4.335 10.35 6.99l-.855-.855a.448.448 0 0 0-.63 0 .448.448 0 0 0 0 .63l1.162 1.162a.457.457 0 0 0 .638 0l2.97-2.962a.448.448 0 0 0 0-.63.448.448 0 0 0-.63 0Z"
                        // stroke="green" 
                        // stroke-width="0.5" 
                        fill='green'
                    >
                    </path>
                </g>
                <defs>
                    <clipPath id="clip0_510_18924" >
                        <path fill="#fff" d="M1.5 1.5h14.25v9H1.5z">
                        </path>
                    </clipPath>
                </defs>
            </svg>





        </div>

    )
}
