import AodIcon from "@mui/icons-material/Aod";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import {
    Button,
    CircularProgress,
    Grid,
    IconButton as MuiIconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { darken } from "polished";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RCSLogo from "../../assets/icons/RCSLogo.png";
import { CampaignReportStatus, getColourByMessageStatus, getStatusToDisplay, messageLastStatus, MessageStatus, smsMessageStatus, smsStatusDisplayName } from "../../config";
import useAuth from "../../hooks/useAuth";

import SmsLogo from "../../assets/icons/smsLogo.png";
import NoRecords from "../components/NoRecords";
import FilterAllCampaignsReportsModel from "../reports/campaignReportsComponents/FilterCampaignReportsModel";
import CampaignSimulator from "../reports/simulatorComponents/ReportSimulator";
// import FilterAllCampaignsReportsModel from "./FilterCampaignReportsModel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RcsDeliveredIcon from "../../assets/icons/rcs-delivered-icon";
import RcsReadIcon from "../../assets/icons/rcs-read-icon";
import RcsSentIcon from "../../assets/icons/rcs-sent-icon";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { CustomTablePagination } from "../../utils/CustomPagination";
import { apiGatewayAxiosInstance } from "../../utils/axios";
import TablePaginationShowPage from "../../utils/tablePagination";
import FetchingSpinner from "../components/FetchingSpinner";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: "14px !important",
        fontWeight: "bold",
        backgroundColor: "#f4f4f4",
        padding: "5px 15px !important",
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "13px !important",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //     backgroundColor: theme.palette.action.hover,
    // },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const IconButton = styled(MuiIconButton)`
    &:hover {
        background-color: ${props => darken(0.05, props.theme.header.search.color)};
    }
`;
const calculateTime = value => {
    const time = new Date(value).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    });
    const day = new Date(value).toLocaleDateString([], { day: "2-digit" });
    const month = new Date(value).toLocaleDateString([], { month: "2-digit" });
    const year = new Date(value).toLocaleDateString([], { year: "numeric" });

    return day + "-" + month + "-" + year + " , " + time;
};



// const messageLastStatus = status => {
//     if (status.READ) {
//         return 'READ';
//     }
//     if (status.DELIVERED) {
//         return 'DELIVERED';
//     }
//     if (status.TTL_EXPIRATION_REVOKED) {
//         return 'TTL_EXPIRATION_REVOKED';
//     }
//     if (status.SENT) {
//         return 'SENT';
//     }
//     if (status.FAILED) {
//         return 'FAILED';
//     }
//     if (status.RETRY) {
//         return 'RETRY';
//     }
//     if (status.PENDING) {
//         return 'PENDING';
//     }
//     return '-';
// };

// phase : 1
export default function ViewCampaignReport({ reload, getCampaignReportDetailsByPageSize, campaign, setShowViewCampaignReport, totalCount, totalCountPageSize, campaignReports, pageSize, setPageSize, currentPage, setCurrentPage, campaignReportLoader }) {
    // const campaignReportState = useSelector(
    //     (state) => state.campaignReportsManagementSlice



    // );
    const dispatch = useDispatch();
    // const [currentPage, setCurrentPage] = useState(0);
    const { user } = useAuth();
    // const [pageSize, setPageSize] = React.useState(10);
    const [showCampaignSimulator, setShowCampaignSimulator] = useState(false);
    const [campaignDetails, setCampaignDetails] = useState({});
    const [selectedMessageStatus, setSelectedMessageStatus] = React.useState("");
    // const [campaignReports, setCampaignReports] = useState([]);
    const [errorResponse, setErrorResponse] = useState("");
    const [loader, setLoader] = useState(false);

    const state = useSelector(state => state.campaignManagmentSlice);
    const campaignReportData = state.capaignReportDataState;
    console.log(state.capaignReportDataState);

    // const [totalCountPageSize, setTotalCountPageSize] = useState(0)


    // const [searchCampaign, setSearchCampaign] = useState([]);

    // const [pageSize, setPageSize] = React.useState(10);
    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);

        // setcampaignDetails({ currentPage: newPage });
    };



    // const handleChangeRowsPerPage = (event) => {
    //     setPageSize(parseInt(event.target.value));
    //     setCurrentPage(0);
    //     // setCampaignDetails({ ...campaignDetails, currentPage: 1 });
    // };



    const ViewCampaignSimulator = campaignReport => {
        setShowCampaignSimulator(true);
        setCampaignDetails(campaignReport);
    };

    const onClickBackButton = () => {
        // dispatch(setShowCampaignReport({ status: false, campaign: null }));
        setShowViewCampaignReport(false);
    };

    useEffect(() => {

        getCampaignReportDetailsByPageSize(campaign);
        // handleShowReportThunk(campaign)

    }, [currentPage, pageSize, reload]);

    return (
        <Grid width={"100%"}>


            {campaignReports && (

                <Grid
                    container
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: 4,
                        alignItems: "center",
                        flexWrap: "wrap"
                    }}
                >

                    <Grid
                        item
                        md={12}
                        sx={{
                            display: "flex",
                            // justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap !important"
                        }}
                        gap={5}
                    >


                        {campaign.isSMSFallbackRequired && <>
                            RCS :
                        </>
                        }

                        <Grid>
                            <Typography
                                sx={{
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <Grid display='flex' flexDirection={'row'}>
                                    TOTAL : {state.isLoadingCapaignReport[CampaignReportStatus.TotalRCSCount] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[CampaignReportStatus.TotalRCSCount]}
                                </Grid>
                            </Typography>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center !important",
                            }}
                        >
                            <Typography
                                color='orange'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >

                                < AccessTimeIcon fontSize='small' sx={{ margin: "5px" }} />
                                PENDING : {state.isLoadingCapaignReport[CampaignReportStatus.Pending] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[MessageStatus.Pending]}


                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography
                                color='primary'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                {/* <DoneIcon fontSize="small" sx={{ margin: "5px" }} /> */}

                                <RcsSentIcon />

                                SENT : {state.isLoadingCapaignReport[CampaignReportStatus.Sent] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[MessageStatus.Sent] ?? "-"}
                                {/* {
                                            campaignReports.filter(
                                                (campaignReport) => { if (campaignReport.statusWithTime.hasOwnProperty('SENT') && campaignReport.provider.toLowerCase() === "vi") return true }
                                            ).length
                                        } */}

                            </Typography>
                        </Grid>

                        <Grid>
                            <Typography
                                color='green'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                {/* <DoneAllIcon fontSize="small" sx={{ margin: "5px" }} /> */}
                                <RcsDeliveredIcon />
                                DELIVERED : {state.isLoadingCapaignReport[CampaignReportStatus.Delivered] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[MessageStatus.Delivered] ?? "-"}
                                {/* {
                                    campaignReports.filter(
                                        (campaignReport) =>
                                            campaignReport.statusWithTime['DELIVERED']
                                    ).length
                                } */}
                            </Typography>
                        </Grid>

                        <Grid>
                            <Typography
                                color='darkblue'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                {/* <RemoveRedEyeIcon fontSize="small" sx={{ margin: "5px" }} /> */}
                                <RcsReadIcon />
                                READ :  {state.isLoadingCapaignReport[CampaignReportStatus.Read] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[MessageStatus.Read] ?? "-"}
                                {/* {
                                    campaignReports.filter(
                                        (campaignReport) =>
                                            campaignReport.statusWithTime['READ']
                                    ).length
                                } */}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography
                                color='error'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <UnpublishedIcon fontSize='small' sx={{ margin: "5px" }} />
                                FAILED : {state.isLoadingCapaignReport[CampaignReportStatus.Failed] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[MessageStatus.Failed] ?? "-"}

                            </Typography>
                        </Grid>
                        {/* <Grid>
                            <Typography
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <ChangeCircleIcon fontSize='small' sx={{ margin: "5px" }} />
                                RETRY : {state.isLoadingCapaignReport[CampaignReportStatus.Retry] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[MessageStatus.Retry] ?? "-"}

                            </Typography>
                        </Grid> */}
                        <Grid>
                            <Typography
                                color='GrayText'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <CurrencyExchangeIcon fontSize='small' sx={{ margin: "5px" }} />
                                Sent Revoked : {state.isLoadingCapaignReport[CampaignReportStatus.TTLExpirationRevoked] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[MessageStatus.TTLExpirationRevoked] ?? "-"}

                            </Typography>
                        </Grid>
                    </Grid>
                    {campaign.isSMSFallbackRequired &&
                        <Grid
                            item
                            md={12}
                            sx={{
                                display: "flex",
                                // justifyContent: "space-between",
                                alignItems: "center",
                                flexWrap: "wrap !important"
                            }}
                            gap={5}
                        >
                            SMS :
                            <Grid>
                                <Typography
                                    sx={{
                                        fontSize: "0.7rem !important",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <Grid display='flex' flexDirection={'row'}>
                                        TOTAL : {state.isLoadingCapaignReport[CampaignReportStatus.TotalSMSCount] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.capaignReportDataState[CampaignReportStatus.TotalSMSCount]}
                                    </Grid>
                                </Typography>
                            </Grid>
                            {/* <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center !important",
                            }}
                        >
                            <Typography
                                color='orange'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >

                                < AccessTimeIcon fontSize='small' sx={{ margin: "5px" }} />
                                PENDING : {state.isLoadingCapaignReport[CampaignReportStatus.Pending] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[MessageStatus.Pending]}


                            </Typography>
                        </Grid> */}
                            <Grid>
                                <Typography
                                    color='primary'
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center !important",
                                        fontSize: "0.7rem !important",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {/* <DoneIcon fontSize="small" sx={{ margin: "5px" }} /> */}

                                    <RcsSentIcon />

                                    SENT : {state.isLoadingCapaignReport[CampaignReportStatus.Sms] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={10} /> : campaignReportData["SMS"]}

                                </Typography>
                            </Grid>

                            <Grid>
                                <Typography
                                    color='green'
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center !important",
                                        fontSize: "0.7rem !important",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {/* <DoneAllIcon fontSize="small" sx={{ margin: "5px" }} /> */}
                                    <RcsDeliveredIcon />
                                    DELIVERED : {state.isLoadingCapaignReport[CampaignReportStatus.SmsDELIVERED] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[CampaignReportStatus.SmsDELIVERED] ?? "-"}
                                    {/* {
                                    campaignReports.filter(
                                        (campaignReport) =>
                                            campaignReport.statusWithTime['DELIVERED']
                                    ).length
                                } */}
                                </Typography>
                            </Grid>


                            <Grid>
                                <Typography
                                    color='error'
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center !important",
                                        fontSize: "0.7rem !important",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <UnpublishedIcon fontSize='small' sx={{ margin: "5px" }} />
                                    Failed : {
                                        (state.isLoadingCapaignReport[CampaignReportStatus.TotalSMSFailed]




                                        ) ?
                                            <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> :

                                            <>
                                                {state.capaignReportDataState[CampaignReportStatus.TotalSMSFailed]
                                                }
                                            </>
                                            ?? "-"}

                                </Typography>
                            </Grid>
                            {/* <Grid>
                            <Typography
                                color='error'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <UnpublishedIcon fontSize='small' sx={{ margin: "5px" }} />
                                DND : {state.isLoadingCapaignReport[CampaignReportStatus.SmsDND] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[CampaignReportStatus.SmsDND] ?? "-"}

                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography
                                color='error'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <UnpublishedIcon fontSize='small' sx={{ margin: "5px" }} />
                                EXPIRED : {state.isLoadingCapaignReport[CampaignReportStatus.SmsEXPIRED] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[CampaignReportStatus.SmsEXPIRED] ?? "-"}

                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography
                                color='error'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <UnpublishedIcon fontSize='small' sx={{ margin: "5px" }} />
                                SMS-INVALID : {state.isLoadingCapaignReport[CampaignReportStatus.SmsINVALID] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[CampaignReportStatus.SmsINVALID] ?? "-"}

                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography
                                color='error'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <UnpublishedIcon fontSize='small' sx={{ margin: "5px" }} />
                                SMS-REJECTED : {state.isLoadingCapaignReport[CampaignReportStatus.SmsREJECTED] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[CampaignReportStatus.SmsREJECTED] ?? "-"}

                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography
                                color='error'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <UnpublishedIcon fontSize='small' sx={{ margin: "5px" }} />
                                SMS-UNDELIVERED : {state.isLoadingCapaignReport[CampaignReportStatus.SmsUNDELIVERED] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : campaignReportData[CampaignReportStatus.SmsUNDELIVERED] ?? "-"}

                            </Typography>
                        </Grid> */}

                        </Grid>}
                </Grid>
            )}

            {
                campaignReportLoader ? (
                    <FetchingSpinner />
                ) :
                    campaignReports && campaignReports.length > 0 ? (
                        <>
                            <TableContainer component={Paper} className='table-container' sx={{ borderRadius: "10px  !important", maxHeight:"46vh" }}>
                                <Table stickyHeader sx={{}}>
                                    <TableHead className='row'>
                                        <TableRow>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Sr No
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                <Typography variant>Users</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Send Time
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Provider
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Reason
                                            </StyledTableCell>

                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Message Status
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                Actions
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {campaignReports.slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((campaignReport, index) => ( */}
                                        {campaignReports.map((campaignReport, index) => (

                                            <StyledTableRow key={index}>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <b>{currentPage * pageSize + index + 1}</b>
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {campaignReport?.receiverNumber}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {calculateTime(campaignReport?.createdAt)}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {campaignReport?.provider && (
                                                        <>
                                                            {campaignReport?.provider.toLowerCase() === "vi" && (
                                                                <img src={RCSLogo} height={"20px"} width={"20px"} title={campaignReport?.provider} />
                                                            )}
                                                            {campaignReport?.provider.toLowerCase() === "sms" && (
                                                                <img src={SmsLogo} height={"20px"} width={"20px"} title={campaignReport?.provider} />
                                                            )}
                                                        </>
                                                    )}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {campaignReport?.reason ?? "-"}
                                                </StyledTableCell>



                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {/* {campaignReport?.statusWithTime && messageLastStatus(campaignReport?.statusWithTime)} */}
                                                    <Typography
                                                        variant='subtitle2'
                                                        color={
                                                            getColourByMessageStatus(campaignReport?.statusWithTime)
                                                        }
                                                    >

                                                        {messageLastStatus(campaignReport?.statusWithTime)}
                                                        {/* <br />
                                                    {campaignReport.messageStatus} */}
                                                    </Typography>
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <Grid
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <IconButton title='View Message' onClick={() => ViewCampaignSimulator(campaignReport)}>
                                                            <AodIcon />
                                                        </IconButton>

                                                        {campaignReport?.statusWithTime && (
                                                            <Tooltip
                                                                sx={{
                                                                    paddingLeft: 2,
                                                                }}
                                                                title={
                                                                    <p
                                                                        style={{
                                                                            fontSize: ".7rem",
                                                                        }}
                                                                    >
                                                                        {Object.values(MessageStatus).map(status => {
                                                                            return (
                                                                                <>
                                                                                    {getStatusToDisplay(status)} :{" "}
                                                                                    {
                                                                                        campaignReport?.statusWithTime[status]
                                                                                            ? calculateTime(campaignReport?.statusWithTime[status])
                                                                                            : "-"
                                                                                    }
                                                                                    <br />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </p>
                                                                }
                                                                className='cursor-pointer'
                                                            >
                                                                <InfoOutlinedIcon
                                                                    sx={{
                                                                        opacity: "50%",
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </Grid>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <NoRecords />
                    )
            }

            {/* <TablePagination
                count={
                    campaignReports.length || 0
                    /// rowsPerPage
                }
                component='div'
                variant='outlined'
                color='primary'
                page={currentPage}
                // onChange={handlePageChange}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            {campaignReports.length > 0 && (
                <Grid
                    className='my-2'
                    style={{
                        zIndex: "2",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Grid
                        style={{
                            marginTop: "15px",
                        }}
                    >
                        Total Records: {totalCountPageSize}
                    </Grid>
                    <TablePaginationShowPage
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        colSpan={3}
                        count={totalCountPageSize}
                        rowsPerPage={pageSize}
                        page={currentPage}
                        slotProps={{
                            select: {
                                "aria-label": "Rows per page",
                            },
                            actions: {
                                showFirstButton: true,
                                showLastButton: true,
                                slots: {
                                    firstPageIcon: FirstPageRoundedIcon,
                                    lastPageIcon: LastPageRoundedIcon,
                                    nextPageIcon: ChevronRightRoundedIcon,
                                    backPageIcon: ChevronLeftRoundedIcon,
                                },
                            },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            )}


            {showCampaignSimulator && (
                <CampaignSimulator
                    open={showCampaignSimulator}
                    setShowCampaignSimulator={setShowCampaignSimulator}
                    campaignDetails={campaignDetails}
                    campaignData={campaign}
                    contentMessage={campaignDetails.messagePayload.payload.contentMessage}
                />
            )}
        </Grid>
    );
}
