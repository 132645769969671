import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
    Button,
    Card,
    CardContent,
    Grid,
    IconButton as MuiIconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";

import AodIcon from "@mui/icons-material/Aod";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DownloadIcon from "@mui/icons-material/Download";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { darken } from "polished";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RCSLogo from "../../../assets/icons/RCSLogo.png";
import SmsLogo from "../../../assets/icons/smsLogo.png";
import { ERoles, MessageStatus } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { setShowCampaignReport } from "../../../redux/campaignReportsManagment/campaignReportsManagmentSlice";
import FetchingSpinner from "../../components/FetchingSpinner";
import NoRecords from "../../components/NoRecords";
import CampaignSimulator from "../simulatorComponents/ReportSimulator";
import FilterAllCampaignsReportsModel from "./FilterCampaignReportsModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: "14px !important",
        fontWeight: "bold",
        backgroundColor: "#f4f4f4",
        padding: "5px 10px !important",
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "13px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //     backgroundColor: theme.palette.action.hover,
    // },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const IconButton = styled(MuiIconButton)`
    &:hover {
        background-color: ${props => darken(0.05, props.theme.header.search.color)};
    }
`;
const calculateTime = value => {
    const time = new Date(value).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    });
    const day = new Date(value).toLocaleDateString([], { day: "2-digit" });
    const month = new Date(value).toLocaleDateString([], { month: "2-digit" });
    const year = new Date(value).toLocaleDateString([], { year: "numeric" });

    return day + "-" + month + "-" + year + " , " + time;
};

export default function CampaignReport() {
    const campaignReportState = useSelector(state => state.campaignReportsManagementSlice);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const { user } = useAuth();
    const [campaignData, setCampaignData] = useState(campaignReportState.controls.showCampaignReport?.campaignData);

    const [showCampaignSimulator, setShowCampaignSimulator] = useState(false);
    const [campaignDetails, setCampaignDetails] = useState({});
    const [selectedMessageStatus, setSelectedMessageStatus] = React.useState("");
    const [campaignReports, setCampaignReports] = useState([...campaignData.otherDetails?.reportDetails]);
    // const [searchCampaign, setSearchCampaign] = useState([]);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [searchParams, setSearchParams] = React.useState({
        messageStatus: "",
        userNumber: "",
        sendTime: "",
        provider: "",
    });

    const handleClickOpenFilterModel = () => {
        setOpenFilter(true);
    };

    const handleCloseFilterModel = () => {
        // setSearchParams({});
        setOpenFilter(false);
    };

    const handleChange = event => {
        setSelectedMessageStatus(event.target.value);
        if (event.target.value) {
            const filteredStatus = campaignData.otherDetails?.reportDetails.filter(report => report.messageStatus.includes(event.target.value));
            setCampaignReports(filteredStatus);
        } else {
            setCampaignReports(campaignData.otherDetails?.reportDetails);
        }
    };

    const ViewCampaignSimulator = campaignReport => {
        setShowCampaignSimulator(true);
        setCampaignDetails(campaignReport);
    };

    const onClickBackButton = () => {
        dispatch(setShowCampaignReport({ status: false, campaignData: null }));
    };
    useEffect(() => { }, [campaignReports]);

    const onClickDownloadReport = () => {
        const csvBlob = new Blob([campaignData.campaignCSVReport], {
            type: "text/csv",
        });
        const csvUrl = URL.createObjectURL(csvBlob);

        const link = document.createElement("a");
        link.href = csvUrl;
        link.setAttribute("download", `${campaignData.campaignName}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const messageLastStatus = status => {
        const allStatus = Object.keys(status);

        const messageStatus = Object.keys(MessageStatus);

        if (status.FAILED) {
            return "FAILED";
        }
        if (status.READ) {
            return "READ";
        }
        if (status.DELIVERED) {
            return "DELIVERED";
        }
        if (status.SENT) {
            return "SENT";
        }
        if (status.RETRY) {
            return "RETRY";
        }
        if (status.PENDING) {
            return "PENDING";
        }

        return "-";
    };

    const onChangeSearchCampaign = userNumber => {
        // setSelectedUserNumber(userNumber);
        const campaignList = campaignData.otherDetails?.reportDetails.filter(user => user.receiverNumber.includes(userNumber));
        setCampaignReports(campaignList);
    };

    const handleClickSearch = () => {
        let campaignList = campaignData.otherDetails?.reportDetails.filter(user => user.messageStatus.includes(searchParams.messageStatus));
        campaignList = campaignList.filter(user => user.receiverNumber.includes(searchParams.userNumber));
        campaignList = campaignList.filter(user => user.provider.includes(searchParams.provider));
        setCampaignReports(campaignList);
        handleCloseFilterModel();
    };

    return (
        <Grid>
            <Card>
                <CardContent sx={{ padding: "10px !important" }}>
                    <Grid container display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item md={6} display={"flex"} alignItems={"center"}>
                            <IconButton color='secondary' onClick={onClickBackButton} size='medium'>
                                <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                            </IconButton>
                            <Typography variant='h3'>&nbsp; {campaignData.campaignName} Report</Typography>
                        </Grid>

                        <Grid item md={6} display={"flex"} alignItems={"end"} flexDirection={"column"}>
                            <Grid display={"flex"} alignItems={"center"}>
                                <Typography variant='button'>Bot Name :</Typography>
                                <Typography variant='button'>&nbsp; {campaignData.botName}</Typography>
                            </Grid>
                            {user.roleName &&  user.roleName === ERoles.SUPER_ADMIN && (
                                <Grid display={"flex"} alignItems={"center"}>
                                    <Typography variant='button'>Organization Name :</Typography>
                                    <Typography variant='button'>&nbsp; {campaignData.otherDetails.orgDetails.clientName}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card sx={{ marginTop: "20px" }}>
                <CardContent>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: 4,
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            item
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                            }}
                        >
                            <Grid>
                                <Typography variant='subtitle2'>TOTAL : {campaignData.otherDetails?.reportDetails.length}</Typography>
                            </Grid>
                            <Grid>
                                <Typography variant='subtitle2' color='orange'>
                                    PENDING :{" "}
                                    {
                                        campaignData.otherDetails?.reportDetails.filter(
                                            campaignReport => campaignReport.messageStatus === MessageStatus.Pending,
                                        ).length
                                    }
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant='subtitle2' color='primary'>
                                    SENT :{" "}
                                    {
                                        campaignData.otherDetails?.reportDetails.filter(
                                            campaignReport => campaignReport.messageStatus === MessageStatus.Sent,
                                        ).length
                                    }
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant='subtitle2' color='green'>
                                    DELIVERED :{" "}
                                    {
                                        campaignData.otherDetails?.reportDetails.filter(
                                            campaignReport => campaignReport.messageStatus === MessageStatus.Delivered,
                                        ).length
                                    }
                                </Typography>
                            </Grid>

                            <Grid>
                                <Typography variant='subtitle2' color='darkblue'>
                                    READ :{" "}
                                    {
                                        campaignData.otherDetails?.reportDetails.filter(campaignReport => campaignReport.messageStatus === "READ")
                                            .length
                                    }
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant='subtitle2' color='error'>
                                    FAILED :{" "}
                                    {
                                        campaignData.otherDetails?.reportDetails.filter(
                                            campaignReport => campaignReport.messageStatus === MessageStatus.Failed,
                                        ).length
                                    }
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item md={6} display={"flex"} justifyContent={"end"}>
                            <Grid item>
                                <Button sx={{ marginRight: "10px" }} variant='contained' onClick={handleClickOpenFilterModel}>
                                    Filter
                                </Button>
                            </Grid>
                            <FormControl sx={{ width: "190px", paddingRight: 2 }} size='small'>
                                <InputLabel id='demo-simple-select-label'>Select Message Status</InputLabel>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={selectedMessageStatus}
                                    defaultValue=' '
                                    label='Select Message Status'
                                    onChange={e => handleChange(e)}
                                    size='small'
                                >
                                    <MenuItem value={MessageStatus.Failed}>{MessageStatus.Failed}</MenuItem>
                                    <MenuItem value={MessageStatus.Delivered}>{MessageStatus.Delivered}</MenuItem>
                                    <MenuItem value={MessageStatus.Pending}>{MessageStatus.Pending}</MenuItem>
                                    <MenuItem value={MessageStatus.Sent}>{MessageStatus.Sent}</MenuItem>
                                    <MenuItem value={MessageStatus.Read}>{MessageStatus.Read}</MenuItem>
                                    <MenuItem value={""}>All</MenuItem>
                                </Select>
                            </FormControl>
                            {campaignData?.campaignCSVReport && (
                                <Button
                                    onClick={() => {
                                        onClickDownloadReport();
                                    }}
                                >
                                    {" "}
                                    <DownloadIcon /> &nbsp; Download Report
                                </Button>
                            )}
                        </Grid>
                    </Grid>

                    {campaignReportState.controls.isCampaignDetailsLoading ? (
                        <FetchingSpinner />
                    ) : campaignData.otherDetails?.reportDetails && campaignData.otherDetails?.reportDetails.length > 0 ? (
                        <TableContainer component={Paper} className='table-container' sx={{ borderRadius: "10px  !important" }}>
                            <Table stickyHeader sx={{}}>
                                <TableHead className='row'>
                                    <TableRow>
                                        <StyledTableCell className='tableContentFont' align='center'>
                                            Sr No
                                        </StyledTableCell>
                                        <StyledTableCell className='tableContentFont' align='center'>
                                            <Typography variant>Users</Typography>
                                        </StyledTableCell>

                                        <StyledTableCell className='tableContentFont' align='center'>
                                            Send Time
                                        </StyledTableCell>
                                        <StyledTableCell className='tableContentFont' align='center'>
                                            Provider
                                        </StyledTableCell>
                                        <StyledTableCell className='tableContentFont' align='center'>
                                            Reason
                                        </StyledTableCell>

                                        {/* <StyledTableCell
                                            className="tableContentFont"
                                            align="center"
                                        >
                                            PENDING
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="tableContentFont"
                                            align="center"
                                        >
                                            RETRY
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="tableContentFont"
                                            align="center"
                                        >
                                            SENT
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="tableContentFont"
                                            align="center"
                                        >
                                            FAILED
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="tableContentFont"
                                            align="center"
                                        >
                                            DELIVERED
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="tableContentFont"
                                            align="center"
                                        >
                                            READ
                                        </StyledTableCell> */}

                                        <StyledTableCell className='tableContentFont' align='center'>
                                            Message Status
                                        </StyledTableCell>
                                        <StyledTableCell className='tableContentFont' align='center'>
                                            Actions
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaignReports.map((campaignReport, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                <b>{(currentPage - 1) * 10 + index + 1}</b>
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                {campaignReport.receiverNumber}
                                            </StyledTableCell>

                                            <StyledTableCell className='tableContentFontForDateAndTime' align='center'>
                                                {calculateTime(campaignReport.sendTime)}
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                {campaignReport.provider && (
                                                    <>
                                                        {campaignReport.provider === "VI" && (
                                                            <img src={RCSLogo} height={"20px"} width={"20px"} title={campaignReport.provider} />
                                                        )}
                                                        {campaignReport.provider === "SMS" && (
                                                            <img src={SmsLogo} height={"20px"} width={"20px"} title={campaignReport.provider} />
                                                        )}
                                                    </>
                                                )}
                                            </StyledTableCell>

                                            <StyledTableCell className='tableContentFont' align='center'>
                                                {campaignReport.reason ?? "-"}
                                            </StyledTableCell>

                                            {/* <StyledTableCell
                                                    className="tableContentFontForDateAndTime"
                                                    align="center"
                                                >
                                                    {(campaignReport?.statusWithTime && campaignReport?.statusWithTime.PENDING) ? calculateTime(campaignReport?.statusWithTime.PENDING) : "-"}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="tableContentFontForDateAndTime"
                                                    align="center"
                                                >
                                                    {(campaignReport?.statusWithTime && campaignReport?.statusWithTime.RETRY) ? calculateTime(campaignReport?.statusWithTime.RETRY) : "-"}

                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="tableContentFontForDateAndTime"
                                                    align="center"
                                                >
                                                    {(campaignReport?.statusWithTime && campaignReport?.statusWithTime?.SENT) ? calculateTime(campaignReport?.statusWithTime?.SENT) : "-"}

                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="tableContentFontForDateAndTime"
                                                    align="center"
                                                >
                                                    {(campaignReport?.statusWithTime && campaignReport?.statusWithTime?.FAILED) ? calculateTime(campaignReport?.statusWithTime?.FAILED) : "-"}

                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="tableContentFontForDateAndTime"
                                                    align="center"
                                                >
                                                    {(campaignReport?.statusWithTime && campaignReport?.statusWithTime?.DELIVERED) ? calculateTime(campaignReport?.statusWithTime?.DELIVERED) : "-"}

                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="tableContentFontForDateAndTime"
                                                    align="center"
                                                >
                                                    {(campaignReport?.statusWithTime && campaignReport?.statusWithTime?.READ) ? calculateTime(campaignReport?.statusWithTime?.READ) : "-"}

                                                </StyledTableCell> */}

                                            <StyledTableCell className='tableContentFont' align='center'>
                                                {campaignReport?.statusWithTime ? (
                                                    <Typography
                                                        variant='subtitle2'
                                                        color={
                                                            messageLastStatus(campaignReport.statusWithTime) === MessageStatus.Failed
                                                                ? "error"
                                                                : messageLastStatus(campaignReport.statusWithTime) === MessageStatus.Delivered
                                                                    ? "green"
                                                                    : messageLastStatus(campaignReport.statusWithTime) === MessageStatus.Pending
                                                                        ? "orange"
                                                                        : messageLastStatus(campaignReport.statusWithTime) === MessageStatus.Sent
                                                                            ? "primary"
                                                                            : messageLastStatus(campaignReport.statusWithTime) === MessageStatus.Read
                                                                                ? "darkblue"
                                                                                : "black"
                                                        }
                                                    >
                                                        {messageLastStatus(campaignReport.statusWithTime)}
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        variant='subtitle2'
                                                        color={
                                                            campaignReport?.messageStatus === MessageStatus.Failed
                                                                ? "error"
                                                                : campaignReport?.messageStatus === MessageStatus.Delivered
                                                                    ? "green"
                                                                    : campaignReport?.messageStatus === MessageStatus.Pending
                                                                        ? "orange"
                                                                        : campaignReport?.messageStatus === MessageStatus.Sent
                                                                            ? "primary"
                                                                            : campaignReport?.messageStatus === MessageStatus.Read
                                                                                ? "darkblue"
                                                                                : "black"
                                                        }
                                                    >
                                                        {campaignReport?.messageStatus}
                                                    </Typography>
                                                )}
                                            </StyledTableCell>

                                            <StyledTableCell className='tableContentFont' align='center'>
                                                <Grid
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <IconButton title='View Message' onClick={() => ViewCampaignSimulator(campaignReport)}>
                                                        <AodIcon />
                                                    </IconButton>

                                                    {campaignReport?.statusWithTime && (
                                                        <Tooltip
                                                            sx={{
                                                                paddingLeft: 2,
                                                            }}
                                                            title={
                                                                <p
                                                                    style={{
                                                                        fontSize: ".7rem",
                                                                    }}
                                                                >
                                                                    {Object.values(MessageStatus).map(status => {
                                                                        return (
                                                                            <>
                                                                                {status} :{" "}
                                                                                {campaignReport?.statusWithTime[status]
                                                                                    ? calculateTime(campaignReport?.statusWithTime[status])
                                                                                    : "-"}
                                                                                <br />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </p>
                                                            }
                                                            className='cursor-pointer'
                                                        >
                                                            <InfoOutlinedIcon
                                                                sx={{
                                                                    opacity: "50%",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoRecords />
                    )}
                </CardContent>
                {openFilter && (
                    <FilterAllCampaignsReportsModel
                        open={openFilter}
                        searchParams={searchParams}
                        handleClickOpenFilterModel={handleClickOpenFilterModel}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setSearchParams={setSearchParams}
                        handleClickSearch={handleClickSearch}
                    />
                )}
            </Card>
            {showCampaignSimulator && (
                <CampaignSimulator
                    open={showCampaignSimulator}
                    setShowCampaignSimulator={setShowCampaignSimulator}
                    campaignDetails={campaignDetails}
                    campaignData={campaignData}
                    contentMessage={campaignDetails.messagePayload.payload.contentMessage}
                />
            )}
        </Grid>
    );
}
