import React, { useState, useEffect, useRef } from 'react';
import { MasterCardLayout } from '../Cards/MasterCardLayout';
import { Handle, Position } from 'react-flow-renderer';
import { ContextMenu } from '../ContextMenu';
import { generateUniqueIdForCard } from '../../../utils/workflow-utils/helpers';

export function ButtonNode(props) {

    const { data, id: buttonCardId, selected, type } = props;
    const methods = data.methods;

    return (
        <MasterCardLayout
            title={data.title}
            tailwindClass={data.cardTailwindStyle}
            key={`button-master-card-${buttonCardId}`}
            startNode={data?.isStartCard}
            hasError={data?.error}
            nodeData={data}
        >
            {
                selected === true &&
                // Show context menu
                <ContextMenu
                    onDuplicate={(event) => { methods.onDuplicate(event, buttonCardId) }}
                    onDelete={methods.onDelete}
                    nodeId={buttonCardId}
                    methods={methods}
                    isStartCard={data.isStartCard}
                />
            }
            <div className="text-updater-node" id={`${buttonCardId}`}>
                <Handle type="target" className='target-handle' position={Position.Left} />
                <div style={{ margin: '10px', fontSize: '10px' }}>
                    <div>
                        {
                            data && data.contents && data.contents.map((ele, idx) => {
                                return (
                                    <div className='p-2'>
                                        <button className='bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'> {ele.value} </button>
                                        <Handle id={ele.handleId} type="source" className='source-handle' position={Position.Right} style={{ top: `${(47 * idx) + 70}px` }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </MasterCardLayout>
    )
}
