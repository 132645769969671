import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowAddCampaignForm } from "../../redux/campaignManagment/campaignManagmentSlice";
import AddCampaign from "./AddCampaign";
import ListCampaigns from "./ListCampaigns";
import ViewCampaignDetails from "./ViewCampaignDetails";

function ManageCampaigns() {
    const state = useSelector((state) => state.campaignManagmentSlice);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [campaign, setCampaign] = useState({});
    const [showViewCampaignForm, setShowViewCampaignForm] = useState(false);
    const [searchParams, setSearchParams] = useState({
        botName: "",
        campaignName: "",
        clientName: "",
        templateName: "",
        createdAtStartDate: "",
        createdAtEndDate: "",
        executedAtStartDate: "",
        executedAtEndDate: "",
        status: "",
    });
    // const [showViewCampaignReport, setShowViewCampaignReport] = useState(false);

    // const handleShowReport = async (campaigndetails) => {
    // const response = await apiGatewayAxiosInstance.get(
    //         `logger/get-campaign-reports-by-campaignId`,
    //         {
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             params: {
    //                 campaignId: campaigndetails._id,
    //             },
    //         }
    //     );
    //     if (response.status === 200) {
    //         setCampaign(response.data.campaignListWithReportDetails[0]);
    //         console.log(response, "response");
    //         // dispatch(
    //         //     setCampaignReportDetails(
    //         //         response.data.campaignListWithReportDetails
    //         //     )
    //         // );
    //         // dispatch(setIsCampaignDetailsLoading(false));
    //         setShowViewCampaignReport(true);
    //     } else {
    //         throw new Error(response);
    //     }
    //     // setCampaign(campaigndetails);
    // };

    const handleViewCampaignDetails = (campaigndetails) => {
        setCampaign(campaigndetails);
        setShowViewCampaignForm(true);
    };

    useEffect(() => {
        dispatch(setShowAddCampaignForm(false));
    }, []);

    return (
        <Grid>
            {/* {showViewCampaignReport ? (
                <ViewCampaignReport
                    campaign={campaign}
                    setShowViewCampaignReport={setShowViewCampaignReport}
                />
            ) : ( */}
            <>
                {showViewCampaignForm ? (
                    <ViewCampaignDetails
                        campaign={campaign}
                        setShowViewCampaignForm={setShowViewCampaignForm}
                    />
                ) : (
                    <>
                        {!state.controls.showAddCampaignForm ? (
                            <ListCampaigns
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                handleViewCampaignDetails={
                                    handleViewCampaignDetails
                                }
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            // handleShowReport={handleShowReport}
                            />
                        ) : (
                            <AddCampaign />
                        )}
                    </>
                )}{" "}
            </>
            {/* )} */}
        </Grid>
    );
}

export default ManageCampaigns;
