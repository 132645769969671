import { FormControl, Grid, MenuItem, Pagination, Select, TableFooter, Typography } from '@mui/material';
import React from 'react'

export default function TablePaginationShowPage({ rowsPerPageOptions, colSpan, count,
    rowsPerPage,
    page,

    onPageChange,
    onRowsPerPageChange }) {


    return (
        <div>

            <TableFooter
                sx={{ display: "flex", alignItems: "center", gap: "20px", justifyContent:"space-between" }}
            >
                <Grid display={"flex"} gap={2} alignItems={"center"}>
                    <Typography variant="body2">Rows per page:</Typography>
                    <FormControl fullWidth={false} >
                        <Select
                        
                            id="row-per-page-component"
                            labelId="row-per-page-label"
                            name={"Page size"}
                            value={rowsPerPage}
                            onChange={(event) => {
                                onRowsPerPageChange(event);
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 15px 15px 2px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px",
                                    },
                                },
                            }}
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                                borderRadius: "8px",
                                width: "70px",
                            }}
                            size="small"
                        >
                            {rowsPerPageOptions.map((value) => (

                                <MenuItem value={value}>{value}</MenuItem>
                            ))}
                            {/* <MenuItem value={"10"}>10</MenuItem>
                            <MenuItem value={"15"}>15</MenuItem>
                            <MenuItem value={"20"}>20</MenuItem>
                            <MenuItem value={"25"}>25</MenuItem>
                            <MenuItem value={"50"}>50</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
                <Pagination
                    m={2}
                    mb={4}
                    count={Math.ceil(count / rowsPerPage)}
                    page={page + 1}
                    size="medium"
                    className="float-end"
                    // variant='outlined'
                    color="primary"
                    onChange={(e, pages) => onPageChange(e, pages - 1)}
                />
            </TableFooter>

        </div>
    )
}
