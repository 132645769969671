import { Grid, Typography } from '@mui/material'
import React from 'react'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';

export default function NoRecords() {
    return (
        <Grid sx={{ width: "100% !important" }} className='no-records'>
            <InboxOutlinedIcon fontSize='large' />
            <Typography variant="h3">
                No records found
            </Typography>
        </Grid>
    )
}
