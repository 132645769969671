import React, { useState } from 'react';
import { MasterCardLayout } from './MasterCardLayout';
import { ContextMenu } from '../ContextMenu';
import { Handle, Position } from 'react-flow-renderer';
import deleteImage from '../../../assets/icons/delete-icon.svg'
import { generateUniqueIdForCard } from '../../../utils/workflow-utils/helpers';



export function ChannelSwitchConditionNode(props) {
    const { selected, data, type, id: channelSwitchNodeId } = props;
    const methods = data.methods;

    return (
        <MasterCardLayout
            title={data.title}
            tailwindClass={data.cardTailwindStyle}
            key={`channel-master-card-${channelSwitchNodeId}`}
            startNode={data?.isStartCard}
            nodeData={data}
        >
            {
                selected === true &&
                // Show context menu
                <ContextMenu
                    onDuplicate={(event) => { methods.onDuplicate(event, channelSwitchNodeId) }}
                    onDelete={methods.onDelete}
                    nodeId={channelSwitchNodeId}
                    methods={methods}
                    isStartCard={data.isStartCard}
                />
            }
            <div className="text-updater-node" id={`${channelSwitchNodeId}`}>
                {
                    data.isStartCard === false &&
                    <Handle className='target-handle' type="target" position={Position.Left} />
                }
                <div className='p-2 flex-col justify-center'>
                    {
                        data.channels.map((channel, idx) => {
                            return (
                                <div key={`channel-switch-${channelSwitchNodeId}_${idx}`}>
                                    <div className='m-2 p-2 border border-gray-400 rounded text-center'>
                                        {channel.channelName}
                                    </div>
                                    <Handle
                                        id={channel.handleId}
                                        key={`${channel.handleId}_${idx}`}
                                        type="source"
                                        className='source-handle'
                                        position={Position.Right}
                                        style={{ top: `${(50 * idx) + 73}px` }}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </MasterCardLayout>
    );
}

