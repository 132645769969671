import React, { useRef, useState, useEffect } from 'react';
import { MasterCardLayout } from './MasterCardLayout';
import { ContextMenu } from '../ContextMenu';
import { Handle, Position } from 'react-flow-renderer';
import deleteImage from '../../../assets/icons/delete-icon.svg'
import { generateUniqueIdForCard, getConditions } from '../../../utils/workflow-utils/helpers';
import { TextEditorReadOnly } from '../TextEditor/TextEditorReadOnly';


export function SwitchNode(props) {
    const conditions = getConditions();
    const { selected, data, type, id: channelSwitchNodeId } = props;
    const methods = data.methods;

    const [heightOfQuestionDiv, setHeightOfQuestionDiv] = useState();

    const questionDivRef = useRef();


    const getOperationName = (keyName) => {
        const result = conditions.find((item) => item.key === keyName);
        return result ? result : ""
    };

    useEffect(() => {
        setHeightOfQuestionDiv(questionDivRef.current.scrollHeight);
    }, [props])


    return (
        <MasterCardLayout
            title={data.title}
            tailwindClass={data.cardTailwindStyle}
            key={`channel-master-card-${channelSwitchNodeId}`}
            startNode={data?.isStartCard}
            nodeData={data}
        >
            {
                selected === true &&
                // Show context menu
                <ContextMenu
                    onDuplicate={(event) => { methods.onDuplicate(event, channelSwitchNodeId) }}
                    onDelete={methods.onDelete}
                    nodeId={channelSwitchNodeId}
                    methods={methods}
                    isStartCard={data.isStartCard}
                />
            }
            <div className="text-updater-node" id={`${channelSwitchNodeId}`}>
                <Handle className='target-handle' type="target" position={Position.Left} />
                <div className='p-2 flex-col justify-center' ref={questionDivRef}>
                    <TextEditorReadOnly
                        contents={data.contents?.question}
                    />
                </div>
                <div className='p-2 flex-col justify-center'>
                    {
                        Array.isArray(data.contents?.conditions) && data.contents.conditions.map((con, idx) => {
                            return (
                                <div key={`channel-switch-${channelSwitchNodeId}_${idx}`}>
                                    <div className='m-2 p-2 border border-gray-400 rounded text-center'>
                                        {getOperationName(con.conditionType).value} {con.compareValue}
                                    </div>
                                    <Handle
                                        id={`${channelSwitchNodeId}_${idx}`}
                                        type="source" className='source-handle'
                                        position={Position.Right}
                                        style={{ top: `${(50 * idx) + 75 + heightOfQuestionDiv}px` }}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </MasterCardLayout>
    );
}

