import styled from "@emotion/styled";
import { Formik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    Alert as MuiAlert,
    TextField as MuiTextField,
    OutlinedInput,
} from "@mui/material";
import { spacing } from "@mui/system";

import { VisibilityOffOutlined, VisibilityOffRounded } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
    const { signIn } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");

    const handleClickShowPassword = () => setShowPassword(show => !show);

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    return (
        <Formik
            initialValues={{
                userName: "",
                password: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                userName: Yup.string().max(255).required("Username is required"),
                password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    const isLoggedIn = await signIn(values.userName, values.password);
                    if (isLoggedIn) {
                        navigate("/dashboard/");
                    }
                } catch (error) {
                    const message = error.message || "Something went wrong";

                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {/* <Alert mt={3} mb={3} severity="info">
            Use <strong>demo@bootlab.io</strong> and{" "}
            <strong>unsafepassword</strong> to sign in
          </Alert> */}
                    {errors.submit && (
                        <Alert mt={2} mb={3} severity='warning'>
                            {errors.submit}
                        </Alert>
                    )}
                    <TextField
                        name='userName'
                        label='Username'
                        value={values.userName}
                        autocomplete="new-password"
                        // focused

                        inputProps={{
                            autocomplete: 'new-password',
                            form: {
                                autocomplete: 'off',
                            },
                        }}

                        error={Boolean(touched.userName && errors.userName)}
                        fullWidth
                        helperText={touched.userName && errors.userName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={2}
                    />
                    <FormControl fullWidth variant='outlined' className='mt-3' error={Boolean(touched.password && errors.password)}>
                        <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
                        <OutlinedInput
                            id='outlined-adornment-password'
                            type={showPassword ? "text" : "password"}
                            name='password'
                            label='Password'
                            value={values.password}
                            error={Boolean(touched.password && errors.password)}
                            fullWidth
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}


                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge='end'
                                    >
                                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOffRounded />}
                                    </IconButton>
                                </InputAdornment>
                            }

                            // helperText={touched.password && errors.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={2}
                        />
                        <FormHelperText>{touched.password && errors.password}</FormHelperText>
                    </FormControl>

                    {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
                    <Button type='submit' fullWidth variant='contained' color='primary' className='mt-3' disabled={isSubmitting}>
                        Sign in
                    </Button>
                    <Button component={Link} to='/auth/reset-password' fullWidth color='primary' className='mt-3'>
                        Forgot password
                    </Button>
                    {/* <p>We have just sent an email with a password resent link to email@gmail.com</p> */}
                </form>
            )}
        </Formik>
    );
}

export default SignIn;
