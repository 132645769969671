import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { blue, red } from '@mui/material/colors'
import React from 'react'

export default function BillingPaymentRadioButtons({ onChangeRadio, isDisabled, billingPaymentType }) {
    return (
        <FormControl className='field-spacing' sx={{ display: "flex", color: "grey" }}>
            <FormLabel id="demo-controlled-radio-buttons-group" >
                <Typography variant="subtitle2">
                    Select Billing Type:
                </Typography>
            </FormLabel>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                sx={{ display: "flex !important", WebkitFlexDirection: "row", gap: "50px" }}
                defaultValue="female"
                name="radio-buttons-group"
            >
                <FormControlLabel
                    value="Prepaid"
                    disabled={isDisabled}
                    control={
                        <Radio
                            checked={billingPaymentType === "Prepaid" ? true : false}
                            color="primary"
                            sx={{
                                color: blue[800],
                                '&.Mui-checked': {
                                    color: blue[600],
                                },
                            }}
                            size='small'
                        />
                    }
                    label={<Typography variant="subtitle2">Prepaid</Typography>}
                    sx={{ color: blue[800] }}
                    onChange={(e) => onChangeRadio("billingPaymentType", e)}

                />

                <FormControlLabel
                    value="Postpaid"
                    disabled={isDisabled}
                    control={
                        <Radio
                            checked={billingPaymentType === "Postpaid" ? true : false}
                            sx={{
                                color: blue[800],
                                '&.Mui-checked': {
                                    color: blue[600],
                                },
                            }}
                            size='small'
                        />
                    }
                    label={<Typography variant="subtitle2">Postpaid</Typography>}
                    sx={{ color: blue[800] }}
                    onChange={(e) => onChangeRadio("billingPaymentType", e)}

                />
            </RadioGroup>
        </FormControl>
    )
}
