import { Typography } from "@mui/material";

export const calculateTime = (value) => {
    const time = new Date(value).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    });
    const day = new Date(value).toLocaleDateString([], { day: "2-digit" });
    const month = new Date(value).toLocaleDateString([], { month: "2-digit" });
    const year = new Date(value).toLocaleDateString([], { year: "numeric" });

    return (<Typography className="tableContentFontForDateAndTime">
        {day}-{month}-{year} <br />
        {time}
    </Typography>)
    // return (day + "-" + month + "-" + year + " , " + time);
};