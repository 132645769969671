import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearErrorMessagesForBots,
    onFailureGetVerifiedBotDetails,
    setEditViewVerifyBotDetailsForm,
    setShowAddBotForm,
    setShowVerifyBotForm,
    setShowVerifyClientSecretForm,
    setShowViewVerifyBotDetailsForm,
    setShowAddTestDeviceForm,
} from "../../redux/botManagement/botManagementSlice";
import { apiGatewayAxiosInstance } from "../../utils/axios";
import AlertMessageComponent from "../components/AlertMessageComponent";
import BotVerificationDetails from "./actions/BotVerificationDetails";
import VerifyBot from "./actions/VerifyBot";
import VerifyClientSecret from "./actions/VerifyClientSecret";
import ManageTestDevices from "./actions/testDevice/manageTestDevice";
import AddBot from "./addBot";
import ListBots from "./listBots";

function ManageBots() {
    const state = useSelector(state => state.botManagementSlice);
    const [botDetailsToShow, setBotDetailsToShow] = useState(null);
    const [editable, setEditable] = useState(true);
    const [botDetailsEditable, setBotDetailsEditable] = useState(false);
    const [botVerificationDetails, setBotVerificationDetails] = useState(null);
    const [brandAndBusinessVerificationDetails, setBrandAndBusinessVerificationDetails] = useState(null);
    const [loadingDetailsSpinner, setLoadingDetailsSpinner] = useState(true);
    const [loadingDetailsErrorMessage, setLoadingDetailsErrorMessage] = useState(null);
    const dispatch = useDispatch();
    const [brandDetails, setBrandDetails] = useState({});
    const [brandVerified, setBrandVerified] = useState(false);
    const [searchParams, setSearchParams] = useState({
        botName: "",
        brandName: "",
        clientName: "",
        botMessageType: "",
        status: "",
    });


    const getBrandDetails = async (brandId, id) => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/bot_management/brand/get-brand-by-id`, {
                headers: {
                    "Content-Type": "application/json",
                    brandId: brandId,
                },
            });
            setBrandDetails(response.data.brandDetails);
            if (response.data.brandDetails.brandStatus === "Verified") {
                setBrandVerified(true);
            }
        } catch (error) {
            let errorMessage = "Failed to schedule campaign.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(onFailureGetVerifiedBotDetails(errorMessage));
        }
    };

    const verifyBotDetails = async ({ id, botStatus, name, isEditable, brandId }) => {
        try {
            getBrandDetails(brandId, id);
            // setLoadingDetailsSpinner(true);
            const botId = id;
            const response = await apiGatewayAxiosInstance.get(`/bot_management/bot/get-verify-bot`, { params: { botId } });
            if (response.status === 200) {
                if (isEditable) {
                    const verificationDetailsToSet = response.data.data.verifyBotDetails.verificationDetails;
                    setBotVerificationDetails({ verificationDetails: verificationDetailsToSet, botStatus: botStatus });
                    const brandDetailsToSet = response.data.data.brandDetails;
                    setBrandAndBusinessVerificationDetails(brandDetailsToSet);

                    dispatch(
                        setEditViewVerifyBotDetailsForm({
                            status: true,
                            botId: id,
                            botName: name,
                            botStatus,
                            isEditable: true,
                        }),
                    );
                } else {
                    const brandDetailsToSet = response.data.data.brandDetails;
                    setBrandAndBusinessVerificationDetails(brandDetailsToSet);
                    dispatch(
                        setShowViewVerifyBotDetailsForm({
                            status: true,
                            botId: id,
                            botName: name,
                            isEditable: false,
                        }),
                    );
                }
                // setLoadingDetailsSpinner(false);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            setLoadingDetailsSpinner(false);
            let errorMessage = "Failed to schedule campaign.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setLoadingDetailsErrorMessage(errorMessage);
        }
    };
    useEffect(() => {
        // verifyBotDetails();
        dispatch(setShowVerifyBotForm({ status: false, botId: "" }));
        dispatch(setShowVerifyClientSecretForm({ status: false, botId: "" }));
        setBotDetailsToShow(null);
        dispatch(setShowAddTestDeviceForm({ status: false, botId: "" }));
        dispatch(setShowAddBotForm(false));
        state.controls.showEditVerifyBotDetailsForm.isEditable && setBotDetailsEditable(true);
    }, []);

    const handleCurrentBotDetails = ({ botDetails, val }) => {
        setBotDetailsToShow(botDetails);
        setEditable(val);
        dispatch(setShowAddBotForm(true));
    };

    const handleVerifyBotDetails = async (id, brandId) => {
        await getBrandDetails(brandId, id);
        dispatch(setShowVerifyBotForm({ status: true, botId: id }));
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForBots({ fieldName }));
    };

    const handleIsEditable = () => {
        setEditable(true);
    };

    return (
        <div>

            {state.controls.showAddBotForm ? (
                <AddBot botDetails={botDetailsToShow} setBotDetailsToShow={setBotDetailsToShow} isBotEditable={editable} setEditable={setEditable} />
            ) : state.controls.showVerifyClientSecretForm.status === true && state.controls.showVerifyClientSecretForm.botId !== "" ? (
                <VerifyClientSecret />
            ) : state.controls.showVerifyBotForm.status === true && state.controls.showVerifyBotForm.botId !== "" ? (
                <VerifyBot
                    brandDetails={brandDetails}
                    brandVerified={brandVerified}
                    botVerificationDetails={null}
                    brandAndBusinessVerificationDetails={null}
                />
            ) : state.controls.showViewVerifyBotDetailsForm.status === true && state.controls.showViewVerifyBotDetailsForm.botId !== "" ? (
                <BotVerificationDetails />
            ) :
            
            // state.controls.showAddTestDeviceForm.status === true && state.controls.showAddTestDeviceForm.botId !== "" ? (
            //     <ManageTestDevices botId={state.controls.showAddTestDeviceForm.botId} />
            // ) :
            
            state.controls.showEditVerifyBotDetailsForm.status === true && state.controls.showEditVerifyBotDetailsForm.botId !== "" ? (
                //    && botVerificationDetails
                <VerifyBot
                    brandDetails={brandDetails}
                    brandVerified={brandVerified}
                    botVerificationDetails={botVerificationDetails}
                    brandAndBusinessVerificationDetails={brandAndBusinessVerificationDetails}
                />
            ) : (
                <ListBots
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    handleCurrentBotDetails={handleCurrentBotDetails}
                    handleVerifyBotDetails={handleVerifyBotDetails}
                    verifyBotDetails={verifyBotDetails}
                />
            )}

            <AlertMessageComponent
                message={state.controls.getVerifiedBrandDetailsErrorMessage}
                fieldName={"getVerifiedBrandDetailsErrorMessage"}
                handleClose={onCloseErrorMessage}
                show={Boolean(state.controls.getVerifiedBrandDetailsErrorMessage)}
            />
        </div>
    );
}

export default ManageBots;
