import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import React, { useEffect, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { canvasPreview } from "../../utils/canvasPreview";
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}


export default function FilePreviewComponent({
  type,
  url,
  previewImage,
  deleteFile,
  width,
  height,
  formikImage,
  isDisabled,
  aspect,
  onSelectImage,
  fieldName,
  imageHeightRatio,
  imageWidthtRatio
}) {

  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    width: 100,
    height: 100,
    x: 0,
    y: 0
  }); // You can set your own aspect ratio here
  const [completeCrop, setCompleteCrop] = useState(null);
  const [isCropImageSelected, setIsCropImageSelected] = useState(false);
  const [originImageHeight, setOriginImageHeight] = useState(0);
  const [originImageWidth, setOriginImageWidth] = useState(0);
  const [reload, setReload] = useState(true);

  const imgRef = useRef();
  const previewCanvasRef = useRef();
  const previewCanvasRef2 = useRef();
  const hiddenAnchorRef = useRef();
  const blobUrlRef = useRef('');
  const supportedImageMimeTypeArray = [
    "image/jpeg",
    "image/png",
    "image/jpg"
  ];

  const onChangeCropImage = (e) => {
    e.unit = "px";
    e.height = e.height;
    e.width = e.height * aspect;
    setCrop(e);
  }


  const onCompleteCropImage = (e) => {
    setCompleteCrop(e);
  }



  useEffect(() => {
    if (completeCrop?.width && completeCrop?.height && imgRef.current && previewCanvasRef.current) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completeCrop, 1, 0)
    };

  }, [completeCrop]);


  const onImageLoad = (e) => {
    if (aspect) {
      const { height, width } = e.currentTarget;

      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function findGCD(a, b) {
    return b === 0 ? a : findGCD(b, a % b);
  }

  const getHeightAndWidth = () => {
    const imageData = new Image();

    imageData.src = url;

    let widthData = imageData.width;
    let heightData = imageData.height;
    const gcd = findGCD(widthData, heightData);
    const widthRatio = widthData / gcd;
    const heightRatio = heightData / gcd;

    let numberMultiple = 1;
    if (heightRatio > widthRatio) {
      // while (heightRatio * numberMultiple < 200) {
      //   numberMultiple = numberMultiple + 1;
      // }
      // setOriginImageHeight(numberMultiple * heightRatio);
      // setOriginImageWidth(numberMultiple * widthRatio);
      setOriginImageHeight(300);

      setOriginImageWidth((widthRatio * 300) / heightRatio);


    } else {
      // while (widthRatio * numberMultiple < 200) {
      //   numberMultiple = numberMultiple + 1;
      // }
      setOriginImageHeight((heightRatio * 300) / widthRatio);

      setOriginImageWidth(300);
      // setOriginImageHeight(numberMultiple * heightRatio);
      // setOriginImageWidth(numberMultiple * widthRatio);
    }




  }


  useEffect(() => { getHeightAndWidth(); }, [url]);

  const onSelectCropImage = async () => {
    setIsCropImageSelected(true);

    const { file, url } = await getBlobUrlOfCroppedImage();
    onSelectImage({ file, url, fieldName });
  }

  function convertToFraction(decimalNumber) {
    // Multiply the decimal number by a power of 10 to eliminate the decimal part
    let widthRatio = decimalNumber * 10;
    let heightRatio = 10;

    // Find the greatest common divisor (GCD) of the widthRatio and heightRatio
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const commonDivisor = gcd(widthRatio, heightRatio);

    // Divide both the widthRatio and heightRatio by the GCD to simplify the fraction
    widthRatio /= commonDivisor;
    heightRatio /= commonDivisor;

    // Output the fraction as a string
    return { widthRatio, heightRatio };
  }

  const getBlobUrlOfCroppedImage = async () => {
    const image = imgRef.current;
    let previewCanvas = previewCanvasRef.current;
    // let previewCanvas2 = structuredClone(previewCanvasRef.current);
    if (!image || !previewCanvas || !completeCrop) {
      throw new Error('Crop canvas does not exist')
    }

    let canvas = document.createElement('canvas');


    const { widthRatio, heightRatio } = { widthRatio: imageWidthtRatio, heightRatio: imageHeightRatio };



    let numberMultiple = 1;

    let commonNumber = 1000;
    if (fieldName) {
      if (!(fieldName.includes("thumbnail") || fieldName.includes("Thumbnail"))) {

        if (fieldName.includes("richCardStandAlone")) {
          commonNumber = 1000
        } else {
          commonNumber = 700
        }
      } else {
        commonNumber = 200
      }

    }

    if (heightRatio > widthRatio) {
      while (heightRatio * numberMultiple < commonNumber) {
        numberMultiple = numberMultiple + 1;
      }


    } else {
      while (widthRatio * numberMultiple < commonNumber) {
        numberMultiple = numberMultiple + 1;
      }

    }
    console.log(commonNumber, (heightRatio * numberMultiple * widthRatio * numberMultiple));


    // size = 1000



    canvas.height = heightRatio * numberMultiple;

    canvas.width = widthRatio * numberMultiple;
    // document.body.appendChild(canvas);


    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      widthRatio * numberMultiple,
      heightRatio * numberMultiple,
    )
    // previewCanvas.height = 400;
    // previewCanvas.width = 500;

    const blob = await new Promise(resolve => {
      canvas.toBlob(resolve, 'image/png');
    });
    const fileName = previewImage.file.name.replace(/[^.]+$/, 'png');

    const file = new File([blob], fileName, { type: 'image/png', lastModified: new Date().getTime() });
    // return URL.createObjectURL(blob)
    let url = URL.createObjectURL(blob)
    return { file, url };
  }

  const onClickDeleteFile = () => {

    deleteFile();
    setCompleteCrop(null);
    setIsCropImageSelected(false);
  }
  return (
    <Grid >
      {(url !== null || formikImage?.url) ? (
        <Box display={"flex"} alignItems={"flex-start"}>
          <Card style={{ padding: "0px", marginBottom: "10px" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingLeft: "10px",
                paddingRight: "0px",
              }}
            >
              <Grid sx={{ alignItems: "center", textAlign: "end" }}>
                {!isDisabled ?

                  <IconButton
                    size="large"
                    style={{ margin: "0px" }}
                    onClick={() => onClickDeleteFile()}
                  >
                    <ClearIcon className="text-danger " />
                  </IconButton>
                  :
                  <Button startIcon={<ContentCopyIcon />} variant="outlined" size="medium">
                    <CopyToClipboard text={formikImage?.url}>
                      <span>Copy Url</span>
                    </CopyToClipboard>
                  </Button>
                }
              </Grid>

              <Grid className="p-1 border" >
                {(supportedImageMimeTypeArray.includes(type) || supportedImageMimeTypeArray.includes(formikImage?.type)) ? (
                  <>
                    {formikImage?.url ?



                      <img
                        // maxWidth={"100px"}
                        // maxHeight={"500px"}

                        src={formikImage?.url}
                        style={{ objectFit: "cover", maxWidth: "500px", maxHeight: height ?? "500px" }}
                        alt=""

                      />


                      :
                      <ReactCrop
                        aspect={aspect}
                        crop={crop}
                        onChange={(pixelCrop, percentCrop) => onChangeCropImage(pixelCrop)}
                        onComplete={(pixelCrop, percentCrop) => onCompleteCropImage(pixelCrop)}

                      >

                        <img onLoad={onImageLoad} ref={imgRef} src={url}
                          // height={"146px"} width={"438px"}
                          height={originImageHeight}
                          width={originImageWidth}
                        />

                      </ReactCrop>
                    }
                  </>
                ) : (type == "image/gif" || formikImage?.type === "image/gif") ? (
                  <>
                    <img
                      width={"438px"}
                      height={"146px"}
                      src={formikImage?.url}
                      style={{ objectFit: "contain" }}
                      alt="gif"
                    />
                  </>
                ) : (type === "video/mp4" || formikImage?.type === "video/mp4") && (
                  <Grid display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
                    {/* <PlayCircleRoundedIcon fontSize="large"/>
                    <Typography variant="subtitle1">
                      {previewImage.file.name}
                    </Typography> */}
                    <video width="350" height="150" controls >
                      <source src={formikImage?.url} type={type ?? formikImage?.type} />
                    </video>
                    <Grid display={"flex"} alignItems={"center"}>
                      {/* <PlayCircleOutlinedIcon fontSize="medium" /> &nbsp; &nbsp; */}
                      {/* <Typography variant="subtitle1">
                        {previewImage.file.name}
                      </Typography> */}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {
                // !!completeCrop &&
                !isCropImageSelected &&

                <Button
                  variant="contained"
                  disabled={!completeCrop || (completeCrop.height === 0 || completeCrop.width === 0)}
                  onClick={() => onSelectCropImage()} >
                  Select
                </Button>
              }
            </CardContent>
          </Card>
        </Box>
      ) : (
        <></>
      )}
      {!!completeCrop && !isCropImageSelected && (
        <>
          <canvas
            id="canvasId"
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              width: completeCrop.width,
              aspectRatio: aspect,
              height: completeCrop.height,
            }}

          />

        </>
      )}
    </Grid>
  );

}
